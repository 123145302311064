import { decomposeColor, recomposeColor } from '@material-ui/core';

export function setScrollbarTheme(isLight: boolean) {
    const color = isLight ? '#ffffff' : '#293056';
    setScrollbarColor(color);
}

export function setScrollbarColor(color: string) {
    const hoverAlpha = 0.8;
    const normalAlpha = 0.5;
    const decomposedColor = decomposeColor(color);
    const hoverColor = recomposeColor({
        type: 'rgba',
        values: [decomposedColor.values[0], decomposedColor.values[1], decomposedColor.values[2], hoverAlpha],
    });
    const normalColor = recomposeColor({
        type: 'rgba',
        values: [decomposedColor.values[0], decomposedColor.values[1], decomposedColor.values[2], normalAlpha],
    });

    const hoverKey = '--hover-scrollbar-color';
    const normalKey = '--scrollbar-color';

    document.documentElement.style.setProperty(hoverKey, hoverColor);
    document.documentElement.style.setProperty(normalKey, normalColor);
}
export function toggleCssScrollbar(enable: boolean) {
    return {
        '&::-webkit-scrollbar': {
            width: enable ? 20 : 0,
        },
    };
}
