import styled from '@emotion/styled';
import React, { useReducer } from 'react';
import DateTimePicker from 'react-datetime-picker';

import { Button } from '@/component-library';
import TextInput from '@/component-library/components/TextInput';
import TypographyV2 from '@/component-library/typography/TypographyV2';

import { NewsArticleRichEditor } from './NewsArticleRichEditor';
import { NewsArticle } from './useNews';

export type NewsArticleFormState = Pick<NewsArticle, 'date' | 'tag' | 'shortDescription' | 'description'>;

export interface NewsArticleFormProps {
    initialState?: NewsArticleFormState;
    onSubmit: (article: NewsArticleFormState) => void;
}

export const NewsArticleForm = (props: NewsArticleFormProps) => {
    const { onSubmit, initialState = {} } = props;

    const [form, update] = useReducer(
        (prev: NewsArticleFormState, next: Partial<NewsArticleFormState>): NewsArticleFormState => ({
            ...prev,
            ...next,
        }),
        initialState,
        (initial: NewsArticleFormState): NewsArticleFormState => ({
            ...initial,
            date: initial.date || new Date(),
        }),
    );

    const submit = () => {
        onSubmit(form);
    };

    return (
        <div>
            <TextInput
                variant="outlined"
                size="small"
                name="tag"
                heading="Tag"
                onChange={(e) => update({ tag: e.target.value })}
                value={form.tag}
            />

            <TextInput
                variant="outlined"
                size="small"
                name="shortDescription"
                heading="Short description"
                onChange={(e) => update({ shortDescription: e.target.value })}
                value={form.shortDescription}
            />

            <NewsArticleRichEditor
                value={form.description}
                onChange={(description) => update({ description: description })}
                isEditable
            />

            <DateField>
                <TypographyV2>Date of post</TypographyV2>
                <DateTimePicker value={form.date} onChange={(e: Date) => update({ date: e })} locale="en-GB" />
            </DateField>

            <Button onClick={() => submit()} variant="solid-blue" size={'l'}>
                Submit
            </Button>
        </div>
    );
};

const DateField = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;
