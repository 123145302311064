import styled from '@emotion/styled';
import React from 'react';
import { SessionScoreModality } from 'wavepaths-shared/core';

export interface ModalityIconProps {
    modality: SessionScoreModality;
}

const Container = styled.div`
    width: 24px;
    height: 24px;
    display: grid;
    place-content: center;
    // padding-bottom: 2px;
`;

const Icon = styled.svg`
    width: 24px;
    height: 24px;
`;

const IconText = styled.text`
    fill: #667085;
    font: bold 10px 'Tenor Sans';
};
`;

const Background = styled.rect`
    fill: #f2f4f7;
`;

const modalityAbbreviation: { [key in SessionScoreModality]: string } = {
    [SessionScoreModality.KETAMINE]: 'K',
    [SessionScoreModality.PSILOCYBIN]: 'Psi',
    [SessionScoreModality.MDMA]: 'M',
    [SessionScoreModality.IBOGAINE]: 'Ibo',
    [SessionScoreModality.PSYCHOTHERAPY]: 'Psy',
    [SessionScoreModality.BREATHWORK]: 'BW',
    [SessionScoreModality.FLOATATION]: 'FT',
    [SessionScoreModality.WELLNESS]: 'W',
    [SessionScoreModality.CLIENT_PREPARATION]: 'CP',
    [SessionScoreModality.AMBIENCE]: 'Amb',
    [SessionScoreModality.NONE]: '-',
};

const ModalityIcon: React.FC<ModalityIconProps> = ({ modality }) => {
    return (
        <Container>
            <Icon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <Background x="0" y="0" width="20" height="20" rx="2" />
                <IconText dominantBaseline="middle" textAnchor="middle" x="50%" y="50%">
                    {modalityAbbreviation[modality]}
                </IconText>
            </Icon>
        </Container>
    );
};

export default ModalityIcon;
