import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    Depth,
    getIntensityForStageAndDirection,
    PathType,
    RequestType,
    SessionEventType,
} from 'wavepaths-shared/core';
import { getTimestampedStagesForWave } from 'wavepaths-shared/domain/wavepath';

import { Connection, useRemoteCurrentWave, useRemoteElapsedTimeSecs } from '@/hooks/useSessionTick';

import UserEvents from '../../../UserEvents';
import { Queueable } from '../actionQueue/useActionQueue';
import { RemoteSessionControlsContext } from '../Guide';

// type ReadProps = {
//     targetDepth?: TickDepth;
//     currentDepth?: TickDepth;
//     transitionTimeSecs?: number;
// };

// type ReadAndWrite = ReadProps & { setTargetDepth?: ({depth: Depth; extraMins?: number}) => void; error?: string | null };

const DEFAULT_TRANSITION_TIME = 45;

export function useRemoteDepth(connection: Connection, queueFunction: (queueable: Queueable) => void) {
    const remoteSession = useContext(RemoteSessionControlsContext);

    const currentWave = useRemoteCurrentWave(remoteSession?.sessionScore?.wavepaths ?? null);

    const elapsedTimeSecs = useRemoteElapsedTimeSecs();
    const [_currentDepth, setCurrentDepth] = useState<number | undefined>(undefined);
    const [_targetDepth, _setTargetDepth] = useState<number | undefined>(undefined);
    const targetDepthLockedForTransition = useRef<boolean>(false);
    const targetDepthTimer = useRef<NodeJS.Timeout | undefined>(undefined);
    const currentWaveStagesTimestamped = useRef<ReturnType<typeof getTimestampedStagesForWave> | undefined>(undefined);
    useEffect(() => {
        if (currentWave.wave) {
            currentWaveStagesTimestamped.current = getTimestampedStagesForWave(currentWave.wave);
        } else {
            currentWaveStagesTimestamped.current = undefined;
        }
    }, [currentWave.wave]);

    useEffect(() => {
        // console.debug({
        //     currentWaveStagesTimestamped: currentWaveStagesTimestamped.current,
        //     currentWave: currentWave.wave,
        //     elapsedTimeSecs,
        // });
        let newDepth: Depth | undefined = undefined;
        if (currentWave.wave !== null && currentWaveStagesTimestamped.current !== undefined) {
            const currentStage = currentWaveStagesTimestamped.current.find(
                (x) => x.toTimeStamp > elapsedTimeSecs * 1000,
            );
            if (currentWave.wave.pathScore.type === PathType.GENERATIVE && currentStage !== undefined) {
                const currentStagePreset = remoteSession?.session?.presets.find((x) => x.preset == currentStage.preset);
                if (currentStagePreset) {
                    //console.debug({ currentStagePreset, currentStage });
                    newDepth = getIntensityForStageAndDirection({
                        stage: currentStage.stage,
                        direction: currentStagePreset.contentPreset,
                    });
                } else {
                    newDepth = undefined;
                }
            } else {
                newDepth = undefined;
            }
        } else {
            newDepth = undefined;
        }
        if (newDepth !== _currentDepth) {
            //console.debug('Set current depth to', { newDepth, _currentDepth });
            setCurrentDepth(newDepth);
            if (!targetDepthLockedForTransition.current) {
                _setTargetDepth(newDepth);
            }
        }
    }, [elapsedTimeSecs, currentWave.wave, targetDepthLockedForTransition.current]);

    const transitionTimeSecs = DEFAULT_TRANSITION_TIME;
    const isLoading = currentWave.wave === null;
    const [_depthChangeError, _setDepthChangeError] = useState<string | null>(null);

    const readProps = useMemo(
        () => ({
            targetDepth: _targetDepth,
            currentDepth: _currentDepth,
            error: _depthChangeError,
            transitionTimeSecs,
        }),
        [_currentDepth, _depthChangeError, transitionTimeSecs, _targetDepth],
    );

    const setTargetDepth = useCallback(
        (depthRequest: { depth: Depth; extraMins?: number }) => {
            _setDepthChangeError(null);
            if (queueFunction) {
                queueFunction({
                    description: 'Holding in intensity',
                    callback: () => {
                        targetDepthLockedForTransition.current = true;
                        connection.sendRequest({ type: RequestType.MoveToDepth, ...depthRequest });
                        targetDepthTimer.current && clearTimeout(targetDepthTimer.current);
                        targetDepthTimer.current = setTimeout(() => {
                            targetDepthTimer.current = undefined;
                            targetDepthLockedForTransition.current = false;
                        }, transitionTimeSecs);
                        _setTargetDepth(depthRequest.depth);
                    },
                    onCancel: () => {
                        // _setTargetDepth(depth);
                        UserEvents.depthChangeCanceled(depthRequest.depth);
                    },
                    onSkipQueue: () => UserEvents.depthChangeSkippedQueue(depthRequest.depth),
                });
                UserEvents.depthChanged(depthRequest.depth);
            }
        },
        [connection, queueFunction, _targetDepth],
    );

    useEffect(() => {
        connection.on(SessionEventType.MoveToDepthRejected, () => {
            _setDepthChangeError(SessionEventType.MoveToDepthRejected);
            targetDepthTimer.current && clearTimeout(targetDepthTimer.current);
            targetDepthLockedForTransition.current = false;
        });
    }, [connection]);

    if (isLoading) return 'loading';

    return {
        ...readProps,
        setTargetDepth,
    };
}
