import { format } from 'date-fns';
import { isNumber } from 'lodash';
import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import styled, { keyframes } from 'styled-components';
import { Wavepath, WavepathType } from 'wavepaths-shared/core';
import { getDuration } from 'wavepaths-shared/domain/wavepath';

import theme from '../../styles/theme';

interface WaveCardWaveDurationProps {
    wave: Wavepath;
}
export const WaveCardWaveDuration: React.FC<WaveCardWaveDurationProps> = ({ wave }) => {
    const duration = getFormattedDurationFromWave(wave);
    const [isFirstRender, setIsFirstRender] = useState(true);
    useUpdateEffect(() => {
        setIsFirstRender(false);
    }, [duration]);
    return (
        <Duration>
            {duration}
            <UpdateHighlight key={duration} isFirstRender={isFirstRender} />
        </Duration>
    );
};

const blink = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Duration = styled.span`
    position: relative;
`;

interface UpdateHighlightProps {
    isFirstRender: boolean;
}
const UpdateHighlight = styled.span<UpdateHighlightProps>`
    position: absolute;
    top: -4px;
    left: -4px;
    bottom: -4px;
    right: -4px;
    border-radius: 4px;
    background-color: ${theme.palette.warning.main};
    mix-blend-mode: multiply;
    opacity: 0;
    animation: alternate ${blink} ${(p) => (p.isFirstRender ? 0 : 0.25)}s ease 4;
`;

export const getFormattedDurationFromWave = (wave: Wavepath): string => {
    if (wave.type === WavepathType.POST) {
        return wave.pathId === FADE_OUT_POSTLUDE__PATH_ID ? '05:00' : '30:00';
    }
    return getFormattedDurationFromPlan(wave.plan);
};

export const getFormattedDurationFromPlan = (plan?: Wavepath['plan']): string =>
    plan && isNumber(plan?.toTime) && isNumber(plan?.fromTime) ? `${format(getDuration(plan), 'mm:ss')}` : '';

export const FADE_OUT_POSTLUDE__PATH_ID = 's_cea1_fade_post';
