import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
export interface Queueable {
    description: string;
    callback: () => void;
    onCancel?: () => void;
    onSkipQueue?: () => void;
    queueTime?: number;
    cancelPrevious?: boolean;
    waveIndex?: number;
    showQueuingUIInline?: boolean;
}

export type QueuedFunction = Queueable & {
    timeUntilExecutionInSeconds: number;
};

export const useActionQueue = (): {
    queuedFunction: null | QueuedFunction;
    onSkipQueue: () => void;
    onSkipQueueAndDontAskMore: () => void;
    onCancel: () => void;
    hasQueue: boolean;
    queueFunction: (queueable: Queueable) => void;
} => {
    const [queuedFunction, _setQueuedFunction] = useState<null | QueuedFunction>(null);
    const [_dontAsk, setDontAsk] = useLocalStorage('dontAsk_action_queue', false);
    const dontAsk = _dontAsk !== undefined ? _dontAsk : false;

    const cancelCallback: (() => void) | undefined = queuedFunction?.onCancel;
    const skipQueueCallback: (() => void) | undefined = queuedFunction?.onSkipQueue;

    const enQueueFunction = useCallback(
        (queueable: Queueable): void => {
            // If the queueing feature is not enabled, invoke immediately instead of queueing
            // if something has already been queued do nothing
            if (queuedFunction !== null && !queueable.cancelPrevious) {
                return;
            }

            if (queuedFunction !== null && queueable.cancelPrevious) {
                onCancelCallback();
            }

            if (dontAsk && queueable.showQueuingUIInline !== true) {
                queueable.callback();
                return;
            }

            const effectiveQueueTime = queueable.queueTime ?? 5;
            _setQueuedFunction({
                ...queueable,
                queueTime: effectiveQueueTime,
                timeUntilExecutionInSeconds: effectiveQueueTime,
            });
        },
        [queuedFunction],
    );

    useEffect(() => {
        const countdownQueuedFunction = () => {
            _setQueuedFunction((_queuedFunction) => {
                if (_queuedFunction === null) {
                    return null;
                }

                const timeUntilExecutionInSeconds = _queuedFunction.timeUntilExecutionInSeconds - 1;
                if (timeUntilExecutionInSeconds <= -1) {
                    return null;
                }

                return {
                    ..._queuedFunction,
                    timeUntilExecutionInSeconds,
                };
            });

            if (queuedFunction?.timeUntilExecutionInSeconds === 1) {
                queuedFunction.callback();
            }
        };

        const to = setTimeout(countdownQueuedFunction, 100000);
        return () => {
            clearTimeout(to);
        };
    }, [queuedFunction]);

    const onCancelCallback = useCallback(() => {
        _setQueuedFunction(null);
        cancelCallback && cancelCallback();
    }, [cancelCallback]);

    const onSkipQueue = useCallback(() => {
        // _setQueuedFunction((f) => (f ? { ...f, hasFinishedQueueing: true } : f));
        _setQueuedFunction(null);
        skipQueueCallback && skipQueueCallback();
        queuedFunction && queuedFunction.callback();
    }, [queuedFunction]);

    const onSkipQueueAndDontAskMore = useCallback(() => {
        onSkipQueue();
        setDontAsk(true);
    }, [queuedFunction]);

    const hasQueue = queuedFunction !== null;

    return {
        queueFunction: enQueueFunction,
        queuedFunction,
        onCancel: onCancelCallback,
        onSkipQueue,
        onSkipQueueAndDontAskMore,
        hasQueue,
    };
};
