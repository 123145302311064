import { PopoverOrigin } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MaterialMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { styled } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { ReactElement, useState } from 'react';

import Typography from '../typography/Typography';
import Button from './Button';

export interface IMenuOption {
    onClick?: (e?: any) => void;
    element: ReactElement | string;
    icon?: ReactElement;
}

export interface IMenuProps {
    options: IMenuOption[];
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
}

export default function Menu({
    options,
    transformOrigin = { vertical: 'top', horizontal: 'left' },
    anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
}: IMenuProps): ReactElement {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [show, setShow] = useState<boolean>(false);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setShow(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTimeout(() => {
            setShow(false);
        }, 300);
    };

    return (
        <div>
            <Button
                variant="solid-light"
                size="s"
                aria-label="more"
                aria-controls="menu"
                aria-haspopup="true"
                onClick={handleClick}
                icon={<MoreHorizIcon style={{ color: 'black' }} fontSize="small" />}
            />
            {show && (
                <MaterialMenu
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                    getContentAnchorEl={null}
                    id="menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                >
                    {options.map(({ onClick, element, icon }, idx) => (
                        <MenuItem
                            key={idx}
                            onClick={(e) => {
                                onClick && onClick(e);
                                handleClose();
                            }}
                        >
                            {icon && <StyledListItemIcon role="img">{icon}</StyledListItemIcon>}
                            {typeof element === 'string' ? <Typography variant="body2">{element}</Typography> : element}
                        </MenuItem>
                    ))}
                </MaterialMenu>
            )}
        </div>
    );
}

const StyledListItemIcon = styled(ListItemIcon)({
    minWidth: '36px',
});
