import React from 'react';

import { Typography } from '@/component-library';

import InfoButtonAndDialog from './InfoButtonAndDialog';

const TherapeuticTemplateInfoDialog: React.FC = () => (
    <InfoButtonAndDialog title="Music Template">
        <Typography variant="body2" color="textSecondary">
            The templates available in the system provide pre-designed sessions that consist of different combinations
            of emotional qualities and intensities.
        </Typography>
        <Typography variant="body2" color="textSecondary">
            The templates are categorized into low, medium and high intensity options. These options service different
            therapeutic functions.
        </Typography>
    </InfoButtonAndDialog>
);

export default TherapeuticTemplateInfoDialog;
