import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import React, { useContext } from 'react';
import {
    ClientVariablesMusicPreference,
    CoreEmotionalAtmosphere,
    SessionRenderType,
    SessionScoreEmotionalIntensity,
    SessionScoreSessionUse,
} from 'wavepaths-shared/core';
import { getDurationFromScore } from 'wavepaths-shared/domain/sessionScore';
import { formatDurationMinutes } from 'wavepaths-shared/util/dateUtils';

import { SessionCacheContext, useSessionCache } from '@/hooks/useAudioCache';

import { useAuthContext } from '../../../auth';
import configs from '../../../configs';
import {
    getAbsoluteSessionAnonymousRecordingLink,
    getAbsoluteSessionIntegrationLink,
    getAbsoluteSessionRecordingLink,
} from '../../../formUtils/formUtils';
import { AudioDownload } from '../../../pages/inSession/AudioDownload';
import { SessionContext } from '../../../pages/inSession/autoGuide/useSession';
import { renderSessionClients } from '../../../pages/sessions/helpers';
import { ShareClientLinkDialog } from '../../../pages/sessions/SharePatientLinkDialog';
import TypographyV2 from '../typography/TypographyV2';
import Button from './Button';
import EvaIcon from './EvaIcon';
import IntensityIcon from './icons/IntensityIcon';
import { musicalPreferenceOptions, renderTypeOptions, sessionUseOptions } from './sessionOptions';
import TemplateArtwork from './TemplateArtwork';

export interface SessionDetailProps {
    imageUrl?: string;
    id: string;
    title: string;
    startedAt?: number;
    endedAt?: number;
    intensity?: SessionScoreEmotionalIntensity;
    emotionalities?: {
        primary: CoreEmotionalAtmosphere;
        secondary: CoreEmotionalAtmosphere;
        tertiary: CoreEmotionalAtmosphere;
    };
    onBackButtonClick: () => void;
    tracklistComponent: JSX.Element | null;
    timelineComponent: JSX.Element | null;
    duration: number;
    renderType: SessionRenderType;
    sessionUse: SessionScoreSessionUse;
    musicalPreference: ClientVariablesMusicPreference;
    scheduledStart?: number;
    onSubmit: () => void;
    submitDisabled: boolean;
    onCopySession?: () => void;
    integrationAnswers?: {
        userName: string;
        userId: string;
        integration: {
            created_at: string;
            answers: {
                experience_rating: number;
                experience_summary: string;
                most_remember: string;
                insights: string;
                confusions: string;
                other: string;
            };
        };
    }[];
    onIntegrationAnswerClick?: (integrationId: string) => void;
    isIntegrationEnabled: boolean;
    showAdminFeatures: boolean;
    isRealTimeRenderingEnabled: boolean;
}

const Container = styled.div<{ isIntegrationEnabled: boolean }>(
    ({ isIntegrationEnabled }) => `
    display: grid;
    grid-template-areas: ${
        isIntegrationEnabled
            ? "'header' 'integration' 'actions' 'music' 'footer'"
            : "'header' 'actions' 'music' 'footer'"
    };
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    gap: 20px;
    margin-top: 64px;

    @media (min-width: 1260px) {
        grid-template-areas: ${
            isIntegrationEnabled
                ? "'header header' 'integration actions' 'music actions' 'footer footer'"
                : "'header header' 'music actions' 'footer footer'"
        };
        grid-template-columns: 2fr 1fr;
        grid-template-rows: repeat(3, min-content);
        gap: 20px 48px;
    }
`,
);

const Header = styled.div`
    grid-area: header;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    @media (min-width: 1260px) {
        margin-bottom: 28px;
    }
`;

const Artwork = styled.div`
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 6px;
    background: #f9fafb; // Grey 50
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.15s ease;
`;

const TitleInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Meta = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-area: meta;
    justify-content: flex-start;
    gap: 16px;
`;

const MetaIcon = styled.div``;

const MetaItem = styled.div`
    align-items: center;
    display: flex;
    gap: 4px;
    margin-bottom: 4px;
`;

const Timeline = styled.div`
    border-radius: 4px;
    background: rgba(252, 252, 253, 1);
    border: 1px solid white;
    border-radius: 4px;
    margin-top: 16px;
    padding: 8px;
    box-shadow: 0px 0px 10px #cfd6e7;
`;

const TimelineContainer = styled.div`
    position: sticky;
    top: -36px;
    padding-top: 16px;
    margin-bottom: 16px;
    margin-top: -20px;
    z-index: 10;
    background-color: #f8f9fb;
`;

const TrackList = styled.div``;

const Actions = styled.div`
    grid-area: actions;
    display: grid;
    align-items: start;
    gap: 16px;
    z-index: 20;
`;

const StickyActions = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    gap: 16px;
    flex-direction: column;
`;

const BackButton = styled.button`
    position: absolute;
    top: 8px;
    left: -12px;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    display: inline-grid;
    place-content: center;

    @media (min-width: 1366px) {
        left: -68px;
    }
    cursor: pointer;
`;

const BackButtonIcon = styled.svg`
    width: 20px;
    height: 20px;
    stroke: white;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
`;

const Music = styled.div`
    grid-area: music;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const IntegrationResponsesContainer = styled.div`
    grid-area: integration;
`;

const COPY_SESSION_LABEL = 'Copy Session';

export type SchedulingStyle = 'now' | 'later' | 'specificTime';

const ValueFieldContainer = styled.div`
    // display: flex;
    // flex-die
    gap: 8px;
`;

const IntegrationResponsesList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 8px;
    margin-top: 16px;
`;

const ValueField = ({ label, value }: { label: string; value?: string }) => {
    return (
        <ValueFieldContainer>
            <TypographyV2 color="grey-700" size="text-sm">
                {label}
            </TypographyV2>
            <TypographyV2 color="grey-700" size="text-sm" weight="semibold">
                {value ?? '-'}
            </TypographyV2>
        </ValueFieldContainer>
    );
};

const SessionStatusIconContainer = styled.div`
    padding: 4px 8px;
    background-color: #f1f3f8;
    border-radius: 4px;
`;
const SessionStatusIcon = ({ startedAt, endedAt }: { startedAt?: number; endedAt?: number }) => {
    const sessionStatus =
        endedAt !== undefined ? 'Ended' : startedAt !== undefined && startedAt <= Date.now() ? 'Ongoing' : 'Scheduled';
    return (
        <SessionStatusIconContainer>
            <TypographyV2 size="text-sm" color="grey-400">
                {sessionStatus}
            </TypographyV2>
        </SessionStatusIconContainer>
    );
};

const IntegrationListItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 16px;
    flex-wrap: wrap;
    box-shadow: 0px 0px 10px #cfd6e7;
    border: 1px solid white;
    border-radius: 8px;
`;
const IntegrationListContent = styled.div``;
const IntegrationListItem = ({
    userName,
    createdAt,
    onClick,
}: {
    userName: string;
    createdAt: string;
    onClick: () => void;
}) => {
    return (
        <IntegrationListItemContainer>
            <IntegrationListContent>
                <TypographyV2 size="text-md" color="grey-600">
                    {userName}
                </TypographyV2>
                <TypographyV2 size="text-sm" color="grey-400">
                    {formatDateTime(new Date(createdAt).getTime())}
                </TypographyV2>
            </IntegrationListContent>
            <Button variant="outlined" size="m" onClick={onClick}>
                View
            </Button>
        </IntegrationListItemContainer>
    );
};

const pulse = keyframes`
    from, to { 
        opacity: 0.4; 
    }

    50% { 
        opacity: 1; 
    }
`;

const LoadingDiv = styled.div({
    animation: `${pulse} 2s ease-in-out infinite`,
});

const IntegrationListLoading = () => {
    return (
        <LoadingDiv>
            <IntegrationListItemContainer>
                <IntegrationListContent style={{ filter: 'blur(4px)' }}>
                    <TypographyV2 size="text-sm" color="grey-400">
                        Content is loading...
                    </TypographyV2>
                    <TypographyV2 size="text-sm" color="grey-400">
                        Date will appear here...
                    </TypographyV2>
                </IntegrationListContent>
            </IntegrationListItemContainer>
        </LoadingDiv>
    );
};

const EmptyIntegrationListItemContainer = styled(IntegrationListItemContainer)`
    box-shadow: none;
    border: none;
    background-color: #f1f3f8;
`;

const EmptyIntegrationItem = () => {
    return (
        <EmptyIntegrationListItemContainer>
            <IntegrationListContent>
                <TypographyV2 size="text-sm" color="grey-400">
                    No responses yet
                </TypographyV2>
            </IntegrationListContent>
        </EmptyIntegrationListItemContainer>
    );
};

const formatDateTime = (time: number) => {
    return format(new Date(time), 'do LLL yyy, HH:mm');
};

const SessionDetail: React.FC<SessionDetailProps> = ({
    id,
    title,
    startedAt,
    endedAt,
    onBackButtonClick,
    tracklistComponent,
    timelineComponent,
    duration,
    renderType,
    sessionUse,
    musicalPreference,
    onSubmit,
    submitDisabled,
    intensity = SessionScoreEmotionalIntensity.LOW,
    emotionalities,
    onCopySession,
    isIntegrationEnabled,
    integrationAnswers,
    onIntegrationAnswerClick,
    showAdminFeatures,
}) => {
    const { firebaseUser, isPersonal } = useAuthContext();
    if (!firebaseUser) {
        throw new Error('Missing user');
    }
    const session = useContext(SessionContext);
    if (!session) {
        throw new Error('No session');
    }
    const streamUrl = `${configs.freud.STREAM_BASE}/${session.broadcastIdentifier}/stream.m3u8`;

    const sessionCache = useSessionCache({
        audioCacheParams: {
            streamUrl: streamUrl,
            broadcastIdentifier: session.broadcastIdentifier,
            estimatedDurationSec: getDurationFromScore(session.score.wavepaths) / 1000,
        },
        session,
    });
    const clientNames = renderSessionClients(session);
    return (
        <Container isIntegrationEnabled={isIntegrationEnabled}>
            <Header>
                <Artwork>
                    <TemplateArtwork
                        randomSeed={id}
                        intensity={intensity}
                        primaryEmotion={emotionalities?.primary ?? CoreEmotionalAtmosphere.SILENCE}
                        secondaryEmotion={emotionalities?.secondary ?? CoreEmotionalAtmosphere.SILENCE}
                        tertiaryEmotion={emotionalities?.tertiary ?? CoreEmotionalAtmosphere.SILENCE}
                    />
                </Artwork>
                <TitleInfo>
                    <TypographyV2
                        element={'h3'}
                        display={'block'}
                        font={'tenor-sans'}
                        size={'display-sm'}
                        weight={'regular'}
                        truncated
                    >
                        {title}
                    </TypographyV2>
                    {!isPersonal ? (
                        <TypographyV2
                            element={'h5'}
                            display={'block'}
                            font={'tenor-sans'}
                            size={'display-xs'}
                            weight={'regular'}
                        >
                            {clientNames}
                        </TypographyV2>
                    ) : (
                        <></>
                    )}
                    {startedAt !== undefined && (
                        <TypographyV2
                            display={'block'}
                            size={'text-md'}
                            weight={'regular'}
                            color={'grey-400'}
                            truncated
                        >
                            {`${formatDateTime(startedAt)}`}
                        </TypographyV2>
                    )}
                    <Meta>
                        <MetaItem>
                            <SessionStatusIcon startedAt={startedAt} endedAt={endedAt} />
                        </MetaItem>
                        <MetaItem>
                            <MetaIcon>
                                <EvaIcon name="clock-outline" size={16} iconStyle={{ fill: '#667085' }} />
                            </MetaIcon>
                            <TypographyV2
                                display={'block'}
                                size={'text-sm'}
                                weight={'regular'}
                                color={'grey-400'}
                                truncated
                            >
                                {formatDurationMinutes(duration * 60 * 1000)}
                            </TypographyV2>
                        </MetaItem>
                        <MetaItem>
                            <MetaIcon>
                                <IntensityIcon intensity={intensity} />
                            </MetaIcon>
                            <TypographyV2 size={'text-sm'} weight={'regular'} color={'grey-400'}>
                                {intensity} intensity
                            </TypographyV2>
                        </MetaItem>
                        <MetaItem>
                            <SessionCacheContext.Provider value={sessionCache}>
                                <AudioDownload />
                            </SessionCacheContext.Provider>
                        </MetaItem>
                    </Meta>
                </TitleInfo>
            </Header>
            {isIntegrationEnabled ? (
                <IntegrationResponsesContainer>
                    <TypographyV2
                        element="h3"
                        weight={'regular'}
                        size={'display-xs'}
                        color={'grey-700'}
                        font={'tenor-sans'}
                    >
                        Clients Integration Responses
                    </TypographyV2>
                    <IntegrationResponsesList>
                        {integrationAnswers ? (
                            integrationAnswers.length ? (
                                integrationAnswers.map(({ userName, userId, integration }) => (
                                    <IntegrationListItem
                                        key={userId}
                                        userName={userName}
                                        createdAt={integration.created_at}
                                        onClick={() => onIntegrationAnswerClick && onIntegrationAnswerClick(userId)}
                                    />
                                ))
                            ) : (
                                <EmptyIntegrationItem />
                            )
                        ) : (
                            <IntegrationListLoading />
                        )}
                    </IntegrationResponsesList>
                </IntegrationResponsesContainer>
            ) : null}
            <Music>
                {timelineComponent && (
                    <TimelineContainer>
                        <TypographyV2
                            element="h3"
                            weight={'regular'}
                            size={'display-xs'}
                            color={'grey-700'}
                            font={'tenor-sans'}
                        >
                            Music
                        </TypographyV2>
                        <Timeline>{timelineComponent}</Timeline>
                    </TimelineContainer>
                )}
                <TrackList>{tracklistComponent}</TrackList>
            </Music>
            {/* </Content> */}
            <Actions>
                <StickyActions>
                    <ValueField
                        label="Session Type"
                        value={renderTypeOptions.find(({ value }) => value === renderType)?.label}
                    />
                    <ValueField
                        label="Musical Preference"
                        value={musicalPreferenceOptions.find(({ value }) => value === musicalPreference)?.label}
                    />
                    {showAdminFeatures ? (
                        <>
                            <ValueField
                                label="Session Use"
                                value={sessionUseOptions.find(({ value }) => value === sessionUse)?.label}
                            />
                        </>
                    ) : null}
                    <Button disabled={submitDisabled} variant="solid-blue" size="m" onClick={onSubmit}>
                        Replay
                    </Button>
                    {onCopySession ? (
                        <Button
                            disabled={submitDisabled}
                            variant="outlined"
                            size="m"
                            icon={<EvaIcon name="save-outline" size={16} fill={'#000'} />}
                            onClick={onCopySession}
                        >
                            {COPY_SESSION_LABEL}
                        </Button>
                    ) : null}
                    {isIntegrationEnabled ? (
                        <ShareClientLinkDialog
                            linkText={'Share Recording Only'}
                            linkUrl={getAbsoluteSessionRecordingLink(id)}
                            anonymousLinkUrl={getAbsoluteSessionAnonymousRecordingLink(id)}
                            clients={session.clients}
                        />
                    ) : null}
                    {isIntegrationEnabled ? (
                        <ShareClientLinkDialog
                            linkText={'Share Recording + Integration Form'}
                            linkUrl={getAbsoluteSessionIntegrationLink(id)}
                            clients={session.clients}
                        />
                    ) : null}
                </StickyActions>
            </Actions>
            <BackButton onClick={onBackButtonClick}>
                <BackButtonIcon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.8334 9.99935H4.16669M4.16669 9.99935L10 15.8327M4.16669 9.99935L10 4.16602"
                        stroke="#475467"
                    />
                </BackButtonIcon>
            </BackButton>
        </Container>
    );
};

export default SessionDetail;
