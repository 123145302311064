import '../../Guide.scss';

import { Dialog as DialogMaterial, DialogContent } from '@material-ui/core';
import { findLastIndex } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useInterval } from 'react-use';
import styled from 'styled-components';
import { BroadcastPersistentState, isScheduledWavepath, SessionRenderType, SessionState } from 'wavepaths-shared/core';
import { getDurationFromScore } from 'wavepaths-shared/domain/sessionScore';

import { Button, Dialog, EvaIcon } from '@/component-library';
import { GenericErrorBoundary } from '@/components/GenericErrorBoundary';
import { GlobalSnackbarContext } from '@/components/Snackbar';
import useAnonymousToken from '@/hooks/useAnonymousToken';
import { SessionCacheContext, useSessionCache } from '@/hooks/useAudioCache';
import {
    useRemoteElapsedTimeSecs,
    useRemoteSessionPlaybackControl,
    useRemoteSessionState,
} from '@/hooks/useSessionTick';
import { TestHooksContext } from '@/hooks/useTestHooks';
import { useThreadMonitor } from '@/hooks/useThreadMonitor';

import * as sessionApi from '../../common/api/sessionApi';
import LoadingOrb from '../../common/components/LoadingOrb';
import configs from '../../configs';
import WavepathsLogo from '../../images/WavepathBlueLogo.svg';
import { SessionName } from '../inSession/SessionName';
import { AudioPlayerContext, useAudioPlayer } from '../sessions/EndedSessionsTable/useAudioPlayer';
import { DebugAudioEl } from '../sessions/helpers';
import { AudioDownload } from './AudioDownload';
import { AudioSettings } from './AudioSettings';
import { SessionContext, useSessionByBroadcastIdentifier } from './autoGuide/useSession';
import { ChoosePlayerDialog, PlayerChoiceContext, usePlayerChoice } from './ChoosePlayer';
import { useRemoteSessionControls } from './Guide';
import {
    AudioControlIcon,
    PlayPauseButton,
    PrecomposedAudioControls,
    VolumeContainer,
    WaveJumpButton,
} from './Precomposed/PrecomposedGuide';
import { Clock } from './SessionClock';
import { VolumeMeter } from './VolumeMeter';

export const DISABLED_LABEL = 'This is disabled until you start your session';

export const ListenContainer: React.FC = () => {
    const { broadcastIdentifier, freePaidPathPart } = useParams<{
        broadcastIdentifier: string;
        freePaidPathPart?: string;
    }>();
    const { session: deprecatedWebsitePlayerSession } = useSessionByBroadcastIdentifier({ broadcastIdentifier });
    const testHooks = useContext(TestHooksContext);
    const { anonymousToken, loading: loadingAnonymousToken } = useAnonymousToken();

    useEffect(() => {
        testHooks?.debug && console.debug({ deprecatedWebsitePlayerSession });
    }, [deprecatedWebsitePlayerSession]);
    const remoteSession = useRemoteSessionControls({ sessionId: deprecatedWebsitePlayerSession?.id, mode: 'listen' });
    useEffect(() => {
        if (testHooks?.debug) {
            console.debug(remoteSession);
        }
    }, [remoteSession]);

    useThreadMonitor({
        enabled: remoteSession.tick?.sessionState === SessionState.MAIN_PHASE,
    });

    const isReadyToLoadPlayer =
        deprecatedWebsitePlayerSession &&
        !loadingAnonymousToken &&
        remoteSession &&
        remoteSession.session &&
        remoteSession.connection;

    const playerChoiceHook = usePlayerChoice();

    return remoteSession && remoteSession.sessionError ? (
        <>Error loading session data</>
    ) : (
        <PlayerChoiceContext.Provider value={playerChoiceHook}>
            {isReadyToLoadPlayer ? (
                <>
                    {playerChoiceHook.playerChoice === undefined || playerChoiceHook.playerChoice === 'native' ? (
                        <>
                            <ChoosePlayerDialog
                                broadcastIdentifier={broadcastIdentifier}
                                renderType={deprecatedWebsitePlayerSession!.renderType}
                                onSetPlayerChoice={playerChoiceHook.setPlayerChoice}
                                freePaidPathPart={freePaidPathPart}
                            />
                        </>
                    ) : (
                        <>
                            <GenericErrorBoundary>
                                <SessionContext.Provider value={remoteSession.session}>
                                    <Listen remoteSession={remoteSession} anonymousToken={anonymousToken} />
                                </SessionContext.Provider>
                            </GenericErrorBoundary>
                        </>
                    )}
                </>
            ) : (
                <>
                    <LoadingOrb></LoadingOrb>
                </>
            )}
        </PlayerChoiceContext.Provider>
    );
};

const Logo = styled.img`
    display: block;
    height: 30px;
    margin: 10px;
`;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: min-content 1fr min-content;
    background: lavender;
`; //TODO: coloring

const Top = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`;

const Settings = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`;

const SettingContainer = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 10px;
`;

const SessionInfo = styled.div`
    text-align: center;
    margin: auto auto;
`;

const Middle = styled.div`
    margin: auto auto;
    justify-items: center;
    display: grid;
    gap: 20px;
`;

const Bottom = styled.div`
    margin: auto auto;
`;

const BottomContent = styled.div``;

export const Listen: FC<{
    remoteSession: ReturnType<typeof useRemoteSessionControls>;
    anonymousToken: string | undefined;
}> = ({ remoteSession, anonymousToken }) => {
    const { freePaidPathPart } = useParams<{
        freePaidPathPart?: string;
    }>();
    if (remoteSession.session === undefined) throw new Error('missing session');
    if (remoteSession.connection === undefined) throw new Error('missing connection');
    const session = remoteSession.session;
    const streamUrl = `${configs.freud.STREAM_BASE}/${session.broadcastIdentifier}/stream.m3u8`;
    const { setSnackbarContent } = useContext(GlobalSnackbarContext);

    const remoteElapsedTimeSecs = useRemoteElapsedTimeSecs();
    //TODO initial state from persistent storage
    const [broadcastState, setBroadcastState] = useState<BroadcastPersistentState>(
        session.broadcastState || {
            timeline: [{ sessionId: session.id, dspOffset: 0, broadcastOffset: session.score.dspOffset }],
            discarded: [],
        },
    );

    useEffect(() => {
        if (!remoteSession.connection) return;
        console.debug('Waiting for broadcastStateUpdate');
        remoteSession.connection.on('broadcastStateUpdate', (data: BroadcastPersistentState) => {
            setBroadcastState(data);
            console.debug('New broadcastState', data);
        });
    }, [remoteSession.connection]);

    const [playerElapsedTimeSecs, setPlayerElapsedTimeSecs] = useState(
        session.renderType !== SessionRenderType.PRE_RENDERED ? remoteElapsedTimeSecs : 0,
    );
    const elapsedTimeSecsEffective =
        session.renderType !== SessionRenderType.PRE_RENDERED ? remoteElapsedTimeSecs : playerElapsedTimeSecs;

    const audioPlayer = useAudioPlayer({
        broadcastIdentifier: session.broadcastIdentifier,
        errorContext: 'Listen Session',
        mode: session.renderType == SessionRenderType.REAL_TIME ? 'live' : 'recording',
        broadcastState,
        broadcastElapsedTimeSecs: elapsedTimeSecsEffective,
        voiceOverStages:
            session.renderType === SessionRenderType.PREDICTIVE_COMPOSED ? session.score.voiceover ?? [] : [],
        playDemoVO: freePaidPathPart === 'free',
    });

    const sessionCache = useSessionCache({
        audioCacheParams: {
            streamUrl: streamUrl,
            broadcastIdentifier: session.broadcastIdentifier,
            estimatedDurationSec: getDurationFromScore(session.score.wavepaths) / 1000,
        },
        session,
    });

    const { pause, resume } = useRemoteSessionPlaybackControl(remoteSession.connection, (q) => q.callback());

    useInterval(() => {
        if (session.renderType == SessionRenderType.PRE_RENDERED) {
            setPlayerElapsedTimeSecs(audioPlayer.currentTimeSecs);
        }
    }, 1000);

    const [audioSettingsOpen, setAudioSettingsOpen] = useState(false);

    useEffect(() => {
        setSnackbarContent(audioPlayer.warning);
    }, [audioPlayer.warning]);

    useEffect(() => {
        setSnackbarContent(remoteSession.snackbarContent);
    }, [remoteSession.snackbarContent]);

    useEffect(() => {
        setSnackbarContent(remoteSession.warningContent ?? null);
    }, [remoteSession.warningContent]);

    const isPostlude =
        remoteSession.tick && [SessionState.POSTLUDE, SessionState.ENDED].includes(remoteSession.tick.sessionState);
    const [audioLoaded, setAudioLoaded] = useState(false);
    const [isPrelude, setIsPrelude] = useState<boolean>(false);

    useEffect(() => {
        console.debug('Broadcast state', broadcastState);

        audioPlayer.actions.unblock();
    }, []);
    const { paused: remotePaused } = useRemoteSessionState();

    useEffect(() => {
        if (!audioLoaded) {
            return;
        }
        if (remoteSession.session?.renderType !== SessionRenderType.PREDICTIVE_COMPOSED) return;

        if (remotePaused) {
            audioPlayer.actions.pause({ reason: 'remote' });
        } else {
            unblockAndPlay();
        }
    }, [remotePaused]);

    useEffect(() => {
        if (!isPostlude) {
            if (
                (session.renderType === SessionRenderType.PRE_RENDERED || remoteSession.sessionInitialised) &&
                !audioLoaded
            ) {
                (async () => {
                    await audioPlayer.actions.unblock();

                    if (!remoteSession.sessionStarted) {
                        console.debug('Playing prelude...');

                        setIsPrelude(true);
                        if (remoteSession.session?.renderType === SessionRenderType.REAL_TIME) {
                            audioPlayer.actions.play();
                        } else {
                            audioPlayer.actions.playPreludePostlude();
                        }
                    } else {
                        if (session.renderType === SessionRenderType.PREDICTIVE_COMPOSED && remotePaused) {
                        } else {
                            audioPlayer.actions.play({ offsetSeconds: elapsedTimeSecsEffective });
                        }
                    }
                    setAudioLoaded(true);
                })();
            }
            if (audioLoaded && remoteSession.sessionStarted) {
                setIsPrelude(false);
                if (remoteSession.session?.renderType === SessionRenderType.REAL_TIME) {
                } else {
                    if (session.renderType === SessionRenderType.PREDICTIVE_COMPOSED && remotePaused) {
                    } else {
                        console.debug('Playing main phase...');
                        audioPlayer.actions.play({ offsetSeconds: elapsedTimeSecsEffective });
                    }
                }
            }
        } else {
            setIsPrelude(false);
            console.debug('Playing postlude...');
            if (remoteSession.session?.renderType === SessionRenderType.REAL_TIME) {
                //noop as postlude is part of the score
            } else {
                audioPlayer.actions.pause({ fadeMs: 30 * 1000, reason: 'fadingIntoPostlude' });
                audioPlayer.actions.playPreludePostlude();
            }
        }
    }, [remoteSession.sessionInitialised, remoteSession.sessionStarted, audioLoaded, isPostlude]);

    const unblockAndPlay = () => {
        (async () => {
            await audioPlayer.actions.unblock();
            if (remoteSession.sessionStarted && !isPostlude) {
                setIsPrelude(false);
                if (remoteSession.session?.renderType === SessionRenderType.REAL_TIME) {
                    //noop as postlude is part of the score
                } else {
                    console.debug('Playing main phase aftert unblock...');
                    audioPlayer.actions.play({ offsetSeconds: elapsedTimeSecsEffective });
                }
            } else {
                if (!isPostlude) {
                    setIsPrelude(true);
                }
                console.debug('Playing prelude/postlude after unblock...');
                if (remoteSession.session?.renderType === SessionRenderType.REAL_TIME) {
                    audioPlayer.actions.play();
                } else {
                    audioPlayer.actions.playPreludePostlude();
                }
            }
        })();
    };

    const togglePlaying = async () => {
        if (session.renderType == SessionRenderType.PREDICTIVE_COMPOSED) {
            if (remotePaused) {
                resume();
            } else {
                pause();
            }
        } else {
            if (audioPlayer.generalPlayerStatus === 'playing') {
                audioPlayer.actions.pause({ reason: 'user' });
            } else {
                await audioPlayer.actions.unblock();
                if (
                    (remoteSession.sessionStarted ||
                        remoteSession.session?.renderType === SessionRenderType.PRE_RENDERED) &&
                    !isPostlude
                ) {
                    setIsPrelude(false);
                    audioPlayer.actions.play({ offsetSeconds: elapsedTimeSecsEffective });
                } else {
                    if (!isPostlude) {
                        setIsPrelude(true);
                    }
                    if (remoteSession.session?.renderType === SessionRenderType.REAL_TIME) {
                        audioPlayer.actions.play();
                    } else {
                        audioPlayer.actions.playPreludePostlude();
                    }
                }
            }
        }
    };

    // TODO: This could be optimised a bit: we update the component every 100ms, but the scheduled wavepath timings don't change that often.
    const scheduledWavepaths = session.score.wavepaths.filter(isScheduledWavepath);
    const scheduledWavepathStartTimesSeconds = scheduledWavepaths.map(
        (p) => (p.plan?.fromTime ?? Number.MAX_SAFE_INTEGER) / 1000,
    );
    const currentWavepathIndex = findLastIndex(
        scheduledWavepathStartTimesSeconds,
        (s) => s <= elapsedTimeSecsEffective,
    );
    const isPossibleToGoToPrev = currentWavepathIndex > 0;
    const isPossibleToGoToNext = currentWavepathIndex < scheduledWavepathStartTimesSeconds.length;
    const goToPrev = async () => {
        setIsPrelude(false);
        await audioPlayer.actions.unblock();
        if (currentWavepathIndex <= 0) return;
        const prevStartTime = scheduledWavepathStartTimesSeconds[currentWavepathIndex - 1];
        audioPlayer.actions.setTime(prevStartTime);
    };

    const goToNext = async () => {
        setIsPrelude(false);
        await audioPlayer.actions.unblock();
        if (currentWavepathIndex >= scheduledWavepathStartTimesSeconds.length - 1) {
            audioPlayer.actions.end();
        } else {
            const nextStartTime = scheduledWavepathStartTimesSeconds[currentWavepathIndex + 1];
            audioPlayer.actions.setTime(nextStartTime);
        }
    };

    const isSessionInitializedForUX =
        (!!remoteSession.remoteSessionLogs &&
            remoteSession.sessionScoreState !== 'loading' &&
            remoteSession.sessionInitialised &&
            !!remoteSession.connection) ||
        session.renderType === SessionRenderType.PRE_RENDERED;
    // console.debug({
    //     isSessionInitializedForUX,
    //     remoteSessionLogs: remoteSession.remoteSessionLogs,
    //     sessionScoreState: remoteSession.sessionScoreState,
    //     connection: remoteSession.connection,
    //     renderType: session.renderType,
    //     sessionInitialised: remoteSession.sessionInitialised,
    //     canClientStartEarly: session.canClientStartEarly,
    // });
    return (
        <GenericErrorBoundary>
            <AudioPlayerContext.Provider value={audioPlayer}>
                <>
                    <Dialog
                        open={audioPlayer.audioStatus === 'blocked'}
                        onConfirm={unblockAndPlay}
                        message="In order to play sound through this tab, you first need to enable audio"
                        confirmText="Enable Audio"
                        title="Enable Audio"
                        disableBackdropClick={true}
                    />

                    {/* <GuideHeader
                            showShareSessionDialog={remoteSession.showShareSessionDialog}
                            setShowShareSessionDialog={remoteSession.setShowShareSessionDialog}
                            session={remoteSession.session}
                            onEndSession={remoteSession.endSessionWithConfirm}
                        /> */}
                    <Container>
                        <Top>
                            <SessionName session={session}></SessionName>
                            <Logo src={WavepathsLogo} alt="Wavepaths" />
                            <Settings>
                                {session.renderType == SessionRenderType.PRE_RENDERED && (
                                    <>
                                        <SettingContainer>
                                            <SessionCacheContext.Provider value={sessionCache}>
                                                <AudioDownload />
                                            </SessionCacheContext.Provider>
                                        </SettingContainer>
                                    </>
                                )}
                                <SettingContainer onClick={() => setAudioSettingsOpen(true)}>
                                    <EvaIcon name="settings-2-outline" fill="rgba(0, 0, 0, 0.54)" />
                                </SettingContainer>
                            </Settings>
                        </Top>
                        <Middle>
                            {isSessionInitializedForUX ? (
                                <>
                                    {isPrelude && (
                                        <>
                                            <SessionInfo>Playing Prelude Music</SessionInfo>
                                            {remoteSession.tick?.sessionState == SessionState.PRELUDE &&
                                                session.canClientStartEarly && (
                                                    <Button
                                                        variant="solid-blue"
                                                        onClick={async (event) => {
                                                            (event.target as HTMLButtonElement).disabled = true;
                                                            remoteSession.startSession();

                                                            (event.target as HTMLButtonElement).disabled = false;
                                                        }}
                                                    >
                                                        Start session
                                                    </Button>
                                                )}
                                        </>
                                    )}
                                    {session.renderType !== SessionRenderType.PRE_RENDERED &&
                                        remoteSession.tick?.sessionState &&
                                        [SessionState.MAIN_PHASE, SessionState.PLANNED].includes(
                                            remoteSession.tick?.sessionState,
                                        ) && <SessionInfo>Session is streaming...</SessionInfo>}
                                    {remoteSession.tick?.sessionState == SessionState.POSTLUDE && (
                                        <SessionInfo>Session Postlude Music</SessionInfo>
                                    )}
                                    {remoteSession.tick?.sessionState == SessionState.ENDED && (
                                        <SessionInfo>Session Ended</SessionInfo>
                                    )}

                                    {(session.renderType === SessionRenderType.PRE_RENDERED ||
                                        remoteSession.tick?.sessionState !== SessionState.ENDED) && (
                                        <>
                                            <Clock
                                                timeElapsedMs={elapsedTimeSecsEffective * 1000}
                                                totalDurationMs={
                                                    Number(session.variableInputs.totalDuration) * 1000 * 60
                                                }
                                            />

                                            <PrecomposedAudioControls>
                                                {audioPlayer.playerStatus === 'loading' ? (
                                                    <>Loading...</>
                                                ) : (
                                                    <>
                                                        {(session.renderType === SessionRenderType.PRE_RENDERED ||
                                                            (session.canClientStartEarly &&
                                                                (remoteSession.tick?.sessionState ==
                                                                    SessionState.MAIN_PHASE ||
                                                                    remoteSession.tick?.sessionState ==
                                                                        SessionState.PAUSE))) && (
                                                            <>
                                                                {session.renderType ==
                                                                    SessionRenderType.PRE_RENDERED && (
                                                                    <WaveJumpButton
                                                                        aria-label="Jump to previous wave"
                                                                        onClick={goToPrev}
                                                                        disabled={!isPossibleToGoToPrev}
                                                                    >
                                                                        <AudioControlIcon
                                                                            width="40"
                                                                            height="40"
                                                                            viewBox="0 0 40 40"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path d="M9.16667 30V10H11.9444V30H9.16667ZM30.8333 30L16.2223 20L30.8333 10V30Z" />
                                                                        </AudioControlIcon>
                                                                    </WaveJumpButton>
                                                                )}
                                                                <PlayPauseButton onClick={togglePlaying}>
                                                                    {audioPlayer.generalPlayerStatus === 'playing' ? (
                                                                        <AudioControlIcon
                                                                            width="48"
                                                                            height="48"
                                                                            viewBox="0 0 48 48"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path d="M28.25 38V10H36V38H28.25ZM12 38V10H19.75V38H12Z" />
                                                                        </AudioControlIcon>
                                                                    ) : (
                                                                        <AudioControlIcon
                                                                            width="48"
                                                                            height="48"
                                                                            viewBox="0 0 48 48"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path d="M14 38V10L36 24L14 38Z" />
                                                                        </AudioControlIcon>
                                                                    )}
                                                                </PlayPauseButton>
                                                                {session.renderType ==
                                                                    SessionRenderType.PRE_RENDERED && (
                                                                    <WaveJumpButton
                                                                        aria-label="Jump to next wave"
                                                                        onClick={goToNext}
                                                                        disabled={!isPossibleToGoToNext}
                                                                    >
                                                                        <AudioControlIcon
                                                                            width="40"
                                                                            height="40"
                                                                            viewBox="0 0 40 40"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path d="M28.0556 30V10H30.8333V30H28.0556ZM9.16667 30V10L23.7778 20L9.16667 30Z" />
                                                                        </AudioControlIcon>
                                                                    </WaveJumpButton>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </PrecomposedAudioControls>
                                            {audioPlayer.isVolumeControllable ? (
                                                <VolumeContainer>
                                                    <VolumeMeter />
                                                </VolumeContainer>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {!remoteSession.sessionInitialised && (
                                        <>
                                            {!session.canClientStartEarly && (
                                                <div>
                                                    Session is not started yet. Please wait for your host to start the
                                                    session.
                                                </div>
                                            )}
                                            {session.canClientStartEarly && (
                                                <>
                                                    <div>Session is not started yet.</div>
                                                    <Button
                                                        variant="solid-blue"
                                                        onClick={async (event) => {
                                                            (event.target as HTMLButtonElement).disabled = true;
                                                            await sessionApi.startSessionEarly(
                                                                session.id,
                                                                undefined,
                                                                anonymousToken,
                                                            );
                                                            unblockAndPlay();
                                                            (event.target as HTMLButtonElement).disabled = false;
                                                        }}
                                                    >
                                                        {session.renderType === SessionRenderType.REAL_TIME ? (
                                                            <>Start Session in Prelude</>
                                                        ) : (
                                                            <>Start Session</>
                                                        )}
                                                    </Button>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Middle>
                        <Bottom>
                            <BottomContent>
                                <DebugAudioEl />
                            </BottomContent>
                        </Bottom>
                        {audioSettingsOpen && (
                            <DialogMaterial open={audioSettingsOpen} onClose={() => setAudioSettingsOpen(false)}>
                                <DialogContent>
                                    <AudioSettings closeDialog={() => setAudioSettingsOpen(false)} />
                                </DialogContent>
                            </DialogMaterial>
                        )}
                    </Container>
                </>
            </AudioPlayerContext.Provider>
        </GenericErrorBoundary>
    );
};
