import './intensitySlider.css';

import colorConvert from 'color-convert';
import React, { useEffect, useRef } from 'react';

import {
    buildStepsForValueRangeX,
    CssKeys,
    lowerBody,
    lowerCircle,
    lowerSpacer,
    middleSpacer,
    setCssProperty,
    upperBody,
    upperCircle,
    upperSpacer,
} from '@/component-library/components/IntensitySlider/intensitySliderHelper';
import ValueIndicator from '@/component-library/components/IntensitySlider/ValueIndicator';

import Typography from '../../typography/Typography';
import EvaIcon from '../EvaIcon';

export interface IIntensitySliderProps {
    min: number;
    max: number;
    divideAt: number;
    currentIntensity?: number;
    currentIntensityTransitionTimeSecs?: number;
    targetIntensity?: number;
    colorHex: string;
    colorHexTransitionTimeSecs?: number;
    disabled?: boolean;
    onChange: (depth: number) => void;
    onClickWhenDisabled?: () => void;
}

export default function IntensitySlider({
    min,
    max,
    divideAt,
    currentIntensity,
    targetIntensity,
    disabled = false,
    onChange,
    colorHex,
    colorHexTransitionTimeSecs,
    onClickWhenDisabled,
}: IIntensitySliderProps): JSX.Element {
    const maxRef = useRef<HTMLDivElement>(null);
    const minRef = useRef<HTMLDivElement>(null);
    const innerSteps = buildStepsForValueRangeX(min + 1, max);

    useEffect(() => {
        setCssProperty(CssKeys.disabledOpacity, disabled ? 0 : 1);
    }, [disabled]);

    const currentIntensityLevel = useRef<number | undefined>(currentIntensity);
    const targetLevel = useRef<number | undefined>(targetIntensity);

    function getActiveCss(level: number): 'filling' | 'draining' {
        if (currentIntensityLevel.current === undefined) return 'draining';
        const rest = level - currentIntensityLevel.current;

        if (rest <= 0) return 'filling';
        if (rest >= 1) return 'draining';
        if (currentIntensityLevel.current > targetIntensity!) return 'draining';
        return 'filling';
    }

    useEffect(() => {
        const [hue, saturation, lightness] = colorConvert.hex.hsl(colorHex);
        setCssProperty(CssKeys.colorLight, `hsla(${hue},${saturation}%,${lightness - 10}%, 0.6)`);
        setCssProperty(CssKeys.colorMid, `hsla(${hue},${saturation + 15}%,${lightness - 15}%, 0.8)`);
        setCssProperty(CssKeys.colorDark, `hsl(${hue},${saturation + 20}%,${lightness - 50}%)`);
        setCssProperty(CssKeys.colorHighlight, `hsl(${hue},${saturation}%,${lightness + 20}%)`);
        setCssProperty(CssKeys.colorShadow, `hsla(${hue},${saturation}%,${lightness - 30}%,0.3)`);
        setCssProperty(CssKeys.colorTransitionTimeInSecs, `${colorHexTransitionTimeSecs}s`);
    }, [colorHex, colorHexTransitionTimeSecs]);

    const handleClick = (step: number) => {
        if (disabled) return;
        targetLevel.current = step;
        onChange(step);
    };

    useEffect(() => {
        setLevels();
        if (targetIntensity) {
            targetLevel.current = targetIntensity;
        }
    }, [targetIntensity]);

    const levelInterval = useRef<NodeJS.Timeout>();
    function setLevels() {
        if (levelInterval.current) {
            clearInterval(levelInterval.current);
        }

        levelInterval.current = setInterval(() => {
            if (currentIntensityLevel.current === undefined) {
                if (levelInterval.current) clearInterval(levelInterval.current);
                return;
            }

            if (targetIntensity! > currentIntensityLevel.current) {
                currentIntensityLevel.current++;
            } else if (targetIntensity! < currentIntensityLevel.current) {
                currentIntensityLevel.current--;
            } else {
                clearInterval(levelInterval.current!);
            }
        }, 1800);
    }

    return (
        <div id="intensity-slider-root">
            <div
                aria-label="Musical Intensity Slider"
                role="slider"
                aria-valuemax={max}
                aria-valuemin={min}
                className="container"
                onClick={disabled ? onClickWhenDisabled : undefined}
            >
                <div className="spacer">
                    <svg className="spacer-image" xmlns="http://www.w3.org/2000/svg" viewBox="-20 -20 40 344">
                        <path d={upperCircle} fill="#fff" />
                        <path d={upperSpacer} fill="#fff" />
                        <path d={upperBody} fill="#fff" />
                        <path d={middleSpacer} fill="#fff" />
                        <path d={lowerBody} fill="#fff" />
                        <path d={lowerSpacer} fill="#fff" />
                        <path d={lowerCircle} fill="#fff" />
                    </svg>
                </div>

                <div
                    className="end-icon-container"
                    onClick={() => handleClick(max)}
                    ref={maxRef}
                    aria-label={`Musical Intensity ${max} (Release)`}
                >
                    <div className="end-icon-background">
                        <div className={`slider-step-active ${getActiveCss(max)}`} />
                        <EvaIcon name="sun-outline" size="20px" iconStyle={{ opacity: '1' }} />
                    </div>
                </div>

                <div className="inner-track">
                    {innerSteps.reverse().map((step) => (
                        <div
                            key={`step-${step}`}
                            className={`slider-step-container${
                                step == min + 1 || step == divideAt ? ' segment-end' : ''
                            } ${step == max - 1 || step == divideAt - 1 ? ' segment-start' : ''}`}
                        >
                            <div
                                className="slider-step"
                                aria-label={`Musical Intensity ${step}`}
                                onClick={() => handleClick(step)}
                            >
                                <div className={`slider-step-active ${getActiveCss(step)}`} />
                            </div>
                        </div>
                    ))}
                </div>

                <div
                    className="end-icon-container"
                    onClick={() => handleClick(min)}
                    ref={minRef}
                    aria-label={`Musical Intensity ${min} (Silence)`}
                >
                    <div className="end-icon-background bottom-icon">
                        <div className={`slider-step-active ${getActiveCss(min)}`} />
                        <EvaIcon name="volume-off-outline" size="16px" />
                    </div>
                </div>

                <ValueIndicator level={targetLevel.current}></ValueIndicator>

                <div className="labels">
                    <div className="section release">
                        <Typography variant="body3" className="label">
                            Release
                        </Typography>
                    </div>
                    <div className="section deepen">
                        <Typography variant="body3" className="label">
                            Deepen
                        </Typography>
                    </div>
                    <div className="section soothe">
                        <Typography variant="body3" className="label">
                            Soothe
                        </Typography>
                    </div>
                    <div className="section silence">
                        <Typography variant="body3" className="label">
                            Silence
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}
