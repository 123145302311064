import arrayMove from 'array-move';
import { isUndefined } from 'lodash';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    AdministrationRoute,
    ClientVariablesMusicPreference,
    ScoreLibrary,
    SessionScore,
    SessionScoreAdministration,
    SessionScoreDosage,
    SessionScoreSessionUse,
    SessionVariables,
    VoiceoverStage,
    Wavepath,
    WavepathType,
} from 'wavepaths-shared/core';
import * as scoreUtils from 'wavepaths-shared/domain/scores';
import { upsertVoiceOverArray } from 'wavepaths-shared/domain/session';
import {
    planSessionFromPreset as planSessionFromTemplate,
    planSessionWavepaths,
    planSessionWavepathsAdjustingDuration,
} from 'wavepaths-shared/domain/sessionPlanner';
import { insertAtIndex, removeAtIndex, replaceAtIndex } from 'wavepaths-shared/util/arrayUtils';

import { getDefaultSessionDurationForModality } from '@/domain/modalities';

import { ScoreTemplate } from '../../common/api/contentApi';
import { ISessionTemplate } from '../../common/api/sessionTemplatesApi';

const REQUIRED_VARIABLE_INPUTS = ['sessionUse'];

const mapScoreTemplateToScore = (scoreTemplate: ScoreTemplate): SessionScore => ({
    ...scoreTemplate,
    showInMenu: true,
    wavepaths: [],
});

export enum Input {
    Medicine,
    Administration,
    Dosage,
    ScoreTemplate,
    Score,
    Duration,
}

// TODO: replace with ReturnType<...>
interface IUseScorePlannerReturn {
    score: SessionScore;
    scoreTemplate?: string;
    sessionVariableInputs: SessionVariables;
    updateVariableInputs: (updates: SessionVariables) => void;
    missingVariableInputs: string[];
    updatePathInScore: (index: number, wp: Pick<Wavepath, 'pathId' | 'pathScore' | 'duration'>) => void;
    addPathToScore: (index: number, wp: Pick<Wavepath, 'pathId' | 'pathScore'>) => void;
    removePathFromScore: (waveIndex: number) => void;
    movePathInScore: (index: number, targetIndex: number) => void;
    dirtyInputs: Input[];
    upsertVoiceOverStage: ({ index, voiceOver }: { index?: number | undefined; voiceOver?: VoiceoverStage }) => void;
}

type ScoreDefaults =
    | {
          template: ScoreTemplate;
          variableInputs?: SessionVariables & { totalDuration: number };
      }
    | ISessionTemplate;

const createScoreForTemplate = (
    template: ScoreTemplate,
    scoreLibrary: Pick<ScoreLibrary, 'pathScores' | 'presetScores'>,
    variables: SessionVariables,
): SessionScore => {
    const plannedWaves = planSessionFromTemplate(template, scoreLibrary, variables);
    return {
        ...mapScoreTemplateToScore(template),
        wavepaths: plannedWaves,
    };
};

const ADMIN_TO_SESSION_SCORE_ADMIN: { [key in AdministrationRoute]: SessionScoreAdministration } = {
    [AdministrationRoute.INTRAMUSCULAR]: SessionScoreAdministration.Intramuscular,
    [AdministrationRoute.INTRAVENOUS]: SessionScoreAdministration.Intravenous,
    [AdministrationRoute.NASAL]: SessionScoreAdministration.Nasal,
    [AdministrationRoute.ORAL]: SessionScoreAdministration.Oral,
    [AdministrationRoute.SUBLINGUAL]: SessionScoreAdministration.Sublingual,
};

const useScorePlanner = (
    scoreLibrary: Pick<ScoreLibrary, 'pathScores' | 'presetScores'>,
    defaults: ScoreDefaults,
): IUseScorePlannerReturn => {
    const [interactedInputs, setInteractedInputs] = useState<Input[]>([]);
    const isCustomised =
        interactedInputs.includes(Input.Score) ||
        ('score' in defaults && !interactedInputs.includes(Input.ScoreTemplate));
    const defaulVariableInputs = {
        sessionUse: SessionScoreSessionUse.IN_PERSON,
        name: '',
        voiceover: 'none',
        Acousticness: ClientVariablesMusicPreference.MIXED,
        ...('template' in defaults && {
            totalDuration:
                defaults.template.defaultDurationMins ??
                getDefaultSessionDurationForModality(defaults.template.modality, {
                    administration: defaults.template.administration,
                    dosage: defaults.template.dosage,
                }),
            administration:
                defaults.template.administration && ADMIN_TO_SESSION_SCORE_ADMIN[defaults.template.administration],
            dosage: defaults.template.dosage && SessionScoreDosage[defaults.template.dosage],
        }),
        ...defaults.variableInputs,
    };

    // const initialSessionScoreTemplate =
    //     initialScore && scoreLibrary.sessionScores.find((score) => score.name === initialScore.name);

    // const initialVariables: SessionVariables = initialSession
    //     ? (({
    //           ...initialSession.variableInputs,
    //           name: initialSession.name,
    //           sessionUse: undefined,
    //           useEmotionalStateForms: true,
    //       } as unknown) as SessionVariables)
    //     : getDefaultVariablesForSession({
    //           sessionType,
    //           medicine,
    //           score: initialScoreTemplate,
    //       });

    const initialScore = useMemo(
        () =>
            'score' in defaults
                ? defaults.score
                : createScoreForTemplate(defaults.template, scoreLibrary, defaulVariableInputs),
        [],
    );

    const [score, setScore] = useState<SessionScore>(initialScore);

    const scoreTemplate = 'template' in defaults ? defaults.template : undefined;
    const [dirtySessionVariables, setDirtySessionVariables] = useState<SessionVariables>({});

    const getVariableInputs = (): SessionVariables => ({
        ...defaulVariableInputs,
        ...dirtySessionVariables,
    });

    // TODO: Improve Typings here
    const updateVariableInputs = (updates: SessionVariables) => {
        const newDirtySessionVariables = { ...dirtySessionVariables, ...updates };
        const updatedVariables = { ...getVariableInputs(), ...newDirtySessionVariables };

        if (updates.totalDuration) {
            setDirtySessionVariables(newDirtySessionVariables);
            setInteractedInputs((i) => [...i, Input.Duration]);

            const hasTemplateBeenEdited = isCustomised;

            if (!defaulVariableInputs.totalDuration && 'template' in defaults) {
                const score = createScoreForTemplate(defaults.template, scoreLibrary, {
                    ...defaulVariableInputs,
                    ...updates,
                });
                setScore(score);
                return;
            }
            if (!hasTemplateBeenEdited && scoreTemplate) {
                const templatePlan = planSessionFromTemplate(scoreTemplate, scoreLibrary, updatedVariables);
                const scoreFromTemplatePlan = scoreUtils.setWavepaths(
                    mapScoreTemplateToScore(scoreTemplate),
                    templatePlan,
                );
                setScore(scoreFromTemplatePlan);
                return;
            }
            const plannedWavepaths = planSessionWavepaths(
                score.wavepaths,
                scoreLibrary,
                updates.totalDuration as number,
            );

            if (plannedWavepaths) {
                const scoreFromPresetPlan = scoreUtils.setWavepaths(score, plannedWavepaths);
                setScore(scoreFromPresetPlan);
            }
            return;
        }

        setDirtySessionVariables(newDirtySessionVariables);
    };

    const updateWavepathsWith = (update: (wavepaths: Wavepath[], index: number, newPath?: Wavepath) => Wavepath[]) => (
        waveIndex: number,
        newPath?: Wavepath,
    ) => {
        const updatedWavepaths = update(score.wavepaths, waveIndex, newPath);

        const { plannedWavepaths, sessionDuration } = planSessionWavepathsAdjustingDuration(
            updatedWavepaths,
            scoreLibrary,
            getVariableInputs().totalDuration as number,
        );

        const updatedScore = scoreUtils.setWavepaths(score, plannedWavepaths);
        setScore(updatedScore);
        setDirtySessionVariables({ ...dirtySessionVariables, totalDuration: sessionDuration });
        setInteractedInputs((i) => [...i, Input.Score]);
    };

    const movePathInScore = (index: number, targetIndex: number) => {
        const moveToIndex = (wavepaths: Wavepath[], waveIndex: number) => arrayMove(wavepaths, waveIndex, targetIndex);
        updateWavepathsWith(moveToIndex)(index);
    };

    const updatePathInScore = (
        waveIndex: number,
        {
            pathId,
            pathScore,
            duration,
            preferredDuration,
        }: Pick<Wavepath, 'pathId' | 'pathScore' | 'duration' | 'preferredDuration'>,
    ) => {
        updateWavepathsWith(replaceAtIndex)(waveIndex, {
            id: score.wavepaths[waveIndex].id,
            pathId,
            pathScore,
            duration,
            preferredDuration,
            type: score.wavepaths[waveIndex].type,
        });
    };

    const addPathToScore = (waveIndex: number, { pathId, pathScore }: Pick<Wavepath, 'pathId' | 'pathScore'>) => {
        updateWavepathsWith(insertAtIndex)(waveIndex, {
            id: uuidv4(),
            pathId,
            pathScore,
            type: WavepathType.SCHEDULED,
        });
    };

    const removePathFromScore = updateWavepathsWith(removeAtIndex);

    const sessionVariableInputs = getVariableInputs();
    const missingVariableInputs = REQUIRED_VARIABLE_INPUTS.filter((i) => isUndefined(sessionVariableInputs[i]));

    const upsertVoiceOverStage = ({ index, voiceOver }: { index?: number; voiceOver?: VoiceoverStage }) => {
        if (index === undefined && !voiceOver) {
            throw new Error('No index or no voiceOver');
        }

        let currentVoiceOverArr = score.voiceover ? [...score.voiceover] : [];
        currentVoiceOverArr = upsertVoiceOverArray({ index, currentVoiceOverArr, newVoiceOver: voiceOver });

        const newScore = {
            ...score,
            voiceover: currentVoiceOverArr,
        };
        console.debug('Setting new score voiceover', newScore.voiceover);
        setScore(newScore);
    };

    return {
        score,
        scoreTemplate: isCustomised ? undefined : scoreTemplate?.id,
        sessionVariableInputs,
        updateVariableInputs,
        missingVariableInputs,
        updatePathInScore,
        addPathToScore,
        removePathFromScore,
        movePathInScore,
        dirtyInputs: interactedInputs,
        upsertVoiceOverStage,
    };
};

export default useScorePlanner;
