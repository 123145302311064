import { createContext, useEffect, useRef, useState } from 'react';

import UserEvents from '../../UserEvents';

export const getIsOnline = (): boolean | null => {
    if (typeof window === 'undefined') {
        return null;
    }
    return navigator.onLine;
};

const useIsOnline = () => {
    const initialStatus = getIsOnline();
    const [isOnline, setOnlineStatus] = useState<boolean | null>(initialStatus);
    // expose Ref so it can always be read by reference from the object for async operations inbetween renders
    const onlineStatusRef = useRef<boolean | null>(isOnline);

    useEffect(() => {
        const handleOffline = () => {
            setOnlineStatus(false);
            onlineStatusRef.current = false;
            UserEvents.goingOffline();
        };
        const handleOnline = () => {
            setOnlineStatus(true);
            onlineStatusRef.current = true;
        };
        if (typeof window !== 'undefined') {
            window.addEventListener('offline', handleOffline);
            window.addEventListener('online', handleOnline);

            return () => {
                window.removeEventListener('offline', handleOffline);
                window.removeEventListener('online', handleOnline);
            };
        }
    }, []);
    return { isOnline, onlineStatusRef };
};

export const OnlineContext = createContext<ReturnType<typeof useIsOnline>>(
    (null as unknown) as ReturnType<typeof useIsOnline>,
);

export default useIsOnline;
