import { range } from 'lodash';

export enum CssKeys {
    disabledOpacity = '--disabled-opacity',
    colorShadow = '--color-shadow',
    colorLight = '--color-light',
    colorMid = '--color-mid',
    colorDark = '--color-dark',
    colorHighlight = '--color-highlight',
    colorTransitionTimeInSecs = '--color-transition-time',
    indicatorHeight = '--indicator-height',
}

export const buildStepsForValueRangeX = (min: number, max: number) => {
    return range(min, max);
};

export function setCssProperty(key: CssKeys, value: any): void {
    try {
        document.getElementById('intensity-slider-root')!.style.setProperty(key, value);
    } catch (e) {}
}

// Font awesome caret-down
export const indicator = `
M 182.6 182.7 
c 12.5 -12.5 12.5 -32.8 -0 -45.3 
l -128 -128 
c -9.2 -9.2 -22.9 -11.9 -34.9 -6.9 
s -19.8 16.6 -19.8 29.6 
L 0 288.1 
c 0 12.9 7.8 24.6 19.8 29.6 
s 25.7 2.2 34.9 -6.9 
l 128 -128 
z
`;

export const upperCircle = `
M -20 0
a 1 1 0 1 1 40 0
a 1 1 0 1 1 -40 0
z`;

export const upperSpacer = `
M -16 12
c 1.13 1.5 2.47 2.84 3.98 3.97
v 0.05
c 4.85 3.65 8 9.44 8 15.97
s -3.15 12.32 -8 15.97
v 0.05
c -1.5 1.13 -2.84 2.47 -3.98 3.97
h 31.96
c -1.13 -1.5 -2.47 -2.84 -3.98 -3.97 v -0.05
c -4.85 -3.65 -8 -9.44 -8 -15.97
s 3.15 -12.32 8 -15.97
v -0.05
c 1.5 -1.13 2.84 -2.47 3.98 -3.97
z`;
export const upperBody = `
M -20 64
a 1 1 0 1 1 40 0
v 70
a 1 1 0 1 1 -40 0
z`;
export const middleSpacer = `
M -16 146
c 1.13 1.5 2.47 2.84 3.98 3.97
v 0.05
c 4.85 3.65 8 9.44 8 15.97
s -3.15 12.32 -8 15.97
v 0.05
c -1.5 1.13 -2.84 2.47 -3.98 3.97
h 31.96
c -1.13 -1.5 -2.47 -2.84 -3.98 -3.97 v -0.05
c -4.85 -3.65 -8 -9.44 -8 -15.97
s 3.15 -12.32 8 -15.97
v -0.05
c 1.5 -1.13 2.84 -2.47 3.98 -3.97
z`;

export const lowerBody = `
M -20 198
a 1 1 0 1 1 40 0
v 42
a 1 1 0 1 1 -40 0
z`;

export const lowerSpacer = `
M -16 252
c 1.13 1.5 2.47 2.84 3.98 3.97
v 0.05
c 4.85 3.65 8 9.44 8 15.97
s -3.15 12.32 -8 15.97
v 0.05
c -1.5 1.13 -2.84 2.47 -3.98 3.97
h 31.96
c -1.13 -1.5 -2.47 -2.84 -3.98 -3.97 v -0.05
c -4.85 -3.65 -8 -9.44 -8 -15.97
s 3.15 -12.32 8 -15.97
v -0.05
c 1.5 -1.13 2.84 -2.47 3.98 -3.97
z`;

export const lowerCircle = `
M -20 304
a 1 1 0 1 1 40 0
a 1 1 0 1 1 -40 0
z`;
