import styled from '@emotion/styled';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { createPortal } from 'react-dom';

const useStyles = makeStyles({
    container: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        pointerEvents: 'none',
    },
});

export interface DepthStateVisualiserProps {
    currentDepth?: number;
    colorHex: string;
    colorTransitionTimeInSecs: number;
}

const MinimalDepthStateVisualizer = styled.div<{ colorHex?: string; colorTransitionTimeInSecs: number }>(
    ({ colorHex, colorTransitionTimeInSecs }) => ({
        width: '100%',
        height: '100%',
        backgroundColor: colorHex,
        opacity: 0.3,
        willChange: 'background-color',
        transition: `background-color ${colorTransitionTimeInSecs}s`,
    }),
);

export const DepthStateVisualiser: React.FC<DepthStateVisualiserProps> = ({ colorHex, colorTransitionTimeInSecs }) => {
    const styles = useStyles();

    const backgroundContainer = document.getElementById('backgroundcontainer');
    if (!backgroundContainer) {
        throw new Error('Document does not have a #backgroundcontainer element for depth state visualiser');
    }

    return createPortal(
        <div className={styles.container}>
            <MinimalDepthStateVisualizer colorHex={colorHex} colorTransitionTimeInSecs={colorTransitionTimeInSecs} />
        </div>,
        backgroundContainer,
    );
};
