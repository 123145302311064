import { sample } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    CoreEmotionalAtmosphere,
    PathScore,
    ScheduledExperimentalBridgePathScore,
    ScheduledWavepath,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { DropdownControl, SegmentedControl } from '@/component-library';
import { ControlOption } from '@/component-library/components/Control/types';

import * as infoCopy from './infoCopy';
import {
    convertListToOptions,
    extractFromEmotionsFromScores,
    extractMusicOptionsFromScores,
    extractToEmotionsFromScores,
} from './pathScoreFns';

interface BridgeWaveSelectorProps {
    pathScores: ScheduledExperimentalBridgePathScore[];
    wavepath: ScheduledWavepath;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
}

export const isBridgePathScore = (p: PathScore): p is ScheduledExperimentalBridgePathScore =>
    p.direction === TherapeuticDirection.BRIDGE;

const filterScoresByFromEmotion = (
    scores: ScheduledExperimentalBridgePathScore[],
    fromEmotion?: CoreEmotionalAtmosphere,
) => scores.filter((score) => (fromEmotion && score.emotion.from ? score.emotion.from === fromEmotion : true));

const filterScoresByToEmotion = (scores: ScheduledExperimentalBridgePathScore[], toEmotion?: CoreEmotionalAtmosphere) =>
    scores.filter((score) => (toEmotion && score.emotion.to ? score.emotion.to === toEmotion : true));

const filterScoresByMusic = (scores: ScheduledExperimentalBridgePathScore[], music?: string) =>
    scores.filter((score) => (music ? score.music === music : true));

function BridgeWaveSelector({ pathScores, wavepath, onUpdateWave }: BridgeWaveSelectorProps): JSX.Element {
    const defaultFrom = isBridgePathScore(wavepath.pathScore)
        ? wavepath.pathScore.emotion.from
        : pathScores[0].emotion.from;
    const [fromEmotion, setFromEmotion] = useState(defaultFrom);

    const defaultTo = isBridgePathScore(wavepath.pathScore) ? wavepath.pathScore.emotion.to : pathScores[0].emotion.to;
    const [toEmotion, setToEmotion] = useState(defaultTo);

    const defaultMusic = isBridgePathScore(wavepath.pathScore) ? wavepath.pathScore.music : pathScores[0].music;
    const [music, setMusic] = useState(defaultMusic);

    const fromEmotionFilteredScores = filterScoresByFromEmotion(pathScores, fromEmotion);
    const toEmotionFilteredScores = filterScoresByToEmotion(fromEmotionFilteredScores, toEmotion);
    const musicFilteredScores = filterScoresByMusic(toEmotionFilteredScores, music);

    const musicOptions = [...extractMusicOptionsFromScores(toEmotionFilteredScores)].map(
        (value): ControlOption<string> => ({
            label: value,
            value: value,
            disabled: false,
        }),
    );

    if (toEmotionFilteredScores.length == 0) {
        setToEmotion(fromEmotionFilteredScores[0].emotion.to);
    }

    const selectedMusicOption = musicOptions.find((option) => option.value == music);
    if (musicOptions.length && (!music || !selectedMusicOption)) {
        if (musicOptions.find((x) => x.value == 'Any')) {
            setMusic('Any');
        } else {
            setMusic(musicOptions[0].value);
        }
    }

    useEffect(() => {
        const currentPathQualifies = musicFilteredScores.find((x) => x.id == wavepath?.pathId);

        if (!currentPathQualifies) {
            console.debug('Current path doesnt qualify for criteria', { wavepath });
            const pathScore = sample(musicFilteredScores);
            if (pathScore) {
                onUpdateWave({
                    ...wavepath,
                    duration: pathScore.duration,
                    preferredDuration: 'Auto',
                    pathScore,
                    pathId: pathScore.id,
                });
            }
        }
    }, [fromEmotion, toEmotion, music]);

    return (
        <>
            <SegmentedControl
                name="wave-fromEmotion"
                heading={'From'}
                canSave={false}
                size="small"
                colour="dark"
                options={convertListToOptions(
                    [
                        CoreEmotionalAtmosphere.SILENCE,
                        CoreEmotionalAtmosphere.STILLNESS,
                        CoreEmotionalAtmosphere.VITALITY,
                        CoreEmotionalAtmosphere.BITTERSWEET,
                        CoreEmotionalAtmosphere.TENSION,
                    ],
                    extractFromEmotionsFromScores(pathScores),
                )}
                value={fromEmotion}
                onChange={setFromEmotion}
                info={infoCopy.emotionFrom}
            />
            <SegmentedControl
                name="wave-toEmotion"
                heading={'To'}
                canSave={false}
                size="small"
                colour="dark"
                options={convertListToOptions(
                    [
                        CoreEmotionalAtmosphere.SILENCE,
                        CoreEmotionalAtmosphere.STILLNESS,
                        CoreEmotionalAtmosphere.VITALITY,
                        CoreEmotionalAtmosphere.BITTERSWEET,
                        CoreEmotionalAtmosphere.TENSION,
                    ],
                    extractToEmotionsFromScores(fromEmotionFilteredScores),
                )}
                value={toEmotion}
                onChange={setToEmotion}
                info={infoCopy.emotionTo}
            />
            <DropdownControl
                name="wave-music"
                heading={'Music'}
                canSave={false}
                size="small"
                colour="dark"
                options={musicOptions}
                value={music}
                onChange={setMusic}
                info={infoCopy.music}
            />
        </>
    );
}

export default BridgeWaveSelector;
