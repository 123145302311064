import firebase from 'firebase/app';
import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';
import useSWR from 'swr';
import { UserData } from 'wavepaths-shared/core';

import useMySubscription from '@/hooks/useMySubscription';

import { getFeaturesForUser } from './featuresApi';

const key = '/features/';

const options = {
    dedupingInterval: 24 * 60 * 60 * 1000,
};

export function useFeatures({
    user,
    userLoaded,
    fbUser,
}: {
    user: UserData | undefined;
    userLoaded: boolean;
    fbUser: firebase.User | undefined;
}): {
    isEnabled: (feature: string) => boolean;
    isLoading: boolean;
} {
    const [membershipCacheKey] = useLocalStorage('membershipCacheKey', '');

    const { subscription, isLoading: isSubscriptionLoading } = useMySubscription({ fbUser });

    const { data, error } = useSWR(
        userLoaded ? [key, user?.uid, membershipCacheKey, subscription?.status] : null,
        async () => await getFeaturesForUser({ userId: user?.uid }),
        options,
    );

    const productFeatures = subscription && subscription.status !== 'inactive' ? subscription.features : [];
    const featuresLoading = !error && !data;

    const availableFeatures = [...(data ?? []), ...productFeatures];

    const isEnabled = useCallback((feature: string) => availableFeatures.includes(feature), [availableFeatures]);
    //console.debug('useFeatures', { featuresLoading, userLoaded });
    return {
        isEnabled,
        isLoading: !userLoaded || featuresLoading || isSubscriptionLoading,
    };
}
