import styled from '@emotion/styled';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import React, { useCallback, useContext, useState } from 'react';
import {
    CoreEmotionalAtmosphere,
    SessionRenderType,
    SessionScore,
    SessionState,
    SessionType,
    VoiceoverStage,
} from 'wavepaths-shared/core';
import { PathType, Wavepath } from 'wavepaths-shared/core';
import { formatDurationMinutes, formatDurationSeconds } from 'wavepaths-shared/util/dateUtils';

import { Button, EvaIcon } from '@/component-library';
import { Typography } from '@/component-library';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { getCEAColour } from '@/util/ceaColours';

import { FeedbackButtons } from '../../../common/components/notifications/FeedbackButtons';
import { Connection, useSessionRemoteTick } from '../../../common/hooks/useSessionTick';
import { AudioPlayerContext } from '../../sessions/EndedSessionsTable/useAudioPlayer';
import { CustomVoiceoverForm, VoiceOverStagesContext } from '../../templateInfo';
import { Queueable } from '../actionQueue/useActionQueue';
import CeaController from '../ceaButtons/CeaController';
import IntensityControllerContainer from '../depthSlider/IntensityController';
import { InstrumentRefreshButton } from '../InstrumentRefresh/InstrumentRefreshButton';
import { UseInstrumentRefreshR } from '../InstrumentRefresh/useInstrumentRefresh';
import { WaveEndCountdownV2 } from '../WaveEndCountdownV2';
import { SessionContext } from './useSession';

const Container = styled.div({
    position: 'relative',
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'row',
    alignItems: 'center',
    justifyItems: 'center',
    gap: '5px',
});

const StyledCard = styled(Card)({
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'repeat(3, min-content)',
    background: 'rgba(255,255,255,0.9)',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginBottom: 0,
});

const CardContent = styled.div({
    padding: '1vw 1vh',
    alignItems: 'center',
    display: 'flex',
    placeItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
});

const Buttons = styled.div({
    display: 'inline-grid',
    gridAutoFlow: 'column',
});

const WaveInfo = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    justifyItems: 'start',
    alignItems: 'end',
    gridTemplateColumns: 'min-content 1fr',
    gap: 16,
});

const WaveInfoText = styled(Typography)({
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#2C3958',
    lineHeight: 1,
    paddingBottom: 3,
});

// const Divider = styled.div({
//     height: 1,
//     background: '#D3D9E9',
//     margin: '0 24px',
// });

const QueueToggle = styled(Button)({
    padding: 4,
    width: '140px',
});

// const formatPathName = (path: PathScore, targetCEA?: CoreEmotionalAtmosphere) => {
//     const pathIdsWithDifferentNaming = [
//         'silence',
//         's_cea1_a_calm_river',
//         's_cea1_a_calm_waves',
//         's_cea1_a_forest_birds',
//     ];
//     const shouldShowIntensity =
//         path.selectionCriteria?.emotionalIntensity && path.type === PathType.GENERATIVE && path.id !== 'silence';
//     const shouldUseTargetCea =
//         path.type === PathType.GENERATIVE && targetCEA && !pathIdsWithDifferentNaming.includes(path.id);
//     const shouldUseMusic = path.type === PathType.EXPERIMENTAL && path.music.length > 0;

//     const pathName =
//         (shouldShowIntensity ? ` ${path.selectionCriteria?.emotionalIntensity} intensity ` : '') +
//         (shouldUseTargetCea ? targetCEA : '') +
//         (shouldUseMusic && path.type === PathType.EXPERIMENTAL ? path.music : '') +
//         (!shouldShowIntensity && !shouldUseTargetCea && !shouldUseMusic ? path.name : '');

//     return pathName;
// };

const Body = ({
    connection,
    isPrelude,
    isPostlude,
    currentWave,
    isLoading,
    onSkipWave,
}: {
    connection: Connection;
    isPostlude: boolean;
    isPrelude: boolean;
    currentWave: Wavepath | null;
    isLoading: boolean;
    onSkipWave?: () => void;
}) => {
    // const ceaState = useRemoteCEA(connection, () => {
    //     //do nothing
    // });
    // const targetCea = ceaState !== 'loading' ? ceaState.targetCea : undefined;

    const shouldExtensionBeDisabled = !currentWave || currentWave.pathScore.type !== PathType.GENERATIVE;

    if (isLoading) {
        return (
            <WaveInfo>
                <WaveInfoText variant={'h6'}>{'Loading...'}</WaveInfoText>
            </WaveInfo>
        );
    } else if (isPrelude) {
        return (
            <WaveInfo>
                <WaveInfoText variant={'h6'}>Prelude Music</WaveInfoText>
            </WaveInfo>
        );
    } else if (isPostlude) {
        return (
            <WaveInfo>
                <WaveInfoText variant={'h6'}>Postlude Music</WaveInfoText>
            </WaveInfo>
        );
    } else if (!currentWave) {
        return (
            <WaveInfo>
                <WaveInfoText variant={'h6'}>{'Waiting for start'}</WaveInfoText>
            </WaveInfo>
        );
    } else if (currentWave.pathScore.type !== PathType.GENERATIVE) {
        return (
            <WaveInfo>
                <WaveEndCountdownV2
                    wave={currentWave}
                    connection={connection}
                    onSkipWave={onSkipWave}
                    shouldExtensionBeDisabled={shouldExtensionBeDisabled}
                />
                {/* <WaveInfoText variant={'h6'} data-test-id="currentWave">
                    {formatPathName(currentWave.pathScore, targetCea)}
                </WaveInfoText>{' '} */}
            </WaveInfo>
        );
    } else {
        return (
            <WaveInfo>
                <WaveEndCountdownV2
                    wave={currentWave}
                    connection={connection}
                    onSkipWave={onSkipWave}
                    shouldExtensionBeDisabled={shouldExtensionBeDisabled}
                />
                {/* <WaveInfoText variant={'h6'} data-test-id="currentWave">
                    {formatPathName(currentWave.pathScore, targetCea)}
                </WaveInfoText>{' '} */}
            </WaveInfo>
        );
    }
};

export default function CurrentWaveCard({
    initialised,
    connection,
    currentWave,
    nextWave,
    onSkipWave,
    onUpsertVoiceOverStage,
    instrumentRefreshArgs,
    voiceOverStages = [],
    elapsedTimeSecs,
    queueFunction,
    isExpanded,
    setIsExpanded,
}: {
    initialised: boolean;
    connection: Connection;
    currentWave: Wavepath | null;
    nextWave?: Wavepath;
    session: { type: SessionType; score: SessionScore } | undefined;
    instrumentRefreshArgs: UseInstrumentRefreshR;
    onSkipWave?: () => void;
    onUpsertVoiceOverStage?: ({ index, voiceOver }: { index?: number | undefined; voiceOver?: VoiceoverStage }) => void;
    voiceOverStages?: VoiceoverStage[];
    elapsedTimeSecs: number;
    queueFunction: (queueable: Queueable) => void;
    isExpanded: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement {
    const isLoading = !initialised;
    nextWave; //TODO

    const tick = useSessionRemoteTick();
    const voiceOverStagesContext = useContext(VoiceOverStagesContext);
    if (!voiceOverStagesContext) {
        throw new Error('No VoiceOverStagesContext');
    }

    const isPrelude = tick?.sessionState === SessionState.PRELUDE;
    const isPostlude = tick?.sessionState === SessionState.POSTLUDE;
    const isPreludeOrPostlude = isPrelude || isPostlude;

    const [ceaOpen, setCeaOpen] = useState<boolean>(false);
    const [intensityControlOpen, setIntensityControlOpen] = useState<boolean>(false);
    // const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const isCeaChangeable = currentWave !== null;

    const voiceOverStagesSortedDesc1 = [...voiceOverStages].sort((a, b) => b.timing.from - a.timing.from);
    const voiceOverStageToPlay1 = voiceOverStagesSortedDesc1.find(
        (x) => x.timing.from <= elapsedTimeSecs && x.timing.to > elapsedTimeSecs,
    );
    const sessionDurationSecs = Math.floor((tick?.sessionDuration ?? 0) / 1000);

    const audioPlayerContext = useContext(AudioPlayerContext);

    const currentBufferSizeSecs = audioPlayerContext?.currentBufferSizeSecs;

    const bufferFormatted =
        currentBufferSizeSecs !== undefined
            ? currentBufferSizeSecs > 60
                ? formatDurationMinutes(currentBufferSizeSecs * 1000)
                : formatDurationSeconds(currentBufferSizeSecs * 1000)
            : undefined;

    const session = useContext(SessionContext);
    if (session === undefined) throw new Error('missing session context');

    const queueFunctionAndCloseIntensityDialog = useCallback((...args: Parameters<typeof queueFunction>) => {
        setIntensityControlOpen(false);
        queueFunction(...args);
    }, []);
    return (
        <Container>
            <StyledCard>
                <CardContent>
                    <Body
                        currentWave={currentWave}
                        connection={connection}
                        isLoading={isLoading}
                        isPrelude={isPrelude}
                        isPostlude={isPostlude}
                        onSkipWave={onSkipWave && (() => onSkipWave())}
                    />
                    <InstrumentRefreshButton instrumentRefreshArgs={instrumentRefreshArgs} />
                    {isCeaChangeable && (
                        <>
                            <Button variant="outlined" onClick={() => setCeaOpen(true)}>
                                <div style={{ display: 'grid', height: '24px' }}>
                                    <span
                                        style={{
                                            backgroundColor: getCEAColour(CoreEmotionalAtmosphere.STILLNESS),
                                            width: '15px',
                                            height: '6px',
                                        }}
                                    ></span>
                                    <span
                                        style={{
                                            backgroundColor: getCEAColour(CoreEmotionalAtmosphere.BITTERSWEET),
                                            width: '15px',
                                            height: '6px',
                                        }}
                                    ></span>
                                    <span
                                        style={{
                                            backgroundColor: getCEAColour(CoreEmotionalAtmosphere.VITALITY),
                                            width: '15px',
                                            height: '6px',
                                        }}
                                    ></span>
                                    <span
                                        style={{
                                            backgroundColor: getCEAColour(CoreEmotionalAtmosphere.TENSION),
                                            width: '15px',
                                            height: '6px',
                                        }}
                                    ></span>
                                </div>
                                <TypographyV2 size="text-sm">Emotionality</TypographyV2>
                            </Button>
                            {ceaOpen ? (
                                <Dialog open={ceaOpen} onClose={() => setCeaOpen(false)} maxWidth={'xs'}>
                                    <DialogTitle>Change emotionality</DialogTitle>
                                    <DialogContent
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: '15px',
                                        }}
                                    >
                                        <CeaController onClose={() => setCeaOpen(false)} />
                                        <div>
                                            <Button variant="clear-underlined" onClick={() => setCeaOpen(false)}>
                                                Close
                                            </Button>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                            ) : (
                                <></>
                            )}
                            <Button
                                variant="outlined"
                                onClick={() => setIntensityControlOpen(true)}
                                className={'tour-intensityController'}
                            >
                                <EvaIcon name="bar-chart-2-outline" size={24} />
                                <TypographyV2 size="text-sm">Intensity</TypographyV2>
                            </Button>
                            {intensityControlOpen ? (
                                <Dialog
                                    open={intensityControlOpen}
                                    onClose={() => setIntensityControlOpen(false)}
                                    maxWidth={'md'}
                                >
                                    <DialogTitle>Change intensity</DialogTitle>
                                    <DialogContent
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: '15px',
                                        }}
                                    >
                                        <IntensityControllerContainer
                                            opacity={initialised ? 1 : 0}
                                            connection={connection}
                                            queueFunction={queueFunctionAndCloseIntensityDialog}
                                            sliderVisible={!isPrelude && !isPostlude}
                                        />

                                        <div>
                                            <Button
                                                variant="clear-underlined"
                                                onClick={() => setIntensityControlOpen(false)}
                                            >
                                                Close
                                            </Button>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                            ) : (
                                <></>
                            )}
                        </>
                    )}

                    <Buttons>
                        <FeedbackButtons />
                    </Buttons>
                </CardContent>
            </StyledCard>
            <StyledCard>
                <CardContent>
                    {!isLoading && !isPreludeOrPostlude ? (
                        voiceOverStageToPlay1 !== undefined ? (
                            <CustomVoiceoverForm
                                key={voiceOverStageToPlay1.stage}
                                customVoiceOvers={voiceOverStagesContext.customVoiceOvers}
                                sessionDuration={sessionDurationSecs}
                                onEdit={onUpsertVoiceOverStage}
                                initialValue={voiceOverStageToPlay1}
                                index={voiceOverStages.findIndex((x) => x.stage == voiceOverStageToPlay1.stage)}
                                voiceOverStages={voiceOverStages}
                                elapsedTimeSecs={elapsedTimeSecs}
                                forceTimingTo={'now'}
                            ></CustomVoiceoverForm>
                        ) : (
                            <>
                                {elapsedTimeSecs > 0 && elapsedTimeSecs < sessionDurationSecs && (
                                    <CustomVoiceoverForm
                                        key={'notPlaying'}
                                        customVoiceOvers={voiceOverStagesContext.customVoiceOvers}
                                        sessionDuration={sessionDurationSecs}
                                        onAdd={onUpsertVoiceOverStage}
                                        voiceOverStages={voiceOverStages}
                                        elapsedTimeSecs={elapsedTimeSecs}
                                        forceTimingTo={'now'}
                                    ></CustomVoiceoverForm>
                                )}
                            </>
                        )
                    ) : (
                        <></>
                    )}
                </CardContent>
            </StyledCard>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                {session.renderType === SessionRenderType.PREDICTIVE_COMPOSED &&
                    bufferFormatted !== undefined &&
                    currentBufferSizeSecs !== undefined && (
                        <Button variant="solid-light" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                            <EvaIcon
                                name="wifi-outline"
                                size={24}
                                fill={
                                    currentBufferSizeSecs > 60 ? 'green' : currentBufferSizeSecs > 20 ? 'orange' : 'red'
                                }
                            />
                            <span style={{ width: '100px' }}>
                                <TypographyV2 size={'text-sm'}>{bufferFormatted}</TypographyV2>
                            </span>
                        </Button>
                    )}

                {!isLoading && !isPreludeOrPostlude ? (
                    <QueueToggle
                        aria-label="Expand waves queue"
                        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
                        variant="solid-light"
                        className="tour-toggleWaveQueue"
                    >
                        <TypographyV2 size="text-sm">{isExpanded ? 'Close Next up' : 'Show Next up'}</TypographyV2>
                        {isExpanded ? (
                            <EvaIcon key={'close'} name={'close-outline'} size={24} />
                        ) : (
                            <EvaIcon key={'open'} name={'chevron-down'} size={24} />
                        )}
                    </QueueToggle>
                ) : (
                    <></>
                )}
            </div>
        </Container>
    );
}
