import EventEmitter from 'events';
import { isNumber, uniqBy, without } from 'lodash';
import { MusicStateEventType, RequestType, SessionRequest, TimestampedSessionEvent } from 'wavepaths-shared/core';

import { HLS_BUFFER_LATENCY } from './hlsUtils';

export type PendingBehaviour = {
    pendingUntilEvent: string;
    exclusiveForEvent: boolean;
    fixedRemoveDelayAfterEvent?: number;
    removeDelayAttrAfterEvent?: string;
    maximumTimeToShow?: number;
    skipDelayOnReplay?: boolean;
    behaviourMessage: string;
};

export class PendingBehaviourTimer extends EventEmitter {
    private pendingBehaviours: PendingBehaviour[] = [];
    private pendingBehavioursWithScheduledRemoval = new Set<PendingBehaviour>();

    constructor() {
        super();
    }

    onConnecting() {
        // Set initial pending behaviours to be shown until we've got an audio stream.
        setTimeout(() => {
            this.pendingBehaviours = [
                ...this.pendingBehaviours,
                {
                    behaviourMessage: 'launch',
                    pendingUntilEvent: 'audioStreamingStarted',
                    exclusiveForEvent: false,
                },
                // {
                //     behaviourMessage: 'audioBuffering',
                //     pendingUntilEvent: 'audioPlayingStarted',
                //     exclusiveForEvent: false,
                //     fixedRemoveDelayAfterEvent: 5000, // Keep "buffering" during perceived silence at the beginning of Stern's s-curve fadein
                // },
                {
                    behaviourMessage: 'initialFadeIn',
                    pendingUntilEvent: 'audioStreamingStarted',
                    exclusiveForEvent: false,
                    fixedRemoveDelayAfterEvent: 35000,
                    skipDelayOnReplay: true, // When replaying log (i.e. refreshed page mid-session), immediately remove this when stream starts
                },
            ];
            this.emit('update', this.pendingBehaviours);
        });
    }

    // onAudioPlaying() {
    //     const pendingBehaviour = this.pendingBehaviours.find((p) => p.pendingUntilEvent === 'audioPlayingStarted');
    //     console.debug('pending on audioPlayingStarted', pendingBehaviour);
    //     this.pendingBehaviours = this.pendingBehaviours.filter((p) => p !== pendingBehaviour);
    //     if (pendingBehaviour) {
    //         this.scheduleBehaviourRemoval(pendingBehaviour, false);
    //     }
    // }

    onStop() {
        this.pendingBehaviours = [
            { pendingUntilEvent: 'stoppedSession', exclusiveForEvent: false, behaviourMessage: 'stop' },
        ];
        this.emit('update', this.pendingBehaviours);
    }

    onRequest(evt: SessionRequest) {
        switch (evt.type) {
            case RequestType.StartSessionTimers:
                this.addBehaviour({
                    pendingUntilEvent: 'startSessionTimers',
                    exclusiveForEvent: false,
                    behaviourMessage: 'start',
                    fixedRemoveDelayAfterEvent: HLS_BUFFER_LATENCY,
                });
                break;
            case RequestType.ToggleManualControl:
                this.addBehaviour({
                    pendingUntilEvent: 'toggleManualControl',
                    exclusiveForEvent: false,
                    behaviourMessage: evt.manual ? 'manualOn' : 'manualOff',
                });
                break;
            case RequestType.ToggleAutoGuide:
                this.addBehaviour({
                    pendingUntilEvent: 'toggleAutoGuide',
                    exclusiveForEvent: false,
                    behaviourMessage: evt.auto ? 'autoOn' : 'autoOff',
                });
                break;
            case RequestType.SkipWave:
                this.addBehaviour({
                    pendingUntilEvent: 'enteredStage',
                    exclusiveForEvent: true,
                    behaviourMessage: 'skipToWave',
                    fixedRemoveDelayAfterEvent: 1500,
                });
                break;
            case RequestType.AddPathAtIndex:
            case RequestType.UpdatePathAtIndex:
            case RequestType.RemovePathAtIndex:
                this.addBehaviour({
                    pendingUntilEvent: 'reviseSessionPlan',
                    exclusiveForEvent: false,
                    behaviourMessage: 'reviseSessionPlan',
                    fixedRemoveDelayAfterEvent: 1500,
                });
                break;
        }
    }

    onEventReceive(evt: TimestampedSessionEvent, isReplay: boolean) {
        if (!isReplay) {
            switch (evt.event) {
                case MusicStateEventType.CeaChangeStarted: {
                    this.addBehaviour({
                        pendingUntilEvent: MusicStateEventType.CeaChangeCompleted,
                        exclusiveForEvent: false,
                        behaviourMessage: 'changeCea',
                        maximumTimeToShow: 120 * 1000,
                        fixedRemoveDelayAfterEvent: HLS_BUFFER_LATENCY,
                    });
                    break;
                }
                case MusicStateEventType.DepthChangeStarted: {
                    this.addBehaviour({
                        pendingUntilEvent: MusicStateEventType.DepthChangeCompleted,
                        exclusiveForEvent: false,
                        fixedRemoveDelayAfterEvent: HLS_BUFFER_LATENCY,
                        behaviourMessage:
                            evt.previousDepth && evt.targetDepth
                                ? evt.previousDepth < evt.targetDepth
                                    ? 'increasingIntensity'
                                    : 'decreasingIntensity'
                                : 'changingIntensity',
                        maximumTimeToShow: 120 * 1000,
                    });
                    break;
                }
                case MusicStateEventType.InstrumentRefreshStarted:
                    this.addBehaviour({
                        pendingUntilEvent: MusicStateEventType.InstrumentRefreshCompleted,
                        exclusiveForEvent: true,
                        behaviourMessage: 'refreshMusic',
                        maximumTimeToShow: 120 * 1000,
                        fixedRemoveDelayAfterEvent: HLS_BUFFER_LATENCY,
                    });
                    break;
            }
        }

        for (const pendingBehaviour of this.pendingBehaviours) {
            if (
                pendingBehaviour.pendingUntilEvent === evt.event &&
                !this.pendingBehavioursWithScheduledRemoval.has(pendingBehaviour)
            ) {
                this.scheduleBehaviourRemoval(pendingBehaviour, isReplay, evt);
                if (pendingBehaviour.exclusiveForEvent) {
                    return;
                }
            }
        }
    }

    private addBehaviour(behaviour: PendingBehaviour) {
        this.pendingBehaviours = uniqBy([...this.pendingBehaviours, behaviour], 'behaviourMessage');
        this.emit('update', this.pendingBehaviours);
        if (isNumber(behaviour.maximumTimeToShow)) {
            setTimeout(() => {
                this.pendingBehaviours = without(this.pendingBehaviours, behaviour);
                this.emit('update', this.pendingBehaviours);
            }, behaviour.maximumTimeToShow);
        }
    }

    private scheduleBehaviourRemoval(behaviourToRemove: PendingBehaviour, isReplay: boolean, context?: any) {
        let delay = 0;
        if (!isReplay || !behaviourToRemove.skipDelayOnReplay) {
            if (isNumber(behaviourToRemove.fixedRemoveDelayAfterEvent)) {
                delay += behaviourToRemove.fixedRemoveDelayAfterEvent;
            }
            if (behaviourToRemove.removeDelayAttrAfterEvent && context?.[behaviourToRemove.removeDelayAttrAfterEvent]) {
                delay += context[behaviourToRemove.removeDelayAttrAfterEvent];
            }
        }
        this.pendingBehavioursWithScheduledRemoval.add(behaviourToRemove);
        setTimeout(() => {
            this.pendingBehaviours = without(this.pendingBehaviours, behaviourToRemove);
            this.pendingBehavioursWithScheduledRemoval.delete(behaviourToRemove);
            this.emit('update', this.pendingBehaviours);
        }, delay);
    }
}
