import { useEffect, useRef, useState } from 'react';

export const useDebounce = <T,>(value: T, delay = 666) => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const debouncer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => clearTimeout(debouncer);
    }, [value, delay]);

    return debouncedValue;
};

export const useDebouncedState = <T,>(
    initialValue: T,
    delay = 500,
): [value: T, setValue: (value: T) => void, immediateValue: T, setValueForced: (value: T) => void] => {
    const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);
    const [immediateValue, setImmediateValue] = useState<T>(initialValue);

    const timer = useRef<NodeJS.Timeout>();
    const setAction = (value: T) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        setImmediateValue(value);
        timer.current = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
    };

    const forceSetAction = (value: T) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        setImmediateValue(value);
        setDebouncedValue(value);
    };

    return [debouncedValue, setAction, immediateValue, forceSetAction];
};
