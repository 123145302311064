import * as Sentry from '@sentry/browser';
import { useContext, useEffect, useRef } from 'react';

import { TestHooksContext } from './useTestHooks';

export const useThreadMonitor = ({ enabled }: { enabled: boolean }) => {
    const testHooks = useContext(TestHooksContext);
    const firstAliveMoment = useRef(Date.now());
    const lastAliveMoment = useRef(Date.now());
    const INTERVAL = 1000;
    const TOLERANCE = 4000;
    const BOOT_GRACE_TIME = 10000;

    const timer = useRef<NodeJS.Timeout | undefined>(undefined);

    const checkInterval = () => {
        try {
            const now = Date.now();

            const isBootTime = now < firstAliveMoment.current + BOOT_GRACE_TIME;
            if (isBootTime) {
                return;
            }

            const delta = now - lastAliveMoment.current;
            const lagMs = delta - INTERVAL;
            if (lagMs > TOLERANCE) {
                console.debug('UX Thread blocked, lag', lagMs, ' above allowed max ', TOLERANCE);
                Sentry.captureMessage('UX Thread blocked', {
                    extra: {
                        ['lagMs']: lagMs,
                    },
                });
            } else if (testHooks?.debugUXThread) {
                console.debug({ info: 'UX Thread lag', lagMs });
            }
        } finally {
            lastAliveMoment.current = Date.now();
            timer.current = setTimeout(checkInterval, INTERVAL);
        }
    };

    useEffect(() => {
        if (enabled) {
            lastAliveMoment.current = Date.now();
            timer.current = setTimeout(checkInterval, INTERVAL);
        } else {
            timer.current && clearTimeout(timer.current);
        }
        return () => timer.current && clearTimeout(timer.current);
    }, [enabled]);
};
