import { AdministrationRoute, DosageLevel, SessionScoreModality } from 'wavepaths-shared/core';

import { NonPATModality, PATModality } from './types';

const KETAMINE: PATModality = {
    id: 'ketamine',
    name: SessionScoreModality.KETAMINE,
    type: 'PAT',
    administrations: [
        {
            route: AdministrationRoute.INTRAMUSCULAR,
            dosage: DosageLevel.LOW,
            durationMins: 55,
            phases: [],
        },
        {
            route: AdministrationRoute.INTRAMUSCULAR,
            dosage: DosageLevel.MEDIUM,
            durationMins: 65,
            phases: [],
        },
        {
            route: AdministrationRoute.INTRAMUSCULAR,
            dosage: DosageLevel.HIGH,
            durationMins: 75,
            phases: [],
        },
        {
            route: AdministrationRoute.INTRAVENOUS,
            dosage: DosageLevel.LOW,
            durationMins: 35,
            phases: [],
        },
        {
            route: AdministrationRoute.INTRAVENOUS,
            dosage: DosageLevel.MEDIUM,
            durationMins: 60,
            phases: [],
        },
        {
            route: AdministrationRoute.INTRAVENOUS,
            dosage: DosageLevel.HIGH,
            durationMins: 75,
            phases: [],
        },
        {
            route: AdministrationRoute.SUBLINGUAL,
            dosage: DosageLevel.VERY_LOW,
            durationMins: 35,
            phases: [],
        },
        {
            route: AdministrationRoute.SUBLINGUAL,
            dosage: DosageLevel.LOW,
            durationMins: 65,
            phases: [],
        },
        {
            route: AdministrationRoute.SUBLINGUAL,
            dosage: DosageLevel.MEDIUM,
            durationMins: 110,
            phases: [],
        },
        {
            route: AdministrationRoute.SUBLINGUAL,
            dosage: DosageLevel.HIGH,
            durationMins: 120,
            phases: [],
        },
        {
            route: AdministrationRoute.NASAL,
            dosage: DosageLevel.VERY_LOW,
            durationMins: 35,
            phases: [],
        },
        {
            route: AdministrationRoute.NASAL,
            dosage: DosageLevel.LOW,
            durationMins: 45,
            phases: [],
        },
        {
            route: AdministrationRoute.NASAL,
            dosage: DosageLevel.MEDIUM,
            durationMins: 55,
            phases: [],
        },
        {
            route: AdministrationRoute.NASAL,
            dosage: DosageLevel.HIGH,
            durationMins: 65,
            phases: [],
        },
    ],
};

const PSILOCYBIN: PATModality = {
    id: 'psilo',
    type: 'PAT',
    name: SessionScoreModality.PSILOCYBIN,
    administrations: [
        {
            route: AdministrationRoute.ORAL,
            dosage: DosageLevel.LOW,
            durationMins: 240,
            phases: [],
        },
        {
            route: AdministrationRoute.ORAL,
            dosage: DosageLevel.MEDIUM,
            durationMins: 300,
            phases: [],
        },
        {
            route: AdministrationRoute.ORAL,
            dosage: DosageLevel.HIGH,
            durationMins: 360,
            phases: [],
        },
    ],
};

const MDMA: PATModality = {
    id: 'mdma',
    type: 'PAT',
    name: SessionScoreModality.MDMA,
    administrations: [
        {
            route: AdministrationRoute.ORAL,
            dosage: DosageLevel.LOW,
            durationMins: 240,
            phases: [],
        },
        {
            route: AdministrationRoute.ORAL,
            dosage: DosageLevel.MEDIUM,
            durationMins: 300,
            phases: [],
        },
        {
            route: AdministrationRoute.ORAL,
            dosage: DosageLevel.HIGH,
            durationMins: 360,
            phases: [],
        },
    ],
};

// const IBOGAINE: PATModality = {
//     id: 'ibogaine',
//     type: 'PAT',
//     name: SessionScoreModality.IBOGAINE,
//     administrations: [],
// };

// const FLOATATION: NonPATModality = {
//     id: 'float',
//     type: 'Non-PAT',
//     name: SessionScoreModality.FLOATATION,
//     durationMins: 60,
// };

const PSYCHOTHERAPY: NonPATModality = {
    id: 'psychotherapy',
    type: 'Non-PAT',
    name: SessionScoreModality.PSYCHOTHERAPY,
    durationMins: 50,
};

const BREATHWORK: NonPATModality = {
    id: 'breathwork',
    type: 'Non-PAT',
    name: SessionScoreModality.BREATHWORK,
    durationMins: 14,
};

const WELLNESS: NonPATModality = {
    id: 'wellness',
    type: 'Non-PAT',
    name: SessionScoreModality.WELLNESS,
    durationMins: 45,
};

const AMBIENCE: NonPATModality = {
    id: 'ambience',
    type: 'Non-PAT',
    name: SessionScoreModality.AMBIENCE,
    durationMins: 60 * 8,
};

const PREPARATION: NonPATModality = {
    id: 'client-preparation',
    type: 'Non-PAT',
    name: SessionScoreModality.CLIENT_PREPARATION,
    durationMins: 45,
};

// const NO_MEDICINE: NonPATModality = {
//     id: 'no-med',
//     type: 'Non-PAT',
//     name: SessionScoreModality.NONE,
//     durationMins: 35,
// };

export const MODALITIES_V2 = [
    KETAMINE,
    PSILOCYBIN,
    MDMA,
    //IBOGAINE,
    PSYCHOTHERAPY,
    //FLOATATION,
    BREATHWORK,
    WELLNESS,
    AMBIENCE,
    PREPARATION,
];

export const getAllModalities = () => MODALITIES_V2;
