import React, { ReactElement } from 'react';

import { useAuthContext } from '../../auth';

interface IFeatureProps {
    name: string;
    activeComponent?: JSX.Element | ReactElement;
    inactiveComponent?: JSX.Element | ReactElement;
}

export function Feature({ activeComponent = <></>, inactiveComponent = <></>, name }: IFeatureProps): JSX.Element {
    const { isEnabled } = useAuthContext();

    if (isEnabled(name)) return activeComponent;

    return inactiveComponent;
}
