import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import LoadingOrb from '@/components/LoadingOrb';

// import useMySubscription from '@/hooks/useMySubscription';
import { useAuthContext } from './auth';
import events from './UserEvents';

export function OptionalRoute({
    children,
    // path,
    ...rest
}: RouteProps) {
    const { userData } = useAuthContext();

    useEffect(() => {
        if (userData) {
            events.identify(userData);
        }
    }, [userData]);

    return <Route {...rest} render={({ location }) => (location ? children : <LoadingOrb />)} />;
}
