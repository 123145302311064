import firebase from 'firebase';

import configs from '../../configs';
import axios from '../util/axios';

export const FREUD_BASE_URL = configs.freud.BASE_URL;

export type NewsArticleData = {
    id: string;

    tag?: string;
    shortDescription?: string;
    description?: string;
    /**
     * ISO 8601 string
     */
    publishedAt?: string;

    archivedAt?: boolean;
    /**
     * ISO 8601 string
     */
    createdAt: string;
    /**
     * ISO 8601 string
     */
    updatedAt: string;
};

export type NewsArticleUpsertData = Pick<NewsArticleData, 'tag' | 'shortDescription' | 'description' | 'publishedAt'>;

export type NewsGetListQuery = {
    includeArchived?: boolean;
};

export const getNews = async (fbUser: firebase.User, query?: NewsGetListQuery): Promise<NewsArticleData[]> => {
    let url = FREUD_BASE_URL + `/news`;
    if (query?.includeArchived) {
        url += '?includeArchived=true';
    }
    try {
        const response = await axios(url, {
            method: 'GET',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};

export const getNewsLastArticle = async (fbUser: firebase.User): Promise<NewsArticleData | null> => {
    const url = FREUD_BASE_URL + `/news/last`;
    try {
        const response = await axios(url, {
            method: 'GET',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};

export const createNewsArticle = async (fbUser: firebase.User, article: NewsArticleUpsertData): Promise<void> => {
    const url = FREUD_BASE_URL + `/news`;
    try {
        const response = await axios(url, {
            method: 'POST',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
            data: article,
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};

export const updateNewsArticle = async (
    fbUser: firebase.User,
    id: string,
    article: NewsArticleUpsertData,
): Promise<void> => {
    const url = FREUD_BASE_URL + `/news/${id}`;
    try {
        const response = await axios(url, {
            method: 'PATCH',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
            data: article,
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};

export const archiveNewsArticle = async (fbUser: firebase.User, id: string): Promise<void> => {
    const url = FREUD_BASE_URL + `/news/${id}/archive`;
    try {
        const response = await axios(url, {
            method: 'POST',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};

export const unarchiveNewsArticle = async (fbUser: firebase.User, id: string): Promise<void> => {
    const url = FREUD_BASE_URL + `/news/${id}/unarchive`;
    try {
        const response = await axios(url, {
            method: 'POST',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        });
        if (response.status != 200) {
            console.debug(response);
            throw new Error('Error fetching');
        }

        return response.data;
    } catch (e) {
        console.debug('Error', e);
        throw e;
    }
};
