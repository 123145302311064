import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Session,
    SessionRenderType,
    SessionScoreDTO,
    SessionScoreSessionUse,
    SessionType,
    SessionVariables,
    Wavepath,
} from 'wavepaths-shared/core';
import * as scoreUtils from 'wavepaths-shared/domain/scores';
import { millisecondsToMinutes } from 'wavepaths-shared/util/dateUtils';

import { Features } from '@/features';

import { useAuthContext } from '../../auth';
import * as api from '../../common/api/sessionApi';
import useScoreLibrary from '../../common/hooks/useScoreLibrary';
import UserEvents from '../../UserEvents';
import IntroSessionCard from './ProductTourCard';

interface InSessionTourCardProps {
    fbUser: firebase.User;
    setFailed: (failed: boolean) => void;
}

export type IntroSessionItem = {
    type: SessionType;
    score: SessionScoreDTO;
    variableInputs: SessionVariables;
    filteredLayerIds: number[];
};

export const buildIntroSessionsFromSessionScores = async (
    sessionScores: SessionScoreDTO[],
): Promise<IntroSessionItem[]> => {
    return sessionScores.map((sessionScore: SessionScoreDTO) => ({
        type: SessionType.INTRO,
        score: sessionScore,
        variableInputs: {
            preludeDuration: 30,
            totalDuration: millisecondsToMinutes(scoreUtils.scoreDuration(sessionScore.wavepaths as Wavepath[])),
            voiceover: 'none',
            sessionUse: SessionScoreSessionUse.TESTING,
        },
        filteredLayerIds: [],
    }));
};

export function InSessionTourCard({ fbUser, setFailed }: InSessionTourCardProps): JSX.Element {
    const history = useHistory();
    const [creatingIntroScore, setCreatingIntroScore] = useState<string | undefined>(undefined);
    const [createdIntroSession, setCreatedIntroSession] = useState<Session | undefined>(undefined);

    const { introScores } = useScoreLibrary(fbUser);
    const [sessions, setSessions] = useState<IntroSessionItem[]>([]);
    const { isEnabled } = useAuthContext();

    useEffect(() => {
        buildIntroSessionsFromSessionScores(introScores).then(setSessions);
    }, [introScores]);

    useEffect(() => {
        if (createdIntroSession) {
            if (creatingIntroScore === createdIntroSession.score.id) {
                history.push(`/session/${createdIntroSession.id}?isTour=true`);
            } else {
                try {
                    api.deleteSession(createdIntroSession.id, 0, fbUser);
                } catch (e) {}
            }
        }
    }, [creatingIntroScore, createdIntroSession, history, fbUser]);

    const onClickPlaySessionNow = async ({ type, score, variableInputs, filteredLayerIds }: IntroSessionItem) => {
        setCreatingIntroScore(score.id);
        setFailed(false);
        try {
            const sessionResponse = await api.startSession(
                type,
                SessionRenderType.REAL_TIME,
                score,
                {
                    ...variableInputs,
                },
                //MK20231031 In the past scheduledStart was coming from the Browser but its not reliable, so we pass 1 as 'now'
                // we correct it here so all the legacy code can still use the absolute timestamp
                // ideally this should come from same server that stamps end time
                { scheduledStart: 1 },
                [],
                filteredLayerIds,
                fbUser,
                [],
            );
            setCreatedIntroSession(sessionResponse);
            UserEvents.sessionStarted(sessionResponse, { introSessionId: score.id });
        } catch (e) {
            setFailed(true);
            setCreatingIntroScore(undefined);
        }
    };

    return (
        <>
            {sessions.map(
                (session, index) =>
                    session.score &&
                    isEnabled(Features.LIVE_SESSION_RENDERING) && (
                        <IntroSessionCard
                            key={session.score.id ?? index}
                            title={session.score.name}
                            duration={'5 mins + music'}
                            info={`How to adapt the music live`}
                            disabled={creatingIntroScore === session.score.id}
                            onClickPlaySessionNow={() => onClickPlaySessionNow(session)}
                        />
                    ),
            )}
        </>
    );
}
