import './EndOfSessionFeedbackInput.scss';

import React from 'react';
import Ratings from 'react-ratings-declarative';
import * as uuid from 'uuid';
import { EndOfSessionFeedback } from 'wavepaths-shared/core';

interface EndOfSessionFeedbackProps {
    title: string;
    feedback?: EndOfSessionFeedback;
    onUpdateFeedback: (newFeedback: EndOfSessionFeedback) => void;
}
export const EndOfSessionFeedbackInput: React.FC<EndOfSessionFeedbackProps> = React.memo(
    ({ title, feedback, onUpdateFeedback, children }) => {
        const onChangeRating = (key: keyof EndOfSessionFeedback, rating: number) => {
            onUpdateFeedback({
                ...(feedback || { feedback: '', rating: -1, id: uuid.v4() }),
                [key]: rating,
            });
        };
        const onChangeFeedback = (text: string) => {
            onUpdateFeedback({
                ...(feedback || { rating: -1, id: uuid.v4() }),
                feedback: text,
            });
        };

        return (
            <div className="endOfSessionFeedbackInput">
                <h3 className="endOfSessionFeedbackInput--header">{title}</h3>
                <div className="endOfSessionFeedbackInput--body">
                    <div className="endOfSessionFeedbackInput--rating endOfSessionFeedbackInput--ratingPrimary">
                        <div className="endOfSessionFeedbackInput--ratingLabel">
                            Overall experience<sup className="required">*</sup>
                        </div>
                        <StarRating
                            rating={feedback?.rating || 0}
                            onChangeRating={(r) => onChangeRating('rating', r)}
                        />
                    </div>
                    <div className="endOfSessionFeedbackInput--rating endOfSessionFeedbackInput--ratingPrimary">
                        <div className="endOfSessionFeedbackInput--ratingLabel">Technical quality</div>
                        <StarRating
                            rating={feedback?.technicalQualityRating || 0}
                            onChangeRating={(r) => onChangeRating('technicalQualityRating', r)}
                        />
                    </div>
                    <div className="endOfSessionFeedbackInput--rating endOfSessionFeedbackInput--ratingPrimary">
                        <div className="endOfSessionFeedbackInput--ratingLabel">Music quality</div>
                        <StarRating
                            rating={feedback?.musicQualityRating || 0}
                            onChangeRating={(r) => onChangeRating('musicQualityRating', r)}
                        />
                    </div>
                    <div className="endOfSessionFeedbackInput--rating endOfSessionFeedbackInput--ratingPrimary">
                        <div className="endOfSessionFeedbackInput--ratingLabel">Ease of use</div>
                        <StarRating
                            rating={feedback?.easeOfUseRating || 0}
                            onChangeRating={(r) => onChangeRating('easeOfUseRating', r)}
                        />
                    </div>
                    <h4>Additional feedback</h4>
                    <textarea
                        className="endOfSessionFeedbackInput--feedback"
                        value={feedback?.feedback}
                        onChange={(evt) => onChangeFeedback(evt.target.value)}
                    ></textarea>
                    {children}
                </div>
            </div>
        );
    },
);

interface StarRatingProps {
    rating: number;
    onChangeRating: (newRating: number) => void;
}
export const StarRating: React.FC<StarRatingProps> = ({ rating, onChangeRating }) => (
    <Ratings
        rating={rating}
        widgetEmptyColors="#D8D8D8"
        widgetHoverColors="#FFC100"
        widgetRatedColors="#FFC100"
        widgetDimensions="20px"
        widgetSpacings="0px"
        changeRating={onChangeRating}
    >
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
    </Ratings>
);

export function isValidEndOfSessionFeedback(feedback?: EndOfSessionFeedback) {
    return feedback && feedback.rating > 0 && feedback.rating <= 5;
}
