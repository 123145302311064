import { createContext, useEffect, useRef, useState } from 'react';
import { Session, Wavepath } from 'wavepaths-shared/core';

import { useAuthContext } from '../../auth';
import * as sessionApi from '../../common/api/sessionApi';
import { useRemoteSessionControls } from '../../pages/inSession/Guide';
import { useRemoteCurrentWave } from '.';

//Here just experimenting with different apis and hooks to see whats easiest but they have all different typing
export type WaveDef = Wavepath;

export const usePreviewPlayer = () => {
    //const [previewInitialized, setPreviewInitialized] = useState(false);
    const [previewSession, setPreviewSession] = useState<Session | null>(null);
    const previewWaveCounter = useRef(0);
    const adaptivePlayer = useRemoteSessionControls({ sessionId: previewSession?.id });
    const { firebaseUser } = useAuthContext();

    // TODO: undefined instead of null
    const { wave: previewWave } = useRemoteCurrentWave(adaptivePlayer.sessionScore?.wavepaths ?? null);

    const templateWave = useRef<WaveDef | undefined>(undefined);

    //const [previewPlayer, setPreviewPlayer] = useState<ReturnType<typeof useAdaptivePlayer> | null>(null);
    //const [previewSessionId, setPreviewSessionId] = useQueryParam<string>("previewSession");

    const playWave = (wave: WaveDef) => {
        if (adaptivePlayer.sessionScoreState !== 'loading') {
            // previewPlayer.sessionScoreState.updatePathAtIndex(...args);
            // if (previewWave?.type == 'pre') {
            //     previewPlayer.startSession();
            // }
            // previewPlayer.sessionScoreState.skipToWave(args[0]);

            templateWave.current = wave;

            previewWaveCounter.current++;
            adaptivePlayer.sessionScoreState.addPathAtIndex(previewWaveCounter.current, wave);
            if (previewWave?.type == 'pre') {
                adaptivePlayer.startSession();
            }

            adaptivePlayer.sessionScoreState.skipToWave(previewWaveCounter.current);

            //previewPlayer.onSkipQueue();
        }
    };

    useEffect(() => {
        //unmount
        return () => {
            //TODO: this is not doing much/ ordering issue if unmount happens on children first?
            //previewPlayer.adaptivePlayer.endSessionFromConfirm();
            console.debug('Unmounting preview', previewSession?.id);
            previewSession && firebaseUser && sessionApi.deleteSession(previewSession.id, undefined, firebaseUser);
        };
    }, []);

    return {
        previewSession,
        setPreviewSession,
        adaptivePlayer,
        previewWaveCounter,
        previewSessionCurrentWave: previewWave,
        playWave,
        templateWave,
    };
};

export const PreviewPlayerContext = createContext<ReturnType<typeof usePreviewPlayer> | undefined>(undefined);
