import './EndOfSessionFeedbackModal.scss';

import React from 'react';
import { EndOfSessionFeedback } from 'wavepaths-shared/core';

import { Button } from '@/component-library';

import {
    EndOfSessionFeedbackInput,
    isValidEndOfSessionFeedback,
} from '../../common/components/EndOfSessionFeedbackInput';
import { Modal } from '../../common/components/Modal';
import iconClose from '../../images/iconClose.svg';

type EndOfSessionFeedbackModalProps = {
    endOfSessionFeedback?: EndOfSessionFeedback;
    setEndOfSessionFeedback: (fb: EndOfSessionFeedback) => void;
    onSubmit: () => void;
    onSubmitAndEnd: () => void;
    onClose: () => void;
    sessionEnded?: boolean;
};

export function EndOfSessionFeedbackModal({
    endOfSessionFeedback,
    setEndOfSessionFeedback,
    onSubmit,
    onSubmitAndEnd,
    onClose,
    sessionEnded = true,
}: EndOfSessionFeedbackModalProps) {
    return (
        <Modal className="guideEndOfSessionFeedback">
            <EndOfSessionFeedbackInput
                title={`How would you rate this session?`}
                feedback={endOfSessionFeedback}
                onUpdateFeedback={setEndOfSessionFeedback}
            >
                <Button
                    variant="solid-blue"
                    onClick={onSubmitAndEnd}
                    disabled={!isValidEndOfSessionFeedback(endOfSessionFeedback)}
                >
                    Submit and end session
                </Button>
                {!sessionEnded && (
                    <Button
                        style={{ marginTop: 16 }}
                        variant="clear-underlined"
                        size="s"
                        onClick={onSubmit}
                        disabled={!isValidEndOfSessionFeedback(endOfSessionFeedback)}
                    >
                        Submit and return to session
                    </Button>
                )}
            </EndOfSessionFeedbackInput>
            <Button variant="solid-blue" className="closeModal" onClick={onClose}>
                <img src={iconClose} alt="Close" />
            </Button>
        </Modal>
    );
}
