import { promiseTimeout } from './freudConnection/hlsUtils';

const AudioCtx = 'AudioContext' in window ? window.AudioContext : (window as any).webkitAudioContext;
export const audioCtx: AudioContext = AudioCtx
    ? new AudioCtx({ latencyHint: 1.0, sampleRate: 48000 })
    : ((null as unknown) as AudioContext);

if (audioCtx) {
    console.log(
        'Audio latency: ',
        audioCtx.baseLatency,
        'sample rate',
        audioCtx.sampleRate,
        ' State: ',
        audioCtx.state,
        audioCtx,
    );

    /**
     * In case the OS or browser suspends the AudioContext (in e.g. output device change), attempt to automatically resume.
     */
    audioCtx.addEventListener('statechange', () => {
        console.log('AudioContext state changed to ', audioCtx.state);
        if (audioCtx.state === 'suspended' || (audioCtx.state as string) === 'interrupted') {
            setTimeout(() => {
                console.log('AudioContext auto-resume attempt...');
                audioCtx
                    .resume()
                    .then(() => {
                        console.log('AudioContext auto-resumed.');
                    })
                    .catch((e) => {
                        console.error('AudioContext auto-resume prevented', e);
                    });
            });
        }
    });
}

// See if volume actually changes on the media element when we change it.
// Needed for non-desktop Safaris that ignore volume changes.
export function isVolumeControllable(audioEl = new Audio()) {
    const originalVolume = audioEl.volume;
    const result = Promise.race([
        new Promise<boolean>((res) => audioEl?.addEventListener('volumechange', () => res(true), { once: true })),
        new Promise<boolean>((res) => setTimeout(() => res(false), 1000)),
    ]).finally(() => {
        audioEl.volume = originalVolume;
    });
    audioEl.volume = 0;
    return result;
}

export const resumeAudioContext = () => {
    if (!audioCtx) return;

    if (audioCtx.state !== 'running') {
        console.log('trying to resume AudioContext');
        //for iPhone it also needs to be synced with user gesture?
        // Need to use timeout because promise may not reject properly when blocked
        // https://github.com/w3c/autoplay/issues/1
        return promiseTimeout(audioCtx.resume(), 1000);
    } else {
        return Promise.resolve();
    }
};
