import firebase from 'firebase';
import useSWR from 'swr';

import { getClients } from '../api/bonnyApi';

export function useClients({
    fbUser,
    query,
    client_id,
    refreshInterval,
}: {
    fbUser: firebase.User;
    query: string;
    client_id?: string;
    refreshInterval?: number;
}) {
    const { data, isValidating } = useSWR(
        fbUser ? ['clients', query, client_id] : null,
        fbUser ? () => getClients(fbUser, query, client_id) : null,
        {
            compare: (a, b) => {
                return JSON.stringify(a) == JSON.stringify(b);
            },
            revalidateOnFocus: false,
            refreshInterval: refreshInterval,
        },
    );

    return {
        clients: data,
        isLoading: isValidating,
    };
}
