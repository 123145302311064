import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { DialogContent } from '@material-ui/core';
import React from 'react';
import Joyride, { BeaconRenderProps, Props as JoyrideProps, TooltipRenderProps } from 'react-joyride';

import { Button, Typography } from '@/component-library';

const StyledDialogContent = styled(DialogContent)({
    width: 'min(95vw,600px)',
    padding: '2vw 5vh !important',
    borderRadius: '8px',
    backgroundColor: 'white',
    maxHeight: '95vh',
    boxShadow: '0px 0px 20px rgba(44,57,88,0.25)',
    '> p': {
        marginBottom: '1em',
        color: '#2C3958',
    },
    '> *:last-child': {
        marginBottom: '0',
    },
    '> p a': {
        color: 'inherit',
        textDecoration: 'underline',
    },
});

const ButtonGroup = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '48px',
    margin: '20px 0 16px 0',
});

const ButtonCell = styled.div({
    display: 'grid',
    placeContent: 'stretch',
});

const Tooltip = ({
    continuous,
    index,
    step,
    isLastStep,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
}: TooltipRenderProps) => (
    <StyledDialogContent {...tooltipProps}>
        {step.title && <Typography variant="h6">{step.title}</Typography>}
        {step.content}
        {!step.hideFooter && (
            <ButtonGroup>
                <ButtonCell>
                    <Button variant="outlined" size="s" {...closeProps}>
                        Skip
                    </Button>
                </ButtonCell>
                <ButtonCell>
                    {index > 0 && !step.hideBackButton && (
                        <Button variant="outlined" size="s" {...backProps}>
                            Back
                        </Button>
                    )}
                </ButtonCell>
                <ButtonCell>
                    {continuous && (
                        <Button variant="solid-blue" size="s" {...primaryProps}>
                            {isLastStep ? 'Finish' : 'Next'}
                        </Button>
                    )}
                    {!continuous && !step.hideCloseButton && (
                        <Button variant="solid-blue" size="s" {...closeProps}>
                            Next
                        </Button>
                    )}
                </ButtonCell>
            </ButtonGroup>
        )}
    </StyledDialogContent>
);

const outerPulse = keyframes`
          0% {
            transform: scale(1);
          }
        
          45% {
            opacity: 0.7;
            transform: scale(0.75);
          }
        
          100% {
            opacity: 0.9;
            transform: scale(1);
          }
`;

const Beacon = styled('button')({
    background: 'none',
    border: 'none',
    borderRadius: '50%',
    height: '5rem',
    position: 'relative',
    width: '5rem',
    cursor: 'pointer',
});

const Inner = styled('div')({
    // animation: `${innerPulse} 1.2s infinite ease-in-out`,
    backgroundColor: '#8C6FE8',
    borderRadius: '50%',
    height: '50%',
    padding: '24px',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
});

const Outer = styled('span')({
    animation: `${outerPulse} 1.2s infinite ease-in-out`,
    backgroundColor: `rgba(140,11,232,0.2)`,
    border: `2px solid #8C6FE8`,
    borderRadius: '50%',
    boxSizing: 'border-box',
    height: '100%',
    left: 0,
    opacity: 0.9,
    position: 'absolute',
    top: 0,
    transformOrigin: 'center',
    width: '100%',
});

const BeaconComponent = React.forwardRef<HTMLButtonElement, BeaconRenderProps>((props, ref) => {
    return (
        <>
            <Beacon ref={ref} {...props}>
                <Inner>
                    <Typography variant="body3" color="inherit">
                        Next
                    </Typography>
                </Inner>
                <Outer />
            </Beacon>
        </>
    );
});

function ProductTourComponent(props: JoyrideProps): JSX.Element {
    return (
        <div style={{ position: 'absolute' }}>
            <Joyride
                styles={{
                    options: {
                        primaryColor: '#000',
                    },
                }}
                callback={props.callback}
                beaconComponent={BeaconComponent as React.ElementType<BeaconRenderProps>}
                getHelpers={props.getHelpers}
                continuous
                tooltipComponent={Tooltip}
                steps={props.steps}
                stepIndex={props.stepIndex}
                spotlightClicks
            />
        </div>
    );
}

export const ProductTour = React.memo(ProductTourComponent);
