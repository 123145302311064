import styled from '@emotion/styled';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useLocalStorage } from 'react-use';
import { clientsFreudApi } from 'wavepaths-shared/core';

import { Button, DropdownControl, EvaIcon, InfoDialog } from '@/component-library';
import TextInput from '@/component-library/components/TextInput';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { GlobalSnackbarContext } from '@/components/Snackbar';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Link = styled.a`
    display: block;
    margin-top: 4px;
`;

export function ShareClientLinkDialog({
    linkUrl,
    linkText,
    anonymousLinkUrl,
    clients,
}: {
    linkUrl: string;
    linkText: string;
    anonymousLinkUrl?: string;
    clients?: clientsFreudApi[];
}) {
    const { setSnackbarContent } = useContext(GlobalSnackbarContext);
    const [clientName, setClientName] = useState<string>(
        clients !== undefined && clients.length === 1 ? `${clients[0].firstname} ${clients[0].lastname}` : '',
    );
    const [clientId, setClientId] = useState<string | undefined>(
        clients !== undefined && clients.length === 1 ? clients[0].client_id : undefined,
    );
    const [clientEmail, setClientEmail] = useState<string>('');
    const [clientLink, setClientLink] = useState<string>(linkUrl);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [privacyModePreference, setPrivacyModePreference] = useLocalStorage<'anonymous' | 'tracked'>(
        'patientLinkPrivacy',
        'tracked',
    );
    let effectivePrivacyMode = privacyModePreference;
    if (privacyModePreference == 'anonymous' && anonymousLinkUrl === undefined) {
        effectivePrivacyMode = 'tracked';
    }

    const effectiveBaseLink = effectivePrivacyMode == 'tracked' ? linkUrl : anonymousLinkUrl ?? linkUrl;

    useEffect(() => {
        const url = new URL(effectiveBaseLink);
        if (clientId !== undefined && clientId.length > 0) {
            url.searchParams.set('client_id', clientId);
        }

        if (clientName) {
            url.searchParams.set('name', clientName);
        }

        if (clientEmail) {
            url.searchParams.set('email', clientEmail);
        }
        setClientLink(url.toString());
    }, [clientName, clientEmail, effectiveBaseLink, clientId]);

    useEffect(() => {
        if (clientId !== undefined && clients !== undefined) {
            const client = clients.find((x) => x.client_id === clientId);
            if (client === undefined) return;
            setClientName(`${client.firstname} ${client.lastname}`);
            setClientEmail('');
        }
    }, [clientName, clientId, clients]);

    const copyLink = useCallback(() => {
        navigator.clipboard.writeText(clientLink);
        setSnackbarContent('Link copied to clipboard');
    }, [clientLink]);

    return (
        <>
            <Button variant="outlined" size="m" onClick={() => setIsOpen(true)}>
                <EvaIcon name="link-outline" size={16} />
                <TypographyV2 weight="semibold" size="text-sm">
                    {linkText}
                </TypographyV2>
            </Button>
            {isOpen ? (
                <InfoDialog title={'Share recording with the Client'} open={isOpen} onClose={() => setIsOpen(false)}>
                    <Content>
                        {anonymousLinkUrl !== undefined ? (
                            <DropdownControl
                                canSave={false}
                                size={'large'}
                                heading="Privacy mode"
                                name="privacy"
                                colour={'dark'}
                                options={[
                                    { value: 'anonymous', label: 'Anonymous' },
                                    { value: 'tracked', label: 'Tracked with sign in' },
                                ]}
                                value={effectivePrivacyMode}
                                onChange={setPrivacyModePreference}
                            />
                        ) : (
                            <></>
                        )}

                        {effectivePrivacyMode == 'tracked' ? (
                            <>
                                {clients !== undefined && clients.length > 1 ? (
                                    <>
                                        <DropdownControl
                                            canSave={false}
                                            size={'large'}
                                            heading="Which Client"
                                            name="client_id"
                                            colour={'dark'}
                                            options={clients.map((x) => {
                                                return {
                                                    value: x.client_id,
                                                    label: `${x.firstname ?? ''} ${x.lastname ?? ''}`,
                                                };
                                            })}
                                            value={clientId}
                                            onChange={setClientId}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <TypographyV2 size="text-sm" color="grey-700">
                                            Client's name (optional):
                                        </TypographyV2>
                                        <TextInput
                                            variant="outlined"
                                            size="small"
                                            name="patient_name"
                                            onChange={(e) => setClientName(e.target.value)}
                                            value={clientName}
                                        />
                                        <TypographyV2 size="text-sm" color="grey-700">
                                            Client's email (optional):
                                        </TypographyV2>
                                        <TextInput
                                            variant="outlined"
                                            size="small"
                                            name="patient_email"
                                            onChange={(e) => setClientEmail(e.target.value)}
                                            value={clientEmail}
                                            type="email"
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                        <TypographyV2 size="text-sm" color="grey-700">
                            This QR code will open the link on Client's device:
                        </TypographyV2>
                        <br />
                        <QRCode value={clientLink} size={150} />
                        <p>
                            <TypographyV2 size="text-sm" color="grey-700">
                                Alternatively, you can share the link directly:
                            </TypographyV2>
                            <Link
                                style={{
                                    overflowWrap: 'anywhere',
                                }}
                                href={clientLink}
                                onClick={(event) => {
                                    copyLink();
                                    event.preventDefault();
                                }}
                            >
                                <TypographyV2 weight="semibold" size="text-sm" color="grey-700">
                                    {clientLink}
                                </TypographyV2>
                            </Link>
                        </p>
                        <Button onClick={copyLink} variant="solid-blue">
                            Copy Link
                        </Button>
                        {effectivePrivacyMode == 'tracked' ? (
                            <>
                                <br />
                                <TypographyV2 size="text-sm" color="grey-900">
                                    Please note that: The linked page will display for you differently than for the
                                    Client; We will ask the Client to sign in to track and correlate sessions data.
                                </TypographyV2>
                            </>
                        ) : (
                            <></>
                        )}
                    </Content>
                </InfoDialog>
            ) : (
                <></>
            )}
        </>
    );
}
