import React from 'react';
import seedrandom from 'seedrandom';
import { CoreEmotionalAtmosphere, SessionScoreEmotionalIntensity } from 'wavepaths-shared/core';

export interface TemplateArtworkProps {
    randomSeed: string;
    intensity: SessionScoreEmotionalIntensity | 'none';
    primaryEmotion: CoreEmotionalAtmosphere;
    secondaryEmotion: CoreEmotionalAtmosphere;
    tertiaryEmotion: CoreEmotionalAtmosphere;
}

const TemplateArtwork: React.FC<TemplateArtworkProps> = ({
    randomSeed,
    intensity,
    primaryEmotion,
    secondaryEmotion,
    tertiaryEmotion,
}) => {
    const canvasSize = 200;
    const primaryEmotionHue = getEmotionColor(primaryEmotion).h;
    const primaryEmotionSaturation = getEmotionColor(primaryEmotion).s;
    const primaryEmotionLightness = getEmotionColor(primaryEmotion).l;
    const backgroundColor = `hsl(${primaryEmotionHue}, ${primaryEmotionSaturation - 20}%, ${
        primaryEmotionLightness + 10
    }%)`;
    // const backgroundColor = '#EDF0FF';
    const blurAmount =
        intensity === SessionScoreEmotionalIntensity.LOW
            ? canvasSize * 0.14
            : intensity === SessionScoreEmotionalIntensity.MEDIUM
            ? canvasSize * 0.12
            : intensity === SessionScoreEmotionalIntensity.HIGH
            ? canvasSize * 0.1
            : canvasSize * 0.2;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={canvasSize}
            height={canvasSize}
            viewBox={`0 0 ${canvasSize} ${canvasSize}`}
        >
            <defs>
                <filter id="noise-filter">
                    <feTurbulence type="fractalNoise" baseFrequency="1.7" numOctaves="1" stitchTiles="stitch" />
                </filter>
                <filter
                    id="blur-filter"
                    x="-100%"
                    y="-100%"
                    width="400%"
                    height="400%"
                    filterUnits="objectBoundingBox"
                    primitiveUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feGaussianBlur
                        stdDeviation={blurAmount}
                        x="0%"
                        y="0%"
                        width="100%"
                        height="100%"
                        in="SourceGraphic"
                        edgeMode="none"
                        result="blur"
                    />
                </filter>
            </defs>
            <rect width={canvasSize} height={canvasSize} x="0" y="0" fill="#fff" />
            <rect width={canvasSize} height={canvasSize} x="0" y="0" fill={backgroundColor} opacity={0.4} />
            <g filter='url("#blur-filter")'>
                <Circle
                    intensity={intensity}
                    canvasSize={canvasSize}
                    circleType={'tertiary'}
                    emotion={tertiaryEmotion}
                    seed={randomSeed + 2}
                />
                <Circle
                    intensity={intensity}
                    canvasSize={canvasSize}
                    circleType={'secondary'}
                    emotion={secondaryEmotion}
                    seed={randomSeed + 1}
                />
                <Circle
                    intensity={intensity}
                    canvasSize={canvasSize}
                    circleType={'primary'}
                    emotion={primaryEmotion}
                    seed={randomSeed}
                />
            </g>
            <rect width="100%" height="100%" filter="url(#noise-filter)" opacity={0.6} />
        </svg>
    );
};

interface CircleProps {
    canvasSize: number;
    circleType: 'primary' | 'secondary' | 'tertiary';
    emotion: CoreEmotionalAtmosphere;
    intensity: SessionScoreEmotionalIntensity | 'none';
    seed: string;
}

const Circle: React.FC<CircleProps> = ({ canvasSize, circleType, emotion, seed, intensity }) => {
    const rng = seedrandom(seed); // create new random generator with the seed
    const random = (min: number, max: number) => rng() * (max - min) + min; // redefine the random function to use the seeded generator

    const gridSize = canvasSize / 5; // divide the canvas size by 5 to get grid size
    // generate a random integer from 0 to 4, multiply by gridSize to get grid position
    // add half the grid size to position the circle in the middle of the grid cell
    const circleX = Math.floor(rng() * 5) * gridSize + gridSize / 2;
    const circleY = Math.floor(rng() * 5) * gridSize + gridSize / 2;

    const circleDiameter =
        circleType === 'primary'
            ? random(canvasSize * 0.7, canvasSize * 0.9)
            : circleType === 'secondary'
            ? random(canvasSize * 0.6, canvasSize * 0.8)
            : circleType === 'tertiary'
            ? random(canvasSize * 0.5, canvasSize * 0.7)
            : random(canvasSize * 0.4, canvasSize * 0.6);
    const intensityMultiplier =
        intensity === SessionScoreEmotionalIntensity.HIGH ? 1.2 : SessionScoreEmotionalIntensity.HIGH ? 1.1 : 1;
    const circleRadius = (intensityMultiplier * circleDiameter) / 2;

    const emotionHue = getEmotionColor(emotion).h;
    const emotionSaturation = getEmotionColor(emotion).s;
    const emotionLightness = getEmotionColor(emotion).l;
    const circleColor = `hsl(${emotionHue}, ${emotionSaturation}%, ${emotionLightness}%)`;

    return <circle r={circleRadius} fill={circleColor} cx={circleX} cy={circleY} opacity={0.8} />;
};

function getEmotionColor(emotion: CoreEmotionalAtmosphere) {
    switch (emotion) {
        case CoreEmotionalAtmosphere.STILLNESS:
            return { h: 133, s: 58, l: 75 };
        case CoreEmotionalAtmosphere.BITTERSWEET:
            return { h: 232, s: 64, l: 73 };
        case CoreEmotionalAtmosphere.VITALITY:
            return { h: 52, s: 110, l: 50 };
        case CoreEmotionalAtmosphere.TENSION:
            return { h: 0, s: 89, l: 58 };
        default:
            return { h: 220, s: 220, l: 220 };
    }
}

export default TemplateArtwork;
