import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ClientVariablesMusicPreference, Session, SessionScoreSessionUse } from 'wavepaths-shared/core';

import SessionDetail from '@/component-library/components/SessionDetail';
import { Features } from '@/features';

import { useAuthContext } from '../../auth';
import { getAllIntegrationAnswers, IntegrationResponse } from '../../common/api/sessionApi';
import { convertSessionToSessionTemplate } from '../../common/api/sessionTemplatesApi';
import { getRelativeSessionRecordingLink, getRelativeSessionReplayLink } from '../../formUtils/formUtils';
import { LayoutContainer } from '../../LayoutContainer';
import useSession, { SessionContext } from '../inSession/autoGuide/useSession';
import { selectNoWave } from '../inSession/autoGuide/waveSelection';
import { PersonalIntegrationModal } from '../inSession/PersonalIntegrationModal';
import { Timeline } from '../inSession/timeline/Timeline';
import LoadingTemplateInfo from '../templateInfo/LoadingTemplateInfo';

export interface CompletedSessionParams {
    sessionId: string;
}
function CompletedSession({ session, fbUser }: { session: Session; fbUser: firebase.User }) {
    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { score } = session;
    const { isPersonal, isEnabled } = useAuthContext();
    const [showAnswersForUserId, setShowAnswersForUserId] = useState<string | null>(null);

    const [integrationAnswers, setIntegrationAnswers] = useState<IntegrationResponse[] | undefined>();

    const isIntegrationEnabled = isEnabled(Features.CLIENT_INTEGRATION);
    const isCopyEnabled = !isPersonal;

    useEffect(() => {
        if (isEnabled(Features.CLIENT_INTEGRATION)) {
            getAllIntegrationAnswers(session.id, fbUser).then(({ entries }) => {
                setIntegrationAnswers(entries);
            });
        }
    }, [session.id, fbUser.uid]);

    const onSubmit = () => {
        setIsSubmitting(true);
        history.push({
            pathname: isPersonal
                ? getRelativeSessionRecordingLink(session.id)
                : getRelativeSessionReplayLink(session.id),
        });
    };

    const onCopySession = () => {
        setIsSubmitting(true);
        const sessionTemplate = convertSessionToSessionTemplate(session);
        history.push({
            pathname: '/session/new/oneOnOne',
            state: { sessionTemplate }, // your data array of objects
        });
    };

    const userAnswers = showAnswersForUserId
        ? integrationAnswers?.find((a) => a.userId === showAnswersForUserId)?.integration
        : undefined;

    return (
        <>
            <SessionContext.Provider value={session}>
                <SessionDetail
                    id={session.id}
                    title={
                        String(session.variableInputs.name).length
                            ? String(session.variableInputs.name)
                            : session.score.name
                    }
                    startedAt={session.startedAt}
                    endedAt={session.endedAt}
                    onBackButtonClick={history.goBack}
                    timelineComponent={
                        score ? (
                            <>
                                <Timeline
                                    score={score}
                                    variables={session.variableInputs}
                                    isScrollable
                                    waveSelection={selectNoWave()}
                                    phasesAlwaysVisible={true}
                                    isPlanner
                                />
                            </>
                        ) : (
                            <></>
                        )
                    }
                    tracklistComponent={null}
                    duration={session.variableInputs.totalDuration as number}
                    renderType={session.renderType}
                    sessionUse={session.variableInputs.sessionUse as SessionScoreSessionUse}
                    musicalPreference={session.variableInputs.Acousticness as ClientVariablesMusicPreference}
                    onSubmit={onSubmit}
                    submitDisabled={isSubmitting}
                    onCopySession={isCopyEnabled ? onCopySession : undefined}
                    isIntegrationEnabled={isIntegrationEnabled}
                    integrationAnswers={integrationAnswers}
                    onIntegrationAnswerClick={(userId) => setShowAnswersForUserId(userId)}
                    showAdminFeatures={false}
                    isRealTimeRenderingEnabled={false}
                    emotionalities={session.score.emotionalities}
                    intensity={session.score.intensity}
                />
                {showAnswersForUserId ? (
                    <PersonalIntegrationModal
                        onClose={() => setShowAnswersForUserId(null)}
                        sessionOwner={session.userId}
                        userId={fbUser.uid}
                        experienceRating={userAnswers?.answers.experience_rating}
                        experienceSummary={userAnswers?.answers.experience_summary}
                        mostRemember={userAnswers?.answers.most_remember}
                        insights={userAnswers?.answers.insights}
                        confusions={userAnswers?.answers.confusions}
                        other={userAnswers?.answers.other}
                    />
                ) : null}
            </SessionContext.Provider>
        </>
    );
}

function CompletedSessionContainer({ fbUser }: { fbUser: firebase.User }) {
    const { sessionId } = useParams<CompletedSessionParams>();
    const { session } = useSession(sessionId);
    if (!session)
        return (
            <LayoutContainer>
                <LoadingTemplateInfo />
            </LayoutContainer>
        );

    return (
        <LayoutContainer>
            <CompletedSession session={session} fbUser={fbUser} />
        </LayoutContainer>
    );
}

export default CompletedSessionContainer;
