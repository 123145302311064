import styled from '@emotion/styled';
import { TextField as MuiTextField, TextFieldProps } from '@material-ui/core';
import React, { ReactNode } from 'react';

import Heading from './Control/Heading';

const TextField = styled(MuiTextField)({
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiOutlinedInput-root': {
        fontSize: '13.33px',
        color: 'rgba(44,57,88,1)',
        '& fieldset': {
            borderColor: '#ADB9D6',
        },
        '&:hover fieldset': {
            borderColor: '#ADB9D6',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#ADB9D6',
        },
    },
});

const Container = styled.div(() => ({
    position: 'relative',
    display: 'grid',
    gap: 8,
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content min-content',
}));

type TextInputProps = {
    heading?: string;
    name?: string;
    colour?: 'dark' | 'light';
    info?: { content: ReactNode; title: string };
    ref?: React.Ref<HTMLInputElement>;
} & TextFieldProps;

const TextInput = ({ heading, name, info, colour = 'dark', ...textFieldProps }: TextInputProps) => {
    const labelId = name && `${name}-text-input`;

    return (
        <Container>
            {heading && (
                <Heading
                    id={labelId}
                    heading={heading}
                    info={info}
                    canSave={false}
                    inline={false}
                    colour={colour}
                ></Heading>
            )}
            <TextField aria-labelledby={labelId} name={name} {...textFieldProps} />
        </Container>
    );
};

export default TextInput;
