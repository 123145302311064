import './intensitySlider.css';

import React, { useEffect } from 'react';

import {
    CssKeys,
    indicator,
    setCssProperty,
} from '@/component-library/components/IntensitySlider/intensitySliderHelper';

function mapLevelToPixel(level: number) {
    switch (level) {
        case 13:
            return 12;

        case 12:
            return 64;
        case 11:
            return 78;
        case 10:
            return 92;
        case 9:
            return 106;
        case 8:
            return 120;
        case 7:
            return 134;
        case 6:
            return 148;

        case 5:
            return 192;
        case 4:
            return 206;
        case 3:
            return 220;
        case 2:
            return 234;
        case 1:
            return 248;

        case 0:
            return 300;
    }
}

function ValueIndicator({ level }: { level: number | undefined }): JSX.Element {
    useEffect(() => {
        if (level === undefined) {
        } else {
            setCssProperty(CssKeys.indicatorHeight, `${mapLevelToPixel(level)}px`);
        }
    }, [level]);

    if (level === undefined) return <></>;

    return (
        <div className="indicator-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="-10 -10 330 522">
                <path d={indicator} />
            </svg>
        </div>
    );
}

export default ValueIndicator;
