import styled from '@emotion/styled';
import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { setSeconds } from 'date-fns';
import { capitalize } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
    AdministrationRoute,
    clientOptionType,
    ClientVariablesMusicPreference,
    CoreEmotionalAtmosphere,
    DosageLevel,
    SessionRenderType,
    SessionScoreEmotionalIntensity,
    SessionScoreModality,
} from 'wavepaths-shared/core';
import { formatDurationMinutes } from 'wavepaths-shared/util/dateUtils';

import DateTimeInput from '@/components/Inputs/DateTimeInput';
import { Features } from '@/features';
import { useDebouncedState } from '@/hooks';

import { useAuthContext } from '../../../auth';
import { getClients } from '../../api/bonnyApi';
import TypographyV2 from '../typography/TypographyV2';
import Button from './Button';
import { DropdownControl, SegmentedControl } from './Control';
import Container from './Control/Container';
import Heading from './Control/Heading';
import Dialog from './Dialog';
import EvaIcon from './EvaIcon';
import AdministrationIcon from './icons/AdministrationIcon';
import DosageIcon from './icons/DosageIcon';
import IntensityIcon from './icons/IntensityIcon';
import ModalityIcon from './icons/ModalityIcon';
import {
    clientStartingTypeOptions,
    contentStatusOptions,
    musicalPreferenceOptions,
    renderTypeOptions,
    schedulingOptions,
    SchedulingStyle,
} from './sessionOptions';
import TemplateArtwork from './TemplateArtwork';
import TextInput from './TextInput';

export interface TemplateDetailProps {
    imageUrl?: string;
    id: string;
    title: string;
    subtitle: string;
    description: string;
    intensity: SessionScoreEmotionalIntensity;
    emotionalities?: {
        primary: CoreEmotionalAtmosphere;
        secondary: CoreEmotionalAtmosphere;
        tertiary: CoreEmotionalAtmosphere;
    };
    minDurationMins: number;
    maxDurationMins: number;
    onBackButtonClick: () => void;
    tracklistComponent: JSX.Element | null;
    timelineComponent: JSX.Element | null;
    duration: number;
    onDurationChange: (duration: number) => void;
    renderType: SessionRenderType;
    onRenderTypeChange: (value: SessionRenderType) => void;
    sessionName: string;
    onSessionNameChange: (name: string) => void;
    contentStatuses: 'Approved' | 'Submitted' | 'All';
    onContentStatusChange: (value: 'Approved' | 'Submitted' | 'All') => void;
    musicalPreference: ClientVariablesMusicPreference;
    onMusicalPreferenceChange: (val: ClientVariablesMusicPreference) => void;
    schedulingType: SchedulingStyle;
    onSchedulingTypeChange: (val: SchedulingStyle) => void;
    canClientStartEarly: boolean;
    onCanClientStartEarlyChange: (val: boolean) => void;
    scheduledStart?: number;
    onScheduledStartChange: (val?: number) => void;
    onDelete: () => void;
    onSubmit: () => void;
    allowedToEditDelete: boolean;
    submitDisabled: boolean;
    modality: SessionScoreModality;
    administration?: AdministrationRoute;
    dosage?: DosageLevel;
    canSaveTemplates?: boolean;
    onCreateTemplate: () => void;
    onEditTemplate: () => void;
    showAdminFeatures: boolean;
    selectedClients: clientOptionType[];
    setSelectedClients: (value: clientOptionType[]) => void;
}

const TemplateDetailContainer = styled.div`
    display: grid;
    grid-template-areas: 'header' 'info' 'actions' 'music' 'footer';
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    gap: 20px;
    margin-top: 64px;

    @media (min-width: 1260px) {
        grid-template-areas: 'header header' 'info actions' 'music actions' 'footer footer';
        grid-template-columns: 2fr 1fr;
        grid-template-rows: repeat(3, min-content);
        gap: 20px 48px;
    }
`;

const Header = styled.div`
    grid-area: header;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    margin-bottom: 28px;
`;

const Artwork = styled.div`
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 6px;
    background: #f9fafb; // Grey 50
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.15s ease;
`;

const TitleInfo = styled.div`
    display: grid;
    gap: 8px;
`;

const Description = styled.div<{ showFullDescription: boolean }>(
    ({ showFullDescription }) => `
    overflow: hidden;
    max-height: ${showFullDescription ? 'auto' : '150px'};
    cursor: pointer;
    position: relative;

    &:after {
        position: absolute;
        box-shadow: ${showFullDescription ? 'none' : 'inset #f8f9fb  0 -44px 16px -24px'};
        content: '';
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`,
);

const DescriptionText = styled(TypographyV2)({
    whiteSpace: 'pre-line',
});

const Meta = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-area: meta;
    justify-content: flex-start;
    gap: 16px;
`;

const MetaIcon = styled.div``;

const MetaItem = styled.div`
    align-items: center;
    display: flex;
    gap: 4px;
    margin-bottom: 4px;
`;

const Timeline = styled.div`
    border-radius: 4px;
    background: rgba(252, 252, 253, 1);
    border: 1px solid white;
    border-radius: 4px;
    margin-top: 16px;
    padding: 8px;
    box-shadow: 0px 0px 20px #cfd6e7;
`;

const TimelineContainer = styled.div`
    position: sticky;
    top: -36px;
    padding-top: 16px;
    margin-bottom: 16px;
    margin-top: -20px;
    z-index: 10;
    background-color: #f8f9fb;
`;

const TrackList = styled.div``;

const Actions = styled.div`
    grid-area: actions;
    display: grid;
    align-items: start;
    gap: 16px;
    z-index: 20;
`;

const StickyActions = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    gap: 16px;
    flex-direction: column;
`;

const Footer = styled.div`
    grid-area: footer;
`;

const BackButton = styled.button`
    position: absolute;
    top: 8px;
    left: -12px;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    display: inline-grid;
    place-content: center;

    @media (min-width: 1366px) {
        left: -68px;
    }
    cursor: pointer;
`;

const BackButtonIcon = styled.svg`
    width: 20px;
    height: 20px;
    stroke: white;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
`;

const Music = styled.div`
    grid-area: music;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const Info = styled.div`
    grid-area: info;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: flex-start;
`;

const SAVE_AS_SESSION_TEMPLATE_LABEL = 'Save as a new Template';

const DurationInputWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '700px',
    margin: '0 auto',
    fontSize: '12px',
});

const DurationLabel = styled.label({
    marginBottom: '0',
});

const DurationSliderInput = styled.input({
    WebkitAppearance: 'none',
    height: '55px !important',
    width: '100%',
    margin: '0',
    padding: '0',
    background: 'transparent',
    border: 'none',
    ':focus': {
        outline: 'none',
    },
    '::-webkit-slider-runnable-track': {
        width: '100%',
        height: '3px',
        cursor: 'pointer',
        animate: '0.2s',
        background: '#6980b4',
        borderRadius: '3px',
        border: 'none',
    },
    '::-webkit-slider-thumb': {
        border: 'none',
        height: '55px',
        width: '15px',
        borderRadius: '15px',
        background: '#2C3958',
        cursor: 'pointer',
        WebkitAppearance: 'none',
        transform: 'translateY(-6.5px)',
    },
});

const DurationBoundsContainer = styled.div({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
});

const DurationBoundsLabel = styled.div({
    display: 'flex',
});

const TemplateDetail: React.FC<TemplateDetailProps> = ({
    id,
    emotionalities,
    title,
    subtitle,
    description,
    intensity,
    minDurationMins,
    maxDurationMins,
    onBackButtonClick,
    tracklistComponent,
    timelineComponent,
    duration,
    onDurationChange,
    renderType,
    onRenderTypeChange,
    onSessionNameChange,
    sessionName,
    contentStatuses,
    onContentStatusChange,
    musicalPreference,
    onMusicalPreferenceChange,
    schedulingType,
    onSchedulingTypeChange,
    canClientStartEarly,
    onCanClientStartEarlyChange,
    scheduledStart,
    onScheduledStartChange,
    onDelete,
    allowedToEditDelete,
    onSubmit,
    submitDisabled,
    dosage,
    modality,
    administration,
    onCreateTemplate,
    onEditTemplate,
    canSaveTemplates,
    showAdminFeatures,
    setSelectedClients,
    selectedClients,
}) => {
    const minRounded = Math.floor(minDurationMins);
    const maxRounded = Math.ceil(maxDurationMins);
    const [innerDuration, setInnerDuration] = React.useState<number>(duration);

    const { isPersonal, firebaseUser, isEnabled } = useAuthContext();
    const renderTypeAllowed = [
        ...(!isPersonal ? [SessionRenderType.PREDICTIVE_COMPOSED] : []),
        ...(!isPersonal && !isEnabled(Features.BLOCK_SHORT_REALTIME) ? [SessionRenderType.REAL_TIME] : []),
        SessionRenderType.PRE_RENDERED,
    ];
    const renderOptions = renderTypeOptions.filter((x) => renderTypeAllowed.includes(x.value));
    useEffect(() => {
        if (!renderOptions.find((x) => x.value === renderType)) {
            if (renderOptions.length < 1) throw new Error('no options');
            onRenderTypeChange(renderOptions[0].value);
        }
    }, [renderOptions.map((x) => x.label).join(','), renderType]);
    if (firebaseUser === undefined) throw new Error('no firebaseUser');

    useEffect(() => {
        if (duration < minRounded) {
            handleDurationChange(minRounded);
        } else if (duration > maxRounded) {
            handleDurationChange(maxRounded);
        }
    }, [minRounded, maxRounded, duration]);

    const sliderCoolDownPeriodInMilliseconds = 200;
    const sliderTouchTimeout = useRef<any>();
    function handleDurationChange(newValue: number) {
        setInnerDuration(newValue);

        if (sliderTouchTimeout.current) {
            clearTimeout(sliderTouchTimeout.current);
        }

        sliderTouchTimeout.current = setTimeout(() => {
            onDurationChange(newValue);
        }, sliderCoolDownPeriodInMilliseconds);
    }

    const [showFullDescription, setShowFullDescription] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const [viewMoreOptions, setViewMore] = useState(false);

    const [clientQuery, setClientQuery] = useDebouncedState<string>('');
    const [clientOptions, setClientOptions] = useState<clientOptionType[]>([]);
    const [loadingClientOptions, setLoadingClientOptions] = useState<boolean>(false);
    useEffect(() => {
        setClientOptions([]);
        setLoadingClientOptions(true);
        (async () => {
            const clientsList = await getClients(firebaseUser, clientQuery);
            const firstSpaceIndex = clientQuery.indexOf(' ');
            const firstname = firstSpaceIndex > -1 ? clientQuery.substring(0, firstSpaceIndex) : clientQuery;
            const lastname = firstSpaceIndex > -1 ? clientQuery.substring(firstSpaceIndex + 1) : '';
            const effectiveSelectionOptions: clientOptionType[] = [
                ...clientsList,
                ...(clientQuery.length > 0
                    ? [
                          {
                              client_id: 'new',
                              firstname: firstname,
                              lastname: lastname,
                          },
                      ]
                    : []),
            ];
            setClientOptions(effectiveSelectionOptions);
            setLoadingClientOptions(false);
        })();
    }, [clientQuery]);

    return (
        <TemplateDetailContainer>
            <Header>
                <Artwork>
                    <TemplateArtwork
                        randomSeed={id}
                        intensity={intensity}
                        primaryEmotion={emotionalities?.primary ?? CoreEmotionalAtmosphere.SILENCE}
                        secondaryEmotion={emotionalities?.secondary ?? CoreEmotionalAtmosphere.SILENCE}
                        tertiaryEmotion={emotionalities?.tertiary ?? CoreEmotionalAtmosphere.SILENCE}
                    />
                </Artwork>
                <TitleInfo>
                    <TypographyV2
                        element={'h3'}
                        display={'block'}
                        font={'tenor-sans'}
                        size={'display-sm'}
                        weight={'regular'}
                        truncated
                    >
                        {title}
                    </TypographyV2>
                    <TypographyV2 display={'block'} size={'text-md'} weight={'regular'} color={'grey-600'} truncated>
                        {subtitle}
                    </TypographyV2>
                </TitleInfo>
            </Header>
            <Info>
                <Meta>
                    <MetaItem>
                        <MetaIcon>
                            <IntensityIcon intensity={intensity} />
                        </MetaIcon>
                        <TypographyV2 size={'text-sm'} weight={'regular'} color={'grey-400'}>
                            {intensity} intensity
                        </TypographyV2>
                    </MetaItem>
                    <MetaItem>
                        <MetaIcon>
                            <EvaIcon name="clock-outline" size={16} iconStyle={{ fill: '#667085' }} />
                        </MetaIcon>
                        <TypographyV2
                            display={'block'}
                            size={'text-sm'}
                            weight={'regular'}
                            color={'grey-400'}
                            truncated
                        >
                            {formatDurationMinutes(minDurationMins * 60 * 1000)} -{' '}
                            {formatDurationMinutes(maxDurationMins * 60 * 1000)}
                        </TypographyV2>
                    </MetaItem>
                    {modality && (
                        <MetaItem>
                            <MetaIcon>
                                <ModalityIcon modality={modality} />
                            </MetaIcon>
                            <TypographyV2 size={'text-sm'} weight={'regular'} color={'grey-400'}>
                                {modality}
                            </TypographyV2>
                        </MetaItem>
                    )}
                    {administration && (
                        <MetaItem>
                            <MetaIcon>
                                <AdministrationIcon administration={administration} />
                            </MetaIcon>
                            <TypographyV2 size={'text-sm'} weight={'regular'} color={'grey-400'}>
                                {capitalize(administration)}
                            </TypographyV2>
                        </MetaItem>
                    )}
                    {dosage && (
                        <MetaItem>
                            <MetaIcon>
                                <DosageIcon dosage={dosage} />
                            </MetaIcon>
                            <TypographyV2 size={'text-sm'} weight={'regular'} color={'grey-400'}>
                                {capitalize(dosage.replace('_', ' '))} dose
                            </TypographyV2>
                        </MetaItem>
                    )}
                </Meta>
                <Description
                    showFullDescription={showFullDescription || (!!description && description.length < 300)}
                    onClick={() => setShowFullDescription(!showFullDescription)}
                >
                    <DescriptionText display={'block'} size={'text-md'} weight={'regular'} color={'grey-600'}>
                        {description}
                    </DescriptionText>
                </Description>
                {description && description.length > 300 && (
                    <Button variant="clear-underlined" onClick={() => setShowFullDescription(!showFullDescription)}>
                        {showFullDescription ? 'Hide' : 'Show'} Description
                    </Button>
                )}
            </Info>
            <Music>
                {timelineComponent && (
                    <TimelineContainer>
                        <TypographyV2
                            element="h3"
                            weight={'regular'}
                            size={'display-xs'}
                            color={'grey-700'}
                            font={'tenor-sans'}
                        >
                            Music and Custom Sounds
                        </TypographyV2>
                        <Timeline>{timelineComponent}</Timeline>
                    </TimelineContainer>
                )}
                <TrackList>{tracklistComponent}</TrackList>
            </Music>
            {/* </Content> */}
            <Actions>
                <StickyActions>
                    <DurationInputWrapper>
                        <DurationLabel htmlFor="sessionDurationSlider">
                            <TypographyV2>
                                Session Duration: {formatDurationMinutes(innerDuration * 60 * 1000)}
                            </TypographyV2>
                        </DurationLabel>
                        <DurationSliderInput
                            type="range"
                            min={minRounded}
                            max={maxRounded}
                            name="session_duration"
                            step={1}
                            id="sessionDurationSlider"
                            value={innerDuration}
                            onChange={(event: any) => handleDurationChange(+event.target.value)}
                        />
                        <DurationBoundsContainer>
                            <DurationBoundsLabel>{formatDurationMinutes(minRounded * 60 * 1000)}</DurationBoundsLabel>
                            <DurationBoundsLabel>{formatDurationMinutes(maxRounded * 60 * 1000)}</DurationBoundsLabel>
                        </DurationBoundsContainer>
                    </DurationInputWrapper>
                    {isPersonal && !isEnabled(Features.LIVE_SESSION_RENDERING) ? (
                        <></>
                    ) : (
                        <DropdownControl
                            canSave={false}
                            size={'large'}
                            heading="Session Type"
                            name="Session Type"
                            colour={'dark'}
                            info={sessionTypeInfo}
                            options={renderOptions}
                            value={renderType}
                            onChange={onRenderTypeChange}
                        />
                    )}
                    <TextInput
                        variant="outlined"
                        size="small"
                        name="session_name"
                        heading="Session Name"
                        placeholder={title}
                        onChange={(e) => onSessionNameChange(e.target.value)}
                        value={sessionName}
                    />
                    {!isPersonal ? (
                        <Container inline={false}>
                            <Heading
                                id={'clients-label'}
                                heading={'Client(s)'}
                                info={undefined}
                                canSave={false}
                                inline={false}
                                colour={'dark'}
                            ></Heading>
                            <Autocomplete
                                multiple
                                id="clients-selection"
                                options={clientOptions}
                                defaultValue={selectedClients}
                                loading={loadingClientOptions}
                                renderOption={(option) => {
                                    return (
                                        <div>
                                            {option.client_id === 'new' ? 'New: ' : ''}
                                            {option.firstname} {option.lastname}
                                        </div>
                                    );
                                }}
                                filterOptions={(x) => x}
                                getOptionLabel={(option) => option.client_id}
                                getOptionSelected={(option, value) =>
                                    option.client_id === value.client_id && option.firstname === value.firstname
                                }
                                freeSolo
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                        <Chip
                                            key={index}
                                            variant="outlined"
                                            label={`${option.firstname} ${option.lastname}`}
                                            {...getTagProps({ index })}
                                        />
                                    ));
                                }}
                                onChange={(_event, value) => {
                                    const strongValue = value as typeof clientOptions;
                                    setSelectedClients(strongValue);
                                }}
                                onInputChange={(_event, value) => {
                                    setClientQuery(value);
                                }}
                                renderInput={(params) => {
                                    return <TextField {...params} variant="standard" placeholder="Select or add new" />;
                                }}
                            />
                        </Container>
                    ) : (
                        <></>
                    )}
                    <Button variant="clear-underlined" onClick={() => setViewMore(!viewMoreOptions)}>
                        {viewMoreOptions ? 'Fewer' : 'More'} options
                    </Button>
                    {viewMoreOptions ? (
                        <>
                            <SegmentedControl
                                canSave={false}
                                size={'large'}
                                heading="Musical Preference"
                                name="Musical Preference"
                                colour={'dark'}
                                info={musicalPreferenceInfo}
                                options={musicalPreferenceOptions}
                                value={musicalPreference}
                                onChange={onMusicalPreferenceChange}
                            />
                            <DropdownControl
                                canSave={false}
                                size={'large'}
                                heading="When is this session for?"
                                name="scheduling"
                                colour={'dark'}
                                info={scheduleTypeInfo}
                                options={schedulingOptions}
                                value={schedulingType}
                                onChange={onSchedulingTypeChange}
                            />

                            {schedulingType === 'specificTime' && (
                                <DateTimeInput
                                    className="scheduled-start-picker"
                                    value={scheduledStart && setSeconds(new Date(scheduledStart), 0)}
                                    disabled={schedulingType !== 'specificTime'}
                                    onChange={(d: Date) => onScheduledStartChange(d ? d.getTime() : undefined)}
                                    clearIcon={null}
                                />
                            )}
                            <DropdownControl
                                canSave={false}
                                size={'large'}
                                heading="Who is able to control the playback?"
                                name="starting"
                                info={clientControlInfo}
                                colour={'dark'}
                                options={clientStartingTypeOptions}
                                value={canClientStartEarly ? 1 : 0} // thanks MUI
                                onChange={(val) => onCanClientStartEarlyChange(!!val)}
                            />
                            {showAdminFeatures ? (
                                <>
                                    <SegmentedControl
                                        canSave={false}
                                        size={'large'}
                                        heading="Statuses to include"
                                        colour={'dark'}
                                        options={contentStatusOptions}
                                        value={contentStatuses}
                                        onChange={onContentStatusChange}
                                    />
                                </>
                            ) : null}
                        </>
                    ) : null}
                    <Button disabled={submitDisabled} variant="solid-blue" size="m" onClick={onSubmit}>
                        {schedulingType !== 'now' ? 'Schedule Session' : 'Start Session'}
                    </Button>
                    {allowedToEditDelete ? (
                        <>
                            <Button
                                disabled={submitDisabled}
                                variant="outlined"
                                size="m"
                                style={{ opacity: canSaveTemplates ? 1 : 0.5 }}
                                icon={
                                    canSaveTemplates ? (
                                        <EvaIcon name="save-outline" size={16} fill={'#000'} />
                                    ) : (
                                        <EvaIcon name="lock-outline" size={16} fill={'#000'} />
                                    )
                                }
                                onClick={onEditTemplate}
                            >
                                Save
                            </Button>
                        </>
                    ) : null}

                    <Button
                        disabled={submitDisabled}
                        variant="outlined"
                        size="m"
                        style={{ opacity: 1 }}
                        icon={<EvaIcon name="save-outline" size={16} fill={'#000'} />}
                        onClick={onCreateTemplate}
                    >
                        {SAVE_AS_SESSION_TEMPLATE_LABEL}
                    </Button>

                    {allowedToEditDelete ? (
                        <>
                            <Button
                                disabled={submitDisabled}
                                variant="outlined"
                                size="m"
                                icon={<EvaIcon name="trash-outline" size={16} fill={'#000'} />}
                                onClick={() => setShowConfirmDelete(true)}
                            >
                                Delete
                            </Button>

                            <Dialog
                                fullWidth={true}
                                open={showConfirmDelete}
                                message={'Are you sure you want to delete this template?'}
                                onClose={() => setShowConfirmDelete(false)}
                                onConfirm={onDelete}
                                confirmText={'Yes, DELETE'}
                            />
                        </>
                    ) : null}
                </StickyActions>
            </Actions>
            <Footer></Footer>
            <BackButton onClick={onBackButtonClick}>
                <BackButtonIcon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.8334 9.99935H4.16669M4.16669 9.99935L10 15.8327M4.16669 9.99935L10 4.16602"
                        stroke="#475467"
                    />
                </BackButtonIcon>
            </BackButton>
        </TemplateDetailContainer>
    );
};

export default TemplateDetail;

const sessionTypeInfo = {
    title: 'Session Type',
    content: (
        <>
            <TypographyV2 size="text-sm" color="grey-700">
                <strong>Real-time Buffered</strong> A new version of Real-time mode, developed to work with all internet
                conditions.
            </TypographyV2>

            <br />
            <TypographyV2 size="text-sm" color="grey-700">
                <strong>Playback Only / Offline</strong> will generate music for the entire session immediately, and has
                reduced music adaptation controls. The internet buffer is much longer and therefore this mode is
                recommended for users with slow or variable internet connections, or for those who wish to run the
                entire session offline.
            </TypographyV2>
            <br />
            <TypographyV2 size="text-sm" color="grey-700">
                <strong>Real-time</strong> will create all music on the fly and allows you to adapt various music
                variables at any time. To ensure the changes feel responsive, the internet buffer is quite short. This
                mode is therefore only appropriate for fast and stable internet connections.
            </TypographyV2>
            <br />
        </>
    ),
};

const musicalPreferenceInfo = {
    title: 'Musical Preference',
    content: (
        <>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                Here you can select the dominant tone colour (or “timbre”) of the music created for your session.
            </TypographyV2>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                <strong>Acoustic</strong> will prioritise instruments such as wind, brass and strings, tuned percussion,
                as well as human voice.
            </TypographyV2>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                <strong>Electronic</strong> will prioritise electronic instruments such as synthesizers.
            </TypographyV2>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                <strong>Mixed acoustic/electronic</strong> includes both of the above, as well as acoustic instruments
                that have been processed electronically.
            </TypographyV2>
            <br />
        </>
    ),
};

const scheduleTypeInfo = {
    title: 'When is this session for',
    content: (
        <>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                <strong>Now</strong> will start the system in prelude mode which plays low intensity Stillness music for
                up to four hours. When in prelude mode you can start the session at any time.
            </TypographyV2>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                <strong>Save for later</strong> will add the session to the ‘scheduled for later’ tab in your sessions
                dashboard.
            </TypographyV2>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                <strong>A specific time</strong> will allow you to set a date and time for this session to start
                automatically.
            </TypographyV2>
            <br />
        </>
    ),
};

const clientControlInfo = {
    title: 'Who is able to control the playback?',
    content: (
        <>
            <TypographyV2 size="text-sm" color="grey-700">
                This determines whether the Client is able to start the session from the remote streaming link, or
                whether the session can only be started from within the Care-Providers control tool.
            </TypographyV2>
        </>
    ),
};
