import React, { createContext, ReactNode } from 'react';

import { useQueryParams } from './useQueryParams';

export const useTestHooks = () => {
    const queryParams = useQueryParams();
    return {
        debug: queryParams.has('debug'),
        debugHls: queryParams.has('debugHls'),
        debugBuffering: queryParams.has('debugBuffering'),
        debugAudio: queryParams.has('debugAudio'),
        debugPlayers: queryParams.has('debugPlayers'),
        debugGain: queryParams.has('debugGain'),
        debugUXThread: queryParams.has('debugUXThread'),
        noVolume: queryParams.has('noVolume'),
        forceHls: queryParams.has('forceHls'),
        forceNativeAudio: queryParams.has('forceNativeAudio'),
        controlVolumeBy: queryParams.has('controlVolumeBy')
            ? (queryParams.get('controlVolumeBy') as 'gain' | 'volume')
            : undefined,
        streamUrl: queryParams.has('streamUrl') ? (queryParams.get('streamUrl') as string) : undefined,
        streamUrlDeckB: queryParams.has('streamUrlDeckB') ? (queryParams.get('streamUrlDeckB') as string) : undefined,
        streamUrlPrelude: queryParams.has('streamUrlPrelude')
            ? (queryParams.get('streamUrlPrelude') as string)
            : undefined,
        maxFileSize: queryParams.has('maxFileSize') ? parseInt(queryParams.get('maxFileSize') ?? '0') : undefined,
        tickStressTestMultiply: queryParams.has('tickStressTestMultiply')
            ? parseInt(queryParams.get('tickStressTestMultiply') ?? '0')
            : undefined,
    };
};
export const TestHooksContext = createContext<ReturnType<typeof useTestHooks> | undefined>(undefined);

export const TestHooksWrapper: React.FC<{ children: ReactNode | ReactNode[] }> = ({ children }) => {
    const testHooks = useTestHooks();
    return (
        <>
            <TestHooksContext.Provider value={testHooks}>{children}</TestHooksContext.Provider>
        </>
    );
};
