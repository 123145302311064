import { SessionScoreModality } from 'wavepaths-shared/core';

export type TemplateProgram = {
    id: string;
    modality: SessionScoreModality;
    name: string;
    useCase: string;
    description: string;
    // actions: { link: string; title: string }[];
    // templates: { id?: string; name: string }[];
};

export const useTemplatePrograms = (): { programs: TemplateProgram[] } => {
    return {
        programs: PROGRAMS,
    };
};

const PROGRAMS: TemplateProgram[] = [
    {
        id: 'k-prog-1',
        modality: SessionScoreModality.KETAMINE,
        name: 'Ketamine Program 1 - Low Intensity',
        useCase:
            'This program is specifically designed to support patients experiencing elevated levels of anxiety, distress or hypervigilance. More broadly, this program is created to offer a sequence of musical experiences that is primarily gentle and calm.',
        description:
            'This therapeutic journey offers a peaceful atmosphere throughout. Featuring Stillness, Vitality and Bittersweet, and with the emotional intensity intentionally kept low, this Program offers the most soothing non-directive musical guidance.',
    },
    {
        id: 'k-prog-2',
        modality: SessionScoreModality.KETAMINE,
        name: 'Ketamine Program 2 - Low to Medium Intensity',
        useCase:
            'This program is specifically designed to support patients experiencing elevated levels of anxiety, distress or hypervigilance. More broadly, this program is created to offer a sequence of musical experiences that is primarily gentle and calm.',
        description:
            'Rooted in compassion and safety, this therapeutic journey embraces a gentle and nurturing approach, providing a sanctuary of calm and re-assurance. Featuring Stillness, Vitality and Bittersweet, and with the emotional intensity intentionally kept low to medium, this Bundle ensures a soothing experience throughout.',
    },
    {
        id: 'k-prog-3',
        modality: SessionScoreModality.KETAMINE,
        name: 'Ketamine Program 3 - Medium to High Intensity',
        useCase: 'This program is tailored to support patients expressing depressive features or hypovigilance. ',
        description:
            'Beginning with a gentle emotional activation, gradually guiding patients towards a place of comfort over the course of the first three sessions. As the program progresses, from the third to the fifth sessions, we introduce music of higher emotional intensity. These templates aim to gently stir and uplift emotions, providing a therapeutic space for patients to explore, express and process their subjective experiences with empathy and understanding. The program ends with the sixth session, on a deeply soothing note.',
    },
    {
        id: 'k-prog-4',
        modality: SessionScoreModality.KETAMINE,
        name: 'Ketamine Program 4 - Medium to High Intensity',
        useCase: 'This program is tailored to support the processing of trauma, tension and depression.',
        description:
            'After the initial session, which incorporates both soothing and dissonant tones, this program consistently amplifies moments of tension. As it unfolds, the music intensifies, featuring a blend of percussive elements and dynamic bridges. These templates are thoughtfully designed to offer a therapeutic environment for patients to delve into, express, and navigate their experiences of tension, friction, and dissonance.',
    },
    {
        id: 'client-prep-prog-3',
        modality: SessionScoreModality.CLIENT_PREPARATION,
        name: 'Client Preparation Program',
        useCase:
            "This program is created to help increase your clients' readiness for their first therapy session by the use of deep listening. Originally this program was developed in the context for Psychedelic Therapy, but it can be used for other use-cases too.",
        description:
            'This Program offers 3 distinct listening experiences, with the first session being short and low in emotional intensity, primarily serving as an initial toe-dipping experience to experience what it is like to lie with an eye-mask listening to music. The second session is longer, but still primarily soft, with the third session offering a long introspective session including more emotional intense music.',
    },
    // {
    //     id: 'psilo-prog-1',
    //     modality: SessionScoreModality.PSILOCYBIN,
    //     name: 'Psilocybin Program 1',
    //     useCase:
    //         'This program is created for facilitators planning to run more than one session with their client using Psilocybin. This program is tailored to support patients expressing depressive features or hypovigilance.',
    //     description:
    //         'For the first session we suggest beginning with our Journey 1 template. This template presents a diverse and dynamic musical experience that engages emotions deeply. Notably, it intentionally omits the emotionality of Tension, which can sometimes be perceived as more challenging.\n\nHowever, please be aware that the Journey template does feature emotionally intense compositions.',
    // },
    // {
    //     id: 'psilo-prog-2',
    //     name: 'Psilocybin Program 2',
    //     modality: SessionScoreModality.PSILOCYBIN,
    //     useCase:
    //         'This program is created for facilitators planning to run more than one session with their client using Psilocybin. This program is specifically designed to support patients experiencing elevated levels of anxiety, distress or hypervigilance. More broadly, this program is created to offer a sequence of musical experiences that starts the most gentle and calm.',
    //     description:
    //         'For your initial session, we suggest choosing one of the templates that spotlight the theme of Vitality. These template cultivate peacefulness and reassuring emotions, providing a gentle introduction to the experience. Moving forward, we recommend the Journey 1 template for your second session. This template presents a diverse and dynamic musical array, featuring more deepening waves and bridges, and is designed to more strongly invite emotional expression. Notably, the Journey 1 template does not incorporate the emotionality Tension, yet still includes moments that may be experienced as more challenging.',
    // },
    // {
    //     id: 'mdma-prog-1',
    //     modality: SessionScoreModality.MDMA,
    //     name: 'MDMA Program 1',
    //     useCase:
    //         'This program is created for facilitators planning to run more than one session with their client using MDMA. This program is tailored to support patients expressing depressive features or hypovigilance.',
    //     description:
    //         'For the first session we suggest beginning with our Journey 1 template. This template presents a diverse and dynamic musical experience that engages emotions deeply. Notably, it intentionally omits the emotionality of Tension, which can sometimes be perceived as more challenging.\n\nHowever, please be aware that the Journey template does feature emotionally intense compositions.',
    // },
    // {
    //     id: 'mdma-prog-2',
    //     modality: SessionScoreModality.MDMA,
    //     name: 'MDMA Program 2',
    //     useCase:
    //         'This program is created for facilitators planning to run more than one session with their client using MDMA. This program is specifically designed to support patients experiencing elevated levels of anxiety, distress or hypervigilance. More broadly, this program is created to offer a sequence of musical experiences that commences with the utmost gentleness and tranquillity.',
    //     description:
    //         'For your initial session, we suggest choosing one of the templates that spotlight the theme of Vitality. These template cultivate peacefulness and reassuring emotions, providing a gentle introduction to the experience. \n\nMoving forward, we recommend the Journey 1 template for your second session. This template presents a diverse and dynamic musical array, featuring more deepening waves and bridges, and is designed to more strongly invite emotional expression. \n\nNotably, the Journey 1 template does not incorporate the emotionality Tension, yet still includes moments that may be experienced as more challenging.',
    // },
];
