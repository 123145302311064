import { Link } from '@material-ui/core';
import firebase from 'firebase/app';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@/component-library';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { Features } from '@/features';
import useMySubscription from '@/hooks/useMySubscription';
import { createCustomerPortal } from '@/util/stripeUtil';

import { useAuthContext } from '../../auth';
import { InputGroup, ProfileSection, Text } from './components';
// import { UsageInfo } from './UsageInfo';

export type ISubscriptionInfoProps = {
    fbUser: firebase.User;
};

export function SubscriptionInfo({ fbUser }: ISubscriptionInfoProps) {
    const history = useHistory();
    const { isPersonal, isEnabled, firebaseUser } = useAuthContext();

    const { userHasActiveSubscription, isLoading: isLoadingMySubscription, activeOfferingTitle } = useMySubscription({
        fbUser: firebaseUser,
    });
    //console.debug({ userHasActiveSubscription, isLoadingMySubscription, activeOfferingTitle });
    // const { isLoading: isLoadingSessionUsage } = useSessionUsage({ fbUser });

    const isLoading = isLoadingMySubscription;
    const handleSubscribe = () => {
        history.push('/subscriptions');
    };

    const handleChangeSubscription = () => {
        history.push('/subscriptions?changing=1');
    };

    const handleCancelSubscription = async () => {
        const response = await createCustomerPortal({
            uid: fbUser.uid,
        });
        const customerPortalUrl = response ? response.url : undefined;
        if (typeof window !== 'undefined' && customerPortalUrl) window.location.href = customerPortalUrl;
    };

    // function convertToActiveBonnySubscription(subscription?: BonnySubscription): ActiveBonnySubscription | undefined {
    //     return subscription as ActiveBonnySubscription;
    // }

    return isLoading ? (
        <>Loading...</>
    ) : !userHasActiveSubscription ? (
        <>
            <section>
                <Text variant="h6">Subscription</Text>
                <ProfileSection>
                    {!isEnabled(Features.FREE_ACCESS) ? (
                        <>
                            <p>You do not currently have an active subscription to Wavepaths.</p>
                            <Button variant="solid-blue" size="m" onClick={handleSubscribe}>
                                Subscribe
                            </Button>
                        </>
                    ) : (
                        <>
                            <Link href="mailto:support@wavepaths.com">
                                <Button variant="solid-blue">Send us an email to cancel subscription.</Button>
                            </Link>
                        </>
                    )}
                </ProfileSection>
            </section>
        </>
    ) : (
        <>
            <section>
                <Text variant="h6">Subscription</Text>
                <ProfileSection>
                    {isPersonal ? (
                        <>
                            <Button variant="outlined" size="m" onClick={handleCancelSubscription}>
                                Cancel Subscription
                            </Button>
                        </>
                    ) : (
                        <>
                            <InputGroup>
                                <label>Subscription</label>
                                <Text variant="body1">{activeOfferingTitle ?? 'Unknown'}</Text>
                            </InputGroup>
                            <TypographyV2>
                                Billing will occur on your usual date and is prorated, meaning you're billed only for
                                the amount of time you spend trying out your new plan. You can swap back at any time.
                            </TypographyV2>
                            <Button variant="outlined" size="m" onClick={handleCancelSubscription}>
                                Cancel Subscription
                            </Button>
                            <Button variant="solid-blue" size="m" onClick={handleChangeSubscription}>
                                Upgrade
                            </Button>
                            <Button variant="outlined" size="m" onClick={handleChangeSubscription}>
                                Downgrade
                            </Button>
                        </>
                    )}
                </ProfileSection>
            </section>
        </>
    );
}
