import styled from '@emotion/styled';
import React, { useState } from 'react';
import { isPreludePathScore, isPrePostLudePathScore, PathScore } from 'wavepaths-shared/core';

import { Button, EvaIcon } from '@/component-library';

export const MOVE_CARD_UP_LABEL = 'Move Up';
export const MOVE_CARD_DOWN_LABEL = 'Move Down';
export const DELETE_WAVE_LABEL = 'Delete';

export const IconButton = styled(Button)({
    display: 'flex',
    width: 32,
    height: 32,
    borderRadius: 32,
    padding: 0,
    // border: '1px solid #ADB9D6',
    transition: 'opacity 0.15s ease',
});

const ActionMenuContainer = styled.div({
    width: '100%',
    display: 'grid',
    gridArea: 'actions',
    gridAutoFlow: 'column',
    gap: '8px',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(3, 1fr)',
});

export interface IWaveCardTopBarProps {
    isOpen: boolean;
    pathScore: PathScore;
    onRemoveWave: (e: any) => void;
    onMoveWaveUp?: (e: any) => void;
    onMoveWaveDown?: (e: any) => void;
}

const WaveCardTopBar = ({
    pathScore,
    onMoveWaveUp,
    onMoveWaveDown,
    onRemoveWave,
}: IWaveCardTopBarProps): JSX.Element | null => {
    const [isPendingDelete, setIsPendingDelete] = useState(false);

    if (isPreludePathScore(pathScore)) return null;

    const removeWaveOnce = (e: any) => {
        if (!isPendingDelete) {
            setIsPendingDelete(true);
            return onRemoveWave(e);
        } else {
            return false;
        }
    };

    if (!isPrePostLudePathScore(pathScore)) {
        return (
            <ActionMenuContainer className="tour-waveActions">
                {onMoveWaveUp && (
                    <IconButton size="m" onClick={onMoveWaveUp} variant="outlined" aria-label={MOVE_CARD_UP_LABEL}>
                        <EvaIcon name="arrow-upward" size={16} fill="rgb(44, 57, 88)" />
                    </IconButton>
                )}
                {onMoveWaveDown && (
                    <IconButton size="m" onClick={onMoveWaveDown} variant="outlined" aria-label={MOVE_CARD_DOWN_LABEL}>
                        <EvaIcon name="arrow-downward" size={16} fill="rgb(44, 57, 88)" />
                    </IconButton>
                )}
                <IconButton size="m" onClick={removeWaveOnce} variant="outlined" aria-label={DELETE_WAVE_LABEL}>
                    <EvaIcon name="trash-2-outline" size={16} fill="rgb(44, 57, 88)" />
                </IconButton>
            </ActionMenuContainer>
        );
    }

    return <div style={{ height: '8px' }} />;
};

export default WaveCardTopBar;
