import styled from '@emotion/styled';
import React, { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

import { Tabs } from '@/components';

import Logo from '../../images/WavepathBlueLogo.svg';
import ExistingUsers from './ExistingUsers';
import NewUsers from './NewUsers';

const Container = styled.div({
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    display: 'grid',
    placeContent: 'center',
    padding: '40px 0',
    background: '#F1F3F8',
});

const Card = styled.div({
    width: '90vw',
    maxWidth: '400px',
    background: 'rgba(255,255,255,0.5)',
    border: '1px solid white',
    borderRadius: '4px',
    paddingTop: '32px',
    paddingBottom: '16px',
    paddingLeft: '32px',
    paddingRight: '32px',
    boxShadow: '0px 0px 20px #CFD6E7',
});

const StyledHeader = styled.header({
    display: 'grid',
    placeContent: 'center',
    paddingBottom: 8,
});

const StyledLogo = styled.img({
    height: '25px',
});

const TabLabel = styled.div({
    margin: 0,
    color: '#2C3958',
});

export const Login = () => {
    const [activeTab, setActiveTab] = useState(1);
    const location = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(location?.search), [location?.search]);
    const loginError = !!searchParams.get('error');

    const setAndShowTabs = (tabToActivate: number) => {
        setActiveTab(tabToActivate);
    };

    const [{ loginReferrer }, setCookie] = useCookies(['loginReferrer']);

    useMemo(() => {
        if (!loginReferrer) {
            const referrer = (location.state as any)?.from;
            if (referrer) {
                console.debug('Setting landing page', referrer);
                setCookie('loginReferrer', referrer, { path: '/' });
            }
        }
    }, []);

    const tabContent = [
        {
            identifier: 'NewUsers',
            label: <TabLabel>New Users</TabLabel>,
            body: <NewUsers />,
        },
        {
            identifier: 'ExistingUsers',
            label: <TabLabel>Existing Users</TabLabel>,
            body: <ExistingUsers initialStatus={loginError ? 'error' : undefined} />,
        },
    ];

    useEffect(() => {
        if (loginError) {
            setAndShowTabs(1);
        }
    }, [loginError]);

    return (
        <Container>
            <Card>
                <StyledHeader>
                    <StyledLogo src={Logo} alt="Wavepaths" />
                </StyledHeader>
                {<Tabs content={tabContent} activeTab={activeTab} onChange={setActiveTab} />}
            </Card>
        </Container>
    );
};
