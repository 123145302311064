import styled from '@emotion/styled';
import Card from '@material-ui/core/Card';
import { format } from 'date-fns';
import React from 'react';
import { Wavepath } from 'wavepaths-shared/core';

import { WaveDurationMenu } from '@/component-library';
import { FeedbackButtons } from '@/components/notifications/FeedbackButtons';

import { useAuthContext } from '../../../auth';

const StyledCard = styled(Card)({
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'repeat(3, min-content)',
    background: 'rgba(255,255,255,0.9)',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    width: '100%',
});

const CardContent = styled.div({
    padding: '12px 16px 12px 24px',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-around',
    alignItems: 'center',
});

export function CurrentWaveCard({ timeRemainingMs }: { timeRemainingMs?: number }) {
    const { isPersonal } = useAuthContext();
    return (
        <StyledCard>
            <CardContent>
                {isPersonal ? (
                    <></>
                ) : (
                    <WaveDurationMenu
                        timerText={timeRemainingMs ? format(timeRemainingMs, 'mm:ss') : '--:--'}
                        extensionOptions={[]}
                        isExtensionDisabled={true}
                    />
                )}
                <FeedbackButtons />
            </CardContent>
        </StyledCard>
    );
}

const getTimeRemainingMs = ({
    elapsedTimeSecs,
    currentWave,
}: {
    elapsedTimeSecs: number;
    currentWave?: Wavepath;
}): number | undefined => {
    if (!currentWave) return undefined;

    const timeEnd = currentWave.plan?.toTime;
    if (!timeEnd) return 0;

    const timeRemaining = timeEnd - elapsedTimeSecs * 1000;
    return timeRemaining;
};

export function CurrentWaveCardContainer({
    elapsedTimeSecs,
    currentWave,
    ...rest
}: {
    elapsedTimeSecs: number;
    currentWave?: Wavepath;
}) {
    return <CurrentWaveCard timeRemainingMs={getTimeRemainingMs({ elapsedTimeSecs, currentWave })} {...rest} />;
}
