import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';
import { CoreEmotionalAtmosphere, SessionHeader } from 'wavepaths-shared/core';

import TemplateArtwork from '@/component-library/components/TemplateArtwork';
import TypographyV2 from '@/component-library/typography/TypographyV2';

export interface ISessionAction {
    description: string;
    action: () => void;
}

export interface ISessionCardProps {
    title: string;
    subtitle: string;
    info: string[];
    session: SessionHeader;
    onClick?: () => void;
}

const Container = styled.div`
    width: 200px;
    display: grid;
    grid-template-rows: repeat(2, min-content);
    cursor: pointer;
    &:hover .artwork {
        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
        transform: scale(1.04) translateY(-5px);
    }
`;

const Artwork = styled.div`
    width: 200px;
    height: 200px;
    overflow: hidden;

    border-radius: 6px;
    background: #f9fafb; // Grey 50
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease, transform 0.2s ease;
    position: relative;
`;

const Info = styled.div`
    display: grid;
    // gap: 4px;
    padding: 8px 0;
`;

const Description = styled.div`
    display: grid;
`;

const Meta = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

const MetaItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
`;

const SessionCard = ({
    title,
    subtitle,
    info,
    onClick,
    session,
}: PropsWithChildren<ISessionCardProps>): JSX.Element => {
    return (
        <>
            <Container
                // className={className} //TODO?
                onClick={onClick}
            >
                <Artwork className="artwork">
                    <TemplateArtwork
                        randomSeed={session.id}
                        intensity={'none'} //TODO
                        primaryEmotion={session.emotionalities?.primary ?? CoreEmotionalAtmosphere.SILENCE}
                        secondaryEmotion={session.emotionalities?.secondary ?? CoreEmotionalAtmosphere.SILENCE}
                        tertiaryEmotion={session.emotionalities?.tertiary ?? CoreEmotionalAtmosphere.SILENCE}
                    />
                    {/* <Overlay>
                        <EvaIcon name="refresh-outline" size={100} iconStyle={{ fill: 'rgba(255,255,255, 0.4)' }} />
                    </Overlay> */}
                </Artwork>
                <Info>
                    <Description>
                        <TypographyV2 element={'h3'} display={'block'} size={'text-sm'} weight={'medium'} truncated>
                            {title}
                        </TypographyV2>
                        <TypographyV2
                            display={'block'}
                            size={'text-xs'}
                            weight={'regular'}
                            color={'grey-600'}
                            truncated
                        >
                            {subtitle}
                        </TypographyV2>
                    </Description>
                    <Meta>
                        {info?.length ? (
                            <MetaItem>
                                <TypographyV2
                                    display={'block'}
                                    size={'text-xs'}
                                    weight={'medium'}
                                    color={'grey-400'}
                                    truncated
                                >
                                    {info[0]}
                                </TypographyV2>
                            </MetaItem>
                        ) : (
                            <></>
                        )}
                    </Meta>
                </Info>
            </Container>
        </>
        // <StyledCard>
        //     <Title variant="h6">{title}</Title>
        //     <Meta>
        //         <MetaColumns>
        //             <div>
        //                 <Subtitle variant="body2">{subtitle}</Subtitle>
        //                 {userInfoText && <User variant="body2">{userInfoText}</User>}
        //             </div>

        //             <ButtonGroup>
        //                 {primaryAction && (
        //                     <Button variant="solid-blue" size="s" onClick={primaryAction}>
        //                         {primaryActionLabel}
        //                     </Button>
        //                 )}
        //                 {actions?.length
        //                     ? actions.map(({ description, action }, idx) => (
        //                           <Button key={idx} variant="outlined" size="s" onClick={action}>
        //                               {description}
        //                           </Button>
        //                       ))
        //                     : null}
        //                 {actionsComponent}
        //             </ButtonGroup>
        //         </MetaColumns>
        //     </Meta>
        //     <Content>{children}</Content>
        //     <Info>
        //         {info.length === 1 ? (
        //             <InfoItem variant="body3">{info}</InfoItem>
        //         ) : (
        //             <InfoItems>
        //                 {info.map((infoItem, idx) => (
        //                     <InfoItem key={idx} variant="body3">
        //                         {infoItem}
        //                     </InfoItem>
        //                 ))}
        //             </InfoItems>
        //         )}
        //         {subActions && (
        //             <Feedback>
        //                 {subActions.map((action, idx) => (
        //                     <Button key={idx} variant="clear-underlined" size="xs" onClick={action.action}>
        //                         {action.description}
        //                     </Button>
        //                 ))}
        //             </Feedback>
        //         )}
        //     </Info>
        //     {footer && <Footer>{footer}</Footer>}
        // </StyledCard>
    );
};

export default SessionCard;
