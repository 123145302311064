import './SessionClock.scss';

import classNames from 'classnames';
import React from 'react';

import { Typography } from '@/component-library';

import { useSessionRemoteTick } from '../../common/hooks/useSessionTick';
import { formatDuration } from '../../dateUtilsV2';

export const SessionClock: React.FC = () => {
    const tick = useSessionRemoteTick();
    const timeElapsedMs = tick ? tick.absoluteTime : 0;
    return <Clock timeElapsedMs={timeElapsedMs} totalDurationMs={(tick && tick?.sessionDuration) ?? 0} />;
};

export const Clock = React.memo(
    ({ timeElapsedMs, totalDurationMs }: { timeElapsedMs: number; totalDurationMs: number }): JSX.Element => {
        return (
            <div className={classNames('sessionClock')}>
                <Typography className="timePassed" variant="h2" isFixedWidth>
                    {timeElapsedMs > 0 ? formatDuration(timeElapsedMs) : '00:00'}
                </Typography>
                <Typography className="timeDivider" variant="subtitle1" isFixedWidth>
                    /
                </Typography>
                <Typography className="timeTotal" variant="subtitle1" isFixedWidth>
                    {totalDurationMs > 0 ? formatDuration(totalDurationMs) : '00:00'}
                </Typography>
            </div>
        );
    },
);
