import React, { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import styled from 'styled-components';

import { Button } from '@/component-library';
import TextInput from '@/component-library/components/TextInput';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { StarRating } from '@/components/EndOfSessionFeedbackInput';

import { useAuthContext } from '../../auth';
import * as sessionApi from '../../common/api/sessionApi';
import { Modal } from '../../common/components/Modal';
import iconClose from '../../images/iconClose.svg';
// import UserEvents from '../../UserEvents';

// type EndOfSessionFeedbackModalProps = {
//     endOfSessionFeedback?: EndOfSessionFeedback;
//     setEndOfSessionFeedback: (fb: EndOfSessionFeedback) => void;
//     onSubmit: () => void;
//     onSubmitAndEnd: () => void;
//     onClose: () => void;
//     sessionId: string;
//     sessionEnded?: boolean;
//     integrationLink: string;
// };
const Container = styled.div`
    padding: 20px;
    max-height: 80vh;
    overflow-y: scroll;
`;

const Actions = styled.div`
    padding: 20px;
    display: flex;
    gap: 8px;
    box-shadow: #f1f1f1 0 -8px 12px -4px;
`;

const Header = styled.h3``;

const Background = styled.div``;

const Question = styled(TypographyV2)`
    margin-top: 12px;
`;

const Required = styled.span`
    color: red;
`;

const Answer = styled.div`
    padding-top: 8px;
    padding-bottom: 8px;
`;

const TextAnswer = styled(TypographyV2)({
    margin: 0,
});

export function PersonalIntegrationModalContainer({
    // endOfSessionFeedback,
    // setEndOfSessionFeedback,
    // onSubmit,
    // onSubmitAndEnd,
    onClose,
    sessionId,
    sessionOwner,
}: // sessionEnded = true,
// integrationLink,
{
    onClose: () => void;
    sessionId: string;
    sessionOwner: string;
}) {
    const { firebaseUser } = useAuthContext();

    useEffect(() => {
        (async () => {
            const data = await sessionApi.getMyIntegrationAnswers(sessionId, firebaseUser!);

            if (data) {
                setExperienceRating(data.answers.experience_rating);
                setExperienceSummary(data.answers.experience_summary);
                setMostRemember(data.answers.most_remember);
                setInsights(data.answers.insights);
                setConfusions(data.answers.confusions);
                setOther(data.answers.other);
            }
        })();
    }, [sessionId]);

    //we capture quite sensitive and fragile information in a lot of text - better remember it than reload accidentally or go offline
    const [experienceSummary, setExperienceSummary] = useLocalStorage<string>(sessionId + 'experienceSummary', '');
    const [mostRemember, setMostRemember] = useLocalStorage<string>(sessionId + 'mostRemember', '');
    const [insights, setInsights] = useLocalStorage<string>(sessionId + 'insights', '');
    const [confusions, setConfusions] = useLocalStorage<string>(sessionId + 'confusions', '');
    const [other, setOther] = useLocalStorage<string>(sessionId + 'other', '');
    const [experienceRating, setExperienceRating] = useLocalStorage<number>(sessionId + 'experienceRating', 0);

    const onSubmit = () => {
        sessionApi
            .saveIntegrationAnswers(
                sessionId,
                {
                    experience_rating: experienceRating || 0,
                    experience_summary: experienceSummary || '',
                    most_remember: mostRemember || '',
                    insights: insights || '',
                    confusions: confusions || '',
                    other: other || '',
                },
                firebaseUser!,
            )
            .then(() => {
                setExperienceSummary(undefined);
                setMostRemember(undefined);
                setInsights(undefined);
                setConfusions(undefined);
                setOther(undefined);
                setExperienceRating(undefined);
                //TODO: show positive feedback dialog for 3s
                onClose();
            })
            .catch((e) => {
                console.error('Save error', e);
                //TODO: User feedback dialog
            });
    };

    return (
        <PersonalIntegrationModal
            onClose={onClose}
            sessionOwner={sessionOwner}
            experienceRating={experienceRating}
            setExperienceRating={setExperienceRating}
            setExperienceSummary={setExperienceSummary}
            experienceSummary={experienceSummary}
            setMostRemember={setMostRemember}
            mostRemember={mostRemember}
            setInsights={setInsights}
            insights={insights}
            setConfusions={setConfusions}
            confusions={confusions}
            setOther={setOther}
            other={other}
            onSubmit={onSubmit}
            userId={firebaseUser!.uid}
        />
    );
}

type PersonalIntegrationModalProps = {
    onClose: () => void;
    sessionOwner: string;
    experienceRating?: number;
    setExperienceRating?: (val: number) => void;
    setExperienceSummary?: (val: string) => void;
    experienceSummary?: string;
    setMostRemember?: (val: string) => void;
    mostRemember?: string;
    setInsights?: (val: string) => void;
    insights?: string;
    setConfusions?: (val: string) => void;
    confusions?: string;
    setOther?: (val: string) => void;
    other?: string;
    onSubmit?: () => void;
    userId: string;
};
export function PersonalIntegrationModal({
    onClose,
    sessionOwner,
    experienceRating,
    setExperienceRating,
    setExperienceSummary,
    experienceSummary,
    setMostRemember,
    mostRemember,
    setInsights,
    insights,
    setConfusions,
    confusions,
    setOther,
    other,
    onSubmit,
    userId,
}: PersonalIntegrationModalProps) {
    return (
        <Modal className="guideEndOfSessionFeedback" isWide>
            <Button variant="solid-blue" className="closeModal" onClick={onClose}>
                <img src={iconClose} alt="Close" />
            </Button>

            <Container>
                <Header>
                    <TypographyV2 size="text-lg" weight="bold">
                        Integration
                    </TypographyV2>
                </Header>
                <Background>
                    <TypographyV2 size="text-md" element="p">
                        Taking an active role in engaging with the experiences from your session is instrumental to
                        their integration into your life.
                    </TypographyV2>
                    <TypographyV2 size="text-md" element="p">
                        Think of these experiences as seeds, and your nurturing care and attention as the vital water
                        and nutrition that enable them to take root and flourish.
                    </TypographyV2>
                    <TypographyV2 size="text-md" element="p">
                        We’ve curated a collection of reflective exercises to aid you in this work.
                    </TypographyV2>
                </Background>

                {userId != sessionOwner ? (
                    <Background>
                        <TypographyV2 size="text-xs" element="p">
                            * Please note that your answers are visible only to you and the guide/therapist you work
                            with.
                        </TypographyV2>
                    </Background>
                ) : (
                    <Background>
                        <TypographyV2 size="text-xs" element="p">
                            * Please note that your answers are visible only to you.
                        </TypographyV2>
                    </Background>
                )}
                <Question weight="bold">
                    How meaningful was this session for you? <Required>*</Required>
                </Question>
                <Answer>
                    <StarRating
                        rating={experienceRating || 0}
                        onChangeRating={(r) => setExperienceRating && setExperienceRating(r)}
                    />
                </Answer>

                <Question weight="bold">Could you describe your experience in just a few words?</Question>
                <Answer>
                    {setExperienceSummary ? (
                        <TextInput
                            variant="outlined"
                            size="small"
                            name="experience_summary"
                            onChange={(e) => setExperienceSummary && setExperienceSummary(e.target.value)}
                            value={experienceSummary}
                        />
                    ) : (
                        <TextAnswer size="text-sm" color="grey-900" element="p">
                            {experienceSummary ?? ''}
                        </TextAnswer>
                    )}
                </Answer>

                <Question weight="bold">Can you describe what you most remember?</Question>
                <Answer>
                    {setMostRemember ? (
                        <TextInput
                            variant="outlined"
                            size="small"
                            name="most_remember"
                            onChange={(e) => setMostRemember && setMostRemember(e.target.value)}
                            value={mostRemember}
                        />
                    ) : (
                        <TextAnswer size="text-sm" color="grey-900" element="p">
                            {mostRemember ?? ''}
                        </TextAnswer>
                    )}
                </Answer>

                <Question weight="bold">Did any thoughts or insights come to you?</Question>
                <Answer>
                    {setInsights ? (
                        <TextInput
                            variant="outlined"
                            size="small"
                            name="insights"
                            onChange={(e) => setInsights && setInsights(e.target.value)}
                            value={insights}
                        />
                    ) : (
                        <TextAnswer size="text-sm" color="grey-900" element="p">
                            {insights ?? ''}
                        </TextAnswer>
                    )}
                </Answer>

                <Question weight="bold">Anything that emerged that is confusing or you're unsure of?</Question>
                <Answer>
                    {setConfusions ? (
                        <TextInput
                            variant="outlined"
                            size="small"
                            name="confusions"
                            onChange={(e) => setConfusions && setConfusions(e.target.value)}
                            value={confusions}
                        />
                    ) : (
                        <TextAnswer size="text-sm" color="grey-900" element="p">
                            {confusions ?? ''}
                        </TextAnswer>
                    )}
                </Answer>

                <Question weight="bold">Anything else that you like to mention?</Question>
                <Answer>
                    {setOther ? (
                        <TextInput
                            variant="outlined"
                            size="small"
                            name="other"
                            onChange={(e) => setOther && setOther(e.target.value)}
                            value={other}
                        />
                    ) : (
                        <TextAnswer size="text-sm" color="grey-900" element="p">
                            {other ?? ''}
                        </TextAnswer>
                    )}
                </Answer>
            </Container>

            {onSubmit ? (
                <Actions>
                    <Button variant="solid-blue" size="m" onClick={onSubmit}>
                        Save
                    </Button>
                    <Button variant="outlined" size="m" onClick={onClose}>
                        Skip for now
                    </Button>
                </Actions>
            ) : null}
        </Modal>
    );
}
