import React, { useEffect, useState } from 'react';
import { ACTIONS, EVENTS, Step, StoreHelpers } from 'react-joyride';

import { Typography } from '@/component-library';
import { ProductTour } from '@/components/ProductTour';

/** TODOS
    [] conditionally render product tour (i.e. only for intro session 1 or whatever)
    [x] add product tour steps
    [x] welcome step
    [] fix styling of tooltip buttons
    [] migrate intro sessions to use v2 api endpoints
    [] how do we test this??
    [] make product tour available anytime in the guide view (stretch goal)
**/

type ProductTourState = 'not-started' | 'started' | 'started-queue-open';

export const getSteps = (state: ProductTourState): Step[] => {
    switch (state) {
        case 'not-started':
            return [
                {
                    title: 'Guiding a Wavepaths session',
                    content: (
                        <>
                            <Typography variant="body2">
                                This tour will guide you through the main controls for your sessions.
                            </Typography>
                            <Typography variant="body2">
                                This is the main screen of your active session. Notice that it will take a short time to
                                load your unique settings. Please take a moment to wait and hear the music slowly fading
                                in.
                            </Typography>
                        </>
                    ),
                    placement: 'center',
                    target: 'body',
                },
                {
                    target: '.tour-startSession',
                    content: (
                        <>
                            <Typography variant="body2">
                                Each session only begins after you have clicked this button. Until then calm music will
                                play indefinitely.
                            </Typography>
                            <Typography variant="body2">
                                If you work with a psychedelic medicine, press this button when the medicine is being
                                administered to synchronise the music with the pharmacodynamics of the medicine. For any
                                other use simply press it when you are ready.
                            </Typography>
                            <Typography variant="body2">To continue the tour please press Start Session.</Typography>
                        </>
                    ),
                    hideFooter: true,
                },
            ];
        case 'started': {
            return [
                {
                    target: '.tour-sparkline',
                    content: (
                        <>
                            <Typography variant="body2">
                                At the top of the screen you can find the timer and the session timeline. As the session
                                has now started, the timer has begun. The black bar has begun progressing though the
                                timeline, indicating where you are in the overall session and the current wave.
                            </Typography>
                        </>
                    ),
                },
                {
                    target: '.tour-sessionOptions',
                    content: (
                        <>
                            <Typography variant="body2">
                                Here you can control the volume for this device, as well as other functions such as
                                copying a link for listening to this session on another device.
                            </Typography>
                        </>
                    ),
                    // // spotlightClicks: true,
                },
                {
                    target: '.tour-intensityController',
                    content: (
                        <>
                            <Typography variant="body2">
                                By adjusting this slider you can control the level of emotional intensity or “depth” of
                                the music. The higher the level, the more musically complex and emotionally intense the
                                music becomes.
                            </Typography>
                            <Typography variant="body2">
                                Notice that when you hover over or tap the slider, a legend appears that shows where you
                                can manually access 4 distinct functions for this wave of music: silence, soothe, deepen
                                and release.
                            </Typography>
                            <Typography variant="body2">
                                Each wave moves through a predetermined sequence of levels, but you can manually change
                                these at any time. Take a moment to see and hear what happens when you move the music up
                                or down a few levels.
                            </Typography>
                        </>
                    ),
                },
                // { // no class added to dom yet as element is not visible
                //     target: '.tour-snackbar',
                //     content: (
                //         <>
                //             <Typography variant="body2">
                //                 Whenever you are changing something to the current music playing, you have 5 seconds to undo the
                //                 action to help prevent music-changes from happening by mistake.{' '}
                //             </Typography>
                //             <Typography variant="body2">
                //                 For desired changes, you can either let the 5 seconds pass by itself, or select “submit now”.
                //             </Typography>
                //         </>
                //     ),
                //     // spotlightClicks: true,
                // },
                {
                    target: '.tour-sparkline',
                    content: (
                        <>
                            <Typography variant="body2">
                                Notice how any changes to the current wave are reflected in the visualisation of that
                                wave in the timeline.
                            </Typography>
                            <Typography variant="body2">
                                Whenever you change intensity manually, it will stay at that level for the duration of
                                the current wave, or until you change it again. It will no longer change automatically
                                as it would have before.
                            </Typography>
                        </>
                    ),
                },
                {
                    target: '.tour-ceaController',
                    content: (
                        <>
                            <Typography variant="body2">
                                Here you can find four distinct “Emotionalities” to select from, each reliably
                                facilitating a different musical experience.
                            </Typography>
                        </>
                    ),
                },
                {
                    target: '.tour-extendWave',
                    content: (
                        <>
                            <Typography variant="body2">
                                Here you can see the time that is left for the current wave. You can manually extend the
                                duration of the current wave by pressing the “+” button.
                            </Typography>
                        </>
                    ),
                },
                {
                    target: '.tour-instrumentRefreshButton',
                    content: (
                        <>
                            <Typography variant="body2">
                                Here you can see what instruments are being played at the moment, and replace selected
                                instruments at wish.
                            </Typography>
                        </>
                    ),
                },
                {
                    target: '.tour-feedback',
                    content: (
                        <>
                            <Typography variant="body2">
                                Here you can provide us with feedback about the current music that is playing. We
                                greatly value receiving feedback, so please do not hesitate to share anything about your
                                or your client's experience here.
                            </Typography>
                        </>
                    ),
                },
                {
                    target: '.tour-toggleWaveQueue',
                    content: (
                        <>
                            <Typography variant="body2">
                                With this button, you can open and close a window that shows you the queue of waves for
                                this session. Go ahead and click it to open the menu.
                            </Typography>
                        </>
                    ),
                    hideFooter: true,
                },
            ];
        }
        case 'started-queue-open': {
            return [
                {
                    target: '.tour-sparkline',
                    content: (
                        <>
                            <Typography variant="body2">
                                Note that you can also simply select any wave that you want to view or edit by selecting
                                the respective wave in the timeline at the top of your screen.
                            </Typography>
                            <Typography variant="body2">Go ahead and select a wave now.</Typography>
                        </>
                    ),
                },
                {
                    target: '.tour-waveQueue',
                    content: (
                        <>
                            <Typography variant="body2">
                                Selecting a wave brings up a menu to edit the variables of the wave. Take a moment to
                                familiarise yourself with all the options, and learn more about each variable using the
                                info icons on their left.
                            </Typography>
                            <Typography variant="body2">
                                Actions to skip, move or remove waves are available above the menu.
                            </Typography>
                        </>
                    ),
                },
                {
                    target: '.tour-waveQueue',
                    content: (
                        <>
                            <Typography variant="body2">
                                Take a moment to select a few new variables for the selected wave. Note that in order to
                                implement these changes, you need to save them first at the bottom of the card.
                            </Typography>
                        </>
                    ),
                },
                {
                    target: 'body',
                    placement: 'center',
                    content: (
                        <>
                            <Typography variant="body2">
                                Congratulations. You have now completed this introduction tour and will have a good
                                grasp on how you can change music while running a session. Please take as long as you
                                want playing around in this session from here on.
                            </Typography>
                        </>
                    ),
                },
            ];
        }
    }
};

function ProductTourComponent({ state }: { state: ProductTourState }): JSX.Element {
    const [stepIndex, setStepIndex] = useState(0);

    const steps = getSteps(state);
    const [helpers, setHelpers] = useState<StoreHelpers | undefined>(undefined);

    useEffect(() => {
        // if steps change, close, but dont trigger the step change
        helpers?.close();
        setStepIndex(0);
    }, [state]);

    return (
        <ProductTour
            callback={(joyride) => {
                const { type, action } = joyride;
                switch (type) {
                    case EVENTS.STEP_AFTER:
                        switch (action) {
                            case ACTIONS.NEXT:
                                return setStepIndex(stepIndex + 1);
                            case ACTIONS.PREV:
                                return setStepIndex(stepIndex - 1);
                        }
                }
            }}
            getHelpers={setHelpers}
            steps={steps}
            stepIndex={stepIndex}
        />
    );
}

export const InSessionProductTour = React.memo(ProductTourComponent);
