import { Checkbox } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import firebase from 'firebase/app';
import React, { FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button, DropdownControl, Typography } from '@/component-library';

import { saveTherapistLeadInfo } from '../../common/api/bonnyApi';
const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background: #f1f3f8;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    @media (min-width: 769px) {
        flex-direction: row;
    }
`;

const BodyTextBase = styled(Typography)({
    color: '#2c3958',
});

const InputGroup = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
});

const CheckboxInputGroup = styled.div({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    marginBottom: '16px',
});

const Check = styled(Checkbox)({
    padding: 0,
});

const InputLabel = styled.label({
    fontSize: '13.3px',
    fontWeight: 500,
    color: '#2C3958',
    marginBottom: 0,
});

const LoginError = styled.div<{ backgroundColor: string }>(({ backgroundColor }) => ({
    backgroundColor,
    marginTop: 8,
    color: 'white',
    padding: '10px',
    borderRadius: '4px',
    '& a': {
        color: 'white',
        textDecoration: 'underline',
    },
}));

const FormContainer = styled.div`
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid white;
    box-shadow: 0px 0px 20px #cfd6e7;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 40px 60px 20px;
    overflow: scroll;

    @media (min-width: 769px) {
        align-items: flex-start;
        padding-top: 8vh;
    }
`;

const FormContent = styled.div`
    display: grid;
    gridautoflow: row;
    gap: 24px;
    max-width: 440px;
    width: 90%;
`;

const Form = styled.form({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '4px',
});

const FormTitle = styled(BodyTextBase)({});

export function TherapistInfo({ fbUser }: { fbUser: firebase.User }) {
    const [state, setState] = useState<'init' | 'error' | 'validationFailed' | 'signingUp'>('init');
    const [modality, setModality] = useState<string | undefined>(undefined);
    const [medicine, setMedicine] = useState<string | undefined>(undefined);
    const [userType, setUserType] = useState<string | undefined>(undefined);
    const [wantsIndividualTraining, setIndividualTraining] = useState<boolean>(false);
    const [wantsTailoredOnboarding, setTailoredOnboarding] = useState<boolean>(false);
    const [sessionCount, setSessionCount] = useState<string | undefined>(undefined);

    const history = useHistory();

    const onSubmit = (evt: FormEvent) => {
        setState('init');
        evt.preventDefault();

        if (!modality || !userType || !sessionCount || (modality === 'PAP' && !medicine)) {
            return setState('validationFailed');
        } else {
            setState('signingUp');
            saveTherapistLeadInfo(fbUser, {
                medicine,
                modality,
                userType,
                wantsIndividualTraining,
                wantsTailoredOnboarding,
                sessionCount,
            })
                .then(() => {
                    history.push('/');
                })
                .catch((e) => {
                    console.log(e);

                    setState('error');
                });
        }
    };

    if (state === 'error') {
        Sentry.captureException('Onboarding form submit error');
    }
    const theme = useTheme();

    return (
        <Container>
            <FormContainer>
                <FormContent>
                    <FormTitle variant="h5">Tell us about your practice</FormTitle>
                    <BodyTextBase variant="body2">We'll use these details to optimise your experience.</BodyTextBase>
                    {state === 'error' && (
                        <LoginError backgroundColor={theme.palette.error.main}>
                            <Typography variant="body3" color="inherit">
                                Sorry, something went wrong, please try again later.
                            </Typography>
                        </LoginError>
                    )}
                    {state === 'validationFailed' && !modality && (
                        <LoginError backgroundColor={theme.palette.error.main}>
                            <Typography variant="body3" color="inherit">
                                Please select which modality you work with
                            </Typography>
                        </LoginError>
                    )}
                    {state === 'validationFailed' && modality === 'PAP' && !medicine && (
                        <LoginError backgroundColor={theme.palette.error.main}>
                            <Typography variant="body3" color="inherit">
                                Please select which medicine you work with
                            </Typography>
                        </LoginError>
                    )}
                    {state === 'validationFailed' && !userType && (
                        <LoginError backgroundColor={theme.palette.error.main}>
                            <Typography variant="body3" color="inherit">
                                Please select your practice type
                            </Typography>
                        </LoginError>
                    )}
                    {state === 'validationFailed' && !sessionCount && (
                        <LoginError backgroundColor={theme.palette.error.main}>
                            <Typography variant="body3" color="inherit">
                                Please select your usage
                            </Typography>
                        </LoginError>
                    )}
                    <Form onSubmit={onSubmit}>
                        <ModalityInput modality={modality} setModality={setModality} />
                        {modality === 'PAP' && <MedicineInput medicine={medicine} setMedicine={setMedicine} />}
                        <UserTypeInput userType={userType} setUserType={setUserType} />
                        <SessionCountInput sessionCount={sessionCount} setSessionCount={setSessionCount} />
                        <CheckboxInputGroup>
                            <Check
                                style={{ paddingLeft: 0 }}
                                aria-label="want one to one training"
                                checked={wantsIndividualTraining}
                                onClick={() => setIndividualTraining(!wantsIndividualTraining)}
                            />
                            <InputLabel htmlFor="modality">I am interested in receiving 1:1 training</InputLabel>
                        </CheckboxInputGroup>
                        <CheckboxInputGroup>
                            <Check
                                aria-label="want one to one training"
                                checked={wantsTailoredOnboarding}
                                onClick={() => setTailoredOnboarding(!wantsTailoredOnboarding)}
                            />
                            <InputLabel htmlFor="modality">
                                I'm interested in email onboarding tailored to my specialism
                            </InputLabel>
                        </CheckboxInputGroup>
                        <Button
                            disabled={state === 'signingUp'}
                            type="submit"
                            size="m"
                            variant="solid-blue"
                            style={{ borderRadius: '50px', backgroundColor: '#2C3958' }}
                        >
                            Finish
                        </Button>
                    </Form>
                </FormContent>
            </FormContainer>
        </Container>
    );
}

function ModalityInput({
    modality,
    setModality,
}: {
    modality?: string;
    setModality: (val?: string) => void;
}): JSX.Element {
    const modalityOptions = [
        {
            label: 'Psychedelic Assisted Psychotherapy (including Ketamine)',
            value: 'PAP',
        },
        {
            label: 'Ketamine Infusion Therapy (without psychotherapy)',
            value: 'KIT',
        },
        {
            label: 'Psychotherapy',
            value: 'PSYCHOTHERAPY',
        },
        {
            label: 'Wellness',
            value: 'WELLNESS',
        },
        {
            label: 'Bodywork',
            value: 'BODYWORK',
        },
        {
            label: 'Breathwork',
            value: 'BREATHWORK',
        },
        {
            label: 'Medical Treatments',
            value: 'MEDICAL_TREATMENTS',
        },
        {
            label: 'Meditation',
            value: 'MEDITATION',
        },
        {
            label: 'Floatation therapy',
            value: 'FLOATATION',
        },
        {
            label: 'Other',
            value: 'OTHER',
        },
    ];

    return (
        <InputGroup>
            <InputLabel className="requiredFieldLabel" htmlFor="modality">
                Which modality does your practice mainly focus on?
            </InputLabel>
            <DropdownControl
                aria-label="modality"
                value={modality}
                options={modalityOptions}
                onChange={setModality}
                canSave={false}
                size={'large'}
                colour={'dark'}
            />
        </InputGroup>
    );
}

function MedicineInput({
    medicine,
    setMedicine,
}: {
    medicine?: string;
    setMedicine: (val?: string) => void;
}): JSX.Element {
    const medicineOptions: { label: string; value: string }[] = [
        'Ketamine',
        'Psilocybin',
        'MDMA',
        'Ibogaine',
        'Cannabis',
        'DMT',
        'Mescaline',
        'Ayahuasca',
        'LSD',
        'Other',
    ].map((opt) => ({
        label: opt,
        value: opt,
    }));

    return (
        <InputGroup>
            <InputLabel className="requiredFieldLabel" htmlFor="medicine">
                Which medicine do you primarily work with?
            </InputLabel>
            <DropdownControl
                aria-label="medicine"
                value={medicine}
                options={medicineOptions}
                onChange={setMedicine}
                canSave={false}
                size={'large'}
                colour={'dark'}
            />
        </InputGroup>
    );
}

function UserTypeInput({
    userType,
    setUserType,
}: {
    userType?: string;
    setUserType: (val?: string) => void;
}): JSX.Element {
    const userTypeOptions = [
        {
            label: 'Clinic',
            value: 'CLINIC',
        },
        {
            label: 'Individual practitioner or therapist',
            value: 'INDIVIDUAL_PRACTITIONER',
        },
        {
            label: 'Not a practitioner or therapist',
            value: 'NOT_PRACTITIONER',
        },
    ];

    return (
        <InputGroup>
            <InputLabel className="requiredFieldLabel" htmlFor="userType">
                Are you a clinic or private practitioner?
            </InputLabel>
            <DropdownControl
                options={userTypeOptions}
                aria-label="userType"
                value={userType}
                onChange={setUserType}
                canSave={false}
                size={'large'}
                colour={'dark'}
            />
        </InputGroup>
    );
}

function SessionCountInput({
    sessionCount,
    setSessionCount,
}: {
    sessionCount?: string;
    setSessionCount: (val?: string) => void;
}): JSX.Element {
    const sessionCountOptions = [
        { label: 'Not yet practising or open', value: 'none' },
        { label: '1 - 5', value: '1+' },
        { label: '6 - 10', value: '6+' },
        { label: '11 - 15', value: '11+' },
        { label: '16 - 20', value: '16+' },
        { label: '20+', value: '20+' },
    ];

    return (
        <InputGroup>
            <InputLabel className="requiredFieldLabel" htmlFor="sessionCount">
                How many sessions do you run each month, on average?
            </InputLabel>
            <DropdownControl
                options={sessionCountOptions}
                aria-label="sessionCount"
                value={sessionCount}
                onChange={setSessionCount}
                canSave={false}
                size={'large'}
                colour={'dark'}
            />
        </InputGroup>
    );
}
