import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Typography } from '@/component-library';

const Container = styled.div({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '16px',
    padding: '24px 0 24px 0',
});

const CenteredTypography = styled(Typography)({
    textAlign: 'center',
    color: '#2c3958',
});

const NewUsers = (): JSX.Element => {
    const history = useHistory();

    const goToSignUp = () => {
        history.push('/signup', history.location.state);
    };
    // const goToPersonalSignUp = () => {
    //     history.push('/signup/personal', history.location.state);
    // };

    const goToPatientSignUp = () => {
        history.push('/signup/patient?groups=Patient', history.location.state);
    };

    return (
        <Container>
            <CenteredTypography variant="body2">Create a Wavepaths account.</CenteredTypography>
            <Button variant="solid-blue" size="m" onClick={goToSignUp}>
                Sign up as a Therapist
            </Button>
            <Button variant="solid-blue" size="m" onClick={goToPatientSignUp}>
                Access to past recordings and integration
            </Button>
            {/* <Button variant="solid-blue" size="m" onClick={goToPersonalSignUp}>
                Sign up for Personal use
            </Button> */}
        </Container>
    );
};

export default NewUsers;
