import {
    ClientVariablesMusicPreference,
    SessionScoreAdministration,
    SessionScoreDosage,
    SessionScoreModality,
    SessionType,
} from 'wavepaths-shared/core';

export const GROUP_GUIDED_DEFAULTS = {
    totalDuration: 50,
};

export const GROUP_INFINITE_DEFAULTS = {
    totalDuration: 1500,
    preludeDuration: 0,
    postludeDuration: 0,
    nameOnWebsite: 'Soothing',
    preferSubmittedContent: 0,
};

export const ONE_ON_ONE_SESSION_DEFAULTS = {
    Acousticness: ClientVariablesMusicPreference.MIXED,
    preludeDuration: 240,
    postludeDuration: 240,
    preferSubmittedContent: 0,
    voiceover: 'none',
};

export const INTRO_SESSION_DEFAULTS = {};

export const SESSION_TYPE_DEFAULTS = {
    [SessionType.ONE_ON_ONE]: ONE_ON_ONE_SESSION_DEFAULTS,
    [SessionType.GROUP_INFINITE]: GROUP_INFINITE_DEFAULTS,
    [SessionType.GROUP_GUIDED]: GROUP_GUIDED_DEFAULTS,
    [SessionType.INTRO]: INTRO_SESSION_DEFAULTS,
    [SessionType.B2C_SELF_GUIDED]: {},
    [SessionType.OFFSET_PREDICTIVE]: {},
};

export const KETAMINE_DEFAULTS = {
    administration: SessionScoreAdministration.Intramuscular,
    dosage: SessionScoreDosage.MEDIUM,
};

export const MDMA_DEFAULTS = {
    administration: SessionScoreAdministration.Oral,
    dosage: SessionScoreDosage.HIGH,
};

export const IBOGAINE_DEFAULTS = {
    administration: SessionScoreAdministration.Oral,
    dosage: SessionScoreDosage.MEDIUM,
};

export const PSILOCYBIN_DEFAULTS = {
    administration: SessionScoreAdministration.Oral,
    dosage: SessionScoreDosage.MEDIUM,
};

export const MEDICINE_SPECIFIC_VARIABLES: {
    [SessionScoreModality.KETAMINE]: { administration: SessionScoreAdministration; dosage: SessionScoreDosage };
    [SessionScoreModality.NONE]: undefined;
    [SessionScoreModality.MDMA]: { administration: SessionScoreAdministration; dosage: SessionScoreDosage };
    [SessionScoreModality.IBOGAINE]: { administration: SessionScoreAdministration; dosage: SessionScoreDosage };
    [SessionScoreModality.PSILOCYBIN]: { administration: SessionScoreAdministration; dosage: SessionScoreDosage };
    [SessionScoreModality.BREATHWORK]: undefined;
    [SessionScoreModality.PSYCHOTHERAPY]: undefined;
    [SessionScoreModality.FLOATATION]: undefined;
    [SessionScoreModality.WELLNESS]: undefined;
    [SessionScoreModality.CLIENT_PREPARATION]: undefined;
    [SessionScoreModality.AMBIENCE]: undefined;
} = {
    [SessionScoreModality.KETAMINE]: KETAMINE_DEFAULTS,
    [SessionScoreModality.NONE]: undefined,
    [SessionScoreModality.MDMA]: MDMA_DEFAULTS,
    [SessionScoreModality.IBOGAINE]: IBOGAINE_DEFAULTS,
    [SessionScoreModality.PSILOCYBIN]: PSILOCYBIN_DEFAULTS,
    [SessionScoreModality.BREATHWORK]: undefined,
    [SessionScoreModality.FLOATATION]: undefined,
    [SessionScoreModality.PSYCHOTHERAPY]: undefined,
    [SessionScoreModality.WELLNESS]: undefined,
    [SessionScoreModality.CLIENT_PREPARATION]: undefined,
    [SessionScoreModality.AMBIENCE]: undefined,
};
