import styled from '@emotion/styled';
import React from 'react';
export interface TemplateGridProps {
    children: React.ReactChild[] | React.ReactChild;
    className?: string;
}

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 36px 48px;
    margin-bottom: 40px;
`;

const TemplateGrid: React.FC<TemplateGridProps> = ({ children, className }) => {
    return <Container className={className}>{children}</Container>;
};

export const DefaultCardContainer: React.FC = ({ children }) => {
    return <>{children}</>;
};

export default TemplateGrid;
