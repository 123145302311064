import { Box, Dialog, DialogContent } from '@material-ui/core';
import React from 'react';

import { Button } from '@/component-library';

import { FeedbackTags } from './FeedbackTags';
import { FeedbackText } from './FeedbackText';
import { NotificationHeader } from './NotificationHeader';

interface FeedbackDialogProps {
    headerContent: string;
    feedbackTagOptions: string[];
    selectedFeedbackTags: string[];
    className?: string;
    text: string;
    onToggleTag: (tag: string, selected: boolean) => void;
    onSetFeedbackText: (text: string) => void;
    onSubmit: () => void;
    onCancel: () => void;
}
export const FeedbackDialog: React.FC<FeedbackDialogProps> = ({
    headerContent,
    text,
    onSetFeedbackText,
    feedbackTagOptions,
    selectedFeedbackTags,
    onToggleTag,
    onSubmit,
    onCancel,
}) => {
    return (
        <Dialog open={true}>
            <DialogContent className={'feedbackDialog'}>
                <NotificationHeader content={headerContent} />
                <FeedbackTags
                    allTags={feedbackTagOptions}
                    selectedTags={selectedFeedbackTags}
                    isExclusive={false}
                    onToggleTag={onToggleTag}
                />
                <FeedbackText text={text} onSetFeedbackText={onSetFeedbackText} />
                <Box marginTop="12px" display="flex" justifyContent="space-evenly" style={{ gap: 8 }}>
                    <Button style={{ width: '100%' }} size="xs" variant="solid-blue" onClick={onSubmit}>
                        Done
                    </Button>
                    <Button style={{ width: '100%' }} size="xs" variant="outlined" onClick={onCancel}>
                        Cancel
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
