import firebase from 'firebase';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useQueryParam } from 'use-query-params';
import { APISessionStatus, ISessionsEndpointResponseType, SessionHeader, SessionType } from 'wavepaths-shared/core';

import { listSessions } from '../../common/api/sessionApiV2';

export const SESSIONS_PER_PAGE = 100;

export interface ISessionsData {
    sessions: SessionHeader[];
    loading: boolean;
    isValidating: boolean;
    error: any;
    currentPageIndex: number;
    previousPage: () => void;
    nextPage: () => void;
    totalPageCount: number;
    totalCount: number;
}

const EMPTY_SESSIONS: SessionHeader[] = [];
export const useSessions = ({
    fbUser,
    name = undefined,
    type = undefined,
    sessionState,
    client_id,
    paginated = false, // this parameter could/should be removed when all session queries are made to be paginated or generic
}: {
    fbUser: firebase.User;
    name?: string | undefined;
    type?: SessionType;
    sessionState: APISessionStatus;
    client_id?: string;
    paginated: boolean;
}): ISessionsData => {
    const defaultPageIndex = 0;
    const [_currentPageIndex, setCurrentPage] = useQueryParam<number | undefined>('page-' + sessionState);
    const currentPageIndex = _currentPageIndex !== undefined ? Number(_currentPageIndex) : defaultPageIndex;

    const { data, error, isValidating } = useSWR<ISessionsEndpointResponseType | undefined>(
        [
            `/v2/sessions/${sessionState}`,
            name,
            type,
            fbUser,
            sessionState,
            currentPageIndex,
            SESSIONS_PER_PAGE,
            client_id,
        ],
        () =>
            listSessions({
                name,
                type,
                fbUser,
                sessionState,
                client_id,
                page: paginated ? currentPageIndex : undefined,
                max: paginated ? SESSIONS_PER_PAGE : undefined,
            }),
        {
            refreshInterval: 5000,
            revalidateOnFocus: false,
        },
    );

    const sessions = data?.entries ?? EMPTY_SESSIONS;
    const loading = typeof data === 'undefined' && !error;

    const [totalPageCount, setTotalPageCount] = useState(0);
    const totalCount = data?.totalCount ?? 0;
    useEffect(() => {
        setTotalPageCount(Math.ceil(totalCount / SESSIONS_PER_PAGE));
    }, [totalCount]);

    const nextPage = () => {
        currentPageIndex < totalPageCount - 1 && setCurrentPage(currentPageIndex + 1);
    };
    const previousPage = () => {
        currentPageIndex > 0 && setCurrentPage(currentPageIndex - 1);
    };

    return {
        sessions,
        loading,
        isValidating,
        error,
        currentPageIndex,
        totalPageCount,
        totalCount,
        nextPage,
        previousPage,
    };
};

export default useSessions;
