import firebase from 'firebase';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SessionHeader } from 'wavepaths-shared/core';

import { formatDateTime } from '../../../dateUtilsV2';
import { renderSessionName } from '../helpers';
import SessionCardV2 from '../SessionCardV2';

export interface Props {
    session: SessionHeader;
    fbUser: firebase.User;
}
const OfflineSessionCard = ({ session }: Props): React.ReactElement => {
    const history = useHistory();
    const sessionName = renderSessionName(session);

    // const userInfoText = session?.user?.name;

    // TODO: it can be a string that says "undefined" - not ideal
    // TODO: modality from where?
    // const subtitle = getSubtitle(`${(session.score as any)?.modality}`, session.variableInputs.dosage);

    // const INTEGRATION_GUIDE_URL = 'https://wavepaths.com/integration-guide';

    const onClick = () => {
        history.push(`/session/recording/${session.id}`);
    };

    return (
        <SessionCardV2
            info={[`${formatDateTime(session.startedAt)}`]}
            title={sessionName}
            subtitle=""
            onClick={onClick}
            session={session}
        />
    );
};

export default OfflineSessionCard;
