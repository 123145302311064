import React from 'react';

import { EvaIcon } from '@/component-library';

import inf from '../../../images/infinity.svg';

const HIGHLIGHTED = 'rgba(13, 28, 46, 1)';
const ENABLED = '#646464';
const DISABLED = '#D0D2D5';

export const musicIcon = {
    enabled: <EvaIcon size={24} evaSize={16} name="music-outline" fill={ENABLED} />,
    disabled: <EvaIcon size={24} evaSize={16} name="music-outline" fill={DISABLED} />,
};

export const optionsIcon = {
    enabled: <EvaIcon size={24} evaSize={16} name="options-2-outline" fill={ENABLED} />,
    disabled: <EvaIcon size={24} evaSize={16} name="options-2-outline" fill={DISABLED} />,
};

export const editIcon = {
    enabled: <EvaIcon size={24} evaSize={16} name="edit-outline" fill={ENABLED} />,
    disabled: <EvaIcon size={24} evaSize={16} name="edit-outline" fill={DISABLED} />,
};

export const activityIcon = {
    enabled: <EvaIcon size={24} evaSize={16} name="activity-outline" fill={ENABLED} />,
    disabled: <EvaIcon size={24} evaSize={16} name="activity-outline" fill={DISABLED} />,
};
export const bookIcon = {
    enabled: <EvaIcon size={24} evaSize={16} name="book-open-outline" fill={ENABLED} />,
    disabled: <EvaIcon size={24} evaSize={16} name="book-open-outline" fill={DISABLED} />,
};
export const saveIcon = {
    enabled: <EvaIcon size={24} evaSize={16} name="save-outline" fill={ENABLED} />,
    disabled: <EvaIcon size={24} evaSize={16} name="save-outline" fill={DISABLED} />,
};
export const workshopsIcon = {
    enabled: <EvaIcon size={24} evaSize={16} name="award-outline" fill={ENABLED} />,
    disabled: <EvaIcon size={24} evaSize={16} name="award-outline" fill={DISABLED} />,
};
export const onboardingIcon = {
    enabled: <EvaIcon size={24} evaSize={16} name="people-outline" fill={ENABLED} />,
    disabled: <EvaIcon size={24} evaSize={16} name="people-outline" fill={DISABLED} />,
};
export const accountManagementIcon = {
    enabled: <EvaIcon size={24} evaSize={16} name="bulb-outline" fill={ENABLED} />,
    disabled: <EvaIcon size={24} evaSize={16} name="bulb-outline" fill={DISABLED} />,
};
export const infinityIcon = <img src={inf} alt="Infinite" style={{ fill: HIGHLIGHTED }} />;
