import { Dialog, DialogContent } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Session, SessionRenderType } from 'wavepaths-shared/core';

import { Button, EvaIcon, Menu } from '@/component-library';

import { Header } from '../../common/components/Header';
import { AudioSettings } from './AudioSettings';
import { SessionClock } from './SessionClock';
import { SessionInfo } from './SessionInfo';
import { SessionName } from './SessionName';
import ShareSessionDialog from './ShareSessionDialog';
import { VolumeMeter } from './VolumeMeter';

interface GuideHeaderProps {
    session: Session;
    onEndSession: () => void;
}

const useStyles = makeStyles<Theme>(() => ({
    paper: { maxWidth: 398 },
}));

// const LongBufferSwitch = React.memo(({ connection }: { connection: FreudConnection }) => {
//     const [isLowConnectionModeEnabled, setLowConnectionMode] = useState<boolean>(connection.isLongBufferEnabled);

//     const handleToggleLongBuffer = () => {
//         const newVal = !isLowConnectionModeEnabled;
//         Sentry.configureScope((scope) => {
//             scope.setTag('usingSlowConnectionMode', `${newVal}`);
//         });

//         connection.toggleLongBuffer(newVal);
//         setLowConnectionMode(newVal);
//     };

//     return (
//         <div
//             onClick={handleToggleLongBuffer}
//             style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
//         >
//             <Typography variant="body3" style={{ textAlign: 'right' }}>
//                 I'm on a slow connection
//             </Typography>
//             <Checkbox style={{ padding: 0, margin: '0 8px' }} size="small" checked={isLowConnectionModeEnabled} />
//         </div>
//     ); // tried using <FormGroup/> as in the docs but got some weird double firing of the onChange handler...
// });

export const GuideHeader: React.FC<GuideHeaderProps> = React.memo(({ session, onEndSession }: GuideHeaderProps) => {
    const history = useHistory();
    const [sessionInfoOpen, setSessionInfoOpen] = useState(false);
    const [audioSettingsOpen, setAudioSettingsOpen] = useState(false);
    const classes = useStyles();

    const [showShareSessionDialog, setShowShareSessionDialog] = useState(false);

    const onShareClick = useCallback(() => {
        setShowShareSessionDialog(true);
    }, [setShowShareSessionDialog]);

    const menuActions = [
        {
            element: 'Share link for remote listening',
            icon: <MobileScreenShareIcon />,
            onClick: onShareClick,
        },
        {
            element: 'Session Info',
            icon: <InfoIcon />,
            onClick: () => setSessionInfoOpen(true),
        },
        {
            element: 'Audio Settings',
            icon: <EvaIcon name="settings-2-outline" fill="rgba(0, 0, 0, 0.54)" />,
            onClick: () => setAudioSettingsOpen(true),
        },
        {
            element: 'Go Back To Dashboard',
            icon: <ArrowBackIcon />,
            onClick: () => history.push('/'),
        },
        {
            element: 'End Session for All',
            icon: <CloseIcon />,
            onClick: onEndSession,
        },
    ];

    return (
        <Header
            left={
                <>
                    <SessionName session={session} />
                    {/* <MobileScreenShareIcon /> */}
                </>
            }
            center={
                <>
                    <div style={{ display: 'grid', gridAutoFlow: 'row', justifyItems: 'center' }}>
                        <SessionClock />
                        <VolumeMeter />
                    </div>
                </>
            }
            right={
                <div className="tour-sessionOptions" style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                    {/* <LongBufferSwitch connection={connection} /> */}
                    <>
                        <Button
                            variant="clear"
                            size="xs"
                            onClick={onShareClick}
                            icon={<MobileScreenShareIcon fontSize="small" />}
                            aria-label="Volume"
                        />
                    </>
                    <Menu
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        options={menuActions}
                    />
                    <ShareSessionDialog
                        sessionId={session.id}
                        broadcastIdentifier={session.broadcastIdentifier}
                        isOpen={showShareSessionDialog}
                        onClose={() => setShowShareSessionDialog(false)}
                        defaultVersion={
                            session.renderType === SessionRenderType.PREDICTIVE_COMPOSED ? 'beta' : undefined
                        }
                    />
                    <Dialog
                        classes={{ paper: classes.paper }}
                        open={sessionInfoOpen}
                        onClose={() => setSessionInfoOpen(false)}
                    >
                        <DialogContent>
                            <SessionInfo closeDialog={() => setSessionInfoOpen(false)} session={session} />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        classes={{ paper: classes.paper }}
                        open={audioSettingsOpen}
                        onClose={() => setAudioSettingsOpen(false)}
                    >
                        <DialogContent>
                            <AudioSettings closeDialog={() => setAudioSettingsOpen(false)} />
                        </DialogContent>
                    </Dialog>
                </div>
            }
        />
    );
});
