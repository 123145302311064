import React from 'react';

import LoadingTemplateCard from './LoadingTemplateCard';

export interface LoadingTemplateGridProps {
    columns?: number;
    Container?: React.ElementType;
}

const DefaultContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return <>{children}</>;
};

const LoadingCardRow: React.FC<LoadingTemplateGridProps> = ({ columns = 4, Container = DefaultContainer }) => {
    return (
        <>
            {Array(columns)
                .fill(0)
                .map((_, idx) => (
                    <Container key={'loading-' + idx}>
                        <LoadingTemplateCard key={'loading-' + idx} />
                    </Container>
                ))}
        </>
    );
};

export default LoadingCardRow;
