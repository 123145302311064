import firebase from 'firebase/app';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

import { useAuthContext } from '../../auth';
import {
    archiveNewsArticle,
    createNewsArticle,
    getNews,
    getNewsLastArticle,
    NewsArticleData,
    unarchiveNewsArticle,
    updateNewsArticle,
} from '../../common/api/newsApi';

export type NewsArticle = {
    id: string;
    tag?: string;
    shortDescription?: string;
    description?: string;
    date?: Date;
    isArchived?: boolean;
};

// TODO: refactor it, move to separate file
export type UseAsyncMutation<P extends any[]> = (
    props?: UseAsyncMutationProps,
) => {
    isLoading: boolean;
    error: unknown | null;
    mutate: (...params: P) => Promise<void>;
};

export type UseAsyncMutationProps = {
    onSuccess?: () => void;
    onFailure?: (error: unknown) => void;
    onFinally?: () => void;
    onLoading?: () => void;
};

export const createUseAsyncMutation = <P extends any[], R>(
    fn: (firebaseUser: firebase.User, ...params: P) => Promise<R>,
): UseAsyncMutation<P> => (props) => {
    const { onLoading, onSuccess, onFailure, onFinally } = props || {};
    const { firebaseUser } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<unknown | null>(null);

    const mutate = async (...params: P) => {
        if (!firebaseUser) {
            throw new Error('User not logged in');
        }
        onLoading?.();
        setIsLoading(true);
        setError(null);
        try {
            await fn(firebaseUser, ...params);
            onSuccess?.();
        } catch (e: any) {
            setError(e);
            onFailure?.(e);
        } finally {
            setIsLoading(false);
            onFinally?.();
        }
    };

    return { isLoading, error, mutate };
};

export type UseNewsProps = {
    includeArchived?: boolean;
};

export const useNews = (props?: UseNewsProps) => {
    const { includeArchived } = props || {};
    const { firebaseUser } = useAuthContext();
    const { data, isValidating, mutate } = useSWR(
        ['news', includeArchived],
        async ([, includeArchived]) => {
            if (!firebaseUser) {
                return [];
            }
            return getNews(firebaseUser, {
                includeArchived,
            });
        },
        {
            compare: (a, b) => JSON.stringify(a) === JSON.stringify(b),
            revalidateOnFocus: false,
        },
    );

    const refresh = async () => {
        await mutate();
    };

    const news: NewsArticle[] = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.map(mapNewsArticleToModel);
    }, [data]);

    return { news, isLoading: isValidating, refresh };
};

export const useNewsCreateArticle = createUseAsyncMutation(createNewsArticle);
export const useNewsUpdateArticle = createUseAsyncMutation(updateNewsArticle);
export const useNewsArchiveArticle = createUseAsyncMutation(archiveNewsArticle);
export const useNewsUnarchiveArticle = createUseAsyncMutation(unarchiveNewsArticle);

export const useNewsArticleLatest = () => {
    const { firebaseUser } = useAuthContext();

    const { data, isValidating } = useSWR(
        'news-last',
        async () => {
            if (!firebaseUser) {
                return undefined;
            }
            return getNewsLastArticle(firebaseUser);
        },
        {
            compare: (a, b) => JSON.stringify(a) === JSON.stringify(b),
            revalidateOnFocus: false,
        },
    );

    const article = useMemo(() => {
        if (!data) {
            return null;
        }
        return mapNewsArticleToModel(data);
    }, [data]);

    return { article, isLoading: isValidating };
};

const mapNewsArticleToModel = (article: NewsArticleData): NewsArticle => ({
    id: article.id,
    tag: article.tag,
    shortDescription: article.shortDescription,
    description: article.description,
    date: article.publishedAt ? new Date(article.publishedAt) : undefined,
    isArchived: !!article.archivedAt,
});

// const NEWS: NewsArticle[] = [
//     {
//         id: '1',
//         tag: 'Update',
//         shortDescription: 'News Feature',
//         description: `
//             Dear Wavepaths Community,
// <br />Over the past few months, we've heard from many of you that important updates often get missed. While we've explored various third-party tools to enhance communication, each has had its own set of challenges.
// <br />To improve our communication, we’ve implemented a new way to share updates directly within our software. Please note that our community platform on Circle has therefore been discontinued.
// <br />You can from now on easily see the most recent news on the home page, with a complete list of all announcements available under the “News” tab on the left sidebar.
// <br />I’m excited to keep you all informed about our ongoing developments through this new channel.
// <br />As always, for any questions feel free to email us directly at <a href="mailto:support@wavepaths.com">support@wavepaths.com</a>
// <br />Warm regards,
// <br />Mendel
//
//         `,
//         date: new Date('2024-09-05'),
//     },
// ];

// const sortNewsByDate = (a: NewsArticle, b: NewsArticle) => {
//     if (!a.date || !b.date) {
//         return 0;
//     }
//     if (!a.date) {
//         return -1;
//     }
//     if (!b.date) {
//         return 1;
//     }
//     return a.date > b.date ? -1 : 1;
// };
