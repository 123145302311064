import './VolumeMeter.scss';

import VolumeDownIcon from '@material-ui/icons/VolumeDownRounded';
import VolumeUpIcon from '@material-ui/icons/VolumeUpRounded';
import React, { useContext, useLayoutEffect, useState } from 'react';

import { InfoIconContainer } from '@/component-library/components/InfoDialog';
import TypographyV2 from '@/component-library/typography/TypographyV2';

import { useAuthContext } from '../../auth';
import { AudioPlayerContext } from '../sessions/EndedSessionsTable/useAudioPlayer';
import { PlayerChoiceContext } from './ChoosePlayer';

export const VolumeMeter: React.FC = React.memo(() => {
    const { isPersonal } = useAuthContext();

    const audioPlayer = useContext(AudioPlayerContext);
    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('no PlayerChoiceContext');

    const [visible, setVisible] = useState<boolean>(false);
    useLayoutEffect(() => {
        if (
            audioPlayer !== undefined &&
            audioPlayer.isVolumeControllable &&
            playerChoiceContext.playerChoice === 'browser'
        ) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [audioPlayer?.isVolumeControllable, playerChoiceContext.playerChoice]);

    return (
        <div className="volumeMeter">
            <div className="volumeMeterContent">
                {visible && audioPlayer !== undefined ? (
                    <>
                        <VolumeDownIcon />
                        <div className="volumeMeterControls">
                            <div className="levels"></div>
                            <input
                                className="slider"
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={audioPlayer.volume}
                                onChange={(e) => audioPlayer.actions.setVolume(+e.target.value)}
                            />
                        </div>
                        <VolumeUpIcon />
                        {!isPersonal ? (
                            <InfoIconContainer title="Volume">
                                <TypographyV2>Volume (this device only)</TypographyV2>
                            </InfoIconContainer>
                        ) : (
                            // Personal dont have multiple devices so no need explanation
                            <></>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
});
