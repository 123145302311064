import React from 'react';

import Container from './Container';
import { ContentDropdown } from './Content';
import Heading from './Heading';
import { OptionDropdown } from './Option';
import { ControlProps } from './types';

type DropdownControlProps<V> = ControlProps<V>;

export const DropdownControl = <ValueType extends string | number | readonly string[] | undefined>({
    heading: headerText,
    info,
    canSave,
    size,
    inline: inlineProp,
    colour,
    options,
    value,
    onChange,
    name,
    disabled: allDisabled = false,
}: DropdownControlProps<ValueType>): JSX.Element => {
    const labelId = name && `${name}-dropdown-label`;

    const inline = inlineProp ?? size === 'small' ? true : false;

    return (
        <Container inline={inline}>
            {headerText && (
                <Heading
                    id={labelId}
                    heading={headerText}
                    info={info}
                    canSave={canSave}
                    inline={inline}
                    colour={colour}
                >
                    {headerText}
                </Heading>
            )}
            {options && (
                <ContentDropdown
                    aria-labelledby={labelId}
                    colour={colour}
                    disabled={allDisabled}
                    size={size}
                    value={value}
                    disableUnderline
                >
                    {options.map(({ label, value: optionValue, disabled }, index) => (
                        <OptionDropdown
                            key={`${label}${index}`}
                            size={size}
                            colour={colour}
                            disabled={disabled || allDisabled}
                            value={optionValue}
                            selected={value === optionValue}
                            onClick={() => onChange(optionValue)}
                        >
                            {label}
                        </OptionDropdown>
                    ))}
                </ContentDropdown>
            )}
        </Container>
    );
};
