import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { EndOfSessionFeedback, RequestType } from 'wavepaths-shared/core';

import * as sessionApi from '../../common/api/sessionApi';
import { useRemoteSessionState } from '../../common/hooks/useSessionTick';
import { FreudConnection } from '../../freudConnection/FreudConnection';
import UserEvents from '../../UserEvents';
import { EndOfSessionFeedbackModal } from './EndOfSessionFeedbackModal';

export interface GuideEndOfSessionFeedbackProps {
    currentUser: firebase.User;
    connection: FreudConnection;
    onEndSession: () => void;
    onAcknowledged: () => void;
    trackEndedSession: ({
        overwriteAsComplete,
        sessionCompletness,
    }: {
        overwriteAsComplete?: boolean;
        sessionCompletness?: number;
    }) => void;
}
export const GuideEndOfSessionFeedbackContainer: React.FC<GuideEndOfSessionFeedbackProps> = ({
    currentUser,
    connection,
    onEndSession,
    onAcknowledged,
    trackEndedSession,
}) => {
    const { ended: sessionEnded, inPostlude } = useRemoteSessionState();
    const [endOfSessionFeedback, setEndOfSessionFeedback] = useState<EndOfSessionFeedback>();
    const [feedbackDone, setFeedbackDone] = useState(false);

    const showModal = (inPostlude || sessionEnded) && !feedbackDone;

    const acknowledge = () => {
        setFeedbackDone(true);
        onAcknowledged();
    };

    const sendFeedbackAndAcknowledge = () => {
        if (endOfSessionFeedback) {
            if (connection.isClosed()) {
                sessionApi.sendPreviousSessionFeedback(connection.sessionId, endOfSessionFeedback, currentUser);
            } else {
                connection.sendRequest({
                    type: RequestType.RecordEndOfSessionFeedback,
                    feedback: endOfSessionFeedback,
                });
            }
            UserEvents.feedbackRecorded(endOfSessionFeedback);
            acknowledge();
        }
    };

    const sendFeedbackAndAcknowledgeAndEndSession = () => {
        sendFeedbackAndAcknowledge();
        onEndSession();
    };

    useEffect(() => {
        if (inPostlude) {
            trackEndedSession({ overwriteAsComplete: true });
        }
    }, [inPostlude, trackEndedSession]);

    return showModal ? (
        <EndOfSessionFeedbackModal
            endOfSessionFeedback={endOfSessionFeedback}
            setEndOfSessionFeedback={setEndOfSessionFeedback}
            onSubmit={sendFeedbackAndAcknowledge}
            onSubmitAndEnd={sendFeedbackAndAcknowledgeAndEndSession}
            onClose={acknowledge}
            sessionEnded={sessionEnded}
        />
    ) : (
        <></>
    );
};
