import React, { useState } from 'react';
import styled from 'styled-components';

import PageHeading from '@/component-library/components/PageHeading';
import { ErrorBox } from '@/components';

import { useAuthContext } from '../../auth';
import { LayoutContainer } from '../../LayoutContainer';
import ExternalResourceCard from './ExternalResourceCard';
import { InSessionTourCard } from './InSessionTourCard';
import { WelcomeProductTour } from './WelcomeProductTour';
import { WelcomeTourCard } from './WelcomeTourCard';

const Container = styled.div`
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, 300px);
`;

function TemplateContainer() {
    const { firebaseUser } = useAuthContext();

    const [sessionStartFailed, setSessionStartFailed] = useState<boolean>(false);

    const [showIntroProductTour, setShowIntroProductTour] = useState(false);
    const { isPersonal } = useAuthContext();

    if (!firebaseUser) return <></>;

    const personalUseCards = [
        { header: 'Introduction to Wavepaths', url: 'https://wavepaths.com/your-guide-to-wavepaths' },

        // {
        //     header: 'Preparation Guide',
        //     url: 'https://wavepaths.com/your-guide-to-wavepaths-making-the-most-of-the-session',
        // },

        { header: 'Deep Listening Guide', url: 'https://wavepaths.com/deep-listening-guide-landing-page-2' },

        // { header: 'Deep Listening Preparation', url: 'https://wavepaths.com/preparation' },

        { header: 'Integration Guide', url: 'https://wavepaths.com/integration-guide' },

        { header: 'Meet the musicians of Wavepaths', url: 'https://wavepaths.com/artists' },

        // {
        //     header: 'The Research',
        //     url:
        //         'https://wavepaths.com/blog/the-hidden-therapist-evidence-for-the-central-role-of-music-in-psychedelic-therapy',
        // },
    ];

    return (
        <>
            {isPersonal ? (
                <>
                    <PageHeading text={'Resources'} />
                    <Container>
                        {personalUseCards.map((x, idx) => (
                            <ExternalResourceCard key={idx + x.header} url={x.url} title={x.header} />
                        ))}
                    </Container>
                </>
            ) : (
                <>
                    <PageHeading text={'Resources'} />
                    <Container>
                        {sessionStartFailed ? (
                            <div className="errorbox">
                                <ErrorBox
                                    message={
                                        'Unable to start the session. Please check your network connection and try again'
                                    }
                                />
                            </div>
                        ) : (
                            <></>
                        )}

                        {showIntroProductTour ? (
                            <WelcomeProductTour setShowIntroProductTour={setShowIntroProductTour} />
                        ) : (
                            <></>
                        )}
                        <WelcomeTourCard setShowIntroProductTour={setShowIntroProductTour} />
                        <InSessionTourCard fbUser={firebaseUser} setFailed={setSessionStartFailed} />
                    </Container>
                    <PageHeading text={'Patient Resources'} />
                    <Container>
                        {personalUseCards.map((x, idx) => (
                            <ExternalResourceCard key={idx + x.header} url={x.url} title={x.header} />
                        ))}
                    </Container>
                </>
            )}
        </>
    );
}

function TemplatesWithNav() {
    return (
        <LayoutContainer>
            <TemplateContainer />
        </LayoutContainer>
    );
}

export default TemplatesWithNav;
