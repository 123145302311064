import { Dialog, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { padStart } from 'lodash';
import React from 'react';

import { Button, Typography } from '@/component-library';

import { QueuedFunction } from './useActionQueue';

const useStyles = makeStyles({
    queuedItem: {
        //position: 'fixed',
        //top: '40%',
        //left: '25%',
        //width: '318px',
        //overflow: 'hidden',
        padding: '16px 16px 20px 16px',
        display: 'grid',
        gridTemplateColumns: '3fr 1fr',
        gridTemplateRows: 'auto',
        gridTemplateAreas: '"description secondsRemaining" "actions actions"',
        //backgroundColor: 'black',
        //color: theme.palette.primary.dark,
        //zIndex: 4,
    },
    queuedItemLeaving: {},
    description: {
        gridArea: 'description',
        //color: theme.palette.primary.dark,
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        lineHeight: '24px',
        maxHeight: '48px',
        textOverflow: 'ellipsis',
    },
    secondsRemaining: {
        gridArea: 'secondsRemaining',
        //color: theme.palette.primary.dark,
        textAlign: 'right',
    },
    actions: {
        gridArea: 'actions',
        display: 'flex',
        gap: '10px',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 16,
    },

    '@keyframes fadeIn': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    '@keyframes fadeOut': {
        '0%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
    '@keyframes slideIn': {
        '0%': {
            transform: 'translateX(-120%)',
        },
        '100%': {
            transform: 'translateX(0)',
        },
    },
    '@keyframes slideOut': {
        '0%': {
            transform: 'translateX(0)',
        },
        '100%': {
            transform: 'translateX(-120%)',
        },
    },
});

export interface QueuedItemProps {
    queuedFunction?: null | QueuedFunction;
    onCancel: () => void;
    onSkipQueue: () => void;
    onSkipQueueAndDontAskMore: () => void;
}

const QueuedItem: React.FC<QueuedItemProps> = React.memo(
    ({ queuedFunction, onCancel, onSkipQueue, onSkipQueueAndDontAskMore }): JSX.Element | null => {
        const classes = useStyles();
        return queuedFunction ? (
            <Dialog open={true} onClose={onCancel} fullWidth={true}>
                <DialogContent className={classNames(classes.queuedItem)}>
                    <Typography variant="subtitle2" className={classes.description}>
                        {queuedFunction.description} in&hellip;
                    </Typography>
                    <Typography variant="subtitleFixedWidth" className={classes.secondsRemaining}>
                        {getSecondsRemainingLabel(queuedFunction)}s
                    </Typography>
                    <div className={classes.actions}>
                        <Button variant="solid-blue" onClick={onSkipQueueAndDontAskMore}>
                            Submit Now and stop asking
                        </Button>
                        <Button variant="outlined" onClick={onSkipQueue}>
                            Submit Now
                        </Button>
                        <Button variant="clear-underlined" onClick={onCancel}>
                            Cancel Changes
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        ) : null;
    },
);

function getSecondsRemainingLabel(queuedFunction: QueuedFunction) {
    const secondsRemaining = Math.ceil(Math.max(0, queuedFunction.timeUntilExecutionInSeconds));
    return padStart('' + secondsRemaining, 2, '0');
}

export default QueuedItem;
