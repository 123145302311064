import './SessionVariableInputs.scss';

import { findIndex, groupBy, startCase, uniq } from 'lodash';
import React, { Children, ReactNode, useContext } from 'react';
import DateTimePicker from 'react-datetime-picker';
import {
    ScoreInputVariable,
    SessionScore,
    SessionState,
    SessionTick,
    SessionVariables,
    UserData,
} from 'wavepaths-shared/core';
import { makeScoreExpressionEvaluator } from 'wavepaths-shared/scoreExpressions';
import {
    getSessionDuration,
    getSessionPostludeDuration,
    getSessionPreludeDuration,
} from 'wavepaths-shared/sessionUtils';

import { AuthContext } from '../../auth';

interface SessionVariableInputsProps {
    score: SessionScore;
    values: SessionVariables;
    disabled?: boolean;
    groupChildren: { [g: string]: ReactNode };
    onUpdateValues: (updates: { [varName: string]: number | string }) => void;
}
export const SessionVariableInputs: React.FC<SessionVariableInputsProps> = ({
    score,
    values,
    disabled,
    groupChildren,
    onUpdateValues,
}) => {
    const { userData } = useContext(AuthContext);
    if (!userData) {
        throw new Error('should never render this screen without a logged in user');
    }

    function renderVariableInput(inputVar: ScoreInputVariable) {
        switch (inputVar.type) {
            case 'string':
                if (inputVar.long) {
                    return (
                        <textarea
                            id={inputVar.name}
                            value={values[inputVar.name] || ''}
                            disabled={disabled || inputVar.disabled}
                            title={inputVar.tooltip}
                            onChange={(evt) => onUpdateValues({ [inputVar.name]: evt.target.value })}
                        />
                    );
                } else {
                    return (
                        <input
                            type="text"
                            id={inputVar.name}
                            value={values[inputVar.name] || ''}
                            disabled={disabled || inputVar.disabled}
                            title={inputVar.tooltip}
                            onChange={(evt) => onUpdateValues({ [inputVar.name]: evt.target.value })}
                        />
                    );
                }
            case 'number':
                return (
                    <input
                        type="number"
                        id={inputVar.name}
                        value={values[inputVar.name] || 0}
                        disabled={disabled || inputVar.disabled}
                        title={inputVar.tooltip}
                        onChange={(evt) => onUpdateValues({ [inputVar.name]: +evt.target.value })}
                    />
                );
            case 'boolean':
                return (
                    <input
                        type="checkbox"
                        id={inputVar.name}
                        checked={!!values[inputVar.name]}
                        disabled={disabled || inputVar.disabled}
                        title={inputVar.tooltip}
                        onChange={(evt) => onUpdateValues({ [inputVar.name]: evt.target.checked ? 1 : 0 })}
                    />
                );
            case 'datetime':
                return (
                    <>
                        <DateTimePicker
                            //TODO: Dont use Browser time
                            value={new Date(values[inputVar.name] || Date.now())}
                            onChange={(d: Date) => onUpdateValues({ [inputVar.name]: d.getTime() })}
                            minDate={new Date()}
                            disabled={disabled || inputVar.disabled}
                            title={inputVar.tooltip}
                            locale="en-GB"
                        />{' '}
                    </>
                );
            case 'enum':
                return (
                    <select
                        id={inputVar.name}
                        value={findIndex(inputVar.options, (o) => values[inputVar.name] === o.value)}
                        disabled={disabled || inputVar.disabled}
                        title={inputVar.tooltip}
                        onChange={(evt) =>
                            onUpdateValues({
                                [inputVar.name]: inputVar.options[+evt.target.value].value,
                            })
                        }
                    >
                        {inputVar.options.map((o, idx) => (
                            <option key={o.value} value={idx} disabled={o.disabled}>
                                {o.label}
                            </option>
                        ))}
                    </select>
                );
        }
    }

    const variableGroups = groupBy(
        score.variables && getScoreInputVariables(score, userData).filter((v) => !v.hidden),
        (v) => v.group || 'sessionSettings',
    );
    const allGroups = uniq(Object.keys(groupChildren).concat(Object.keys(variableGroups)))
        .map((group) => ({
            group,
            variables: variableGroups[group] || [],
            children: groupChildren[group] || [],
        }))
        .filter((g) => g.variables.length || Children.count(g.children) > 0);

    return (
        <div className="sessionVariableInputs">
            {allGroups.map(({ group, variables, children }) => (
                <div key={group} className={'sessionVariableInputsGroup'}>
                    <h4>{startCase(group)}</h4>
                    <div className="sessionVariableInputsInner">
                        <div className="sessionVariableInputsInnerColumns">
                            {children}
                            {variables.map((inputVar) => (
                                <div className="sessionVariableInputs--input inputGroup" key={inputVar.name}>
                                    <label htmlFor={inputVar.name}>
                                        {inputVar.label || startCase(inputVar.name)}{' '}
                                        {inputVar.type === 'datetime' ? (
                                            <>(in {Intl.DateTimeFormat().resolvedOptions().timeZone} time)</>
                                        ) : (
                                            ''
                                        )}
                                    </label>
                                    {renderVariableInput(inputVar)}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export function getZeroTick(score: SessionScore, variableInputs: SessionVariables): SessionTick {
    const expr = makeScoreExpressionEvaluator(score, variableInputs, []);
    return {
        sessionState: SessionState.PLANNED,
        timeUntilStart: 0,
        absoluteTime: 0,
        effectiveTime: 0,
        timeSinceInit: 0,
        wallClockTime: 0,
        presetVolume: 0,
        contentStage: 0,
        musicalContent: {
            activeLayers: [],
        },
        preludeDuration: getSessionPreludeDuration(expr),
        sessionDuration: getSessionDuration(expr),
        postludeDuration: getSessionPostludeDuration(expr),
        connectedUserCount: 0,
    };
}

export function getScoreInputVariables(score: SessionScore, userData: UserData) {
    const allInputVariables = score.variables?.inputs ?? [];
    return allInputVariables.filter((i) => !i.acl || i.acl.some((role) => userData.roles?.includes(role)));
}
