import { useCallback, useEffect, useState } from 'react';
import {
    ActiveLayerDetails,
    LayerNumber,
    PathType,
    SessionEventType,
    SessionPlan,
    TickDepth,
} from 'wavepaths-shared/core';

import { Connection, useRemoteSessionState } from '../../../common/hooks/useSessionTick';
import { useRemoteCurrentWave } from '../../../common/hooks/useSessionTick';
import { Queueable } from '../actionQueue/useActionQueue';
import {
    IN_EXPERIMENTAL_DISABLED_MESSAGE,
    IN_PLAYLIST_DISABLED_MESSAGE,
    NO_LAYERS_DISABLED_MESSAGE,
    SESSION_NOT_STARTED_MESSAGE,
    TOO_CLOSE_TO_END_OF_WAVE_MESSAGE,
} from './copy';
import { filterToRefreshableLayers } from './filterToRefreshableLayers';
import { useActiveLayers } from './useActiveLayers';

export interface UseInstrumentRefreshArgs {
    connection: Connection;
    queueFunction: (queueable: Queueable) => void;
    sessionPlan: SessionPlan;
    setSnackbarContent: (str: string) => void;
    depth?: TickDepth;
}

export enum InstrumentRefreshState {
    loading = 'loading',
    ready = 'ready',
}

export type UseInstrumentRefreshR =
    | {
          state: InstrumentRefreshState.ready;
          isOpen: boolean;
          handleOpenClick: () => void;
          handleCloseClick: () => void;
          onRefreshLayers: (layerNumbers: LayerNumber[]) => void;
          activeLayers: ActiveLayerDetails[];
          handleIconButtonClick: () => void;
      }
    | { state: InstrumentRefreshState.loading };

export const useInstrumentRefresh = ({
    connection,
    queueFunction,
    sessionPlan,
    setSnackbarContent,
    depth,
}: UseInstrumentRefreshArgs): UseInstrumentRefreshR => {
    const [isOpen, setIsOpen] = useState(false);
    const activeLayersHook = useActiveLayers(connection, queueFunction);
    const { started: sessionStarted } = useRemoteSessionState();
    const { wave: currentWave } = useRemoteCurrentWave(sessionPlan);

    const handleOpenClick = useCallback(() => setIsOpen(true), []);
    const handleCloseClick = useCallback(() => setIsOpen(false), []);

    useEffect(() => {
        if (connection) {
            connection.on(SessionEventType.ContentSwitchRejected, () => {
                setSnackbarContent(TOO_CLOSE_TO_END_OF_WAVE_MESSAGE);
            });
        }
    }, [connection, setSnackbarContent]);

    const onRefreshLayers = useCallback(
        (layerNumbers: LayerNumber[]) => {
            if (activeLayersHook === 'loading') {
                return;
            }
            activeLayersHook.refreshActiveLayers(layerNumbers);
        },
        [depth, setSnackbarContent, activeLayersHook],
    );

    if (activeLayersHook === 'loading') {
        return { state: InstrumentRefreshState.loading };
    }

    const isInPlaylist = currentWave?.pathScore?.type === PathType.CURATED;
    const isInExperimental = currentWave?.pathScore?.type === PathType.EXPERIMENTAL;

    const handleIconButtonClick = (): void => {
        if (!sessionStarted) {
            setSnackbarContent(SESSION_NOT_STARTED_MESSAGE);
        } else if (isInPlaylist) {
            setSnackbarContent(IN_PLAYLIST_DISABLED_MESSAGE);
        } else if (isInExperimental) {
            setSnackbarContent(IN_EXPERIMENTAL_DISABLED_MESSAGE);
        } else {
            const hasRefreshableLayers = filterToRefreshableLayers(activeLayersHook.activeLayers).length > 0;
            !hasRefreshableLayers ? setSnackbarContent(NO_LAYERS_DISABLED_MESSAGE) : handleOpenClick();
        }
    };

    return {
        state: InstrumentRefreshState.ready,
        isOpen,
        handleOpenClick,
        handleCloseClick,
        handleIconButtonClick,
        onRefreshLayers,
        activeLayers: activeLayersHook.activeLayers,
    };
};
