import React, { FC, useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Session, SessionHeader, SessionType } from 'wavepaths-shared/core';

import { TestHooksContext } from '@/hooks/useTestHooks';

import { AudioPlayerContext } from './EndedSessionsTable/useAudioPlayer';

// TODO: this can return a string that says "undefined"
export const renderSessionName = (session: Session | SessionHeader): string =>
    String(session.variableInputs?.name || session.variableInputs?.nameOnWebsite || session.score?.name);

export const renderSessionClients = (session: Session | SessionHeader): string => {
    return (session.clients ?? []).map((x) => `${x.firstname ?? ''} ${x.lastname ?? ''}`).join(', ');
};

export const renderSessionType = (type: SessionType): string => {
    switch (type) {
        case 'groupGuided':
            return 'Guided group';
        case 'groupInfinite':
            return 'Infinite group';
        case 'oneOnOne':
            return 'Music-Guided';
        case 'intro':
            return 'Intro';
        default:
            return '';
    }
};

// TODO: Investigate potenential backward compatibility issues
//        Old session data will have `firstName` and `lastName` while new data will have just `name`
export const renderSessionUser = (session: Session): JSX.Element | null =>
    session.user ? (
        <>
            {session.user.name} &lt;
            {session.user.email}&gt;
        </>
    ) : null;

const AudioElPortal: FC<{
    node: HTMLElement;
    portalId: string;
}> = (props) => {
    useEffect(() => {
        const portal = document.getElementById(props.portalId);
        if (portal === null) throw new Error('missing portal ' + props.portalId);
        portal.appendChild(props.node);

        return () => {
            portal.removeChild(props.node);
        };
    }, [props.children]);

    return createPortal(props.children, props.node);
};

export const DebugAudioEl: FC = () => {
    const audioPlayer = useContext(AudioPlayerContext);
    const testHooks = useContext(TestHooksContext);
    if (!testHooks?.debugPlayers) return null;

    return (
        <div>
            {audioPlayer?.playersDom.map((x, index) => (
                <div key={x.errorContext}>
                    <div>{x.errorContext}</div>

                    {x.audioElRef.current && (
                        <>
                            <div id={'audioPortal' + index} />
                            <AudioElPortal node={x.audioElRef.current} portalId={'audioPortal' + index} />
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};
