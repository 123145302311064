import styled from '@emotion/styled';
import React from 'react';

import { EvaIcon } from '@/component-library';
import TypographyV2 from '@/component-library/typography/TypographyV2';

import { TemplateProgram } from './useTemplatePrograms';

const ProgramContainer = styled.div<{ isExpanded: boolean }>(({ isExpanded }) => ({
    backgroundColor: 'white',
    // width: 280,
    // height: 150,
    // marginRight: 30,
    // display: 'flex',
    // flexDirection
    // position: 'relative',
    // userSelect: 'none',
    height: isExpanded ? 'auto' : '58px',
    overflow: 'hidden',
    padding: '0 32px 16px',
    opacity: '100%',
    position: 'relative',
    boxShadow: '0px 0px 10px #CFD6E7',
    border: '1px solid white',
    borderRadius: '8px',
    color: 'rgb(44, 57, 88)',
    cursor: 'pointer',
    marginBottom: 20,
}));

const Title = styled(TypographyV2)({
    paddingTop: 12,
    paddingBottom: 12,
    marginBottom: 12,
    borderBottom: '1px solid rgba(17, 17, 17, 0.1)',
});
const Content = styled(TypographyV2)({
    marginBottom: 16,
});
const SectionHeading = styled(TypographyV2)({});
const ExpandIconContainer = styled.div<{ isExpanded: boolean }>`
    position: absolute;
    left: 6px;
    top: 17px;
`;

type IProgramProps = {
    program: TemplateProgram;
    isExpandedDefault: boolean;
    onClick: () => void;
    children: React.ReactChild[] | React.ReactChild;
};

export function Program({ program, isExpandedDefault = false, onClick, children }: IProgramProps) {
    return (
        <ProgramContainer isExpanded={isExpandedDefault}>
            <div onClick={onClick}>
                <ExpandIconContainer
                    style={{ transform: isExpandedDefault ? 'rotate(90deg)' : 'rotate(0)' }}
                    isExpanded={isExpandedDefault}
                >
                    <EvaIcon name="chevron-right-outline" fill="#475467" size={24} />
                </ExpandIconContainer>
                <Title element={'h4'} font={'tenor-sans'} weight={'regular'} size={'display-xs'} color={'grey-700'}>
                    {program.name}
                </Title>
            </div>
            <SectionHeading color="grey-600" size="text-md" weight="medium">
                Use Case
            </SectionHeading>
            <Content color="grey-600" size="text-sm">
                {program.useCase}
            </Content>
            <SectionHeading color="grey-600" size="text-md" weight="medium">
                Description
            </SectionHeading>
            <Content color="grey-600" size="text-sm">
                {program.description}
            </Content>
            {children}
        </ProgramContainer>
    );
}
