import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

import { useSessionRemoteTick } from '@/hooks';

import { formatDuration } from '../../../dateUtilsV2';
import Typography from '../typography/Typography';

export interface IStatusBarItemProps {
    message?: string | ReactNode | ReactNode[];
    timestamp?: number;
}

const Item = styled.div({
    display: 'inline-grid',
    gridAutoFlow: 'column',
    alignItems: 'stretch',
    gap: 8,
    height: 24,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    border: '1px solid #ADB9D6',
    borderRadius: 12,
    padding: '0 12px',
});

const Text = styled(Typography)({
    color: '#2C3958',
});

const Countdown = styled.div({
    display: 'inline-grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: 4,
    paddingBottom: 1,
});

const Divider = styled.div({
    width: 1,
    background: '#ADB9D6',
});

const Message = styled.div({
    display: 'inline-grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    paddingBottom: 1,
});

const CountdownTimer = React.memo(({ timestamp }: { timestamp?: number }) => {
    const tick = useSessionRemoteTick();
    if (!timestamp) return null;

    const timeElapsed = tick?.absoluteTime ?? 0;
    const countdownSecs = timestamp - timeElapsed;

    return (
        <>
            <Countdown>
                <Text variant="body3">in</Text>
                <Text variant="body3" isFixedWidth>
                    {formatDuration(countdownSecs)}
                </Text>
            </Countdown>
            <Divider />
        </>
    );
});

function StatusBarItem({ message, timestamp }: IStatusBarItemProps): JSX.Element {
    return (
        <Item>
            <CountdownTimer timestamp={timestamp} />
            <Message>{typeof message === 'string' ? <Text variant="body3">{message}</Text> : <>{message}</>}</Message>
        </Item>
    );
}

export default React.memo(StatusBarItem);
