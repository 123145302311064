import { uniq } from 'lodash';
import {
    AdministrationRoute,
    DosageLevel,
    DurationPreset,
    ScoreSelectionCriteria,
    SessionScoreModality,
} from 'wavepaths-shared/core';

import { getAllModalities } from './data/modalities';

type Administration = {
    id: AdministrationRoute;
    name: string;
};

export const MODALITY_THERAPIST_LABELS: { [key in SessionScoreModality]: string } = {
    [SessionScoreModality.NONE]: 'No Medicine',
    [SessionScoreModality.KETAMINE]: 'Ketamine',
    [SessionScoreModality.MDMA]: 'MDMA',
    [SessionScoreModality.IBOGAINE]: 'Ibogaine',
    [SessionScoreModality.PSILOCYBIN]: 'Psilocybin',
    [SessionScoreModality.PSYCHOTHERAPY]: 'Psychotherapy',
    [SessionScoreModality.BREATHWORK]: 'Breathwork',
    [SessionScoreModality.FLOATATION]: 'Floatation Tank',
    [SessionScoreModality.WELLNESS]: 'Wellness',
    [SessionScoreModality.CLIENT_PREPARATION]: 'Client Preparation',
    [SessionScoreModality.AMBIENCE]: 'Ambience',
};

export const MODALITY_PERSONAL_LABELS: { [key in SessionScoreModality]: string } = {
    [SessionScoreModality.NONE]: 'No Medicine',
    [SessionScoreModality.KETAMINE]: 'Ketamine',
    [SessionScoreModality.MDMA]: 'MDMA',
    [SessionScoreModality.IBOGAINE]: 'Ibogaine',
    [SessionScoreModality.PSILOCYBIN]: 'Psilocybin',
    [SessionScoreModality.PSYCHOTHERAPY]: 'Deep Listening',
    [SessionScoreModality.BREATHWORK]: 'Breathwork',
    [SessionScoreModality.FLOATATION]: 'Floatation Tank',
    [SessionScoreModality.WELLNESS]: 'Wellness',
    [SessionScoreModality.CLIENT_PREPARATION]: 'Preparation',
    [SessionScoreModality.AMBIENCE]: 'Ambience',
};

export const POSSIBLE_DURATIONS_FOR_MODALITY: { [key in SessionScoreModality]: DurationPreset[] } = {
    [SessionScoreModality.NONE]: [],
    [SessionScoreModality.KETAMINE]: [
        DurationPreset.TWENTY_20M,
        DurationPreset.THIRTY_FIVE_35M,
        DurationPreset.ONE_HOUR,
        DurationPreset.TWO_HOURS,
        DurationPreset.THREE_HOURS,
    ],
    [SessionScoreModality.MDMA]: [DurationPreset.FOUR_HOURS, DurationPreset.FIVE_HOURS, DurationPreset.SIX_HOURS],
    [SessionScoreModality.IBOGAINE]: [],
    [SessionScoreModality.PSILOCYBIN]: [
        DurationPreset.THREE_HOURS,
        DurationPreset.FOUR_HOURS,
        DurationPreset.FIVE_HOURS,
        DurationPreset.SIX_HOURS,
    ],
    [SessionScoreModality.PSYCHOTHERAPY]: [DurationPreset.ONE_HOUR, DurationPreset.TWO_HOURS],
    [SessionScoreModality.BREATHWORK]: [
        DurationPreset.TWENTY_20M,
        DurationPreset.THIRTY_FIVE_35M,
        DurationPreset.ONE_HOUR,
        DurationPreset.TWO_HOURS,
        DurationPreset.THREE_HOURS,
    ],
    [SessionScoreModality.FLOATATION]: [],
    [SessionScoreModality.WELLNESS]: [
        DurationPreset.TWENTY_20M,
        DurationPreset.THIRTY_FIVE_35M,
        DurationPreset.ONE_HOUR,
        DurationPreset.TWO_HOURS,
    ],
    [SessionScoreModality.CLIENT_PREPARATION]: [
        DurationPreset.TWENTY_20M,
        DurationPreset.THIRTY_FIVE_35M,
        DurationPreset.ONE_HOUR,
    ],
    [SessionScoreModality.AMBIENCE]: [
        DurationPreset.TWO_HOURS,
        DurationPreset.THREE_HOURS,
        DurationPreset.FOUR_HOURS,
        DurationPreset.FIVE_HOURS,
    ],
};

export const ADMINISTRATION_LABELS: { [key in AdministrationRoute]: string } = {
    [AdministrationRoute.INTRAMUSCULAR]: 'Intramuscular',
    [AdministrationRoute.INTRAVENOUS]: 'Intravenous',
    [AdministrationRoute.SUBLINGUAL]: 'Oral',
    [AdministrationRoute.NASAL]: 'Nasal',
    [AdministrationRoute.ORAL]: 'Oral',
};

export const getAdministrationsForModality = (modality: SessionScoreModality): Administration[] => {
    const medicine = getAllModalities().find(({ name }) => name === modality);
    if (!medicine || medicine.type !== 'PAT') return [];

    return uniq(medicine.administrations.map(({ route }) => route)).map((route) => ({
        id: route,
        name: ADMINISTRATION_LABELS[route],
    }));
};

type Dosage = {
    id: DosageLevel;
    name: string;
};

export const DOSAGE_LABELS: { [key in DosageLevel]: string } = {
    [DosageLevel.VERY_LOW]: 'Very Low',
    [DosageLevel.LOW]: 'Low',
    [DosageLevel.MEDIUM]: 'Medium',
    [DosageLevel.HIGH]: 'High',
};

export const getDosagesForMedicineAndAdministration = (
    modality: SessionScoreModality,
    administration?: AdministrationRoute,
): Dosage[] => {
    const medicine = getAllModalities().find(({ name }) => name === modality);
    if (!medicine || medicine.type !== 'PAT') return [];

    const adminstrations = medicine.administrations.filter(({ route }) => route == administration);

    return adminstrations.map(({ dosage }) => ({ id: dosage, name: DOSAGE_LABELS[dosage] }));
};

export const getDefaultSessionDurationForModality = (
    selectedModality: SessionScoreModality,
    { administration, dosage }: { administration?: AdministrationRoute | null; dosage?: DosageLevel | null } = {},
): number => {
    const modality = getAllModalities().find(({ name }) => name === selectedModality);

    if (!modality) return 35;

    if (modality.type === 'Non-PAT') return modality.durationMins;

    const admin = modality.administrations.find(
        ({ route: adminRoute, dosage: adminDosage }) => adminRoute === administration && dosage === adminDosage,
    );

    return admin?.durationMins ?? 35;
};

export const getMedicineFromScore = (
    selectionCriteria?: Partial<ScoreSelectionCriteria>,
): SessionScoreModality | undefined => {
    const medicine = selectionCriteria?.medicine;
    return Array.isArray(medicine) ? medicine[0] : medicine;
};

export default {
    getDefaultSessionDurationForMedicine: getDefaultSessionDurationForModality,
    getDosagesForMedicineAndAdministration,
    getAdministrationsForMedicine: getAdministrationsForModality,
    getMedicineFromScore,
};
