import { Dialog, DialogContent } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Session, SessionRenderType } from 'wavepaths-shared/core';

import { Button, EvaIcon, Menu } from '@/component-library';
import { SubscribeModal } from '@/components';

import { useAuthContext } from '../../../auth';
import { Header } from '../../../common/components/Header';
import { AudioDownload } from '../AudioDownload';
import { AudioSettings } from '../AudioSettings';
import { SessionInfo } from '../SessionInfo';
import { SessionName } from '../SessionName';
import ShareSessionDialog from '../ShareSessionDialog';

interface PrecomposedGuideHeader {
    session: Session;
}

const useStyles = makeStyles<Theme>(() => ({
    paper: { maxWidth: 398 },
}));

export const PrecomposedGuideHeader: React.FC<PrecomposedGuideHeader> = React.memo(({ session }) => {
    const history = useHistory();
    const [sessionInfoOpen, setSessionInfoOpen] = useState(false);
    const [audioSettingsOpen, setAudioSettingsOpen] = useState(false);
    const classes = useStyles();

    const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState<boolean>(false);

    const { isPersonal, firebaseUser } = useAuthContext();

    const [showShareSessionDialog, setShowShareSessionDialog] = useState(false);

    const menuActions = [
        ...(isPersonal || !firebaseUser || session.renderType !== SessionRenderType.PRE_RENDERED
            ? []
            : [
                  {
                      element: 'Share link for remote listening',
                      icon: <MobileScreenShareIcon />,
                      onClick: () => setShowShareSessionDialog(true),
                  },
                  {
                      element: 'Session Info',
                      icon: <InfoIcon />,
                      onClick: () => setSessionInfoOpen(true),
                  },
              ]),
        {
            element: 'Audio Settings',
            icon: <EvaIcon name="settings-2-outline" fill="rgba(0, 0, 0, 0.54)" />,
            onClick: () => setAudioSettingsOpen(true),
        },
        {
            element: 'Go Back To Dashboard',
            icon: <ArrowBackIcon />,
            onClick: () => history.push('/'),
        },
    ];

    return (
        <>
            <SubscribeModal isOpen={!!upgradeModalIsOpen} closeModal={() => setUpgradeModalIsOpen(false)} />

            <Header
                left={<SessionName session={session} />}
                center={<></>}
                right={
                    <div
                        className="tour-sessionOptions"
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}
                    >
                        <>
                            {session.renderType !== SessionRenderType.PREDICTIVE_COMPOSED && <AudioDownload />}
                            {isPersonal || !firebaseUser || session.renderType !== SessionRenderType.PRE_RENDERED ? (
                                <></>
                            ) : (
                                <Button
                                    variant="outlined"
                                    style={{
                                        background: 'rgba(255, 255, 255, 0.4)',
                                        border: '1px solid rgba(255, 255, 255, 0.4)',
                                        borderRadius: '75px',
                                    }}
                                    onClick={() => setShowShareSessionDialog(true)}
                                    icon={<MobileScreenShareIcon />}
                                    aria-label="Volume"
                                />
                            )}
                        </>
                        <Menu
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            options={menuActions}
                        />
                        {session.renderType === SessionRenderType.PRE_RENDERED && (
                            <ShareSessionDialog
                                sessionId={session.id}
                                broadcastIdentifier={session.broadcastIdentifier}
                                isOpen={showShareSessionDialog}
                                onClose={() => setShowShareSessionDialog(false)}
                                defaultVersion={'beta'}
                            />
                        )}
                        <Dialog
                            classes={{ paper: classes.paper }}
                            open={sessionInfoOpen}
                            onClose={() => setSessionInfoOpen(false)}
                        >
                            <DialogContent>
                                <SessionInfo closeDialog={() => setSessionInfoOpen(false)} session={session} />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            classes={{ paper: classes.paper }}
                            open={audioSettingsOpen}
                            onClose={() => setAudioSettingsOpen(false)}
                        >
                            <DialogContent>
                                <AudioSettings closeDialog={() => setAudioSettingsOpen(false)} />
                            </DialogContent>
                        </Dialog>
                    </div>
                }
            />
        </>
    );
});
