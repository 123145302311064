import React, { ReactNode } from 'react';

function LoadingOrb({ children }: { children?: ReactNode }): JSX.Element {
    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#f1f3f8',
                }}
                data-cy={'loading-orb'}
            >
                <svg
                    className="loader"
                    viewBox="0 0 300 300"
                    style={{ width: '300px', height: '300px' }}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <defs>
                        <clipPath id="loaderRectClip">
                            <rect x="93" y="93" width="114" height="114" rx="57">
                                <animate
                                    attributeName="width"
                                    values="114;81;92;49;114"
                                    dur="16s"
                                    keyTimes="0;0.25;0.5;0.75;1"
                                    keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                                    calcMode="spline"
                                    repeatCount="indefinite"
                                />
                                <animate
                                    attributeName="height"
                                    values="114;65;137;57;114"
                                    dur="16s"
                                    keyTimes="0;0.25;0.5;0.75;1"
                                    keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                                    calcMode="spline"
                                    repeatCount="indefinite"
                                />
                                <animate
                                    attributeName="rx"
                                    values="57;32;46;24;57"
                                    dur="16s"
                                    keyTimes="0;0.25;0.5;0.75;1"
                                    keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                                    calcMode="spline"
                                    repeatCount="indefinite"
                                />
                                <animate
                                    attributeName="x"
                                    values="93;109.5;104;125.5;93"
                                    dur="16s"
                                    keyTimes="0;0.25;0.5;0.75;1"
                                    keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                                    calcMode="spline"
                                    repeatCount="indefinite"
                                />
                                <animate
                                    attributeName="y"
                                    values="93;117.5;81.5;121.5; 93"
                                    dur="16s"
                                    keyTimes="0;0.25;0.5;0.75;1"
                                    keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                                    calcMode="spline"
                                    repeatCount="indefinite"
                                />
                            </rect>
                        </clipPath>
                        <filter id="loaderShadow" x="-100%" y="-100%" width="300%" height="300%">
                            <feColorMatrix
                                type="matrix"
                                values="1 0 0 0 0 
                         0 1 0 0 0 
                         0 0 1 0 0 
                         0 0 0 100 0"
                                result="boostedInput"
                            />
                            <feDropShadow dx="0" dy="0" stdDeviation="50" floodColor="rgba(0, 0, 0)" floodOpacity="0.2">
                                <animate
                                    attributeName="stdDeviation"
                                    values="50;5;50;25;50"
                                    dur="16s"
                                    keyTimes="0;0.25;0.5;0.75;1"
                                    keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                                    calcMode="spline"
                                    repeatCount="indefinite"
                                />
                                <animate
                                    attributeName="flood-opacity"
                                    values="0.2;0;0.2;0.01;0.2"
                                    dur="16s"
                                    keyTimes="0;0.25;0.5;0.75;1"
                                    keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                                    calcMode="spline"
                                    repeatCount="indefinite"
                                />
                            </feDropShadow>
                            <feComposite operator="out" in2="boostedInput" />
                        </filter>
                    </defs>
                    <rect
                        x="93"
                        y="93"
                        width="114"
                        height="114"
                        rx="57"
                        transform-origin="160 160"
                        style={{ transform: 'scale(0.95)', filter: 'url(#loaderShadow)' }}
                        stroke="none"
                        fill="black"
                        fillOpacity="0.01"
                    >
                        <animate
                            attributeName="width"
                            values="114;81;92;49;114"
                            dur="16s"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                            calcMode="spline"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="height"
                            values="114;65;137;57;114"
                            dur="16s"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                            calcMode="spline"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="rx"
                            values="57;32;46;24;57"
                            dur="16s"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                            calcMode="spline"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="x"
                            values="93;109.5;104;125.5;93"
                            dur="16s"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                            calcMode="spline"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="y"
                            values="93;117.5;81.5;121.5; 93"
                            dur="16s"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                            calcMode="spline"
                            repeatCount="indefinite"
                        />
                    </rect>
                    <image
                        clipPath="url(#loaderRectClip)"
                        width="972"
                        height="986"
                        x="-765"
                        y="93"
                        xlinkHref="/loadingscreenbg.jpg"
                    >
                        <animate
                            attributeName="x"
                            values="-765;109.5;104;-797.5;-765"
                            dur="16s"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                            calcMode="spline"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="y"
                            values="93;-803.5;81.5;-807.5;93"
                            dur="16s"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1;0.45,0,0.55,1"
                            calcMode="spline"
                            repeatCount="indefinite"
                        />
                    </image>
                </svg>
            </div>
            <div
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div style={{ marginTop: 300, opacity: '40%', maxWidth: '480px', textAlign: 'center' }}>{children}</div>
            </div>
        </>
    );
}

export default LoadingOrb;
