import { styled, Theme as DefaultTheme } from '@material-ui/core/styles';
import cn from 'classnames';
import React from 'react';

import { EvaIcon } from '@/component-library';

import helpfulMusic from '../../../images/iconHelpfulMusic.svg';
import leaveNote from '../../../images/iconLeaveNote.svg';
import unhelpfulMusic from '../../../images/iconUnhelpfulMusic.svg';
import IconButton from '../IconButton';

const Icon = styled('img')({
    height: 21,
    width: 21,
    marginTop: 1,
});

const FeedbackIconButton = styled(IconButton)<DefaultTheme, { disabled?: boolean }>(({ disabled }) => ({
    //maxWidth: 'none',
    //maxHeight: 'none',
    //width: 44,
    //height: 44,
    display: 'grid',
    placeContent: 'center',
    //border: 'none',
    opacity: disabled ? '0.3' : '1',
}));

interface FeedbackButtonProps {
    ariaLabel?: string;
    icon: string;
    isActive?: boolean;
    isDisabled?: boolean;
    onClick: () => void;
    classNames?: string | string[];
}
const getIcon = (icon: string) => {
    switch (icon) {
        case 'helpfulMusic':
            return <Icon src={helpfulMusic} alt="Helpful Music" />;
        case 'unhelpfulMusic':
            return <Icon src={unhelpfulMusic} alt="Unhelpful Music" />;
        case 'leaveNote':
            return <Icon src={leaveNote} alt="Unhelpful Music" />;
        case 'reportIssue':
            return <EvaIcon name="alert-triangle-outline" size={21} fill="#2C3958" />;
    }
};
export const FeedbackButton: React.FC<FeedbackButtonProps> = ({
    ariaLabel,
    icon,
    isActive,
    isDisabled,
    onClick,
    classNames,
}) => {
    return (
        <FeedbackIconButton
            className={cn(classNames, 'feedbackButton', `feedbackButton-${icon}`, {
                isActive,
                isDisabled,
            })}
            onClick={onClick}
            disabled={isDisabled}
            aria-label={ariaLabel}
            icon={getIcon(icon)}
            // @ts-ignore
            title={ariaLabel}
            variant="outlined"
        />
    );
};
