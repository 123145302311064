import { SessionScoreDTO, WavepathType } from 'wavepaths-shared/core';

const introScore1 = {
    id: 'intro-score-1-v2',
    acl: ['Admin'],
    name: 'Guiding a session',
    variables: {
        inputs: [
            {
                name: 'voiceover',
                type: 'enum',
                group: 'content',
                options: [
                    {
                        label: 'None',
                        value: 'none',
                    },
                    {
                        label: 'Josie Taylor Long (en-GB)',
                        value: 'josie-taylor',
                    },
                    {
                        label: 'Josie Taylor Short 01 (en-GB)',
                        value: 'josie-taylor-short-01',
                    },
                    {
                        label: 'Josie Taylor Short 02 (en-GB)',
                        value: 'josie-taylor-short-02',
                    },
                ],
                defaultValue: 'josie-taylor-short-01',
            },
            {
                name: 'totalDuration',
                type: 'number',
                group: 'timing',
                label: 'Session Duration',
                defaultValue: 40,
            },
            {
                name: 'preludeDuration',
                type: 'number',
                group: 'timing',
                defaultValue: 30,
            },
            {
                name: 'postludeDuration',
                type: 'number',
                group: 'timing',
                defaultValue: 30,
            },
            {
                name: 'nameOnWebsite',
                type: 'string',
                group: 'website',
                defaultValue: 'Deep Listening - Gentle & Joyful',
            },
            {
                long: true,
                name: 'descriptionOnWebsite',
                type: 'string',
                group: 'website',
            },
            {
                name: 'emotionalityLevel',
                type: 'string',
                group: 'website',
                hidden: true,
                defaultValue: 'Medium',
            },
            {
                name: 'sessionType',
                type: 'string',
                group: 'website',
                hidden: true,
                defaultValue: 'Deep Listening',
            },
            {
                acl: ['Admin'],
                name: 'preferSubmittedContent',
                type: 'boolean',
                group: 'content',
                label: 'Prefer Submitted content over Approved',
                defaultValue: 0,
            },
        ],
        derived: [
            {
                name: 'preOnsetDuration',
                expression: 'totalDuration * 0.25',
            },
            {
                name: 'onsetDuration',
                expression: 'totalDuration * 0.25',
            },
            {
                name: 'peakDuration',
                expression: 'totalDuration * 0.25',
            },
            {
                name: 'returnDuration',
                expression: 'totalDuration * 0.25',
            },
        ],
    },
    wavepaths: [
        {
            type: WavepathType.PRE,
            id: '68aff7ae-9127-44b3-b69d-51c6747077f2',
            pathId: 's_cea1_pre',
        },
        {
            plan: {
                toTime: 240000,
                fromTime: 0,
            },
            type: WavepathType.SCHEDULED,
            id: '2c32513c-b333-46f5-9662-a829406d89ab',
            pathId: 's_cea1_a',
            duration: 4,
        },
        {
            plan: {
                toTime: 840000,
                fromTime: 240000,
            },
            type: WavepathType.SCHEDULED,
            id: '537e1eaa-38f0-4a31-82d0-d9ac85699535',
            pathId: 's_cea1_b',
            duration: 10,
        },
        {
            plan: {
                toTime: 1080000,
                fromTime: 840000,
            },
            type: WavepathType.SCHEDULED,
            id: '76f6fe2d-cc96-4489-ab11-bd820db20e47',
            pathId: 's_cea2_a',
            duration: 4,
        },
        {
            plan: {
                toTime: 1680000,
                fromTime: 1080000,
            },
            type: WavepathType.SCHEDULED,
            id: 'd760e1e3-057d-4b3e-b77a-a3cea37a0522',
            pathId: 's_cea2_b',
        },
        {
            plan: {
                toTime: 1920000,
                fromTime: 1680000,
            },
            type: WavepathType.SCHEDULED,
            id: '78ce8f11-3fdd-4857-b971-cc59d2505a14',
            pathId: 's_cea4_a',
            duration: 4,
        },
        {
            plan: {
                toTime: 2520000,
                fromTime: 1920000,
            },
            type: WavepathType.SCHEDULED,
            id: '67cc3e31-e1fd-4d71-9db5-cfee7c28968d',
            pathId: 's_cea4_b',
            duration: 10,
        },
        {
            plan: {
                toTime: 2760000,
                fromTime: 2520000,
            },
            type: WavepathType.SCHEDULED,
            id: 'd185306b-9809-4d80-8c85-a048b6e3ffe7',
            pathId: 's_cea3_a',
            duration: 4,
        },
        {
            plan: {
                toTime: 3360000,
                fromTime: 2760000,
            },
            type: WavepathType.SCHEDULED,
            id: '9fd743ac-420e-4f5a-b3af-69b9541651a6',
            pathId: 's_cea3_b',
        },
        {
            type: WavepathType.POST,
            id: 'eb810232-d93f-410e-9dbe-a8cf69f904a4',
            pathId: 's_cea1_post',
        },
    ],
    showInMenu: true,
    timingPlan: [
        {
            name: 'Prelude',
            type: WavepathType.PRE,
            id: 'ac5db3db-1fb6-48cb-876c-d3639855853b',
        },
        {
            name: 'Pre-onset',
            type: 'regular',
            durationVariable: 'preOnsetDuration',
        },
        {
            name: 'Onset',
            type: 'regular',
            durationVariable: 'onsetDuration',
        },
        {
            name: 'Peak',
            type: 'regular',
            durationVariable: 'peakDuration',
        },
        {
            name: 'Return',
            type: 'regular',
            durationVariable: 'returnDuration',
        },
        {
            name: 'Postlude',
            type: WavepathType.POST,
            id: 'c186dc5b-0818-48a8-9144-23b4ec2fba12',
        },
    ],
    description: 'Emotional Intensity: Medium',
    sessionTypes: ['groupGuided', 'oneOnOne'],
    selectionCriteria: {
        medicine: 'None',
        emotionalIntensity: 'Low',
    },
} as SessionScoreDTO;

const introSessions = [introScore1];

export const loadIntroScores = (): SessionScoreDTO[] => introSessions;
