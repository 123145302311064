import { createContext, useCallback } from 'react';
import { useEffect, useState } from 'react';
import {
    RequestType,
    ReviseSessionPlanEvent,
    Session,
    SessionEventType,
    SessionRenderType,
    TimestampedSessionEvent,
} from 'wavepaths-shared/core';

import useAnonymousToken from '@/hooks/useAnonymousToken';

import { useAuthContext } from '../../../auth';
import * as sessionApi from '../../../common/api/sessionApi';
import { Connection } from '../../../common/hooks/useSessionTick';
import {
    AllLatestEvents,
    SessionEventsReplayedEvent,
} from '../../../freudConnection/InboundEvents/InboundEventManager.types';
import UserEvents from '../../../UserEvents';

export const SessionContext = createContext<Session | undefined>(undefined);

function useRemoteSession(
    sessionId?: string,
    connection?: Connection | null,
): {
    session?: Session;
    loading: boolean;
    error: boolean;
    startSession: () => void;
} {
    const { firebaseUser } = useAuthContext();
    const [session, setSession] = useState<Session | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [logReplayed, setLogReplayed] = useState(false);
    const { anonymousToken, loading: loadingAnonymousToken } = useAnonymousToken();

    useEffect(() => {
        if (sessionId) {
            // not using SWR to fetch as we don't need caching or revalidation (i.e. all updates come via the session log)
            (async () => {
                if (!firebaseUser && !anonymousToken) return;
                try {
                    const sessionResult = await sessionApi.getSession(sessionId, firebaseUser, anonymousToken);
                    setSession((s) => ({
                        ...s,
                        ...sessionResult,
                        score: s?.score ?? sessionResult.score,
                        variableInputs: s?.variableInputs ?? sessionResult.variableInputs,
                    }));
                } catch (e: any) {
                    setError(e);
                }
            })();
        }
    }, [sessionId, firebaseUser, loadingAnonymousToken, anonymousToken]);

    useEffect(() => {
        if (connection) {
            connection.on(AllLatestEvents, (newEvents: TimestampedSessionEvent[]) => {
                const reviseSessionPlanEvents = newEvents.filter(
                    (p) => p.event === SessionEventType.ReviseSessionPlanEvent,
                ) as ReviseSessionPlanEvent[];
                reviseSessionPlanEvents.forEach((event) => {
                    setSession((s) => ({
                        //TODO: get rid of the typecast
                        ...(s as Session),
                        //TODO: this is very sneaky way to substitute the data, this should be composed object or something to not confuse what is what
                        score: event.revisedScore,
                        variableInputs: event.revisedVariables,
                    }));
                });
            });
            connection.on(SessionEventsReplayedEvent, () => {
                setLogReplayed(true);
            });
        }
    }, [connection]);

    //TODO: the only way it works for Precomposed sessions is that we dont check loaded at all...
    //TODO remove this logReplaying once we update session in DB after each change
    const loaded = error || (session && (logReplayed || session.renderType === SessionRenderType.PREDICTIVE_COMPOSED));

    const startSession = useCallback(() => {
        if (session) {
            UserEvents.sessionStarted(session);
        }
        if (!connection) return;
        connection.sendRequest({
            type: RequestType.StartSessionTimers,
        });
    }, [connection, session]);

    return {
        session,
        loading: !loaded,
        error: !!error,
        startSession,
    };
}

export function useSessionByBroadcastIdentifier({ broadcastIdentifier }: { broadcastIdentifier: string }) {
    const [session, setSession] = useState<Session | undefined>(undefined);
    const { firebaseUser } = useAuthContext();
    const { anonymousToken, loading: loadingAnonymousToken } = useAnonymousToken();
    const loaded = !loadingAnonymousToken;

    useEffect(() => {
        if (broadcastIdentifier && loaded) {
            (async () => {
                const sessionResult = await sessionApi.getSessionByBroadcastIdentifier(
                    broadcastIdentifier,
                    firebaseUser,
                    anonymousToken,
                );
                setSession(sessionResult);
            })();
        }
    }, [broadcastIdentifier, loaded]);

    return { session };
}

export default useRemoteSession;
