import firebase from 'firebase/app';
import { useContext, useEffect } from 'react';
import { Session } from 'wavepaths-shared/core';
import { create } from 'zustand';

import { useCurrentMusicalContentStore, useTickStore } from '@/hooks';
import useAnonymousToken from '@/hooks/useAnonymousToken';
import { TestHooksContext } from '@/hooks/useTestHooks';

import { FreudConnection } from '../../freudConnection/FreudConnection';

export type UseFreudConnectionProps = {
    session?: Session;
    firebaseUser?: firebase.User;
    mode?: 'control' | 'listen';
};

type UseFreudConnectionReturn = FreudConnection | null;
type ConnectionStore = {
    connection: FreudConnection | null;
    setConnection: (connection: FreudConnection | null) => void;
};

export const useConnectionStore = create<ConnectionStore>((set) => ({
    connection: null,
    setConnection: (connection) => set({ connection }),
}));

export const useFreudConnection = ({
    session,
    firebaseUser,
    mode,
}: UseFreudConnectionProps): UseFreudConnectionReturn => {
    const { connection, setConnection } = useConnectionStore();
    const { setTick } = useTickStore();
    const { setMusicalContent } = useCurrentMusicalContentStore();
    const { anonymousToken, loading: loadingAnonymousToken } = useAnonymousToken();
    const testHooks = useContext(TestHooksContext);
    if (testHooks === undefined) throw new Error('No testHooks context');

    useEffect(() => {
        // console.debug({ session, firebaseUser, anonymousToken, connection, loadingAnonymousToken });
        if (!!session && (!!firebaseUser || anonymousToken) && !connection && !loadingAnonymousToken) {
            const conn = new FreudConnection(
                session,
                firebaseUser,
                anonymousToken,
                mode,
                testHooks.tickStressTestMultiply,
            );
            setConnection(conn);
            conn.on('tick', (tick) => {
                setTick(tick);
                setMusicalContent(tick.musicalContent);
            });
        }
    }, [session, firebaseUser, connection, anonymousToken, loadingAnonymousToken]);

    useEffect(() => {
        if (connection) {
            return () => {
                console.log('Closing connection');
                connection.off('tick', setTick);
                setTick(undefined);
                setConnection(null);
                setMusicalContent(undefined);
                connection.removeAllListeners();
                connection.close();
            };
        }
    }, [connection]);

    return connection;
};

export default useFreudConnection;
