import { Card, CardContent, Chip, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useQueryParam } from 'use-query-params';
import { APISessionStatus, clientsFreudApi, InstrumentDictionaryItem } from 'wavepaths-shared/core';

import { Button } from '@/component-library';
import PageHeading from '@/component-library/components/PageHeading';
import { ScrollRow, ScrollRowItem } from '@/component-library/components/ScrollRow';
import SectionHeading from '@/component-library/components/SectionHeading';
import TextInput from '@/component-library/components/TextInput';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { SearchBar } from '@/components';
import { GlobalSnackbarContext } from '@/components/Snackbar';
import { useDebouncedState } from '@/hooks';
import { useClients } from '@/hooks/useClients';

import { useAuthContext } from '../../auth';
import { getAllInstruments, upsertClient } from '../../common/api/bonnyApi';
import { LayoutContainer } from '../../LayoutContainer';
import { CardRowSessionContainer } from '../home';
import TherapistEndedSessionCardV2 from '../sessions/EndedSessionsTable/TherapistEndedSessionCardV2';
import useSessions, { ISessionsData } from '../sessions/useSessions';

const NoResultsContainer = styled.div`
    width: 100%;
    grid-column: 1/-1;
    text-align: center;
`;

const Container = styled.div`
    display: grid;
    gap: 5px;
`;

const Clients = React.memo(
    ({
        clients,
        isLoading,
        ContainerComponent = Container,
    }: {
        clients?: clientsFreudApi[];
        isLoading: boolean;
        ContainerComponent?: React.FC;
    }) => {
        const history = useHistory();

        return (
            <ContainerComponent>
                <>
                    {clients &&
                        (clients.length ? (
                            clients.map((x, index) => (
                                <Card
                                    key={index}
                                    variant="outlined"
                                    onClick={() => history.push(`client/${x.client_id}`)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <CardContent>
                                        {x.firstname} {x.lastname}
                                    </CardContent>
                                </Card>
                            ))
                        ) : (
                            <NoResultsContainer>
                                <TypographyV2 color="grey-400" size="text-md">
                                    Sorry, we couldn't find any results
                                </TypographyV2>
                            </NoResultsContainer>
                        ))}
                    {isLoading ? <div>Loading...</div> : <></>}
                </>
            </ContainerComponent>
        );
    },
);

export function ClientsList() {
    const { firebaseUser } = useAuthContext();
    if (firebaseUser === undefined) throw new Error('No firebaseUser');

    const [query, setQuery] = useQueryParam<string | undefined>('query');
    const effectiveQuery = query ?? '';
    const { clients, isLoading } = useClients({ fbUser: firebaseUser, query: effectiveQuery, refreshInterval: 3000 });
    const [newDialogOpen, setNewDialogOpen] = useState<boolean>(false);

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <SearchBar
                    value={effectiveQuery}
                    label="Find clients"
                    onChange={setQuery}
                    onClear={() => {
                        setQuery('');
                    }}
                />
                <Button variant="solid-blue" onClick={() => setNewDialogOpen(true)}>
                    Add new
                </Button>
            </div>
            <Clients isLoading={isLoading} clients={clients} />
            {newDialogOpen ? (
                <Dialog open={true} onClose={() => setNewDialogOpen(false)} fullWidth={true} maxWidth={'md'}>
                    <DialogTitle>Add new Client</DialogTitle>
                    <DialogContent>
                        <ClientDetail
                            client={undefined}
                            endedSessions={undefined}
                            onSave={() => {
                                setNewDialogOpen(false);
                            }}
                        />
                    </DialogContent>
                </Dialog>
            ) : (
                <></>
            )}
        </>
    );
}

export function ClientsPage() {
    return (
        <LayoutContainer>
            <PageHeading text={'Your Clients'} />
            <ClientsList />
        </LayoutContainer>
    );
}

export function ClientDetail({
    client,
    endedSessions,
    onSave,
}: {
    client?: clientsFreudApi;
    endedSessions?: ISessionsData;
    onSave?: ({ client }: { client: clientsFreudApi }) => void;
}) {
    const { firebaseUser } = useAuthContext();
    if (firebaseUser === undefined) throw new Error('No firebaseUser');

    const [allInstruments, setAllInstruments] = useState<InstrumentDictionaryItem[] | 'loading'>('loading');
    useEffect(() => {
        (async () => {
            setAllInstruments(await getAllInstruments({ fbUser: firebaseUser }));
        })();
    }, []);
    const clientInstrumentBlacklistOptions =
        allInstruments !== 'loading' && client !== undefined
            ? allInstruments.filter((x) => client.instrument_blacklist.includes(x.key))
            : [];
    const [clientInstrumentBlacklist, _setClientInstrumentBlacklist] = useState<string[]>([]);
    useEffect(() => {
        client && _setClientInstrumentBlacklist(client.instrument_blacklist);
    }, [client?.client_id]);

    const [firstname, _setFirstname, immediateFirstname] = useDebouncedState<string>(client?.firstname ?? '', 1000);
    const [lastname, _setLastname, immediateLastname] = useDebouncedState<string>(client?.lastname ?? '', 1000);
    const [notes, _setNotes, immediateNotes] = useDebouncedState<string>(client?.notes ?? '', 1000);
    const { setSnackbarContent } = useContext(GlobalSnackbarContext);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const submitNew = () => {
        (async () => {
            setSubmitDisabled(true);
            try {
                const client = await upsertClient(firebaseUser, {
                    firstname,
                    lastname,
                    notes,
                    instrument_blacklist: clientInstrumentBlacklist,
                });
                setSnackbarContent('Client added');
                onSave && onSave({ client });
            } catch (e) {
                setSnackbarContent('Error saving data');
                setSubmitDisabled(false);
            }
        })();
    };
    const needSavingRef = useRef<boolean>(false);
    useEffect(() => {
        (async () => {
            if (!needSavingRef.current) {
                //TODO: how to ignore "initial" state of variables and only run this without having to use useRef counting
                return;
            }
            needSavingRef.current = false;
            console.debug('Change', { firstname, lastname, notes, clientInstrumentBlacklist });
            if (client?.client_id === undefined) return;

            try {
                const clientUpdated = await upsertClient(firebaseUser, {
                    client_id: client?.client_id,
                    firstname,
                    lastname,
                    notes,
                    instrument_blacklist: clientInstrumentBlacklist,
                });
                onSave && onSave({ client: clientUpdated });
                setSnackbarContent('Changes saved');
            } catch (e) {
                setSnackbarContent('Error saving data');
            }
        })();
    }, [firstname, lastname, notes, clientInstrumentBlacklist]);

    const setFirstname: typeof _setFirstname = (x) => {
        needSavingRef.current = true;
        _setFirstname(x);
    };

    const setLastname: typeof _setLastname = (x) => {
        needSavingRef.current = true;
        _setLastname(x);
    };

    const setNotes: typeof _setNotes = (x) => {
        needSavingRef.current = true;
        _setNotes(x);
    };

    const setClientInstrumentBlacklist: typeof _setClientInstrumentBlacklist = (x) => {
        needSavingRef.current = true;
        _setClientInstrumentBlacklist(x);
    };

    return (
        <>
            {allInstruments === 'loading' ? (
                <>Loading...</>
            ) : (
                <div>
                    <SectionHeading text={`Identification`}></SectionHeading>
                    <TextInput
                        variant="outlined"
                        size="small"
                        name="firstname"
                        heading="First Name or Nickname"
                        onChange={(e) => setFirstname(e.target.value)}
                        value={immediateFirstname}
                    />
                    <TextInput
                        variant="outlined"
                        size="small"
                        name="lastname"
                        heading="Last Name or Nickname (optional)"
                        onChange={(e) => setLastname(e.target.value)}
                        value={immediateLastname}
                    />
                    <TextInput
                        variant="outlined"
                        size="small"
                        name="notes"
                        heading="Notes (optional)"
                        onChange={(e) => setNotes(e.target.value)}
                        value={immediateNotes}
                        multiline={true}
                        rows={5}
                    />
                    {
                        //TODO instrument cleanup before displaying to endusers
                        //<SectionHeading text={'Musical Blacklist'} ></SectionHeading>
                    }
                    <Autocomplete
                        style={{ display: 'none' }}
                        multiple
                        id="client-instrument_blacklist"
                        options={allInstruments}
                        defaultValue={clientInstrumentBlacklistOptions}
                        loading={false}
                        renderOption={(option) => {
                            return <div>{option.label}</div>;
                        }}
                        getOptionLabel={(option) => option.key}
                        getOptionSelected={(option, value) => option.key === value.key}
                        renderTags={(value, getTagProps) => {
                            return value.map((option, index) => (
                                <Chip
                                    key={index}
                                    variant="outlined"
                                    label={`${option.label}`}
                                    {...getTagProps({ index })}
                                />
                            ));
                        }}
                        onChange={(_event, value) => {
                            const strongValue = value as typeof clientInstrumentBlacklistOptions;
                            setClientInstrumentBlacklist(strongValue.map((x) => x.key));
                        }}
                        renderInput={(params) => {
                            return (
                                <TextInput
                                    {...params}
                                    color="secondary"
                                    variant="outlined"
                                    placeholder="Select instrument(s)"
                                />
                            );
                        }}
                    />
                    {endedSessions !== undefined ? (
                        <>
                            <br />
                            <SectionHeading text={'Completed Sessions'}></SectionHeading>
                            <ScrollRow>
                                <>
                                    {endedSessions.sessions.map((session, idx) => (
                                        <ScrollRowItem key={'session' + idx}>
                                            <CardRowSessionContainer>
                                                <TherapistEndedSessionCardV2 key={session.id} session={session} />
                                            </CardRowSessionContainer>
                                        </ScrollRowItem>
                                    ))}
                                </>
                            </ScrollRow>
                            {!endedSessions.loading && endedSessions.totalCount == 0 ? (
                                <>
                                    <TypographyV2 size="text-sm">No sessions yet</TypographyV2>
                                </>
                            ) : (
                                <></>
                            )}

                            {endedSessions.loading && endedSessions.totalCount == 0 ? (
                                <div key="loading">Loading...</div>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                    {client === undefined ? (
                        <>
                            <Button
                                disabled={submitDisabled}
                                onClick={() => submitNew()}
                                variant="solid-blue"
                                size={'l'}
                            >
                                Submit
                            </Button>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </>
    );
}

export function ClientDetailPage() {
    const { clientId } = useParams<{ clientId: string }>();
    if (!clientId) throw new Error('No clientId');

    const { firebaseUser } = useAuthContext();
    if (firebaseUser === undefined) throw new Error('No firebaseUser');

    const { clients, isLoading: isClientLoading } = useClients({
        fbUser: firebaseUser,
        client_id: clientId,
        query: '',
    });
    const endedSessions = useSessions({
        fbUser: firebaseUser,
        sessionState: APISessionStatus.ENDED,
        paginated: false,
        client_id: clientId,
    });
    const client = clients && clients.length ? clients[0] : undefined;

    return (
        <LayoutContainer>
            {isClientLoading || client === undefined || endedSessions.loading ? (
                <>Loading...</>
            ) : (
                <ClientDetail client={client} endedSessions={endedSessions} />
            )}
        </LayoutContainer>
    );
}
