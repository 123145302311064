export const sleep = (ms: number) => new Promise<void>((res) => setTimeout(() => res(), ms));

export async function fetchWithTimeout(input: RequestInfo, options: RequestInit & { timeout?: number } = {}) {
    const { timeout = 8000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(input, {
        ...options,
        signal: controller.signal,
    });
    clearTimeout(id);

    return response;
}

export async function ParallelPromises<T>({
    tasks,
    limit = 5,
}: {
    tasks: {
        runPromise: () => Promise<T>;
        label: string;
    }[];
    limit?: number;
}) {
    const results: {
        result?: T;
        status: 'complete' | 'rejected' | 'new';
        task: { promise?: Promise<T>; label: string };
        error: any;
    }[] = [];
    const remainingTasks = [...tasks];

    const mainPromisePool: Promise<void>[] = [];
    for (let i = 0; i < limit; i++) {
        let status: 'complete' | 'rejected' | 'new' = 'new';
        let result: T | undefined;
        if (!remainingTasks.length) break;

        const runNext = async (chainCounter = 0) => {
            if (!remainingTasks.length) return;
            const task = remainingTasks.splice(0, 1)[0];
            let error: any = undefined;
            let executedPromise: Promise<T> | undefined = undefined;
            try {
                //get next from the top
                //console.debug('Running', task.label, Date.now(), 'Thread:', i, 'Chain:', chainCounter);
                executedPromise = task.runPromise();
                result = await executedPromise;
                //console.debug('Finished', task.label, Date.now(), 'Thread:', i, 'Chain:', chainCounter, result);

                status = 'complete';
            } catch (err: any) {
                //console.debug('Failed', task.label, Date.now(), 'Thread:', i, 'Chain:', chainCounter, result);

                status = 'rejected';
                error = err;
            } finally {
                results.push({
                    result,
                    status,
                    task: { promise: executedPromise, label: task.label },
                    error,
                });
            }

            if (remainingTasks.length) {
                await runNext(chainCounter + 1);
            }
        };

        mainPromisePool.push(runNext());
    }

    await Promise.allSettled(mainPromisePool);

    return results;
}
