import styled from '@emotion/styled';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import React, { PropsWithChildren, useCallback, useState } from 'react';

import { EvaIcon } from '..';
import Typography from '../typography/Typography';
import Button from './Button';

const StyledDialogTitle = styled(DialogTitle)({
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr min-content',
    alignItems: 'center',
    marginTop: '8px',
});

const StyledDialogContent = styled(DialogContent)({
    paddingBottom: '36px',
    '> p': {
        marginBottom: '1em',
    },
    '> *:last-child': {
        marginBottom: '0',
    },
    '> p a': {
        color: 'inherit',
        textDecoration: 'underline',
    },
});

export interface InfoDialogProps {
    title: string;
    open: boolean;
    onClose: () => void;
}
const InfoDialog = ({ open, title, onClose, children }: PropsWithChildren<InfoDialogProps>): JSX.Element => (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
        <StyledDialogTitle disableTypography>
            <Typography variant="h6" style={{ marginBottom: 0 }}>
                {title}
            </Typography>
            <Button variant="outlined" size="xs" icon={<CloseIcon fontSize="small" />} onClick={onClose}>
                Close
            </Button>
        </StyledDialogTitle>
        <StyledDialogContent>{children}</StyledDialogContent>
    </Dialog>
);

export const InfoIconContainer = (props: PropsWithChildren<{ title: string }>): JSX.Element => {
    const [infoOpen, setInfoOpen] = useState(false);
    const onClose = useCallback(() => setInfoOpen(false), []);
    const onClick = useCallback(() => setInfoOpen(true), []);
    return (
        <>
            <span onClick={onClick}>
                <EvaIcon size={20} evaSize={20} name="info-outline" fill="#6980B4" />
            </span>
            <InfoDialog {...props} open={infoOpen} onClose={onClose} />
        </>
    );
};

export default InfoDialog;
