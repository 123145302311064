import { useEffect, useState } from 'react';
import { TimestampedSessionEvent } from 'wavepaths-shared/core';

import { FreudConnection } from '../../freudConnection/FreudConnection';
import { AllLatestEvents } from '../../freudConnection/InboundEvents/InboundEventManager.types';
// import { PendingBehaviour } from '../../freudConnection/PendingBehaviourTimer';

export type SessionState = {
    log: TimestampedSessionEvent[];
    // pendingBehaviours: PendingBehaviour[];
};

export const useRemoteSessionLogs = ({ connection }: { connection: FreudConnection | undefined }): SessionState => {
    const emptySessionState: SessionState = {
        log: [],
        // pendingBehaviours: [],
    };
    const [sessionState, setSessionState] = useState<SessionState>(emptySessionState);

    useEffect(() => {
        if (!!connection) {
            const handleLogUpdate = (log: TimestampedSessionEvent[]) => {
                setSessionState((s) => ({ ...s, log }));
            };
            connection.on(AllLatestEvents, handleLogUpdate);
            // const handlePendingBehavioursUpdate = (pendingBehaviours: PendingBehaviour[]) => {
            //     setSessionState((s) => ({ ...s, pendingBehaviours }));
            // };
            // connection.on('pendingBehavioursUpdate', handlePendingBehavioursUpdate);

            return () => {
                console.log('Removing logUpdate listeners');
                connection.removeListener(AllLatestEvents, handleLogUpdate);
                // connection.removeListener('pendingBehavioursUpdate', handlePendingBehavioursUpdate);
            };
        }
    }, [connection]);

    return sessionState;
};

export default useRemoteSessionLogs;
