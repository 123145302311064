import { range } from 'lodash';

type Point = { x: number; y: number };

export const findPathX = (at: number, points: Point[]) => {
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('d', pointsToSvgPath(points).d);
    const l = at < 0 ? path.getTotalLength() + at : at;
    const p = path.getPointAtLength(l);
    return p.x;
};

// https://stackoverflow.com/questions/7054272/how-to-draw-smooth-curve-through-n-points-using-javascript-html5-canvas
export const pointsToSvgPath = (points: Point[]) => {
    let d = `M ${points[0].x} ${points[0].y}`;

    let i = 0;
    if (points.length > 2) {
        for (i = 1; i < points.length - 2; i++) {
            const { x, y } = points[i];
            const { x: x1, y: y1 } = points[i + 1];
            const xc = (x + x1) / 2;
            const yc = (y + y1) / 2;
            d += ` Q ${x} ${y}, ${xc} ${yc}`;
        }
    }

    if (i < points.length - 1) {
        const { x, y } = points[i];
        const { x: x1, y: y1 } = points[i + 1];
        d += ` Q ${x} ${y}, ${x1} ${y1}`;
    }

    const closedPath = d + ` L ${points[i + 1].x + 1} ${40} L ${0} ${40} Z`;
    return { d, closedPath };
};

export const sampleSvgPathPoints: (d: string) => Point[] = (pathDef: string) => {
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('d', pathDef);
    return range(path.getTotalLength() + 1).map((d) => path.getPointAtLength(d));
};
