import '../../Guide.scss';

import React from 'react';
import { useParams } from 'react-router';
import { SessionState } from 'wavepaths-shared/core';

import { Typography } from '@/component-library';
import { GenericErrorBoundary } from '@/components/GenericErrorBoundary';
import { useThreadMonitor } from '@/hooks/useThreadMonitor';

import LoadingOrb from '../../common/components/LoadingOrb';
import { SessionContext } from './autoGuide/useSession';
import { Guide, GuideParams, RemoteSessionControlsContext, useRemoteSessionControls } from './Guide';

export const DISABLED_LABEL = 'This is disabled until you start your session';

export const GuideContainer: React.FC = () => {
    const { sessionId } = useParams<GuideParams>();

    const remoteSession = useRemoteSessionControls({ sessionId });
    useThreadMonitor({
        enabled: remoteSession.tick?.sessionState === SessionState.MAIN_PHASE,
    });

    return remoteSession.sessionError ? (
        <>Error</>
    ) : remoteSession.connection && remoteSession.session ? (
        <GenericErrorBoundary>
            <RemoteSessionControlsContext.Provider value={remoteSession}>
                <SessionContext.Provider value={remoteSession.session}>
                    <Guide remoteSession={remoteSession} />
                </SessionContext.Provider>
            </RemoteSessionControlsContext.Provider>
        </GenericErrorBoundary>
    ) : (
        // TODO: why does it have a different background color until it switches over to the guidewithconnection loader and
        <LoadingOrb>
            <Typography variant={'body3'}>
                Your session is being prepared. Please wait on this page; it will load as soon as it is ready.
            </Typography>
            <br />
            <Typography variant={'body3'}>
                If you are concerned about the loading time, please email us at{' '}
                <a href="mailto:support@wavepaths.com">support@wavepaths.com</a>
            </Typography>
        </LoadingOrb>
    );
};
