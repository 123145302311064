import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useContext, useState } from 'react';

// import useMySubscription from '@/hooks/useMySubscription';
import { AuthContext } from '../../auth';
import { getTrialPeriod } from '../../common/domain/subscriptions';
import { createCheckoutSession } from '../../common/util/stripeUtil';
import configs from '../../configs';

let stripePromise: Promise<Stripe | null> | undefined;

function useStripeCheckout(): {
    isSubscribing: boolean;
    error: string | undefined;
    handleSubscribe: (priceID: string) => Promise<void>;
    dismissError: () => void;
    upgradeDone: boolean;
} {
    const { firebaseUser, userData } = useContext(AuthContext);

    const [isSubscribing, setIsSubscribing] = useState(false);
    const [upgradeDone, setUpgradeDone] = useState(false);
    const [error, setSubscribingError] = useState<string | undefined>(undefined);
    const trialPeriod = getTrialPeriod();
    if (stripePromise === undefined) {
        stripePromise = loadStripe(configs.stripe.PUBLIC_KEY);
    }

    const handleSubscribe = async (priceId: string) => {
        if (!firebaseUser) throw new Error('No firebaseUser');
        if (!userData) throw new Error('No userData');
        setIsSubscribing(true);
        try {
            const response = await createCheckoutSession({
                priceId,
                customerId: userData.customerId,
                uid: firebaseUser.uid,
                email: firebaseUser.email!,
                ...(trialPeriod && { trialPeriodDays: trialPeriod }),
            });

            if (response?.sessionId) {
                const stripe = await stripePromise;
                if (stripe === undefined || stripe === null) throw new Error('No stripe');
                await stripe.redirectToCheckout({ sessionId: response?.sessionId });
            } else if (response?.status) {
                setUpgradeDone(true);
            } else {
                setSubscribingError("We're having trouble connecting to our payment provider, please try again.");
            }
        } catch (e) {
            setSubscribingError("We're having trouble connecting to our payment provider, please try again.");
            console.log(e);
        }
        setIsSubscribing(false);
    };

    return { isSubscribing, error, dismissError: () => setSubscribingError(undefined), handleSubscribe, upgradeDone };
}

export default useStripeCheckout;
