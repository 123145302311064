import configs from '../../configs';

export type StripeError = { error: { message: string } };

const headers = { 'Content-Type': 'application/json' };

interface ICreateCheckoutSession {
    priceId: string;
    uid: string;
    email: string;
    customerId?: string;
    trialEnd?: number;
    trialPeriodDays?: number;
}

export const createCheckoutSession = async ({
    priceId,
    customerId,
    uid,
    email,
    trialEnd,
    trialPeriodDays,
}: ICreateCheckoutSession): Promise<{ sessionId?: string; status?: 'done' } | undefined> => {
    try {
        const response = await fetch(`${configs.cloudFunctions.URL}/createStripeCheckoutSession`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                priceId,
                customerId,
                uid,
                email,
                ...(trialEnd && { trialEnd }),
                ...(trialPeriodDays && { trialPeriodDays }),
            }),
        });

        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.log(error);
    }
};

type CreateCustomerPortal = { uid?: string; customerId: string } | { uid: string; customerId?: string };

export const createCustomerPortal = async ({
    uid,
    customerId,
}: CreateCustomerPortal): Promise<{ url: string } | undefined> => {
    try {
        const response = await fetch(`${configs.cloudFunctions.URL}/createStripeCustomerPortal`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                uid,
                customerId,
            }),
        });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.log(error);
    }
};
