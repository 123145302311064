import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import classNames from 'classnames';
import map from 'lodash/map';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { Button, Typography, UserOfferingCard } from '@/component-library';
import { Features } from '@/features';
import useMySubscription from '@/hooks/useMySubscription';
import { useQueryParams } from '@/hooks/useQueryParams';

import { signOut, useAuthContext } from '../../auth';
import { ActiveBonnySubscription } from '../../common/api/types';
import Snackbar, { GlobalSnackbarContext } from '../../common/components/Snackbar';
import logoDrugScience from '../../images/logo-drug-science.png';
import logoFluence from '../../images/logo-fluence.png';
import logoImperial from '../../images/logo-imperial-college-london.png';
import logoMonash from '../../images/logo-monash.png';
import logoMcGill from '../../images/logo-university-mcgill.png';
import logoWashington from '../../images/logo-university-washington.png';
import { LayoutContainer } from '../../LayoutContainer';
import offeringsBreakdownPeriods, { prices } from './data/offerings';
import useStripeCheckout from './useStripeCheckout';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        // padding: '60px 24px 120px 24px',
        '& input[type=checkbox]': {
            height: '100%',
        },
        // background: '#f1f3f8',
        '& a': {
            color: '#000',
            ':active': {
                color: '#000',
            },
        },
    },
    header: {
        display: 'flex',
        width: '100%',
        maxWidth: '1200px',
        justifyContent: 'space-between',
    },
    flexRow: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    },
    section: {
        width: '100%',
        maxWidth: '1200px',
    },
    sectionWide: {
        width: '100%',
        maxWidth: '1362px',
    },
    periodToggle: {
        display: 'flex',
        alignItems: 'center',
    },
    priceCards: {
        marginTop: '20px',
        display: 'grid',
        justifyContent: 'center',
        gap: '16px',
        height: '100%',
        placeItems: 'center',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        },
    },
    smallPrint: {},
    trustedBy: {
        display: 'grid',
        gridAutoFlow: 'row',
        gap: '32px',
        textAlign: 'center',
    },
    logos: {
        display: 'grid',
        justifyItems: 'center',
        justifyContent: 'center',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'auto auto auto',
        gap: '8px 32px',
        [theme.breakpoints.between('md', 'lg')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateRows: 'auto auto',
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(6, 1fr)',
            gridTemplateRows: 'auto',
        },
    },
    logo: {
        height: '70px',
    },
    card: {
        padding: '24px',
        background: 'rgba(255, 255, 255, 0.7)',
        borderRadius: '16px',
    },
    trial: {},
    trialHeading: {
        marginBottom: '1rem',
    },
    testimonials: {},
    quote: {
        textAlign: 'center',
    },
    citation: {
        fontStyle: 'normal',
        textAlign: 'center',
        marginTop: '1em',
        color: 'rgba(0,0,0,0.5)',
    },
    switchThumb: {
        color: 'rgb(44, 57, 88)',
    },
    switchTrack: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
}));

const Subscriptions = (): React.ReactElement => {
    const [showAnnual, setShowAnnual] = useState(false);
    const { handleSubscribe, error, dismissError, upgradeDone } = useStripeCheckout();
    const { isPersonal, isEnabled, firebaseUser } = useAuthContext();

    const { subscription, isLoading: isLoadingMySubscription } = useMySubscription({ fbUser: firebaseUser });
    const [, setMembershipCacheKey] = useLocalStorage('membershipCacheKey', '');

    const activeSubscription = subscription as ActiveBonnySubscription;
    const isLoading = isLoadingMySubscription;
    const params = useQueryParams();
    const isChanging = params.has('changing');
    const { setSnackbarContent } = useContext(GlobalSnackbarContext);

    const history = useHistory();

    const classes = useStyles();

    const havePaidFeature = isEnabled(Features.FREE_ACCESS);

    useEffect(() => {
        //TODO a landing page for Personal use, for now just pointing to Stripe
        if (isPersonal && !havePaidFeature && !isLoading) {
            handleSubscribe(prices.personal.monthly.usd);
        }
    }, [isPersonal, havePaidFeature, isLoading]);

    useEffect(() => {
        if (upgradeDone) {
            setMembershipCacheKey(Date.now().toString());
            setSnackbarContent('Your subscription is now updated');
            history.push('/');
        }
    }, [upgradeDone]);

    const showGBP = false;

    if (!isLoading && havePaidFeature && !isChanging) {
        history.push('/');
    }

    if (isPersonal) {
        //TODO a landing page for Personal use, for now just redirecting to Stripe
        return <></>;
    }

    return (
        <>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <LayoutContainer>
                    <div className={classes.root}>
                        <header className={classes.header}>
                            <Typography variant="h3">Your Subscription</Typography>
                            <Button variant="outlined" size="s" onClick={signOut}>
                                Sign out
                            </Button>
                        </header>
                        <section className={classNames(classes.section, classes.card)}>
                            <div className={classes.trial}>
                                {isChanging ? (
                                    <>
                                        <Typography variant="subtitle2">
                                            Your new subscription will be proportianally adjusted in the next billing
                                            period. You can change or cancel any time.
                                        </Typography>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </section>
                        <section className={classes.section}>
                            <div className={classes.flexRow}>
                                <div className={classes.periodToggle}>
                                    <Typography variant="subtitle2">Monthly</Typography>
                                    <Switch
                                        classes={{ thumb: classes.switchThumb, track: classes.switchTrack }}
                                        checked={showAnnual}
                                        size="medium"
                                        onChange={() => setShowAnnual((showAnnual) => !showAnnual)}
                                    />
                                    <Typography variant="subtitle2">
                                        Yearly <span style={{ fontSize: 'smaller' }}>(20% discount)</span>
                                    </Typography>
                                </div>
                            </div>
                        </section>
                        <section className={classNames(classes.section, classes.priceCards)}>
                            {map(offeringsBreakdownPeriods(handleSubscribe, showGBP), (o, k) => {
                                const offeringProps = showAnnual ? o.annual : o.monthly;
                                const highlighted = o.highlighted;

                                return (
                                    <div key={k}>
                                        <UserOfferingCard
                                            {...offeringProps}
                                            ctaCopy={
                                                activeSubscription && activeSubscription.tier == offeringProps.tier
                                                    ? 'Current'
                                                    : 'Select'
                                            }
                                            highlighted={highlighted}
                                            ctaDisabled={
                                                activeSubscription && activeSubscription.tier == offeringProps.tier
                                            }
                                            footerContent={
                                                <Typography variant="body2">
                                                    Compare our plans in detail{' '}
                                                    <a href="https://wavepaths.com/music-for-care-providers#wavepaths-pricing">
                                                        here
                                                    </a>
                                                    .
                                                </Typography>
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </section>
                        <section className={classNames(classes.section, classes.smallPrint)}>
                            <Typography variant="subtitle2">
                                If you manage multiple clinics, please{' '}
                                <a href="mailto:support@wavepaths.com">contact us</a> to discuss your requirements.
                            </Typography>
                        </section>
                        <section className={classNames(classes.sectionWide, classes.trustedBy)}>
                            <Typography variant="h5">Wavepaths is trusted by</Typography>
                            <div className={classes.logos}>
                                <img className={classes.logo} src={logoImperial} alt="Imperial College London logo" />
                                <img className={classes.logo} src={logoMonash} alt="Monash Univeristy logo" />
                                <img className={classes.logo} src={logoMcGill} alt="McGill University logo" />
                                <img
                                    className={classes.logo}
                                    src={logoWashington}
                                    alt="University of Washington logo"
                                />
                                <img className={classes.logo} src={logoFluence} alt="Fluence logo" />
                                <img className={classes.logo} src={logoDrugScience} alt="Drug Science logo" />
                            </div>
                        </section>
                        <Snackbar
                            type="error"
                            isLongButton={true}
                            message={error ?? ''}
                            confirmText={'OK'}
                            open={error !== undefined}
                            closeSnackbar={dismissError}
                        />
                    </div>
                </LayoutContainer>
            )}
        </>
    );
};

export default Subscriptions;
