import * as history from 'history';
import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import LoadingOrb from '@/components/LoadingOrb';

// import useMySubscription from '@/hooks/useMySubscription';
import { useAuthContext } from './auth';
import events from './UserEvents';

export function RedirectWrapper({
    children,
    location,
}: {
    children: React.ReactNode;
    location: history.Location<history.History.PoorMansUnknown>;
}) {
    const searchParams = new URLSearchParams(location.search);
    const [, setDefaultName] = useLocalStorage<string>('name', '');
    const [, setDefaultClientId] = useLocalStorage<string | undefined>('client_id', undefined);
    const [, setDefaultEmail] = useLocalStorage<string>('email', '');
    useEffect(() => {
        if (searchParams.has('name')) {
            setDefaultName(searchParams.get('name') || '');
        }

        if (searchParams.has('client_id')) {
            setDefaultClientId(searchParams.get('client_id') || '');
        }

        if (searchParams.has('email')) {
            setDefaultEmail(searchParams.get('email') || '');
        }
    }, []);

    return <>{children}</>;
}

export function PrivateRoute({ children, ...rest }: RouteProps) {
    const { userData } = useAuthContext();

    useEffect(() => {
        if (userData) {
            events.identify(userData);
        }
    }, [userData]);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                location ? (
                    userData ? (
                        children
                    ) : (
                        <RedirectWrapper location={location}>
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: { from: location },
                                }}
                            />
                        </RedirectWrapper>
                    )
                ) : (
                    <LoadingOrb />
                )
            }
        />
    );
}
