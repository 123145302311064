import styled from '@emotion/styled';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { SessionRenderType, SessionState } from 'wavepaths-shared/core';

import { Button } from '@/component-library';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { useRemoteElapsedTimeSecs, useRemoteSessionState } from '@/hooks';

// import { GlobalSnackbarContext } from '@/components/Snackbar';
// import { useSessionLink } from '@/util/broadcastLinkUtils';
import { AudioPlayerContext } from '../../sessions/EndedSessionsTable/useAudioPlayer';
import { ChoosePlayerDialog, PlayerChoiceContext } from '../ChoosePlayer';
import { RemoteSessionControlsContext } from '../Guide';
import ShareSessionDialog from '../ShareSessionDialog';
interface IStartEndSessionButtonProps {
    onStartSession: () => void;
    onEndSession: () => void;
    broadcastIdentifier: string;
}

export const START_LABEL = 'Start Session';
export const StartButton = ({ onStartSession }: { onStartSession: () => void }) => {
    const [clicked, setClicked] = useState(false);

    const handleStartSession = () => {
        setClicked(true);
        onStartSession();
    };

    return (
        <Button
            className="tour-startSession"
            variant="solid-blue"
            size="l"
            disabled={clicked}
            onClick={handleStartSession}
        >
            {START_LABEL}
        </Button>
    );
};

export const END_LABEL = 'End Session';
const EndButton = ({ onEndSession }: { onEndSession: () => void }) => {
    const [clicked, setClicked] = useState(false);

    const handleEndSession = () => {
        setClicked(true);
        onEndSession();
    };

    return (
        <Button variant="solid-blue" size="l" disabled={clicked} onClick={handleEndSession}>
            {END_LABEL}
        </Button>
    );
};

const useStyles = makeStyles({
    container: {
        display: 'inline-grid',
        placeContent: 'center',
        gap: '10px',
    },
});

const StartContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export default function StartEndSessionButton({
    onStartSession,
    onEndSession,
    broadcastIdentifier,
}: IStartEndSessionButtonProps): JSX.Element {
    const classes = useStyles();
    const remoteSession = useContext(RemoteSessionControlsContext);
    if (!remoteSession) {
        throw new Error('no RemoteSessionControlsContext');
    }
    const isPrelude =
        remoteSession.tick && [SessionState.PRELUDE, SessionState.PLANNED].includes(remoteSession.tick.sessionState);
    const isPostlude =
        remoteSession.tick && [SessionState.POSTLUDE, SessionState.ENDED].includes(remoteSession.tick.sessionState);
    const isEnded = remoteSession.tick && remoteSession.tick.sessionState === SessionState.ENDED;
    const { paused } = useRemoteSessionState();

    const [showShareSessionDialog, setShowShareSessionDialog] = useState(false);

    const [dismissedPlayerChoice, setDismissedPlayerChoice] = useState<boolean>(false);

    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('no PlayerChoiceContext');

    useEffect(() => {
        if (
            playerChoiceContext.whereToPlayMusic == 'remoteOnly' ||
            playerChoiceContext.whereToPlayMusic == 'thisAndRemote'
        ) {
            setShowShareSessionDialog(true);
        }
    }, [playerChoiceContext.whereToPlayMusic]);

    const audioPlayer = useContext(AudioPlayerContext);
    const audioLoaded = useRef(false);

    const unblockAndSetWhereToPlayMusic = async (
        ...args: Parameters<typeof playerChoiceContext.setWhereToPlayMusic>
    ) => {
        playerChoiceContext.setWhereToPlayMusic(...args);
    };

    const elapsedTimeSecs = useRemoteElapsedTimeSecs();

    useEffect(() => {
        (async () => {
            if (!audioLoaded.current) return;

            if (paused) {
                audioPlayer?.actions.pause({ reason: 'user' });
            } else {
                await audioPlayer?.actions.unblock();
                audioPlayer?.actions.play({ offsetSeconds: elapsedTimeSecs });
            }
        })();
    }, [paused]);

    useEffect(() => {
        (async () => {
            console.debug('Init playback', {
                sessionInitialised: remoteSession.sessionInitialised,
                whereToPlayMusic: playerChoiceContext.whereToPlayMusic,
                sessionStarted: remoteSession.sessionStarted,
                audioLoaded: audioLoaded.current,
                isPostlude,
                paused,
            });
            if (
                playerChoiceContext.whereToPlayMusic !== 'remoteOnly' &&
                playerChoiceContext.playerChoice === 'browser'
            ) {
                if (!isPostlude) {
                    if (remoteSession.sessionInitialised && !audioLoaded.current) {
                        (async () => {
                            if (!remoteSession.sessionStarted) {
                                console.debug('Playing prelude...');
                                await audioPlayer?.actions.unblock();
                                if (remoteSession.session?.renderType === SessionRenderType.REAL_TIME) {
                                    //For real-time prelude is part of the main record
                                    audioPlayer?.actions.play();
                                } else {
                                    audioPlayer?.actions.playPreludePostlude();
                                }
                            } else {
                                if (!paused) {
                                    await audioPlayer?.actions.unblock();
                                    audioPlayer?.actions.play();
                                }
                            }
                            audioLoaded.current = true;
                        })();
                    }
                    if (audioLoaded.current && remoteSession.sessionStarted) {
                        if (remoteSession.session?.renderType === SessionRenderType.REAL_TIME) {
                            //noop as its already part of the main score
                        } else {
                            if (!paused) {
                                console.debug('Playing main phase...');
                                if (audioPlayer === undefined) throw new Error('No audioPlayer');
                                await audioPlayer?.actions.unblock();
                                audioPlayer?.actions.play();
                            }
                        }
                    }
                } else {
                    if (remoteSession.session?.renderType === SessionRenderType.REAL_TIME) {
                        //noop as the postlude is part of the main score
                    } else {
                        console.debug('Playing postlude...');
                        audioPlayer?.actions.pause({ fadeMs: 30 * 1000, reason: 'sessionEnd' });
                        audioPlayer?.actions.playPreludePostlude();
                    }
                }
            }
        })();
    }, [
        remoteSession.sessionInitialised,
        playerChoiceContext.whereToPlayMusic,
        remoteSession.sessionStarted,
        audioLoaded.current,
        isPostlude,
        playerChoiceContext.playerChoice,
    ]);

    const showContainer =
        isPostlude ||
        isPrelude ||
        !playerChoiceContext.whereToPlayMusic ||
        (playerChoiceContext.whereToPlayMusic !== 'remoteOnly' &&
            playerChoiceContext.playerChoice !== 'browser' &&
            !dismissedPlayerChoice);

    return (
        <>
            {showContainer && (
                <div className={classes.container}>
                    {isPostlude && !isEnded && <EndButton onEndSession={onEndSession} />}

                    {(isPrelude || !playerChoiceContext.whereToPlayMusic) && (
                        <StartContainer>
                            {playerChoiceContext.whereToPlayMusic !== undefined && isPrelude ? (
                                <>
                                    <StartButton onStartSession={onStartSession} />
                                    <Button
                                        variant="clear-underlined"
                                        size="m"
                                        onClick={() => setShowShareSessionDialog(true)}
                                    >
                                        Streaming Devices
                                    </Button>
                                </>
                            ) : (
                                <></>
                            )}
                            {playerChoiceContext.whereToPlayMusic === undefined && (
                                <>
                                    <TypographyV2 element="h3">Play music on:</TypographyV2>
                                    <Button
                                        variant="solid-blue"
                                        size="l"
                                        onClick={() => unblockAndSetWhereToPlayMusic('thisDevice')}
                                    >
                                        This device
                                    </Button>
                                    <Button
                                        variant="solid-blue"
                                        size="l"
                                        onClick={() => unblockAndSetWhereToPlayMusic('remoteOnly')}
                                    >
                                        Remote devices
                                    </Button>
                                    <Button
                                        variant="solid-blue"
                                        size="l"
                                        onClick={() => unblockAndSetWhereToPlayMusic('thisAndRemote')}
                                    >
                                        This device and remote devices
                                    </Button>
                                </>
                            )}
                        </StartContainer>
                    )}

                    {playerChoiceContext.whereToPlayMusic !== undefined &&
                        playerChoiceContext.whereToPlayMusic !== 'remoteOnly' &&
                        playerChoiceContext.playerChoice !== 'browser' &&
                        !dismissedPlayerChoice && (
                            <>
                                <StartContainer>
                                    <ChoosePlayerDialog
                                        broadcastIdentifier={broadcastIdentifier}
                                        renderType={remoteSession.session!.renderType}
                                        onSetPlayerChoice={playerChoiceContext.setPlayerChoice}
                                        onClose={() => setDismissedPlayerChoice(true)}
                                    />
                                </StartContainer>
                            </>
                        )}
                </div>
            )}
            {showShareSessionDialog && (
                <>
                    <ShareSessionDialog
                        sessionId={remoteSession.session!.id}
                        broadcastIdentifier={broadcastIdentifier}
                        isOpen={showShareSessionDialog}
                        onClose={() => setShowShareSessionDialog(false)}
                        defaultVersion={
                            remoteSession.session?.renderType === SessionRenderType.PREDICTIVE_COMPOSED
                                ? 'beta'
                                : undefined
                        }
                    />
                </>
            )}
        </>
    );
}
