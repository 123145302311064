import styled from '@emotion/styled';
import { useTheme } from '@material-ui/core/styles';
import * as EmailValidator from 'email-validator';
import React, { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, Typography } from '@/component-library';

import * as auth from '../../auth';
import Logo from '../../images/WavepathBlueLogo.svg';

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    paddingBottom: '8px',
    color: '#2c3958',
});

const Main = styled.main({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '16px',
    paddingTop: '16px',
    paddingBottom: '24px',
});

const Container = styled.div({
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    display: 'grid',
    placeContent: 'center',
    padding: '40px 0',
    background: '#F1F3F8',
});

const Card = styled.div({
    width: '90vw',
    maxWidth: '400px',
    background: 'rgba(255,255,255,0.5)',
    border: '1px solid white',
    borderRadius: '4px',
    paddingTop: '32px',
    paddingBottom: '16px',
    paddingLeft: '32px',
    paddingRight: '32px',
    boxShadow: '0px 0px 20px #CFD6E7',
});

const StyledHeader = styled.header({
    display: 'grid',
    placeContent: 'center',
    paddingBottom: 8,
});

const StyledLogo = styled.img({
    height: '25px',
});

const Form = styled.form({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '16px',
});

const InputGroup = styled.div({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '8px',
});

const InputLabel = styled.label({
    fontSize: '14px',
    fontWeight: 500,
    color: '#2C3958',
    marginBottom: 0,
});

const StyledInput = styled.input({
    height: '44px',
    padding: '0 16px',
    background: 'transparent',
    border: '1px solid #6980B4',
    borderRadius: '4px',
    fontSize: '16px',
    color: '#2C3958',
    '&:focus': {
        borderColor: '#2C3958',
    },
});

const LoginError = styled.div<{ backgroundColor: string }>(({ backgroundColor }) => ({
    backgroundColor,
    color: 'white',
    padding: '10px',
    borderRadius: '4px',
    '& a': {
        color: 'white',
        textDecoration: 'underline',
    },
}));

const LoginSuccess = styled.div<{ backgroundColor: string }>(({ backgroundColor }) => ({
    backgroundColor,
    color: 'white',
    padding: '10px',
    borderRadius: '4px',
}));

const Footer = styled.footer({
    padding: '0 0 16px 0',
    color: '#2C3958',
});

const FooterTypography = styled(Typography)({
    textAlign: 'center',
    color: '#2c3958',
});

const FooterLink = styled(Link)({
    color: '#2C3958',
});

const ResetPassword = (): JSX.Element => {
    const [email, setEmail] = useState('');
    const [state, setState] = useState<auth.ResetPasswordStatus | 'init' | 'validationFailed' | 'resetting'>('init');

    const onSubmit = (evt: FormEvent) => {
        evt.preventDefault();
        if (!EmailValidator.validate(email)) {
            setState('validationFailed');
        } else {
            setState('resetting');
            auth.resetPassword(email).then(setState);
        }
    };

    const theme = useTheme();

    return (
        <Container>
            <Card>
                <StyledHeader>
                    <StyledLogo src={Logo} alt="Wavepaths" />
                </StyledHeader>
                <Main>
                    <StyledTypography variant="body2">
                        Enter the email address linked to your Wavepaths account to request a new password.
                    </StyledTypography>
                    {state === 'success' && (
                        <LoginSuccess backgroundColor={theme.palette.success.main}>
                            <Typography variant="body3" color="inherit">
                                We have sent you an email with instructions to reset your password.
                            </Typography>
                        </LoginSuccess>
                    )}
                    {state === 'validationFailed' && !EmailValidator.validate(email) && (
                        <LoginError backgroundColor={theme.palette.error.main}>
                            <Typography variant="body3" color="inherit">
                                Please enter a valid email address.
                            </Typography>
                        </LoginError>
                    )}
                    {state === 'userNotFound' && (
                        <LoginError backgroundColor={theme.palette.error.main}>
                            <Typography variant="body3" color="inherit">
                                Sorry, we can't find a user account with those details.
                            </Typography>
                        </LoginError>
                    )}
                    {state === 'invalidEmail' && (
                        <LoginError backgroundColor={theme.palette.error.main}>
                            <Typography variant="body3" color="inherit">
                                Sorry, there was a problem with this email address.
                            </Typography>
                        </LoginError>
                    )}
                    {state === 'error' && (
                        <LoginError backgroundColor={theme.palette.error.main}>
                            <Typography variant="body3" color="inherit">
                                Sorry, it looks like something went wrong during sign-up. Please try again or send us a
                                message at <a href="mailto:support@wavepaths.com">support@wavepaths.com</a>.
                            </Typography>
                        </LoginError>
                    )}
                    <Form onSubmit={onSubmit}>
                        <InputGroup>
                            <InputLabel htmlFor="email">Email address</InputLabel>
                            <StyledInput
                                id="email"
                                aria-label="email"
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </InputGroup>
                        <Button
                            type="submit"
                            variant="solid-blue"
                            size="m"
                            style={{ borderRadius: '50px', backgroundColor: '#2C3958' }}
                        >
                            Reset password
                        </Button>
                    </Form>
                </Main>
                <Footer>
                    <FooterTypography variant="body3">
                        Arrived here by mistake? Head to our <FooterLink to="/login">login page</FooterLink> instead.
                    </FooterTypography>
                </Footer>
            </Card>
        </Container>
    );
};

export default ResetPassword;
