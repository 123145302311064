import EventEmitter from 'events';
import { some } from 'lodash';
import { InboundSessionEvent, TimestampedSessionEvent } from 'wavepaths-shared/core';
import { getLayerContentDetails } from 'wavepaths-shared/domain/layerContent';

import { AllLatestEvents, NewEventEmittedEvent, SessionEventsReplayedEvent } from './InboundEventManager.types';

export class InboundEventManager extends EventEmitter {
    private eventDictionary = new Map<string, InboundSessionEvent>();

    public handleSessionEvent(event: InboundSessionEvent) {
        this.storeEvent(event);
        if (this.isLatestEvent(event)) {
            this.sendEvent(event.event, false);
            this.handleCompleteEvents();
            this.logCertainEvents([event.event]);
        }
    }

    public handleEventReplay(events: InboundSessionEvent[]) {
        this.storeEvents(events);
        this.processReplayEvents();
        this.handleCompleteEvents();
        this.logCertainEvents(events.map((e) => e.event));
        this.emit(SessionEventsReplayedEvent);
    }

    private storeEvents(events: InboundSessionEvent[]) {
        for (const event of events) {
            this.storeEvent(event);
        }
    }

    private storeEvent(event: InboundSessionEvent) {
        const eventType = event.event.event;
        const index = event.index;

        if (this.eventDictionary.has(eventType)) {
            const existingIndex = this.eventDictionary.get(eventType)!.index;
            if (index < existingIndex) {
                return;
            }
        }

        this.eventDictionary.set(eventType, event);
    }

    private getCompleteEvents() {
        return [...this.eventDictionary.values()].map((p) => p.event);
    }

    private isLatestEvent(event: InboundSessionEvent): boolean {
        const key = event.event.event;
        const index = event.index;
        return this.eventDictionary.has(key) && this.eventDictionary.get(key)!.index === index;
    }

    private processReplayEvents() {
        const completeEvents = this.getCompleteEvents();
        completeEvents.forEach((e) => {
            this.sendEvent(e, true);
        });
    }

    private sendEvent(event: TimestampedSessionEvent, isReplay: boolean) {
        this.emit(NewEventEmittedEvent, event, isReplay);
    }

    private handleCompleteEvents() {
        // This feels like a code smell, we send the separate events, so the rest of the app does not have to subscribe to this.
        // something for a later refactor.
        this.emit(AllLatestEvents, this.getCompleteEvents());
    }

    private logCertainEvents(events: TimestampedSessionEvent[]) {
        if (some(events, (e) => e.event === 'addStageContent')) {
            console.table(getLayerContentDetails(events));
        }
    }
}
