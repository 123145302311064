import { styled } from '@material-ui/core/styles';
import React from 'react';

import { EvaIcon } from '@/component-library';

import IconButton from '../../../common/components/IconButton';
import { UseInstrumentRefreshR } from './useInstrumentRefresh';

const RefreshButton = styled(IconButton)({
    //maxWidth: 'none',
    //maxHeight: 'none',
    width: 42,
    height: 36,
    //border: 'none',
});

export const InstrumentRefreshButton = ({
    instrumentRefreshArgs,
}: {
    instrumentRefreshArgs: UseInstrumentRefreshR;
}): React.ReactElement => {
    if (instrumentRefreshArgs.state !== 'ready') {
        return <></>;
    }
    return (
        <RefreshButton
            onClick={instrumentRefreshArgs.handleIconButtonClick}
            icon={<EvaIcon name="options-2-outline" size={22} fill="#2C3958" />}
            aria-label="Refresh Instruments"
            className="tour-instrumentRefreshButton"
            variant="outlined"
        />
    );
};
