import { Environment as ItlyEnvironment } from '@itly/sdk';

const firebase = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSEGING_ID,
    appId: process.env.FIREBASE_APP_ID,
};

const stripe = {
    PUBLIC_KEY: process.env.STRIPE_PUBLIC_KEY ?? '',
    SUBSCRIPTION_PRICE_ID: process.env.STRIPE_SUBSCRIPTION_PRICE_ID ?? '',
};

const cloudFunctions = {
    URL: process.env.CLOUD_FUNCTIONS_ENDPOINT_URL,
};

const googleAnalytics = {
    TRACKING_CODE: process.env.GA_TRACKING_CODE,
};

enum FreudEnvironment {
    prod = 'prod',
    staging = 'staging',
    dev = 'dev',
    'dev-local' = 'dev-local',
}

const getEnv = (env?: string): FreudEnvironment => {
    return FreudEnvironment[env as keyof typeof FreudEnvironment] ?? FreudEnvironment['dev-local'];
};

const freud = {
    ENV: getEnv(process.env.FREUD_ENV),
    STREAM_BASE: process.env.FREUD_STREAM_BASE,
    BUFFER_STALL_FALLBACK_MUSIC:
        process.env.BUFFER_STALL_FALLBACK_MUSIC ??
        {
            dev: 'https://freud-streams-dev.wavepaths.com/fallback/prelude_postlude_30m.mp3',
            staging: 'https://freud-streams-dev.wavepaths.com/fallback/prelude_postlude_30m.mp3',
            prod: 'https://freud-streams.wavepaths.com/fallback/prelude_postlude_30m.mp3',
            'dev-local': 'https://freud-streams-dev.wavepaths.com/fallback/prelude_postlude_30m.mp3',
        }[getEnv(process.env.FREUD_ENV)],
    PRELUDE_POSTLUDE_MUSIC:
        process.env.PRELUDE_POSTLUDE_MUSIC ??
        {
            dev: 'https://freud-streams-dev.wavepaths.com/fallback/prelude_postlude_30m.mp3',
            staging: 'https://freud-streams-dev.wavepaths.com/fallback/prelude_postlude_30m.mp3',
            prod: 'https://freud-streams.wavepaths.com/fallback/prelude_postlude_30m.mp3',
            'dev-local': 'https://freud-streams-dev.wavepaths.com/fallback/prelude_postlude_30m.mp3',
        }[getEnv(process.env.FREUD_ENV)],
    FREE_ACCOUNT_VO:
        process.env.FREE_ACCOUNT_VO ??
        {
            dev: 'https://freud-streams-dev.wavepaths.com/fallback/FreeAccountVOTrimmed.mp3',
            staging: 'https://freud-streams-dev.wavepaths.com/fallback/FreeAccountVOTrimmed.mp3',
            prod: 'https://freud-streams.wavepaths.com/fallback/FreeAccountVOTrimmed.mp3',
            'dev-local': 'https://freud-streams-dev.wavepaths.com/fallback/FreeAccountVOTrimmed.mp3',
        }[getEnv(process.env.FREUD_ENV)],
    BASE_URL:
        process.env.FREUD_BASE_URL ??
        {
            dev: 'https://freud-dev.wavepaths.com',
            staging: 'https://freud-staging.wavepaths.com',
            prod: 'https://freud.wavepaths.com',
            'dev-local': 'http://localhost:8080',
        }[getEnv(process.env.FREUD_ENV)],
    CUSTOM_VOICEOVERS_PREVIEW_BASE_URL:
        process.env.CUSTOM_VOICEOVERS_BASE_URL ??
        {
            dev: 'https://freud-dev-sampledata.wavepaths.com/custom_voiceovers/',
            staging: 'https://freud-dev-sampledata.wavepaths.com/custom_voiceovers/',
            prod: 'https://freud-prod-sampledata.wavepaths.com/custom_voiceovers/',
            'dev-local': 'https://freud-dev-sampledata.wavepaths.com/custom_voiceovers/',
        }[getEnv(process.env.FREUD_ENV)],
};

const sentry = {
    ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
};
const iteratively: { ENVIRONMENT: ItlyEnvironment; MIXPANEL_HOST: string } = {
    ENVIRONMENT: process.env.ITERATIVELY_ENVIRONMENT === 'production' ? 'production' : 'development',
    MIXPANEL_HOST:
        process.env.MIXPANEL_HOST ??
        {
            dev: 'https://mixpanel-dev.wavepaths.com',
            staging: 'https://mixpanel-dev.wavepaths.com',
            prod: 'https://mixpanel.wavepaths.com',
            'dev-local': 'https://mixpanel-dev.wavepaths.com',
        }[getEnv(process.env.FREUD_ENV)],
};

const website = {
    BASE_URL: process.env.WEBSITE_BASE_URL,
};

const bonny = {
    BASE_URL:
        process.env.BONNY_BASE_URL ??
        window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : ''),
};

const playerWebsite = {
    BASE_URL: process.env.PLAYER_WEBSITE_BASE_URL,
};

const features = {
    BASE_URL: process.env.FEATURE_SERVICE_BASE_URL ?? cloudFunctions.URL + '/features',
};

const googleTagManager = {
    ID: process.env.GTM_ID ?? 'GTM-N4NMP4V',
};

const intercom = {
    APP_ID: process.env.INTERCOM_APP_ID ?? 'wvfo0br8',
};

const templateRecommender = {
    BASE_URL: process.env.TEMPLATE_RECOMMENDER_URL,
};

export default {
    firebase,
    website,
    playerWebsite,
    stripe,
    cloudFunctions,
    googleAnalytics,
    freud,
    sentry,
    features,
    googleTagManager,
    iteratively,
    intercom,
    templateRecommender,
    bonny,
};
