import { CoreEmotionalAtmosphere } from 'wavepaths-shared/core';
import { convertVaToCea } from 'wavepaths-shared/domain/wavepath';

// old colours for ref
// const ceaColour: { [cea in CoreEmotionalAtmosphere]: string } = {
//     [CoreEmotionalAtmosphere.STILLNESS]: '#90CE6F',
//     [CoreEmotionalAtmosphere.BITTERSWEET]: '#81B6EA',
//     [CoreEmotionalAtmosphere.TENSION]: '#DD5059',
//     [CoreEmotionalAtmosphere.VITALITY]: '#FFD502',
//     [CoreEmotionalAtmosphere.SILENCE]: '#DFECDB',
// };

const ceaColour: { [cea in CoreEmotionalAtmosphere]: string } = {
    [CoreEmotionalAtmosphere.STILLNESS]: '#9AE4AA',
    [CoreEmotionalAtmosphere.BITTERSWEET]: '#8E9AE6',
    [CoreEmotionalAtmosphere.TENSION]: '#DD5059',
    [CoreEmotionalAtmosphere.VITALITY]: '#FFDD00',
    [CoreEmotionalAtmosphere.SILENCE]: '#DFECDB',
};

export function getCEAColour(cea: CoreEmotionalAtmosphere) {
    return ceaColour[cea];
}

export function getCEAColourForValenceArousal(valence: number, arousal: number) {
    const cea = convertVaToCea(valence, arousal);
    const colour = getCEAColour(cea);
    return colour;
}

const addAlphaToHex = (hexColour: string, alpha: number) => hexColour + alpha;

export function getColourForValenceArousalAndStage(valence: number, arousal: number, stage: number | string) {
    const colour = getCEAColourForValenceArousal(valence, arousal);
    return Number(stage) === 0 ? addAlphaToHex(colour, 30) : colour;
}
