import React, { useState } from 'react';
import {
    Acousticness,
    CoreEmotionalAtmosphere,
    PathScore,
    PathScoreModes,
    ScheduledExperimentalPathScore,
    ScheduledGenerativePathScore,
    ScheduledWavepath,
    SootheModes,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { DropdownControl, SegmentedControl } from '@/component-library';

import { usePathScoreDurationSelector } from '.';
import * as infoCopy from './infoCopy';
import * as pathScoreFns from './pathScoreFns';

export interface SelectorState {
    mode?: PathScoreModes;
    emotion?: CoreEmotionalAtmosphere;
    intensity?: string;
    tone?: Acousticness;
    ambience?: string;
    music?: string;
    duration: number | string;
}

const selectorStateDefault: SelectorState = {
    mode: 'Unstructured',
    emotion: CoreEmotionalAtmosphere.STILLNESS,
    intensity: 'Low',
    tone: 'Mixed',
    ambience: 'None',
    music: 'Any',
    duration: pathScoreFns.AUTO_VALUE,
};

export type SoothePathScore = (ScheduledGenerativePathScore | ScheduledExperimentalPathScore) & {
    therapeuticDirection: TherapeuticDirection.SOOTHE;
    mode: SootheModes;
};

interface SootheWaveSelectorProps {
    pathScores: SoothePathScore[];
    wavepath: ScheduledWavepath;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
}

export const isSoothePathScore = (p: PathScore): p is SoothePathScore => p.direction === TherapeuticDirection.SOOTHE;

function SootheWaveSelector({ pathScores, wavepath, onUpdateWave }: SootheWaveSelectorProps): JSX.Element {
    const [emotion, setEmotion] = useState(
        typeof wavepath.pathScore.emotion == 'string' ? wavepath.pathScore.emotion : selectorStateDefault.emotion,
    );
    const emotionFilteredScores = pathScoreFns.filterScoresByEmotion(pathScores, emotion);

    const defaultMode = wavepath.pathScore.mode ?? selectorStateDefault.mode;
    const [mode, setMode] = useState(defaultMode);
    const isStructured = mode === 'Structured';
    const modeFilteredScores = pathScoreFns.filterScoresByMode(emotionFilteredScores, mode);

    const defaultIntensity = wavepath.pathScore.selectionCriteria?.emotionalIntensity ?? selectorStateDefault.intensity;
    const [intensity, setIntensity] = useState(defaultIntensity);
    const intensityFilteredScores = pathScoreFns.filterScoresByIntensity(modeFilteredScores, intensity);

    const defaultTone =
        'acousticness' in wavepath.pathScore ? wavepath.pathScore.acousticness : selectorStateDefault.tone;
    const [tone, setTone] = useState(defaultTone);
    const toneColourFilteredScores = pathScoreFns.filterScoresByTone(intensityFilteredScores, tone);

    const defaultAmbience = wavepath.pathScore.selectionCriteria?.emotionalIntensity ?? selectorStateDefault.intensity;
    const [ambience, setAmbience] = useState(defaultAmbience);
    const ambienceFilteredScores = pathScoreFns.filterScoresByAmbience(intensityFilteredScores, ambience);

    const defaultMusic = 'music' in wavepath.pathScore ? wavepath.pathScore.music : selectorStateDefault.music;
    const [music, setMusic] = useState(defaultMusic);
    const musicFilteredScores = pathScoreFns.filterScoresByMusic(ambienceFilteredScores, music);

    const preDurationFilteredScores = isStructured ? toneColourFilteredScores : musicFilteredScores;

    if (!emotionFilteredScores.length) {
        setEmotion(undefined);
    }

    if (!modeFilteredScores.length) {
        setMode(undefined);
    }

    if (!intensityFilteredScores.length && modeFilteredScores[0]) {
        setIntensity(modeFilteredScores[0].selectionCriteria?.emotionalIntensity);
    }

    if (isStructured && !toneColourFilteredScores.length && intensityFilteredScores[0]) {
        //@ts-ignore TODO
        setTone(intensityFilteredScores[0].acousticness || selectorStateDefault.tone);
    }

    if (!isStructured && !ambienceFilteredScores.length && intensityFilteredScores[0]) {
        //@ts-ignore TODO
        setAmbience(intensityFilteredScores[0].ambience || selectorStateDefault.ambience);
    }

    if (!isStructured && !musicFilteredScores.length && ambienceFilteredScores[0]) {
        //@ts-ignore TODO
        setMusic(ambienceFilteredScores[0].music || selectorStateDefault.music);
    }

    const { durationOptions, preferredDuration, setPreferredDuration } = usePathScoreDurationSelector({
        wavepath,
        onUpdateWave,
        preDurationFilteredScores,
        dependencies: [emotion, tone, ambience, intensity, music],
    });

    return (
        <>
            <SegmentedControl
                name="wave-emotion"
                heading={'Emotionality'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    [
                        CoreEmotionalAtmosphere.STILLNESS,
                        CoreEmotionalAtmosphere.VITALITY,
                        CoreEmotionalAtmosphere.BITTERSWEET,
                        CoreEmotionalAtmosphere.TENSION,
                    ],
                    pathScoreFns.extractEmotionsFromScores(pathScores),
                )}
                value={emotion}
                onChange={setEmotion}
                info={infoCopy.emotion}
            />
            <SegmentedControl
                name="wave-mode"
                heading={'Mode'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    ['Unstructured', 'Structured'] as PathScoreModes[],
                    pathScoreFns.extractModesFromScores(pathScores),
                )}
                value={mode}
                onChange={setMode}
                info={infoCopy.sootheMode}
            />
            <SegmentedControl
                name="wave-intensity"
                heading={'Intensity'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    ['Low', 'Medium'],
                    pathScoreFns.extractIntensitiesFromScores(modeFilteredScores),
                )}
                value={intensity}
                onChange={setIntensity}
                info={infoCopy.intensity}
            />
            {isStructured ? (
                <SegmentedControl
                    name="wave-tone"
                    heading={'Tone'}
                    canSave={false}
                    size="small"
                    colour="dark"
                    options={pathScoreFns.convertListToOptions(
                        ['Acoustic', 'Mixed', 'Electronic'] as Acousticness[],
                        pathScoreFns.extractToneColoursFromScores(
                            intensityFilteredScores as ScheduledExperimentalPathScore[],
                        ),
                    )}
                    value={tone}
                    onChange={setTone}
                    info={infoCopy.ambience}
                />
            ) : (
                <>
                    <SegmentedControl
                        name="wave-nature"
                        heading={'Ambience'}
                        canSave={false}
                        size="small"
                        colour="dark"
                        options={[
                            {
                                label: 'None',
                                value: 'None',
                                disabled: false,
                            },
                            ...[...pathScoreFns.extractNatureSoundsFromScore(intensityFilteredScores)].map(
                                (item: string) => ({
                                    label: item,
                                    value: item,
                                    disabled: false,
                                }),
                            ),
                        ]}
                        value={ambience}
                        onChange={setAmbience}
                        info={infoCopy.ambience}
                    />
                    <SegmentedControl
                        name="wave-music"
                        heading={'Music'}
                        canSave={false}
                        size="small"
                        colour="dark"
                        options={pathScoreFns.convertListToOptions(
                            [...pathScoreFns.extractMusicOptionsFromScores(ambienceFilteredScores)],
                            pathScoreFns.extractMusicOptionsFromScores(ambienceFilteredScores),
                        )}
                        value={music}
                        onChange={setMusic}
                        info={infoCopy.composition}
                    />
                </>
            )}
            <DropdownControl
                heading={'Duration'}
                name="wave-duration"
                canSave={false}
                size="small"
                colour="dark"
                options={durationOptions}
                value={preferredDuration}
                onChange={setPreferredDuration}
                info={infoCopy.duration}
            />
        </>
    );
}

export default SootheWaveSelector;
