import { createMuiTheme } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';

const theme = createMuiTheme({
    typography: {
        fontFamily: ['Inter', 'Tenor Sans', 'sans-serif'].join(','),
        button: {
            textTransform: 'capitalize',
        },
        body1: {
            opacity: 60,
            color: '#000',
        },
        subtitle1: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '24px',
            fontStyle: 'normal',
        },

        h6: {
            border: 'none',
        },
    },
    breakpoints: {
        unit: 'px',
        values: { xs: 0, sm: 600, md: 769, lg: 1200, xl: 1536 },
    },
    palette: {
        primary: {
            main: '#000000',
            light: '#000000',
            dark: '#FFFFFF',
        },
        secondary: {
            main: 'rgba(0, 0, 0, 0.3)',
            light: 'rgba(0, 0, 0, 0.3)',
            dark: 'rgba(0, 0, 0, 0.3)',
        },
        error: {
            main: '#FD3A57',
            light: '#FD3A57',
            dark: '#FD3A57',
        },
        warning: {
            main: '#FFB015',
            light: '#FFB015',
            dark: '#FFB015',
        },
        success: {
            main: '#2EB743',
            light: '#2EB743',
            dark: '#2EB743',
        },
        text: {
            secondary: 'rgba(0, 0, 0, 0.6)',
        },
    },
    overrides: {
        MUIRichTextEditor: {
            root: {
                backgroundColor: '#ebebeb',
            },
            container: {
                display: 'flex',
                flexDirection: 'column-reverse',
            },
            editor: {
                backgroundColor: 'white',
                padding: '8px',
                height: '200px',
                maxHeight: '200px',
                overflow: 'auto',
            },
            toolbar: {
                borderTop: '1px solid gray',
                backgroundColor: '#ebebeb',
            },
            placeHolder: {
                backgroundColor: '#ebebeb',
                paddingLeft: 20,
                width: 'inherit',
                position: 'absolute',
                top: '8px',
            },
            anchorLink: {
                color: '#333333',
                textDecoration: 'underline',
            },
        },
    } as Overrides,
});

export default theme;
