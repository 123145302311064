import { useEffect, useState } from 'react';

export function usePlayheadPositionV2({
    sessionDurationMs,
    elapsedTimeMs,
    pixelsPerMillis,
}: {
    sessionDurationMs: number;
    elapsedTimeMs: number;
    pixelsPerMillis: number;
}): number {
    const [playheadPosition, setPlayheadPosition] = useState({ x: 0 });

    useEffect(() => {
        const clampedEffectiveTime = Math.min(elapsedTimeMs, sessionDurationMs);
        const x = clampedEffectiveTime * pixelsPerMillis;
        setPlayheadPosition({ x });
    }, [sessionDurationMs, elapsedTimeMs]);

    return playheadPosition.x;
}
