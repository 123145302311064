import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PathScore, RequestType, SessionScore, VoiceoverStage, Wavepath } from 'wavepaths-shared/core';

import { Connection } from '../../../common/hooks/useSessionTick';
import UserEvents from '../../../UserEvents';
import { Queueable } from '../actionQueue/useActionQueue';
import useRemoteSession from './useSession';

export type UseSessionScoreReturn =
    | {
          sessionScore: SessionScore;
          updatePathAtIndex: (
              index: number,
              { pathId, pathScore, duration }: { pathId: string; pathScore: PathScore; duration?: number },
              showQueuingUIInline?: boolean,
          ) => void;
          addPathAtIndex: (
              index: number,
              { pathId, pathScore, duration }: { pathId: string; pathScore: PathScore; duration?: number },
          ) => void;
          removePathAtIndex: (index: number, name?: string) => void;
          movePathToIndex: (index: number, targetIndex: number) => void;
          skipToWave: (index: number, showQueuingUIInline?: boolean) => void;
          upsertVoiceOverStage: ({ index, voiceOver }: { index?: number; voiceOver?: VoiceoverStage }) => void;
      }
    | 'loading';

export function useRemoteSessionScorePlanner(
    sessionId: string | undefined,
    connection: Connection | undefined,
    queueFunction: (queueable: Queueable) => void,
): UseSessionScoreReturn {
    const { session, loading } = useRemoteSession(sessionId, connection);

    const upsertVoiceOverStage = useCallback(
        ({ index, voiceOver }: { index?: number; voiceOver?: VoiceoverStage }) => {
            connection?.sendRequest({
                type: RequestType.UpsertVoiceOver,
                index,
                voiceOver,
            });
        },
        [connection, queueFunction],
    );
    const updatePathAtIndex = useCallback(
        (
            index: number,
            {
                pathId,
                pathScore,
                duration,
                preferredDuration,
            }: Pick<Wavepath, 'pathId' | 'pathScore' | 'duration' | 'preferredDuration'>,
            showQueuingUIInline?: boolean,
        ): void => {
            queueFunction({
                description: `Editing wave in progress... Autosave `,
                queueTime: 15,
                cancelPrevious: true,
                waveIndex: index,
                showQueuingUIInline: showQueuingUIInline !== undefined ? showQueuingUIInline : false,
                callback: () =>
                    connection?.sendRequest({
                        type: RequestType.UpdatePathAtIndex,
                        index,
                        pathId,
                        pathScore,
                        duration,
                        preferredDuration,
                    }),
                onCancel: UserEvents.cancelWaveUpdate,
                onSkipQueue: UserEvents.skipQueueWaveUpdate,
            });
        },
        [connection, queueFunction],
    );

    const addPathAtIndex = useCallback(
        (
            index: number,
            {
                pathId,
                pathScore,
                duration,
                preferredDuration,
            }: Pick<Wavepath, 'pathId' | 'pathScore' | 'duration' | 'preferredDuration'>,
        ): void => {
            connection?.sendRequest({
                id: uuidv4(),
                type: RequestType.AddPathAtIndex,
                index,
                pathId,
                pathScore,
                duration,
                preferredDuration,
            });
        },
        [connection, queueFunction],
    );

    const skipToWave = useCallback(
        (index: number, showQueuingUIInline?: boolean): void => {
            queueFunction({
                description: `Skipping to wave`,
                callback: () =>
                    connection?.sendRequest({
                        type: RequestType.SkipWave,
                        index,
                    }),
                waveIndex: index,
                showQueuingUIInline: showQueuingUIInline !== undefined ? showQueuingUIInline : false,
                onCancel: UserEvents.cancelWaveUpdate,
                onSkipQueue: UserEvents.skipQueueWaveUpdate,
            });
        },
        [connection, queueFunction],
    );

    const removePathAtIndex = useCallback(
        (index: number): void => {
            connection?.sendRequest({
                type: RequestType.RemovePathAtIndex,
                index,
            });
        },
        [connection, queueFunction],
    );

    const movePathToIndex = useCallback(
        (index: number, targetIndex: number): void => {
            connection?.sendRequest({
                type: RequestType.MovePathToIndex,
                index,
                targetIndex,
            });
        },
        [connection, queueFunction],
    );

    if (loading || !session) return 'loading';

    return {
        sessionScore: session.score,
        updatePathAtIndex,
        addPathAtIndex,
        removePathAtIndex,
        movePathToIndex,
        skipToWave,
        upsertVoiceOverStage,
    };
}
