import styled from '@emotion/styled';
import React, { useCallback, useContext, useState } from 'react';
import QRCode from 'react-qr-code';

import { Button, EvaIcon, InfoDialog } from '@/component-library';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { GlobalSnackbarContext } from '@/components/Snackbar';
import { Features } from '@/features';
import { useSessionLinkForControls, useSessionLinkForTheClient } from '@/util/broadcastLinkUtils';

import { useAuthContext } from '../../auth';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Link = styled.a`
    display: block;
    margin-top: 4px;
`;

const ChoiceContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
`;

const ChoiceItem = styled.div`
    text-align: center;
`;

const VersionInfo = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

function ShareSessionDialog({
    broadcastIdentifier,
    sessionId,
    isOpen,
    onClose,
    defaultVersion,
    defaultMode,
}: {
    broadcastIdentifier: string;
    sessionId: string;
    isOpen: boolean;
    onClose: () => void;
    defaultVersion?: 'classic' | 'beta';
    defaultMode?: 'client' | 'controls';
}) {
    const { setSnackbarContent } = useContext(GlobalSnackbarContext);
    const [version, setVersion] = useState<'classic' | 'beta' | undefined>(defaultVersion);
    const [mode, setMode] = useState<'client' | 'controls' | undefined>(defaultMode);
    const sessionLinkForTheClient = useSessionLinkForTheClient({
        broadcastIdentifier,
        version: version,
    });
    const sessionLinkForControls = useSessionLinkForControls({
        sessionId,
    });

    const { isEnabled } = useAuthContext();

    const onCloseEffective = () => {
        setVersion(defaultVersion);
        setMode(defaultMode);
        onClose();
    };
    //const
    const copyClientLink = useCallback(() => {
        navigator.clipboard.writeText(sessionLinkForTheClient);
        setSnackbarContent('Client link copied');

        onCloseEffective();
    }, [sessionLinkForTheClient]);

    const copyControlsLink = useCallback(() => {
        navigator.clipboard.writeText(sessionLinkForControls);
        setSnackbarContent('Controls link copied');
        setVersion(defaultVersion);
        setMode(defaultMode);
        onCloseEffective();
    }, [sessionLinkForControls]);

    const blockedToShareWithClient = isEnabled(Features.BLOCKED_REMOTE_LISTEN);

    return (
        <InfoDialog title={'Play on remote devices'} open={isOpen} onClose={onCloseEffective}>
            <Content>
                {mode === undefined ? (
                    <>
                        <TypographyV2 size="text-sm" color="grey-700">
                            For You as a Care-Provider:
                        </TypographyV2>
                        <br />
                        <ChoiceContainer>
                            <ChoiceItem>
                                <Button
                                    variant="solid-blue"
                                    onClick={() => {
                                        setMode('controls');
                                        setVersion('beta');
                                    }}
                                >
                                    Control session on remote device
                                </Button>
                            </ChoiceItem>
                            <ChoiceItem>
                                <Button variant="solid-blue" onClick={() => setMode('client')}>
                                    Play music on another device
                                </Button>
                            </ChoiceItem>
                        </ChoiceContainer>
                        <br />
                        <TypographyV2 size="text-sm" color="grey-700">
                            For Your Client:
                        </TypographyV2>
                        <br />
                        <ChoiceContainer>
                            <ChoiceItem>
                                <Button variant="solid-blue" onClick={() => setMode('client')}>
                                    Play music on Client's device
                                </Button>
                            </ChoiceItem>
                        </ChoiceContainer>
                    </>
                ) : (
                    <>
                        {version ? (
                            <>
                                {blockedToShareWithClient ? (
                                    <>
                                        <TypographyV2 size="text-sm" color="grey-700">
                                            Your subscription doesn't include this feature, please upgrade first on your
                                            profile page.
                                        </TypographyV2>
                                        <Link href="/profile">Profile page</Link>
                                    </>
                                ) : (
                                    <>
                                        <TypographyV2 size="text-sm" color="grey-700">
                                            This QR code will open the session:
                                        </TypographyV2>
                                        <br />
                                        <QRCode
                                            value={mode === 'client' ? sessionLinkForTheClient : sessionLinkForControls}
                                        />
                                        <p>
                                            <TypographyV2 size="text-sm" color="grey-700">
                                                Alternatively, you can click or send the link directly:
                                            </TypographyV2>
                                            <Link
                                                href={
                                                    mode === 'client' ? sessionLinkForTheClient : sessionLinkForControls
                                                }
                                                target="_blank"
                                            >
                                                <TypographyV2 weight="semibold" size="text-sm" color="grey-700">
                                                    {mode === 'client'
                                                        ? sessionLinkForTheClient
                                                        : sessionLinkForControls}
                                                </TypographyV2>
                                            </Link>
                                        </p>
                                        <Button
                                            onClick={mode === 'client' ? copyClientLink : copyControlsLink}
                                            variant="solid-blue"
                                        >
                                            Copy Link
                                        </Button>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <TypographyV2 size="text-sm" color="grey-700">
                                    Choose version:
                                </TypographyV2>
                                <br />
                                <ChoiceContainer>
                                    <ChoiceItem>
                                        <Button variant="solid-blue" onClick={() => setVersion('classic')}>
                                            Classic Player
                                        </Button>

                                        <VersionInfo>
                                            <EvaIcon name="close-outline" fill="#FF0000"></EvaIcon>
                                            Sensitive to weak network conditions
                                        </VersionInfo>
                                        <VersionInfo>
                                            <EvaIcon name="close-outline" fill="#FF0000"></EvaIcon> No mobile app
                                            support
                                        </VersionInfo>
                                        <VersionInfo>
                                            <EvaIcon name="close-outline" fill="#FF0000"></EvaIcon> No Offline supported
                                        </VersionInfo>
                                        <VersionInfo>
                                            <EvaIcon name="close-outline" fill="#FF0000"></EvaIcon> No playback controls
                                        </VersionInfo>
                                    </ChoiceItem>
                                    <ChoiceItem>
                                        <Button variant="solid-blue" onClick={() => setVersion('beta')}>
                                            New Browser Player
                                        </Button>
                                        <VersionInfo>
                                            <EvaIcon name="checkmark-outline" fill="#00FF00"></EvaIcon>
                                            Graceful network conditions degradation
                                        </VersionInfo>
                                        <VersionInfo>
                                            <EvaIcon name="checkmark-outline" fill="#00FF00"></EvaIcon>
                                            Support for Wavepaths Apple native app
                                        </VersionInfo>
                                        <VersionInfo>
                                            <EvaIcon name="checkmark-outline" fill="#00FF00"></EvaIcon>
                                            Offline supported
                                        </VersionInfo>
                                        <VersionInfo>
                                            <EvaIcon name="checkmark-outline" fill="#00FF00"></EvaIcon>
                                            Playback controls
                                        </VersionInfo>
                                    </ChoiceItem>
                                </ChoiceContainer>
                            </>
                        )}
                    </>
                )}
            </Content>
        </InfoDialog>
    );
}

export default ShareSessionDialog;
