import styled from '@emotion/styled';
import { Card } from '@material-ui/core';
import React, { PropsWithChildren, ReactNode } from 'react';

import { Button, Typography } from '@/component-library';

export interface ISessionAction {
    description: string;
    action: () => void;
}

export interface ISessionCardProps {
    title: string;
    subtitle: string;
    info: string[];
    footer?: ReactNode;
    primaryActionLabel?: string;
    primaryAction?: () => void;
    actions?: ISessionAction[];
    actionsComponent?: ReactNode;
    subActions?: ISessionAction[];
    userInfoText?: string;
}

const StyledCard = styled(Card)({
    backgroundColor: 'rgba(255,255,255,0.5)',
    padding: '15px 16px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    // gridAutoFlow: 'row',
    // gridTemplateRows: 'min-content min-content 1fr min-content',
    boxShadow: '0px 0px 10px #CFD6E7',
    border: '1px solid white',
    borderRadius: '8px',
});

const MetaColumns = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
});

const Title = styled(Typography)({
    color: '#2c3958',
    marginBottom: '8px',
    textWrap: 'wrap',
});

const ButtonGroup = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
});

// const StyledMenu = styled(Menu)({
//     background: 'transparent',
// });

const Meta = styled.div({
    display: 'grid',
    gridAutoFlow: 'row',
});

const Subtitle = styled(Typography)({
    color: '#6980B4',
});

const User = styled(Typography)({
    color: '#6980B4',
});

const Content = styled.div({
    minHeight: 16,
});

const Info = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const InfoItems = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    gap: 8,
});

const InfoItem = styled(Typography)({
    color: '#2c3958',
});

const Feedback = styled.div({
    display: 'flex',
    justifyContent: 'end',
});

const Footer = styled.div`
    border-top: 1px solid #cfd6e7;
    margin-top: 16px;
    padding-top: 16px;
`;

const SessionCard = ({
    title,
    footer,
    subtitle,
    info,
    primaryActionLabel = 'Open',
    primaryAction,
    actions = [],
    actionsComponent,
    subActions,
    userInfoText,
    children,
}: PropsWithChildren<ISessionCardProps>): JSX.Element => {
    return (
        <StyledCard>
            <Title variant="h6">{title}</Title>
            <Subtitle variant="body2">{subtitle}</Subtitle>
            <Meta>
                <MetaColumns>
                    <div>{userInfoText && <User variant="body2">{userInfoText}</User>}</div>

                    <ButtonGroup>
                        {primaryAction && (
                            <Button variant="solid-blue" size="s" onClick={primaryAction}>
                                {primaryActionLabel}
                            </Button>
                        )}
                        {actions?.length
                            ? actions.map(({ description, action }, idx) => (
                                  <Button key={idx} variant="outlined" size="s" onClick={action}>
                                      {description}
                                  </Button>
                              ))
                            : null}
                        {actionsComponent}
                    </ButtonGroup>
                </MetaColumns>
            </Meta>
            <Content>{children}</Content>
            <Info>
                {info.length === 1 ? (
                    <InfoItem variant="body3">{info}</InfoItem>
                ) : (
                    <InfoItems>
                        {info.map((infoItem, idx) => (
                            <InfoItem key={idx} variant="body3">
                                {infoItem}
                            </InfoItem>
                        ))}
                    </InfoItems>
                )}
                {subActions && (
                    <Feedback>
                        {subActions.map((action, idx) => (
                            <Button key={idx} variant="clear-underlined" size="xs" onClick={action.action}>
                                {action.description}
                            </Button>
                        ))}
                    </Feedback>
                )}
            </Info>
            {footer && <Footer>{footer}</Footer>}
        </StyledCard>
    );
};

export default SessionCard;
