import styled from '@emotion/styled';
import { isEqual } from 'lodash';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
//useInterval
import {
    Session,
    SessionPlan,
    SessionRenderType,
    SessionScore,
    SessionVariables,
    TimestampedSessionEvent,
} from 'wavepaths-shared/core';

// import { useAudioCache } from '@/hooks/useAudioCache';
import { useQueryParams } from '@/hooks/useQueryParams';
import { getCEAColour } from '@/util/ceaColours';

import WaveQueueEditor from '../../../common/components/WaveQueueEditor';
import {
    Connection,
    useRemoteCurrentWave,
    useRemoteElapsedTimeSecs,
    useRemoteSessionPlaybackControl,
    useRemoteSessionState,
} from '../../../common/hooks/useSessionTick';
// import configs from '../../../configs';
import { getZeroTick } from '../../planner/SessionVariableInputs';
import { DebugAudioEl } from '../../sessions/helpers';
import { AudioEditorTabs, ListVoiceOverStages, VoiceOverStagesContextWrapper } from '../../templateInfo';
import { Queueable } from '../actionQueue/useActionQueue';
import { useRemoteCEA } from '../ceaButtons/useCEA';
import { DepthStateVisualiser } from '../depthSlider/DepthStateVisualizer';
import { useRemoteDepth } from '../depthSlider/useDepth';
import { InSessionProductTour } from '../InSessionProductTour';
import { InstrumentRefreshPanel } from '../InstrumentRefresh/InstrumentRefreshPanel';
import { useInstrumentRefresh } from '../InstrumentRefresh/useInstrumentRefresh';
import { AudioControlIcon, PlayPauseButton, PrecomposedAudioControls } from '../Precomposed/PrecomposedGuide';
import { Timeline } from '../timeline/Timeline';
import { AutoGuideTracking } from './autoGuideTracking';
import CurrentWaveCard from './CurrentWaveCard';
import StartEndSessionButton from './StartEndSessionButton';
import { UseSessionScoreReturn } from './useSessionScore';
import { isDiscardingEdits, selectNoWave, WaveSelection } from './waveSelection';

export type AutoGuideProps = {
    onStartSession: () => void;
    onEndSession: () => void;
    session: Session;
    log: TimestampedSessionEvent[];
    connection: Connection;
    score: SessionScore;
    sessionScoreState: UseSessionScoreReturn;
    snackbarContent: string | null;
    setSnackbarContent: (content: string | null) => void;
    variables: SessionVariables;
    queueFunction: (queueable: Queueable) => void;
    sessionPlan: SessionPlan;
};

const Container = styled.div({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    /* 20240613 IpadOS 17.5.1 breaking css changes caused overflow - forcing it to 90vh height seem to fix */
    height: '90vh',
    display: 'grid',
    gridAutoFlow: 'row',
    alignContent: 'space-around',
});

const Top = styled.div({
    width: '100%',
});

const Middle = styled.div({
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    transition: 'height 0.15s ease',
});

const Bottom = styled.div({
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'min-content 1fr',
    justifyItems: 'center',
    overflowY: 'hidden',
});

const BottomContent = styled.div({
    width: '100%',
    display: 'grid',
    justifyItems: 'center',
});

const CurrentWaveCardWrapper = styled.div<{ shouldShowQueue?: boolean }>(({ shouldShowQueue }) => ({
    display: 'flex',
    flexDirection: 'row',
    minWidth: 280,
    width: '100%',
    maxWidth: 650,
    zIndex: 1,
    position: undefined,
    bottom: undefined,
    paddingBottom: shouldShowQueue ? 0 : 24,
}));

const WaveQueueEditorContainer = styled.div<{ shouldShow: boolean }>(({ shouldShow }) => ({
    display: 'flex',
    overflowY: 'hidden',
    background: 'rgba(255,255,255,0.6)',
    padding: '8px 16px',
    opacity: shouldShow ? 1 : 0,
    height: shouldShow ? 'auto' : 0,
    transition: 'opacity 0.15s ease',
    maxWidth: 650,
    paddingTop: 8,
    margin: '-8px auto 0',
    width: '100%',
}));

export const SAVE_OR_CANCEL_LABEL = 'Please save or cancel your changes first.';

export function AutoGuide({
    session,
    connection,
    log,
    score,
    sessionScoreState,
    variables,
    onStartSession,
    onEndSession,
    snackbarContent,
    setSnackbarContent,
    queueFunction,
    sessionPlan,
}: AutoGuideProps): JSX.Element {
    const params = useQueryParams();
    const isTour = params.has('isTour');
    const { wave: currentWave, index: currentWaveIndex } = useRemoteCurrentWave(score.wavepaths);
    const nextWave = score.wavepaths[currentWaveIndex + 1];
    // const isinintrosession2 = session.type === SessionType.INTRO && session.score.id === 'intro-score-2';
    const isPrelude = currentWave?.type === 'pre';
    const isPostlude = currentWave?.type === 'post';
    const zeroTick = useMemo(() => getZeroTick(score, variables), [score, variables]);
    const { initialised, started, paused } = useRemoteSessionState(zeroTick);
    const { pause, resume } = useRemoteSessionPlaybackControl(connection, queueFunction);
    const isPreludeOrPostlude = !currentWave || isPrelude || isPostlude;
    const [_shouldShowQueue, setShouldShowQueue] = useState<boolean>(false);
    const shouldShowQueue = !isPreludeOrPostlude && !!currentWave?.plan && _shouldShowQueue;
    const [waveSelection, setWaveSelection] = useState<WaveSelection>(selectNoWave());
    const depthState = useRemoteDepth(connection, queueFunction);

    const elapsedTimeSecs = useRemoteElapsedTimeSecs();

    const instrumentRefreshArgs = useInstrumentRefresh({
        connection,
        queueFunction,
        sessionPlan,
        setSnackbarContent,
        depth: depthState !== 'loading' ? depthState.targetDepth : undefined,
    });

    useLayoutEffect(() => {
        if (isEqual(waveSelection, selectNoWave()) && snackbarContent === SAVE_OR_CANCEL_LABEL) {
            setSnackbarContent(null);
        }
    }, [snackbarContent, waveSelection]);

    const setWaveSelectionOrNotify = useCallback(
        (newSelection: WaveSelection, force = false) => {
            if (!force && isDiscardingEdits(newSelection, waveSelection)) {
                setSnackbarContent(SAVE_OR_CANCEL_LABEL);
            } else if (!isPreludeOrPostlude && !!currentWave?.plan) {
                setShouldShowQueue(true);
                setWaveSelection(newSelection);
            }
        },
        [waveSelection, isPreludeOrPostlude, currentWave, setSnackbarContent],
    );
    // const intensityOpacity = showDepthController && !isPreludeOrPostlude && initialised ? 1 : 0;

    const productTourState = isPrelude ? 'not-started' : shouldShowQueue ? 'started-queue-open' : 'started';

    const [renderProductTour, setRenderProductTour] = useState(false);

    useLayoutEffect(() => {
        setTimeout(() => {
            setRenderProductTour(true);
        }, 1000);
    }, [productTourState]);

    const cea = useRemoteCEA(connection, queueFunction);
    const colorHex = cea !== 'loading' && cea.targetCea ? getCEAColour(cea.targetCea) : '#D3D3D3';
    const colorTransitionTimeInSecs = cea !== 'loading' ? cea.transitionTimeSecs ?? 0 : 0;

    const togglePlaying = async () => {
        if (!paused) {
            pause();
            //audioPlayer.actions.pause({ reason: 'user' });
        } else {
            resume();
            //await audioPlayer.actions.unblock();
            //audioPlayer.actions.play();
        }
    };

    return (
        <VoiceOverStagesContextWrapper>
            <Container>
                {isTour && renderProductTour && <InSessionProductTour state={productTourState} />}

                <Top>
                    {!shouldShowQueue ? (
                        <Timeline
                            score={score}
                            variables={variables}
                            log={log}
                            waveSelection={waveSelection}
                            setWaveSelection={setWaveSelectionOrNotify}
                            connection={connection}
                            session={session}
                            elapsedTimeMs={elapsedTimeSecs * 1000}
                            isScrollable
                            phasesAlwaysVisible
                        />
                    ) : (
                        <></>
                    )}
                </Top>
                <Middle>
                    <StartEndSessionButton
                        onStartSession={onStartSession}
                        onEndSession={onEndSession}
                        broadcastIdentifier={session.broadcastIdentifier}
                    />
                    {started && session.renderType === SessionRenderType.PREDICTIVE_COMPOSED ? (
                        <PrecomposedAudioControls>
                            {/* <WaveJumpButton aria-label="Jump to previous wave" onClick={goToPrev}>
                                <AudioControlIcon
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M9.16667 30V10H11.9444V30H9.16667ZM30.8333 30L16.2223 20L30.8333 10V30Z" />
                                </AudioControlIcon>
                            </WaveJumpButton> */}
                            <PlayPauseButton onClick={togglePlaying}>
                                {!paused ? (
                                    <AudioControlIcon
                                        width="48"
                                        height="48"
                                        viewBox="0 0 48 48"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M28.25 38V10H36V38H28.25ZM12 38V10H19.75V38H12Z" />
                                    </AudioControlIcon>
                                ) : (
                                    <></>
                                )}
                                {paused ? (
                                    <AudioControlIcon
                                        width="48"
                                        height="48"
                                        viewBox="0 0 48 48"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M14 38V10L36 24L14 38Z" />
                                    </AudioControlIcon>
                                ) : (
                                    <></>
                                )}
                            </PlayPauseButton>
                            {/* <WaveJumpButton
                                                            aria-label="Jump to next wave"
                                                            onClick={goToNext}
                                                            disabled={!isPossibleToGoToNext}
                                                        >
                                                            <AudioControlIcon
                                                                width="40"
                                                                height="40"
                                                                viewBox="0 0 40 40"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path d="M28.0556 30V10H30.8333V30H28.0556ZM9.16667 30V10L23.7778 20L9.16667 30Z" />
                                                            </AudioControlIcon>
                                                        </WaveJumpButton> */}
                        </PrecomposedAudioControls>
                    ) : (
                        <></>
                    )}
                    <DebugAudioEl />
                </Middle>
                <Bottom>
                    <BottomContent>
                        <CurrentWaveCardWrapper shouldShowQueue={shouldShowQueue}>
                            <CurrentWaveCard
                                initialised={initialised}
                                connection={connection}
                                currentWave={currentWave}
                                nextWave={nextWave}
                                session={session}
                                instrumentRefreshArgs={instrumentRefreshArgs}
                                onUpsertVoiceOverStage={
                                    sessionScoreState !== 'loading' ? sessionScoreState.upsertVoiceOverStage : undefined
                                }
                                onSkipWave={
                                    sessionScoreState !== 'loading'
                                        ? () => sessionScoreState.skipToWave(currentWaveIndex + 1)
                                        : undefined
                                }
                                elapsedTimeSecs={elapsedTimeSecs}
                                voiceOverStages={score.voiceover ?? []}
                                queueFunction={queueFunction}
                                isExpanded={shouldShowQueue}
                                setIsExpanded={setShouldShowQueue}
                            />
                        </CurrentWaveCardWrapper>
                    </BottomContent>
                    {sessionScoreState !== 'loading' && currentWave?.plan && shouldShowQueue && (
                        <WaveQueueEditorContainer shouldShow={shouldShowQueue}>
                            <AudioEditorTabs
                                waveSelection={waveSelection}
                                mainComponent={
                                    <WaveQueueEditor
                                        key={'wavequeue'}
                                        shouldDisplayHeader
                                        shouldShow={shouldShowQueue}
                                        wavepaths={score.wavepaths}
                                        waveSelection={waveSelection}
                                        setWaveSelection={setWaveSelectionOrNotify}
                                        updatePathAtIndex={sessionScoreState.updatePathAtIndex}
                                        addPathAtIndex={sessionScoreState.addPathAtIndex}
                                        removePathAtIndex={sessionScoreState.removePathAtIndex}
                                        skipToWave={sessionScoreState.skipToWave}
                                        movePathToIndex={sessionScoreState.movePathToIndex}
                                        currentWaveIndex={currentWaveIndex}
                                        setSnackbarContent={setSnackbarContent}
                                        trackingHandlers={AutoGuideTracking}
                                    />
                                }
                                customComponent={
                                    <div
                                        key={'custom1'}
                                        style={{
                                            overflowY: 'scroll',
                                            position: 'relative',
                                            scrollbarGutter: 'stable',
                                            height: '88%',
                                        }}
                                    >
                                        <ListVoiceOverStages
                                            voiceOverStages={score.voiceover ?? []}
                                            onRemove={sessionScoreState.upsertVoiceOverStage}
                                            onEdit={sessionScoreState.upsertVoiceOverStage}
                                            onAdd={sessionScoreState.upsertVoiceOverStage}
                                            sessionDuration={Number(variables.totalDuration) * 60}
                                            elapsedTimeSecs={elapsedTimeSecs}
                                        ></ListVoiceOverStages>
                                    </div>
                                }
                            ></AudioEditorTabs>
                        </WaveQueueEditorContainer>
                    )}
                </Bottom>

                <InstrumentRefreshPanel instrumentRefreshArgs={instrumentRefreshArgs} />
                <DepthStateVisualiser colorHex={colorHex} colorTransitionTimeInSecs={colorTransitionTimeInSecs} />
            </Container>
        </VoiceOverStagesContextWrapper>
    );
}
