import styled from '@emotion/styled';
import React from 'react';
import { useQueryParam } from 'use-query-params';
import { SessionScoreModality } from 'wavepaths-shared/core';

import PageHeading from '@/component-library/components/PageHeading';
import ScrollRow, { ScrollRowItem } from '@/component-library/components/ScrollRow';
import TypographyV2 from '@/component-library/typography/TypographyV2';

import { LayoutContainer } from '../../LayoutContainer';
import { TemplateContainer } from '../templates/all';
import { Program } from './Program';
import { useTemplatePrograms } from './useTemplatePrograms';

const Description = styled.div`
    margin-bottom: 32px;
`;

const Programs = styled.div``;

const Content = styled.div`
    max-width: 700px;
    margin: 0 auto;
`;

function ProgramsContainer() {
    const { programs } = useTemplatePrograms();
    const [program, setProgram] = useQueryParam<string | undefined>('program');
    const [, setModality] = useQueryParam<SessionScoreModality | undefined>('modality');

    return (
        <Content>
            <PageHeading text={'Template Programs'} />
            <Description>
                <TypographyV2 color="grey-600" size="text-sm">
                    Our programs are collections of musical templates that we curated to offer a suggested sequence of
                    templates that you can select and tailor to your client's therapy sessions.
                </TypographyV2>
                <br />
                <TypographyV2 color="grey-600" size="text-sm">
                    These programs are suggestions, a starting point from which you can customise and tailor the
                    experience to suit your client's unique needs and preferences. Your client's input and feedback
                    always remains an invaluable compass to guide your template selection and music adaptation.
                </TypographyV2>
            </Description>
            <Programs>
                {programs.map((p) =>
                    !!program && program !== p.id ? (
                        <></>
                    ) : (
                        <>
                            <Program
                                key={p.id}
                                program={p}
                                onClick={() => {
                                    if (program == p.id) {
                                        setProgram(undefined);
                                        setModality(undefined);
                                    } else {
                                        setProgram(p.id);
                                        setModality(p.modality);
                                    }
                                }}
                                isExpandedDefault={!!program && p.id === program}
                            >
                                {!!program ? (
                                    <TemplateContainer
                                        ContainerComponent={ScrollRow}
                                        CardComponent={ScrollRowItem}
                                        program={program}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Program>
                        </>
                    ),
                )}
            </Programs>
        </Content>
    );
}

function ProgramsWithNav() {
    return (
        <LayoutContainer>
            <ProgramsContainer />
        </LayoutContainer>
    );
}

export default ProgramsWithNav;
