import styled from '@emotion/styled';
import React from 'react';

import HelpButton from '@/components/HelpButton';

import ContentArea from './ContentArea';
import PrimaryNavigation from './PrimaryNavigation';

export interface LayoutProps {
    currentRoute: string;
    userName: string;
    onSignOutClick: () => void;
    children: React.ReactChild[] | React.ReactChild;
    isAdmin: boolean;
    showSavedTemplates: boolean;
    showTemplatePrograms: boolean;
}

const Container = styled.main`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: min-content 1fr;
`;

const Layout: React.FC<LayoutProps> = ({
    isAdmin,
    showSavedTemplates,
    currentRoute,
    userName,
    onSignOutClick,
    children,
    showTemplatePrograms,
}) => {
    return (
        <Container>
            <PrimaryNavigation
                showSavedTemplates={showSavedTemplates}
                showTemplatePrograms={showTemplatePrograms}
                currentRoute={currentRoute}
                userName={userName}
                showInfiniteLink={isAdmin}
                onSignOutClick={onSignOutClick}
            />
            <ContentArea>{children}</ContentArea>
            <HelpButton />
        </Container>
    );
};

export default React.memo(Layout);
