import styled from '@emotion/styled';
import { Card, CardContent, Link } from '@material-ui/core';
import React from 'react';

import { Button, Typography } from '@/component-library';

import breathingLines from '../../images/BreathingLines.svg';

const Container = styled(Card)({
    backgroundImage: `url(${breathingLines})`,
    backgroundSize: 'contatain',
    width: 280,
    height: 150,
    marginRight: 30,
    display: 'inline-block',
    position: 'relative',
    userSelect: 'none',
    opacity: '100%',
    boxShadow: '0px 0px 10px #CFD6E7',
    border: '1px solid white',
    borderRadius: '8px',
    color: 'rgb(44, 57, 88)',
});

const Text = styled(Typography)({
    color: '#2c3958',
});

const ExternalResourceCard = ({ title, url }: { title: string; url: string }): JSX.Element => {
    return (
        <Container>
            <CardContent>
                <Text variant="h6">{title}</Text>
                <div
                    style={{
                        position: 'absolute',
                        right: 16,
                        bottom: 16,
                        height: '30px',
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Link href={url} target="_blank">
                        <Button variant={'outlined'}>Go</Button>
                    </Link>
                </div>
            </CardContent>
        </Container>
    );
};

export default ExternalResourceCard;
