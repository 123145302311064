import * as Sentry from '@sentry/browser';
import { compact } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { createEnumArrayParam, createEnumParam, NumberParam, useQueryParam } from 'use-query-params';
import {
    AdministrationRoute,
    DosageLevel,
    DurationPreset,
    DurationPresets,
    Guidance,
    Intention,
    SESSION_SCORE_EMOTIONAL_INTENSITIES,
    SessionScoreEmotionalIntensity,
    SessionScoreModality,
} from 'wavepaths-shared/core';

import { Button } from '@/component-library';
import FilterInputDialog from '@/component-library/components/FilterInputs';
import LoadingCardRow from '@/component-library/components/LoadingTemplateCardRow';
import PageHeading from '@/component-library/components/PageHeading';
import PillValue from '@/component-library/components/PillValue';
import { TemplateGridProps } from '@/component-library/components/ScrollRow';
import TemplateCard from '@/component-library/components/TemplateCard';
import TemplateGrid, { DefaultCardContainer } from '@/component-library/components/TemplateGrid';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { MODALITIES_V2 } from '@/domain/data/modalities';
import {
    getAdministrationsForModality,
    getDefaultSessionDurationForModality,
    getDosagesForMedicineAndAdministration,
    MODALITY_PERSONAL_LABELS,
    MODALITY_THERAPIST_LABELS,
    POSSIBLE_DURATIONS_FOR_MODALITY,
} from '@/domain/modalities';
import useScoreTemplates from '@/hooks/useScoreTemplates';

import { useAuthContext } from '../../../auth';
import { ListScoreTemplatesItem } from '../../../common/api/contentApi';
import { LayoutContainer } from '../../../LayoutContainer';

const NoResultsContainer = styled.div`
    width: 100%;
    grid-column: 1/-1;
    text-align: center;
`;

const Templates = React.memo(
    ({
        templates,
        isLoading,
        duration,
        ContainerComponent = TemplateGrid,
        CardComponent = DefaultCardContainer,
    }: {
        templates?: ListScoreTemplatesItem[];
        duration?: number | null;
        isLoading: boolean;
        ContainerComponent?: React.FC<TemplateGridProps>;
        CardComponent?: React.FC;
    }) => {
        const history = useHistory();

        if (!templates?.length && !isLoading) {
            // no results is kind of an error for the user and a bad experience
            Sentry.captureMessage('Templates no results');
        }

        return (
            <ContainerComponent>
                <>
                    {templates &&
                        (templates.length ? (
                            templates.map((t) => (
                                <CardComponent key={'template-' + t.id}>
                                    <TemplateCard
                                        key={'template-' + t.id}
                                        onCardClick={() => {
                                            history.push({
                                                pathname: '/templates/' + t.id,
                                                search: duration ? `?duration=${duration}` : '',
                                            });
                                        }}
                                        title={t.name}
                                        subtitle={t.subtitle}
                                        intensity={t.intensity}
                                        modality={t.modality}
                                        minDurationMins={Math.ceil(t.durationMins.min)}
                                        maxDurationMins={Math.floor(t.durationMins.max)}
                                        emotionalities={t.emotionalities}
                                        id={t.id}
                                    ></TemplateCard>
                                </CardComponent>
                            ))
                        ) : (
                            <NoResultsContainer>
                                <TypographyV2 color="grey-400" size="text-md">
                                    Sorry, we couldn't find any templates for these filters
                                </TypographyV2>
                            </NoResultsContainer>
                        ))}
                    {isLoading && <LoadingCardRow columns={4} />}
                </>
            </ContainerComponent>
        );
    },
);

const FilterInputsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    flex-direction: row;
`;

const FilterInputsListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    flex-direction: row;
    padding: 8px 0;
`;

const LoadMoreButtonContainer = styled.div({
    width: '100%',
    gridColumn: '1/-1',
    textAlign: 'center',
});

export function TemplateContainer({
    ContainerComponent = TemplateGrid,
    CardComponent = DefaultCardContainer,
    program = '',
}) {
    const { userData, firebaseUser, isPersonal } = useAuthContext();
    const groups = userData?.groups || [];

    const [isDialogOpen, setDialogIsOpen] = useState(false);

    const [durationMins, setDuration] = useQueryParam<number | null | undefined>('duration', NumberParam);

    const [_intensityValues, setIntensityValues] = useQueryParam(
        'intensity',
        createEnumArrayParam(Object.values(SessionScoreEmotionalIntensity)),
    );
    const intensityValues = _intensityValues ?? [];
    const intensityOptions = SESSION_SCORE_EMOTIONAL_INTENSITIES.map((intensity) => ({
        label: intensity,
        value: intensity,
    }));
    const allowedModalities = Object.values(SessionScoreModality).filter((x) => {
        if (!isPersonal || !groups.includes('LimitedModalities')) return true;
        const groupNameForModality = x + '_Modality';
        if (groups.includes(groupNameForModality)) return true;

        return false;
    });

    const allowedModalityOptionsEnum = createEnumParam(
        allowedModalities?.length ? allowedModalities : [SessionScoreModality.NONE],
    );

    const [modality, setModality] = useQueryParam('modality', allowedModalityOptionsEnum);

    const modalityLabels = isPersonal ? MODALITY_PERSONAL_LABELS : MODALITY_THERAPIST_LABELS;

    const modalityOptions = MODALITIES_V2.filter((x) => allowedModalities.includes(x.name)).map(({ name }) => ({
        label: modalityLabels[name],
        value: name,
    }));

    const durationPresets: DurationPreset[] = modality
        ? (Object.keys(DurationPresets).filter((x) =>
              POSSIBLE_DURATIONS_FOR_MODALITY[modality!].includes(x as DurationPreset),
          ) as DurationPreset[])
        : [];
    const durationOptions = durationPresets.map((x) => ({
        label: DurationPresets[x].label,
        value: DurationPresets[x].duration,
    }));

    const [administration, _setAdministration] = useQueryParam(
        'administration',
        createEnumParam(Object.values(AdministrationRoute)),
    );
    const administrationOptions = modality
        ? getAdministrationsForModality(modality).map(({ name, id }) => ({
              label: name,
              value: id,
          }))
        : [];

    const allowedDosages = Object.values(DosageLevel).filter((x) => {
        if (!isPersonal || !groups.includes('Limited_dosages')) return true;
        const groupNameForDosage = x + '_dosage';
        if (groups.includes(groupNameForDosage)) return true;

        return false;
    });

    const [dosage, _setDosage] = useQueryParam('dosage', createEnumParam(allowedDosages));
    const dosageOptions =
        modality && administration
            ? getDosagesForMedicineAndAdministration(modality, administration)
                  .filter((x) => allowedDosages.includes(x.id))
                  .map(({ name, id }) => ({
                      label: name,
                      value: id,
                  }))
            : [];

    const setDosage = (dosage?: DosageLevel | null) => {
        _setDosage(dosage);
        setDuration(undefined);
    };

    const setAdministration = (administration?: AdministrationRoute | null) => {
        _setAdministration(administration);
        setDosage(undefined);
        setDuration(undefined);
    };

    const [_intentionValues, setIntentionValues] = useQueryParam(
        'intentions',
        createEnumArrayParam(Object.values(Intention)),
    );
    const intentionValues = _intentionValues ?? [];

    const [_guidanceValues, setGuidanceValues] = useQueryParam(
        'guidances',
        createEnumArrayParam(Object.values(Guidance)),
    );
    const guidanceValues = _guidanceValues ?? [];

    //intention option only for Ketamine for now - need to tag other templates
    const intentionOptions =
        modality == SessionScoreModality.KETAMINE
            ? Object.values(Intention).map((intention) => ({
                  label: intention,
                  value: intention,
              }))
            : [];

    const guidanceOptions = Object.values(Guidance)
        //TODO allow guidence options for Therapists
        .filter((x) => x && isPersonal)
        .map((x) => ({
            label: x,
            value: x,
        }));

    const filterCriteria = {
        modalities: modality && [modality],
        administrations: administration && [administration],
        dosages: dosage && [dosage],
        durationMins,
        intensities: intensityValues,
        intentions: intentionValues,
        guidances: guidanceValues,
        program,
    };

    const handleSetModality = (modality?: SessionScoreModality | null) => {
        setModality(modality);
        const availableAdministrations = modality ? getAdministrationsForModality(modality) : [];
        setAdministration(availableAdministrations.length === 1 ? availableAdministrations[0].id : undefined);
        setDosage(undefined);
        //TODO: set intention for all modalities
        setIntentionValues([]);
    };

    useEffect(() => {
        if (dosage && modality && !durationMins) {
            setDuration(getDefaultSessionDurationForModality(modality, { administration, dosage }));
        }
    }, [dosage, modality]);

    const { templates, loadNext, isLoading, remainingPages } = useScoreTemplates({
        fbUser: firebaseUser,
        itemsPerPage: 256,
        criteria: filterCriteria,
    });

    const readyForShowingTemplates = modality == SessionScoreModality.KETAMINE ? administration && dosage : !!modality;

    return (
        <>
            <FilterInputsContainer>
                {/* <FilterLabel size={'text-sm'} color={'grey-700'}>
                    Filtered by
                </FilterLabel> */}
                <FilterInputsListContainer>
                    {!!modality && (
                        <PillValue
                            options={modalityOptions!}
                            onPillClick={() => setDialogIsOpen(true)}
                            name=""
                            values={compact([modality])}
                            onItemClick={() => setDialogIsOpen(true)}
                            onItemDelete={() => handleSetModality(undefined)}
                        />
                    )}
                    {!!administrationOptions.length && !!administration ? (
                        <PillValue
                            options={administrationOptions}
                            onPillClick={() => setDialogIsOpen(true)}
                            name=""
                            values={compact([administration])}
                            onItemClick={() => setDialogIsOpen(true)}
                            onItemDelete={() => setAdministration(undefined)}
                        />
                    ) : null}
                    {!!dosageOptions.length && !!dosage ? (
                        <PillValue
                            options={dosageOptions.map((x) => {
                                return {
                                    ...x,
                                    label: x.label + ' Dose',
                                };
                            })}
                            onPillClick={() => setDialogIsOpen(true)}
                            name=""
                            values={compact([dosage])}
                            onItemClick={() => setDialogIsOpen(true)}
                            onItemDelete={() => setDosage(undefined)}
                        />
                    ) : null}
                    {/* {!!durationMins && (
                        <PillValue
                            options={[{ value: durationMins, label: `${durationMins} mins` }]}
                            onPillClick={() => setDialogIsOpen(true)}
                            name=""
                            values={durationMins ? [durationMins] : []}
                            onItemClick={() => setDialogIsOpen(true)}
                            onItemDelete={() => setDuration(undefined)}
                        />
                    )} */}
                    {!!guidanceValues.length && (
                        <PillValue
                            options={guidanceOptions}
                            onPillClick={() => setDialogIsOpen(true)}
                            name=""
                            values={guidanceValues}
                            onItemClick={(itemValue) =>
                                setGuidanceValues(guidanceValues.filter((val) => itemValue !== val))
                            }
                            onItemDelete={(itemValue) =>
                                setGuidanceValues(guidanceValues.filter((val) => itemValue !== val))
                            }
                        />
                    )}

                    {!!intensityValues.length && (
                        <PillValue
                            options={intensityOptions.map((x) => {
                                return {
                                    ...x,
                                    label: x.label + ' Intensity',
                                };
                            })}
                            onPillClick={() => setDialogIsOpen(true)}
                            name=""
                            values={intensityValues}
                            onItemClick={(itemValue) =>
                                setIntensityValues(intensityValues.filter((val) => itemValue !== val))
                            }
                            onItemDelete={(itemValue) =>
                                setIntensityValues(intensityValues.filter((val) => itemValue !== val))
                            }
                        />
                    )}
                    {!!intentionValues.length && (
                        <PillValue
                            options={intentionOptions.map((x) => {
                                return {
                                    ...x,
                                    label: 'To ' + x.label,
                                };
                            })}
                            onPillClick={() => setDialogIsOpen(true)}
                            name=""
                            values={intentionValues}
                            onItemClick={(itemValue) =>
                                setIntentionValues(intentionValues.filter((val) => itemValue !== val))
                            }
                            onItemDelete={(itemValue) =>
                                setIntentionValues(intentionValues.filter((val) => itemValue !== val))
                            }
                        />
                    )}
                </FilterInputsListContainer>
            </FilterInputsContainer>
            <FilterInputDialog
                isOpen={isDialogOpen}
                modalityOptions={modalityOptions}
                modalityValue={modality}
                onModalityValueChange={handleSetModality}
                intensityOptions={intensityOptions}
                intensityValues={intensityValues}
                onIntensityValuesChange={setIntensityValues}
                guidanceOptions={guidanceOptions}
                guidanceValues={guidanceValues}
                onGuidanceValuesChange={setGuidanceValues}
                intentionOptions={intentionOptions}
                intentionValues={intentionValues}
                onIntentionValuesChange={setIntentionValues}
                administrationOptions={administrationOptions}
                administration={administration}
                onAdministrationChange={setAdministration}
                dosageOptions={dosageOptions}
                dosage={dosage}
                onDosageChange={setDosage}
                onDurationChange={setDuration}
                durationValue={durationMins}
                durationOptions={durationOptions}
                onClose={() => setDialogIsOpen(false)}
                program={program}
            />
            {readyForShowingTemplates && (
                <Templates
                    isLoading={isLoading}
                    templates={templates}
                    duration={durationMins}
                    ContainerComponent={ContainerComponent}
                    CardComponent={CardComponent}
                />
            )}
            {readyForShowingTemplates && !isLoading && remainingPages > 0 && (
                <LoadMoreButtonContainer>
                    <Button onClick={loadNext} variant="clear-underlined">
                        Load more
                    </Button>
                </LoadMoreButtonContainer>
            )}
        </>
    );
}

function TemplatesWithNav() {
    return (
        <LayoutContainer>
            <PageHeading text={'Explore All Templates'} />
            <TemplateContainer />
        </LayoutContainer>
    );
}

export default TemplatesWithNav;
