import styled from '@emotion/styled';
import Box from '@material-ui/core/Box';
import firebase from 'firebase';
import React, { useContext, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import { APISessionStatus, SessionType, UserData } from 'wavepaths-shared/core';
import { isAdmin as checkAdmin } from 'wavepaths-shared/domain/user';

import { Typography } from '@/component-library';
import PageHeading from '@/component-library/components/PageHeading';
import { ErrorBox, SearchBar, SubscribeModal, TabLabelWithCount, Tabs } from '@/components';
import HelpButton from '@/components/HelpButton';
import Snackbar from '@/components/Snackbar';
import { Features } from '@/features';
import { useDebounce, useSnackbar } from '@/hooks';

import { AuthContext } from '../../auth';
import { LayoutContainer } from '../../LayoutContainer';
import EndedSessionsTable from '../sessions/EndedSessionsTable';
import OngoingSessionsTable from '../sessions/OngoingSessionsTable';
import ScheduledSessionsTable from '../sessions/ScheduledSessionsTable';
import useSessions from '../sessions/useSessions';

enum TabIndex {
    SCHEDULED = 0,
    ACTIVE = 1,
    COMPLETED = 2,
}

const TabContent = styled.div({
    paddingTop: '24px',
});

const LockedSessionsContainer = styled(Box)((props: { isLocked: boolean }) => ({
    flexDirection: 'column',
    display: 'flex',
    opacity: props.isLocked ? 0.5 : 1,
    cursor: props.isLocked ? 'pointer' : 'initial',
}));

const NoResults: React.FC = () => (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Typography variant="body2" style={{ color: '#2c3958' }}>
            No sessions found
        </Typography>
    </div>
);

const Container = styled.div`
    position: relative;
    min-height: 100%;
    display: grid;
    grid-auto-flow: row;
    align-items: start;
    padding: 0 min(1vw, 20px) min(2vh, 80px) min(1vw, 20px);
    max-width: 1024px;
    margin: auto;

    &--primaryActions {
        button {
            margin-bottom: 4px;
            margin-top: 4px;
            margin-right: 10px;
            &:last-of-type {
                margin-right: 0px;
            }
        }
    }
`;

const Sessions: React.FC = () => {
    const authCtx = useContext(AuthContext);
    const fbUser = authCtx.firebaseUser as firebase.User; // page will not have loaded if firebase user is undefined
    const isEnabled = authCtx.isEnabled;
    const isAdmin = checkAdmin(authCtx.userData as UserData);

    const sessionSignups = {};
    const [searchTerm, setSearchTerm] = useQueryParam<string, string>('q');
    const defaultTabIndex = TabIndex.ACTIVE as number;
    const [_activeTab, setActiveTab] = useQueryParam<number | undefined>('tabIndex');
    const activeTab = _activeTab !== undefined ? Number(_activeTab) : Number(defaultTabIndex);

    const { closeSnackbar, content: snackbarContent } = useSnackbar();

    const [modalIsOpen, setModalIsOpen] = useState<boolean | undefined>(undefined);

    const scheduled = useSessions({
        fbUser,
        name: useDebounce(searchTerm),
        sessionState: APISessionStatus.SCHEDULED,
        type: isAdmin ? undefined : SessionType.ONE_ON_ONE,
        paginated: true,
    });
    const ongoing = useSessions({
        fbUser,
        name: useDebounce(searchTerm),
        sessionState: APISessionStatus.ACTIVE,
        type: isAdmin ? undefined : SessionType.ONE_ON_ONE,
        paginated: true,
    });
    const ended = useSessions({
        fbUser,
        name: useDebounce(searchTerm),
        sessionState: APISessionStatus.ENDED,
        type: isAdmin ? undefined : SessionType.ONE_ON_ONE,
        paginated: true,
    });

    if (!fbUser) {
        throw new Error('no logged in user on sessions screen');
    }

    const [sessionStartFailed] = useState<boolean>(false);

    const onTabChange = (newTab: number) => {
        setActiveTab(newTab);
    };

    const handleDisabledContentClick = (e: any) => {
        if (!isEnabled(Features.SESSION_DASHBOARD) || !isEnabled(Features.LIVE_SESSION_RENDERING)) {
            e.stopPropagation();
            setModalIsOpen(true);
            return;
        }
    };

    return (
        <Box minHeight="100vh">
            <PageHeading text={'Your sessions'} />
            <Container className="sessionsv2">
                {sessionStartFailed && (
                    <div className="errorbox">
                        <ErrorBox
                            message={'Unable to start the session. Please check your network connection and try again'}
                        />
                    </div>
                )}
                <LockedSessionsContainer
                    // TODO: Decouple Session scheduling from Scheduling session and Adaptive features
                    isLocked={!isEnabled(Features.SESSION_DASHBOARD) || !isEnabled(Features.LIVE_SESSION_RENDERING)}
                    onClickCapture={handleDisabledContentClick}
                >
                    <SearchBar
                        value={searchTerm}
                        label="Find sessions"
                        onChange={setSearchTerm}
                        onClear={() => {
                            setSearchTerm('');
                        }}
                    />
                    <Tabs
                        className="tour-sessionTables"
                        activeTab={activeTab}
                        onChange={onTabChange}
                        content={[
                            {
                                identifier: 'scheduled',
                                label: (
                                    <TabLabelWithCount
                                        name="Scheduled"
                                        count={scheduled.sessions?.length ?? 0}
                                        loading={scheduled.loading}
                                    />
                                ),
                                body: (
                                    <TabContent>
                                        {!scheduled.loading && scheduled.sessions?.length === 0 ? (
                                            <NoResults />
                                        ) : (
                                            <ScheduledSessionsTable
                                                sessions={scheduled.sessions}
                                                loading={scheduled.loading}
                                                isAdmin={isAdmin}
                                                //@ts-ignore
                                                firebaseUser={fbUser}
                                            />
                                        )}
                                    </TabContent>
                                ),
                            },
                            {
                                identifier: 'active',
                                label: (
                                    <TabLabelWithCount
                                        name="Active"
                                        count={ongoing.sessions?.length ?? 0}
                                        loading={ongoing.loading}
                                    />
                                ),
                                body: (
                                    <TabContent>
                                        {!ongoing.loading && ongoing.sessions?.length === 0 ? (
                                            <NoResults />
                                        ) : (
                                            <OngoingSessionsTable
                                                sessions={ongoing.sessions}
                                                loading={ongoing.loading}
                                                sessionsError={ongoing.error}
                                                fbUser={fbUser}
                                                isAdmin={isAdmin}
                                            />
                                        )}
                                    </TabContent>
                                ),
                            },
                            {
                                identifier: 'completed',
                                label: (
                                    <TabLabelWithCount
                                        name="Completed"
                                        count={ended.totalCount ?? 0}
                                        loading={ended.loading}
                                    />
                                ),
                                body: (
                                    <TabContent>
                                        {!ended.loading && ended.sessions?.length === 0 ? (
                                            <NoResults />
                                        ) : (
                                            <EndedSessionsTable
                                                endedSessionsInterface={ended}
                                                signups={sessionSignups}
                                                isAdmin={isAdmin}
                                                fbUser={fbUser}
                                            />
                                        )}
                                    </TabContent>
                                ),
                            },
                        ]}
                    />
                </LockedSessionsContainer>
                {/* TODO: Refactor and Replace Intro Session with Product Tour carousel */}
                <SubscribeModal isOpen={!!modalIsOpen} closeModal={() => setModalIsOpen(false)} />
                <Snackbar
                    type="error"
                    isLongButton={false}
                    message={snackbarContent}
                    confirmText={'OK'}
                    open={snackbarContent !== null}
                    closeSnackbar={closeSnackbar}
                />
            </Container>

            <HelpButton />
        </Box>
    );
};

function SessionsWithNav() {
    return (
        <LayoutContainer>
            <Sessions />
        </LayoutContainer>
    );
}

export default SessionsWithNav;
