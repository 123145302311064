import React from 'react';
import { useHistory } from 'react-router-dom';
import { SessionHeader } from 'wavepaths-shared/core';

import { formatDateTime } from '../../../dateUtilsV2';
import { renderSessionClients, renderSessionName } from '../helpers';
import SessionCardV2 from '../SessionCardV2';

const TherapistEndedSessionCardV2 = ({ session }: { session: SessionHeader }): React.ReactElement => {
    const history = useHistory();
    const sessionName = renderSessionName(session);
    const clientNames = renderSessionClients(session);

    const onClick = () => {
        history.push(`/session/details/${session.id}`);
    };

    return (
        <SessionCardV2
            info={[`${formatDateTime(session.endedAt)}`]}
            title={sessionName}
            subtitle={clientNames}
            session={session}
            onClick={onClick}
        />
    );
};

export default TherapistEndedSessionCardV2;
