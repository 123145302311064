import * as Sentry from '@sentry/browser';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export function GenericErrorBoundary(props: { children: React.ReactNode }) {
    function fallbackRender({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
        return (
            <div role="alert">
                <p>Something went wrong. Our team is notified and investigating.</p>
                <pre style={{ color: 'red' }}>{error.message}</pre>
                <button onClick={resetErrorBoundary}>Try again</button>
            </div>
        );
    }

    return (
        <ErrorBoundary
            fallbackRender={fallbackRender}
            onError={(error, info) => {
                Sentry.withScope((scope) => {
                    try {
                        console.debug({ info, error });
                        scope.setExtra('info', info);
                        Sentry.captureException(error);
                    } catch (err2: any) {
                        try {
                            console.debug({ info: 'Capturing Sentry exception error', err2 });
                            Sentry.captureException('Capturing Sentry exception error');
                        } catch (err3: any) {
                            console.debug({ info: 'Logging Sentry exception error', err3 });
                        }
                    }
                });
            }}
        >
            {props.children}
        </ErrorBoundary>
    );
}
