import { useCallback, useRef, useState } from 'react';

export type UseSnackbarReturn = {
    content: string | null;
    setSnackbarContent: (content: string | null, onDismiss?: () => void) => void;
    closeSnackbar: () => void;
};

export const useSnackbar = (): UseSnackbarReturn => {
    const [snackbarState, setSnackbarContent] = useState<{ content: string | null; onDismiss?: () => void }>({
        content: null,
    });

    const showTimeout = useRef<NodeJS.Timeout | null>(null);

    const closeSnackbar = useCallback(() => {
        setSnackbarContent({ content: null });
        snackbarState.onDismiss && snackbarState.onDismiss();
    }, [snackbarState]);

    const setSnackbarContentHandler = useCallback((content: string | null, onDismiss?: () => void) => {
        if (showTimeout.current) clearTimeout(showTimeout.current);
        setSnackbarContent({ content, onDismiss });
        showTimeout.current = setTimeout(() => {
            onDismiss && onDismiss();
            setSnackbarContent({ content: null });
        }, 5000);
    }, []);

    return {
        content: snackbarState.content,
        setSnackbarContent: setSnackbarContentHandler,
        closeSnackbar,
    };
};
