import React from 'react';
import { Depth } from 'wavepaths-shared/core';

import { IntensitySlider as IntensitySliderComponent } from '@/component-library';

import { MAX_SERVER_DEPTH, MIN_DEEPEN_DEPTH, MIN_SERVER_DEPTH } from './depthUtils';

export interface IDepthSliderProps {
    targetIntensity?: number;
    currentIntensity?: number;
    currentIntensityTransitionTimeSecs?: number;
    colorHex: string;
    colorHexTransitionTimeSecs: number;
    disabled?: boolean;
    onDepthChange: (depthRequest: { depth: Depth; extraMins?: number }) => void;
    onClickWhenDisabled?: () => void;
    setValueRefs?: (valueRefs: { value: number; ref: React.RefObject<HTMLDivElement> }[]) => void;
}
function IntensitySlider({
    targetIntensity,
    currentIntensity,
    currentIntensityTransitionTimeSecs,
    colorHex,
    colorHexTransitionTimeSecs,
    onDepthChange,
    onClickWhenDisabled,
    disabled = false,
}: IDepthSliderProps): JSX.Element {
    return (
        <div
            style={{
                minHeight: '300px',
                flexDirection: 'column',
                alignItems: 'center',
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
            }}
        >
            <IntensitySliderComponent
                min={MIN_SERVER_DEPTH}
                max={MAX_SERVER_DEPTH}
                divideAt={MIN_DEEPEN_DEPTH}
                targetIntensity={targetIntensity}
                currentIntensity={currentIntensity}
                currentIntensityTransitionTimeSecs={currentIntensityTransitionTimeSecs}
                colorHex={colorHex}
                colorHexTransitionTimeSecs={colorHexTransitionTimeSecs}
                disabled={disabled}
                onChange={(x) => onDepthChange({ depth: x })}
                onClickWhenDisabled={onClickWhenDisabled}
            />
        </div>
    );
}

export default IntensitySlider;
