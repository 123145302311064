import React from 'react';
import { Step } from 'react-joyride';

import { Typography } from '@/component-library';
import { ProductTour } from '@/components/ProductTour';
import { Features } from '@/features';

import { useAuthContext } from '../../auth';

const LoomVideo = () => {
    return (
        <div style={{ position: 'relative', paddingBottom: '60.8%', marginBottom: '1em', height: 0 }}>
            <iframe
                src="https://www.loom.com/embed/b9bfaf15519240d6a821f39e0f760b9b"
                frameBorder="0"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            ></iframe>
        </div>
    );
};

export const getSteps = (isEnabled: (ft: Features) => boolean): Step[] => {
    return [
        {
            title: 'Planning your first session',
            content: (
                <>
                    <Typography variant="body2">
                        Many things are possible when composing a Wavepaths session, so please familiarise yourself with
                        some of our key concepts by watching the introductory video below.
                    </Typography>
                    <LoomVideo />
                    <Typography variant="body2">
                        We'll then show you how to put these principles into practice, so please proceed and we'll guide
                        you through planning your first Wavepaths session.
                    </Typography>
                </>
            ),
            placement: 'center',
            target: 'body',
        },
        {
            target: '.tour-medicineInputs',
            title: 'Selecting use case',
            content: (
                <>
                    <Typography variant="body2">
                        Here you select the <b>medicine</b> or <b>modality</b> you work with, followed by the route of{' '}
                        <b>administration</b> and <b>dosage</b>, in case you're working with psychedelic medicines.
                    </Typography>
                    <Typography variant="body2">
                        When working with psychedelic medicines, these options will predict the pharmacodynamics, the
                        resulting “phases” of the session (example: timings of onset and peak of effects), and in turn
                        the musical variables that are filtered to be played for these different phases.
                    </Typography>
                    <Typography variant="body2">
                        <i>
                            For the purpose of this tour, go ahead and select Ketamine, and whichever dosage and
                            administration you prefer, then proceed.
                        </i>
                    </Typography>
                </>
            ),
        },
        {
            target: '.tour-musicalInputs',
            title: 'Selecting templates and music preferences',
            content: (
                <>
                    <Typography variant="body2">
                        <b>Music templates</b> form the basis of the music in your session. You can pick from a set
                        curated for each modality, that define how the <b>emotionality</b> and <b>intensity</b> of the
                        music will change throughout its duration. This can then be fully customised to your clients
                        needs.
                    </Typography>
                    <Typography variant="body2">
                        <b>Musical Preference</b> determines what kind of instruments will be prioritised when
                        generating the music for your session. Selecting either Electronic or Acoustic will set a
                        preference for the selected option, while Mixed will play both equally, as well as acoustic
                        instruments that have been processed electronically.
                    </Typography>
                    <Typography variant="body2">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://wavepaths.com/starter-toolkit-core-emotional-atmospheres"
                        >
                            Learn more about each emotionality, intensity and musical preference in Chapters 3 and 4 of
                            your Wavepaths Starter Pack.
                        </a>
                    </Typography>
                    <Typography variant="body2">
                        <i>
                            For the purpose of this tour, select a medium intensity percussive template. Then Proceed.
                        </i>
                    </Typography>
                </>
            ),
        },
        {
            target: '.tour-scoreOverview',
            title: 'Overview & advanced template design',
            content: (
                <>
                    <Typography variant="body2">
                        Once you've selected an initial template, an overview of what will play in the session is
                        displayed here. The colours denote the emotionality, the height of each wave corresponds to
                        emotional intensity, and the pattern of the line the therapeutic function.
                    </Typography>
                    {isEnabled(Features.WAVE_EDITOR) && (
                        <Typography variant="body2">
                            <i>
                                For the purpose of this tour, click "Edit" to see the journey in detail and make any
                                adjustments. Then Proceed.
                            </i>
                        </Typography>
                    )}
                </>
            ),
        },
        {
            target: '.tour-sessionNameInput',
            title: 'Session naming',
            content: (
                <>
                    <Typography variant="body2">
                        Choose a name for your session here, this can help you find the finished session on the
                        dashboard page. Care–providers often use client initials or other codes that help them identify
                        and find sessions of clients.
                    </Typography>
                </>
            ),
        },
        {
            target: '.tour-renderingType',
            title: 'Generation types',
            content: (
                <>
                    <Typography variant="body2">
                        This input allows you to chooses whether to run a session in real-time (Adaptable), or generate
                        the entire session upfront (Fixed).
                    </Typography>
                    <Typography variant="body2">
                        Fixed audio is particularly useful for users on slower connections as it permits a longer audio
                        buffer, whereas Adaptable allows you to adapt the music to your client's ongoing experience.
                    </Typography>
                    <Typography variant="body2">
                        <i>
                            For now, set it to {isEnabled(Features.LIVE_SESSION_RENDERING) ? '"Adaptable"' : '"Fixed"'},
                            then proceed.
                        </i>
                    </Typography>
                </>
            ),
        },
        ...(isEnabled(Features.LIVE_SESSION_RENDERING)
            ? [
                  {
                      target: '.tour-schedulingInput',
                      title: 'Session scheduling',
                      content: (
                          <>
                              <Typography variant="body2">
                                  This input allows you to schedule a session to either start a session right now, or
                                  automatically at a specific time, or to save a prepared session for an unspecified
                                  time in the future.
                              </Typography>
                          </>
                      ),
                  },
              ]
            : []),
        {
            target: '.tour-sessionUseInput',
            title: 'Session type',
            content: (
                <>
                    <Typography variant="body2">
                        Selecting the Session Use optimises your experience for the different use-cases, and allows us
                        to better understand how you're using our product.
                    </Typography>
                    <Typography variant="body2">
                        <i>For the purpose of this tour, select "Testing", then proceed.</i>
                    </Typography>
                </>
            ),
        },
        {
            target: '.tour-createSessionButton',
            title: 'Start or Save this Session',
            content: (
                <>
                    <Typography variant="body2">
                        Once you've finished setting up your session, you can either create it, or save it as a template
                        that you can re-use for future sessions.
                    </Typography>
                    {isEnabled(Features.WAVE_EDITOR) && (
                        <Typography variant="body2">
                            <i>
                                Choose "Start Session Now" if you'd like to continue the tour and hear the selected
                                session.
                            </i>
                        </Typography>
                    )}
                </>
            ),
        },
    ];
};

export function PlannerProductTour(): JSX.Element {
    const { isEnabled } = useAuthContext();
    const steps = getSteps(isEnabled);

    return <ProductTour steps={steps} />;
}
