import React from 'react';
import { PrePostLudePathScore, Wavepath } from 'wavepaths-shared/core';

import { DropdownControl } from '@/component-library';
import { ControlOption } from '@/component-library/components/Control/types';

export interface IPrePostludeWavepathSelectorProps {
    pathScores: PrePostLudePathScore[];
    wavepath: Wavepath;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
}

export function PrePostludePathSelector(props: IPrePostludeWavepathSelectorProps): JSX.Element {
    const { wavepath, onUpdateWave, pathScores } = props;

    const waveOptions: ControlOption<string>[] = pathScores.map((p) => ({
        label: p.name,
        value: p.id,
        disabled: false,
    }));

    const handleChange = (pathId: string) => {
        const pathScore = pathScores.find((p) => p.id === pathId);
        console.debug('Update Prelude Postlude', pathScore);

        pathScore && onUpdateWave({ ...wavepath, pathId, pathScore });
    };

    return (
        <DropdownControl
            name="wave-function"
            heading={'Wave'}
            canSave={false}
            size="small"
            colour="dark"
            options={waveOptions}
            value={wavepath.pathId}
            onChange={handleChange}
        />
    );
}
