import styled from '@emotion/styled';
import React from 'react';
export interface TemplateGridProps {
    children: React.ReactChild[] | React.ReactChild;
    className?: string;
}

const Container = styled.div`
    overflow: auto;
    display: grid;
    grid-auto-flow: column;
    padding-bottom: 15px;
`;

export const ScrollRow: React.FC<TemplateGridProps> = ({ children, className }) => {
    return <Container className={className}>{children}</Container>;
};

export const ScrollRowItem = styled.div`
    padding: 15px;
`;

export default ScrollRow;
