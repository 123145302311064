import React, { useContext } from 'react';
import { RequestType, Wavepath } from 'wavepaths-shared/core';
import { formatDurationSeconds } from 'wavepaths-shared/util/dateUtils';

import { WaveDurationMenu } from '@/component-library';

import { Connection, useSessionRemoteTick } from '../../common/hooks/useSessionTick';
import UserEvents from '../../UserEvents';
import { useRemoteDepth } from './depthSlider/useDepth';
import { RemoteSessionControlsContext } from './Guide';

export interface WaveEndCountdownProps {
    wave: Wavepath;
    connection: Connection;
    shouldExtensionBeDisabled: boolean;
    onSkipWave?: () => void;
}

export const WaveEndCountdownV2: React.FC<WaveEndCountdownProps> = ({
    wave,
    connection,
    shouldExtensionBeDisabled,
    onSkipWave,
}) => {
    const tick = useSessionRemoteTick();

    const extensionOptionsMinutes = [5, 10, 15];

    const waveDuration = wave.type === 'scheduled' && wave.plan ? wave.plan.toTime - wave.plan.fromTime : undefined;

    const pathScoreId = wave.pathScore.id;

    const timeUntilEnd =
        wave.type === 'scheduled' && tick && wave.plan ? wave.plan.toTime - tick.effectiveTime : undefined;
    const remoteSession = useContext(RemoteSessionControlsContext);
    if (remoteSession === undefined) throw new Error('missing remoteSession');

    const depthState = useRemoteDepth(connection, remoteSession.queueFunction);
    if (depthState === 'loading') return null;

    const onExtensionSelected = (extensionMins: number) => {
        const extensionSeconds = extensionMins * 60;
        remoteSession.queueFunction({
            description: 'Holding intensity and extending time',
            callback: () => {
                if (depthState.currentDepth === undefined) throw new Error('missing depthState.currentDepth');
                connection.sendRequest({
                    type: RequestType.ExtendCurrentWave,
                    extendBySeconds: extensionSeconds,
                    depth: depthState.currentDepth,
                });
                UserEvents.currentWaveExtended({
                    effectiveTimeSeconds: tick ? tick?.effectiveTime / 1000 : undefined,
                    pathScoreId,
                    timeUntilWaveEndSeconds: timeUntilEnd ? timeUntilEnd / 1000 : undefined,
                    waveDurationSeconds: waveDuration ? waveDuration / 1000 : undefined,
                    extendedBySeconds: extensionSeconds,
                });
            },
        });
    };

    const timeUntilEndText = timeUntilEnd && formatDurationSeconds(timeUntilEnd);

    return (
        <div className="tour-extendWave">
            {timeUntilEndText && (
                <WaveDurationMenu
                    timerText={timeUntilEndText}
                    extensionOptions={extensionOptionsMinutes}
                    onExtensionSelected={onExtensionSelected}
                    isExtensionDisabled={shouldExtensionBeDisabled}
                    onSkipClicked={onSkipWave}
                />
            )}
        </div>
    );
};
