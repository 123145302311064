import { Link } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import React, { FC } from 'react';

import { Button, Typography } from '@/component-library';

const HelpLink = styled(Link)({
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: 100,
});

const HelpButtonBase = styled(Button)({
    boxShadow: '0px 2px 8px rgba(0,14,51,0.4)',
});

const HelpIconLabel = styled(Typography)({
    color: 'white',
    paddingInlineStart: '.3em',
});

// TODO: replace the design once we can access shared components
// If we use a labelled button, we'll probably need to add a new variant
// (secondary/warning/important/danger or a way of overriding the main accent
// colour)
const HelpButton: FC = () => {
    return (
        <>
            <HelpLink href="mailto:support@wavepaths.com">
                <HelpButtonBase variant="solid-blue" size="m" icon={<>🛟</>}>
                    <HelpIconLabel variant="button">Help</HelpIconLabel>
                </HelpButtonBase>
            </HelpLink>
            {/* <HelpDialog open={openDialog} onClose={() => setOpenDialog(false)} /> */}
        </>
    );
};

export default HelpButton;
