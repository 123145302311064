import styled from '@emotion/styled';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import React, { useContext } from 'react';
import { isScheduledWavepath, PathScore, Wavepath } from 'wavepaths-shared/core';

import { Button, EvaIcon, Typography } from '@/component-library';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { PreviewPlayerContext } from '@/hooks/usePreviewPlayer';

import {
    isWaveSelected,
    selectNoWave,
    selectWave,
    WaveSelection,
} from '../../../pages/inSession/autoGuide/waveSelection';
import { RemoteSessionControlsContext } from '../../../pages/inSession/Guide';
import { WaveSparkline } from '../../../pages/inSession/timeline/WaveSparkline';
import UserEvents from '../../../UserEvents';
import WavePathSelector from '../WavePathSelector';
import WaveCardTopBar from './WaveCardTopBar';
import { WaveCardWaveDuration } from './WaveCardWaveDuration';

export const SKIP_WAVE_LABEL = 'Skip To This Wave';

const StyledCard = styled(Card)({
    width: '100%',
    height: 'auto',
    display: 'grid',
    // gridAutoFlow: 'row',
    justifyItems: 'center',
    padding: '0 12px 0 20px',
    borderRadius: 4,
    background: 'rgba(255,255,255,1)',
    boxShadow: 'none',
    position: 'relative',
    zIndex: 'auto',
    overflow: 'visible',

    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        borderRadius: 4,
        boxShadow: '0px 0px 20px rgba(0,0,0,0.1)',
    },
});

const Header = styled.div({
    width: '100%',
    height: '52px',
    display: 'flex',
    placeContent: 'center',
    cursor: 'pointer',
});

const HeaderContent = styled.div({
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateAreas: '"title sparkline duration actions"',
    gridTemplateColumns: '1fr 150px 45px minmax(min-content, auto)',
    alignItems: 'center',
    gap: '8px',
});

const WaveTitle = styled(Typography)({
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#2c3958',
});

const SparklineWrapper = styled.div({
    gridArea: 'sparkline',
    height: '100%',
    position: 'relative',
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'start',
    overflow: 'hidden',
});

const SparklineOverflow = styled.div({
    width: '100%',
    height: '100%',
    padding: '4px', // Preventing overflow issue
});

const Duration = styled(Typography)({
    gridArea: 'duration',
    textAlign: 'right',
    color: '#2c3958',
});

const ExpandedContentContainer = styled.div({
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
});

const WaveIcon = styled.div({});

export const CARD_HEIGHT_PADDING = 12;
const Container = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: CARD_HEIGHT_PADDING,
    // position: 'relative',
});

function WaveCard({
    index,
    wave,
    previousWave,
    waveSelection,
    onUpdateWave,
    onSkipToWave,
    onRemoveWave,
    onMoveWaveUp,
    onMoveWaveDown,
    onSetWaveSelection,
}: {
    wave: Wavepath;
    index: number;
    previousWave?: Wavepath;
    waveSelection: WaveSelection;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
    onSkipToWave?: (e: any) => void;
    onRemoveWave: (e: any) => void;
    onMoveWaveUp: (e: any) => void;
    onMoveWaveDown: (e: any) => void;
    onSetWaveSelection: (waveSelection: WaveSelection, force?: boolean) => void;
}): JSX.Element {
    const isOpen = isWaveSelected(waveSelection, wave.id);
    const { pathScore } = wave;
    const { name } = pathScore;

    const onCardClick = () => {
        if (isOpen) {
            onSetWaveSelection(selectNoWave());
            UserEvents.closeWaveViaQueue();
        } else {
            onSetWaveSelection(selectWave(wave));
            UserEvents.openWaveViaQueue();
        }
    };

    const remoteContext = useContext(RemoteSessionControlsContext);

    return (
        <>
            <Container>
                <StyledCard>
                    <Header aria-label="Edit upcoming wave" onClick={onCardClick}>
                        <WaveCardHeaderContentContainer
                            name={name}
                            wave={wave}
                            previousWave={previousWave}
                            isOpen={isOpen}
                            pathScore={pathScore}
                            onRemoveWave={onRemoveWave}
                            onMoveWaveUp={onMoveWaveUp}
                            onMoveWaveDown={onMoveWaveDown}
                        />
                    </Header>
                </StyledCard>
            </Container>
            {isOpen && (
                <Dialog open={isOpen}>
                    <DialogTitle>Edit wave</DialogTitle>
                    <DialogContent>
                        <>
                            <WaveCardHeaderContentContainer
                                name={name}
                                wave={wave}
                                previousWave={previousWave}
                                isOpen={isOpen}
                                pathScore={pathScore}
                                onRemoveWave={onRemoveWave}
                            />
                            <ExpandedContentContainer>
                                {onSkipToWave ? (
                                    <Button
                                        style={{ marginBottom: 16 }}
                                        size="s"
                                        onClick={onSkipToWave}
                                        variant="outlined"
                                    >
                                        <EvaIcon name="skip-forward" size={16} />
                                        <Typography variant="body2">{SKIP_WAVE_LABEL}</Typography>
                                    </Button>
                                ) : null}
                                <WavePathSelector wavepath={wave} onUpdateWave={onUpdateWave} />
                            </ExpandedContentContainer>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                {remoteContext &&
                                    remoteContext.queuedFunction &&
                                    remoteContext.queuedFunction.waveIndex === index &&
                                    remoteContext.queuedFunction.showQueuingUIInline && (
                                        <>
                                            <Button
                                                variant="solid-blue"
                                                onClick={() => {
                                                    remoteContext.onSkipQueue();
                                                    onCardClick();
                                                }}
                                            >
                                                Apply now or autosave in ...{' '}
                                                {remoteContext.queuedFunction.timeUntilExecutionInSeconds}s
                                            </Button>
                                            <Button
                                                variant="clear-underlined"
                                                onClick={() => {
                                                    remoteContext.onCancelQueuedFunction();
                                                }}
                                            >
                                                Cancel edit
                                            </Button>
                                        </>
                                    )}
                                <Button variant="clear-underlined" onClick={onCardClick}>
                                    Close
                                </Button>
                            </div>
                        </>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
}

export default WaveCard;
function WaveCardHeaderContentContainer({
    name,
    wave,
    previousWave,
    isOpen,
    pathScore,
    onRemoveWave,
    onMoveWaveUp,
    onMoveWaveDown,
}: {
    name: string;
    wave: Wavepath;
    previousWave: Wavepath | undefined;
    isOpen: boolean;
    pathScore: PathScore;
    onRemoveWave: (e: any) => void;
    onMoveWaveUp?: (e: any) => void;
    onMoveWaveDown?: (e: any) => void;
}) {
    const previewPlayerContext = useContext(PreviewPlayerContext);

    return (
        <HeaderContent>
            <div
                style={{
                    gridArea: 'title',
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                }}
            >
                <WaveTitle variant="body2">{name}</WaveTitle>

                {previewPlayerContext?.adaptivePlayer.connection &&
                previewPlayerContext?.previewSessionCurrentWave &&
                wave.id == previewPlayerContext.templateWave.current?.id ? (
                    <>
                        <WaveIcon>
                            <EvaIcon key={'preview'} name={'volume-up-outline'} size={22} fill="#6980b4" />
                        </WaveIcon>
                        <TypographyV2 size="text-sm">Previewing...</TypographyV2>
                        {/* //TODO: Preview time doesnt match the wave in template
                            <WaveEndCountdownV2
                            connection={previewPlayerContext.adaptivePlayer.connection}
                            wave={previewPlayerContext.previewSessionCurrentWave}
                            shouldExtensionBeDisabled={true}
                        /> */}
                    </>
                ) : (
                    <></>
                )}
            </div>
            {isScheduledWavepath(wave) && wave.plan && (
                <SparklineWrapper>
                    <SparklineOverflow>
                        <WaveSparkline
                            wave={wave}
                            previousWave={previousWave && isScheduledWavepath(previousWave) ? previousWave : undefined}
                        />
                    </SparklineOverflow>
                </SparklineWrapper>
            )}

            {/* <Intensity variant="body3">{capitalize(emotionalIntensity)}</Intensity> */}
            <Duration variant="body3">
                <WaveCardWaveDuration wave={wave} />
            </Duration>
            <WaveCardTopBar
                isOpen={isOpen}
                pathScore={pathScore}
                onRemoveWave={onRemoveWave}
                onMoveWaveUp={onMoveWaveUp}
                onMoveWaveDown={onMoveWaveDown}
            />
        </HeaderContent>
    );
}
