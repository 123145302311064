import React, { useState } from 'react';
import {
    Acousticness,
    BaseScheduledGenerativePathScore,
    CoreEmotionalAtmosphere,
    isScheduledGenerativeDeepenPathScore,
    PathScore,
    ScheduledExperimentalPathScore,
    ScheduledGenerativeDeepenPathScore,
    SessionScoreEmotionalIntensity,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { DropdownControl, SegmentedControl } from '@/component-library';

import { usePathScoreDurationSelector } from '.';
import * as infoCopy from './infoCopy';
import * as pathScoreFns from './pathScoreFns';

export interface SelectorState {
    tone?: Acousticness;
    music?: string;
    intensity?: SessionScoreEmotionalIntensity;
    duration: number | string;
}

const selectorStateDefault: SelectorState = {
    tone: 'Mixed',
    intensity: SessionScoreEmotionalIntensity.LOW,
    music: 'Any',
    duration: pathScoreFns.AUTO_VALUE,
};

export type DeepenPathScore =
    | BaseScheduledGenerativePathScore
    | ScheduledGenerativeDeepenPathScore
    | ScheduledExperimentalPathScore;

interface PercDeepenWaveSelectorProps {
    pathScores: DeepenPathScore[];
    wavepath: Wavepath;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
}

export const isDeepenPathScore = (p: PathScore): p is DeepenPathScore => p.direction === TherapeuticDirection.DEEPEN;
export const isPercussivePathScore = (pathScore: DeepenPathScore) => pathScore.mode === 'Percussive';

function PercDeepenWaveSelector({ pathScores, wavepath, onUpdateWave }: PercDeepenWaveSelectorProps): JSX.Element {
    const filteredPathScores = pathScores.filter(isScheduledGenerativeDeepenPathScore);

    const defaultTone =
        'acousticness' in wavepath.pathScore ? wavepath.pathScore.acousticness : selectorStateDefault.tone;
    const [tone, setTone] = useState(defaultTone);
    const toneColourFilteredScores = pathScoreFns.filterScoresByTone(filteredPathScores, tone);

    const defaultMusic = 'music' in wavepath.pathScore ? wavepath.pathScore.music : selectorStateDefault.music;
    const [music, setMusic] = useState(defaultMusic);
    const musicFilteredScores = pathScoreFns.filterScoresByMusic(toneColourFilteredScores, music);

    const defaultEmotion = isScheduledGenerativeDeepenPathScore(wavepath.pathScore)
        ? (wavepath.pathScore.emotion as CoreEmotionalAtmosphere)
        : musicFilteredScores.length && isScheduledGenerativeDeepenPathScore(musicFilteredScores[0])
        ? musicFilteredScores[0].emotion
        : CoreEmotionalAtmosphere.STILLNESS;
    const [emotion, setEmotion] = useState<CoreEmotionalAtmosphere | 'None'>(defaultEmotion);
    const emotionFilteredScores = pathScoreFns.filterScoresByEmotion(musicFilteredScores, emotion);

    const defaultIntensity = wavepath.pathScore.selectionCriteria?.emotionalIntensity ?? selectorStateDefault.intensity;
    const [intensity, setIntensity] = useState(defaultIntensity);
    const intensityFilteredScores = pathScoreFns.filterScoresByIntensity(emotionFilteredScores, intensity);

    if (musicFilteredScores.length == 0 && toneColourFilteredScores[0]) {
        setMusic(toneColourFilteredScores[0].music ?? 'Any');
    }

    if (emotionFilteredScores.length == 0 && musicFilteredScores[0]) {
        setEmotion(musicFilteredScores[0].emotion ?? 'None');
    }

    if (intensityFilteredScores.length == 0 && emotionFilteredScores[0]) {
        setIntensity(emotionFilteredScores[0].selectionCriteria?.emotionalIntensity);
    }

    const { durationOptions, preferredDuration, setPreferredDuration } = usePathScoreDurationSelector({
        wavepath,
        onUpdateWave,
        preDurationFilteredScores: intensityFilteredScores,
        dependencies: [tone, intensity, music, emotion],
    });

    return (
        <>
            <SegmentedControl
                name="wave-tone"
                heading={'Tone'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    ['Acoustic', 'Mixed', 'Electronic'] as Acousticness[],
                    pathScoreFns.extractToneColoursFromScores(filteredPathScores),
                )}
                value={tone}
                onChange={setTone}
                info={infoCopy.tone}
            />
            <DropdownControl
                name="wave-music"
                heading={'Music'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    [...pathScoreFns.extractMusicOptionsFromScores(toneColourFilteredScores)],
                    pathScoreFns.extractMusicOptionsFromScores(toneColourFilteredScores),
                )}
                value={music}
                onChange={setMusic}
                info={infoCopy.composition}
            />
            <SegmentedControl
                name="wave-emotion"
                heading={'Emotionality'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    [
                        'None',
                        CoreEmotionalAtmosphere.STILLNESS,
                        CoreEmotionalAtmosphere.VITALITY,
                        CoreEmotionalAtmosphere.BITTERSWEET,
                        CoreEmotionalAtmosphere.TENSION,
                    ] as (CoreEmotionalAtmosphere | 'None')[],
                    pathScoreFns.extractEmotionsFromScoresWithNone(musicFilteredScores),
                )}
                value={emotion}
                onChange={setEmotion}
                info={infoCopy.emotion}
            />
            <SegmentedControl
                name="wave-intensity"
                heading={'Intensity'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    [
                        SessionScoreEmotionalIntensity.LOW,
                        SessionScoreEmotionalIntensity.MEDIUM,
                        SessionScoreEmotionalIntensity.HIGH,
                        SessionScoreEmotionalIntensity.ALL,
                    ],
                    pathScoreFns.extractIntensitiesFromScores(musicFilteredScores),
                )}
                value={intensity}
                onChange={setIntensity}
                info={infoCopy.intensity}
            />
            <DropdownControl
                name="wave-duration"
                heading={'Duration'}
                canSave={false}
                size="small"
                colour="dark"
                options={durationOptions}
                value={preferredDuration}
                onChange={setPreferredDuration}
                info={infoCopy.duration}
            />
        </>
    );
}

export default PercDeepenWaveSelector;
