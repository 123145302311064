import React, { useContext, useEffect } from 'react';

import { Button, DropdownControl } from '@/component-library';

import { AudioPlayerContext } from '../sessions/EndedSessionsTable/useAudioPlayer';

export const AudioSettings: React.FC<{
    closeDialog: () => void;
}> = ({ closeDialog }) => {
    const audioPlayer = useContext(AudioPlayerContext);
    const options =
        audioPlayer?.devices.outputDevices.map((x) => {
            return {
                value: x.deviceId,
                label: x.label,
            };
        }) ?? [];

    useEffect(() => {
        if (!audioPlayer) {
            console.debug('No Audio Player context');
            return;
        }
        (async () => {
            try {
                await audioPlayer.devices.getConsentToListDevices();
            } catch {
                console.debug('Error listing devices or no consent');
            }
            await audioPlayer.devices.refreshDevices();
        })();
    }, []);

    return (
        <>
            {audioPlayer ? (
                <div>
                    <h3>Output device</h3>
                    <DropdownControl
                        options={options}
                        canSave={true}
                        onChange={audioPlayer.devices.setCurrentOutputDevice}
                        size="large"
                        colour="dark"
                        value={audioPlayer.devices.currentOutputDevice}
                    />
                </div>
            ) : (
                <div>
                    <h3>To control output, choose "This Device" for playback</h3>
                </div>
            )}
            <Button onClick={closeDialog}>Close</Button>
        </>
    );
};
