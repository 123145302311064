import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect } from 'react';

import { useDebouncedState } from '@/hooks';

// import styled, { css } from '@emotion/styled';
interface ISearchBar {
    label: string;
    onChange?: (value: string) => void;
    value: string;
    onClear: () => void;
}

export const SearchBar = ({ value, label, onChange, onClear }: ISearchBar): React.ReactElement => {
    const theme = useTheme();
    const useStyles = makeStyles({
        root: {
            margin: '0.5em 0',
        },
        inputLabel: {
            color: theme.palette.action.disabled,
        },
    });
    const { inputLabel, root } = useStyles();
    const empty = value === '';

    const [debouncedValue, setDebouncedValue, immediateValue] = useDebouncedState<string>(value, 500);

    useEffect(() => {
        onChange && onChange(debouncedValue);
    }, [debouncedValue]);

    return (
        <>
            <TextField
                classes={{ root }}
                label={label}
                aria-label={label}
                variant="outlined"
                onChange={(event) => setDebouncedValue(event.target.value)}
                value={immediateValue}
                InputLabelProps={{
                    className: inputLabel,
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                disabled={empty}
                                onClick={() => {
                                    onClear();
                                    setDebouncedValue('');
                                }}
                                color="primary"
                            >
                                {empty ? <SearchIcon /> : <ClearIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
};
