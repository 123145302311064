export enum Features {
    FREE_ACCESS = 'freeAccess',
    BONNY = 'bonny',
    THERAPIST_NOTES = 'therapistNotes',
    GUIDED_ONE_ON_ONE = 'guidedOneOnOne',
    TEMPLATE_RECOMMENDER = 'templateRecommender',
    LIVE_SESSION_RENDERING = 'realTimeAdaptation',
    UPLOAD_CUSTOM_SOUNDS = 'uploadCustomSounds',
    SAVE_SESSION_TEMPLATES = 'saveSessionTemplates',
    SESSION_DASHBOARD = 'sessionDashboard',
    WAVE_EDITOR = 'waveEditor',
    CLIENT_INTEGRATION = 'clientIntegration',
    SESSION_LAUNCHING_V2 = 'sessionLaunchingV2',
    ADAPTIVE_PERCUSSIVE = 'adaptivePercussive',
    TEMPLATE_PROGRAMS = 'templatePrograms',
    BLOCKED_REMOTE_LISTEN = 'blockedRemoteListen',
    BLOCK_SHORT_REALTIME = 'BlockShortRealtime',
}

const enabledFeatures: Features[] = [
    Features.BONNY,
    Features.FREE_ACCESS,
    Features.GUIDED_ONE_ON_ONE,
    Features.SESSION_LAUNCHING_V2,
    Features.TEMPLATE_RECOMMENDER,
    Features.ADAPTIVE_PERCUSSIVE,

    // advanced tier features below
    Features.LIVE_SESSION_RENDERING,
    Features.UPLOAD_CUSTOM_SOUNDS,
    Features.TEMPLATE_PROGRAMS,
    Features.SAVE_SESSION_TEMPLATES,
    Features.SESSION_DASHBOARD,
    Features.WAVE_EDITOR,
    Features.CLIENT_INTEGRATION,
    Features.BLOCKED_REMOTE_LISTEN,
    Features.BLOCK_SHORT_REALTIME,
];

export default enabledFeatures;
