import React, { useContext } from 'react';
import styled from 'styled-components';

import { Button, EvaIcon } from '@/component-library';
import { SessionCacheContext } from '@/hooks/useAudioCache';

const CacheContainer = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 75px;
    background-color: rgba(255, 255, 255, 0.4);
`;

export const AudioDownload: React.FC = () => {
    const cacheContext = useContext(SessionCacheContext);
    if (!cacheContext) {
        return <></>;
    }
    const audioCache = cacheContext.audioCache;

    const onClick =
        audioCache.status == 'idle' || audioCache.status == 'failed' || audioCache.status == 'deleting'
            ? () => cacheContext.cacheSession()
            : audioCache.status == 'done'
            ? () => cacheContext.deleteSession()
            : undefined;

    return (
        <CacheContainer>
            <Button
                onClick={onClick}
                style={{
                    backgroundColor: audioCache.status == 'done' ? 'rgba(46, 204, 113, 0.5)' : 'inherit',
                }}
            >
                {audioCache.status == 'idle' ? <EvaIcon name="download-outline" size={25} fill="#2c3958" /> : <></>}
                {audioCache.status == 'failed' ? (
                    <>
                        <EvaIcon name="download-outline" size={25} fill="#2c3958" />
                        <span>Failed, try again?</span>
                    </>
                ) : (
                    <></>
                )}

                {audioCache.status == 'deleting' ? (
                    <>
                        <EvaIcon name="download-outline" size={25} fill="#2c3958" />
                        <span>Deleted</span>
                    </>
                ) : (
                    <></>
                )}

                {audioCache.status == 'downloading' ? (
                    <>
                        <EvaIcon name="loader-outline" size={25} fill="#2c3958" />
                        <span>{audioCache.percentCompleted} %</span>
                    </>
                ) : (
                    <></>
                )}
                {audioCache.status == 'generating' ? (
                    <>
                        <EvaIcon name="clock-outline" size={25} fill="#2c3958" />
                        <span>{audioCache.percentCompleted} %</span>
                    </>
                ) : (
                    <></>
                )}
                {audioCache.status == 'done' ? (
                    <>
                        <EvaIcon name="download-outline" size={25} fill="#2c3958" />
                        <EvaIcon name="done-all" size={16} fill="#2c3958" />
                    </>
                ) : (
                    <></>
                )}
            </Button>
        </CacheContainer>
    );
};
