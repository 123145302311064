import styled from '@emotion/styled';
import MaterialMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

import Typography from '../typography/Typography';
import Button from './Button';
import EvaIcon from './EvaIcon';

export interface IWaveDurationMenuProps {
    timerText: string;
    extensionOptions: number[];
    onExtensionSelected?: (extension: number) => void;
    isExtensionDisabled?: boolean;
    isSkipDisabled?: boolean;
    onSkipClicked?: () => void;
}

const Container = styled.div({
    height: 24,
    borderRadius: 12,
    display: 'grid',
    gridAutoFlow: 'column',
    gap: 8,
    alignItems: 'center',
    alignContent: 'center',
});

export const Countdown = styled(Typography)({
    fontSize: 18,
    lineHeight: 1,
    color: '#2C3958',
    minWidth: '80px',
});

const IconButton = styled(Button)({
    display: 'flex',
    width: 36,
    height: 36,
    //borderRadius: '14px',
    //border: '1px solid #ADB9D6',
    pointerEvents: 'auto',
    transition: 'opacity 0.15s ease',
    ':disabled': {
        display: 'none',
        opacity: 0,
        posinterEvents: 'none',
    },
});

const Icon = styled(EvaIcon)({});

const StyledMenu = styled(MaterialMenu)({
    ul: {
        minWidth: 180,
        padding: 0,
        margin: 0,
    },
    '& .MuiMenu-paper': {
        boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)',
    },
});

const WaveDurationMenu = ({
    timerText,
    extensionOptions,
    onExtensionSelected,
    onSkipClicked,
    isSkipDisabled = false,
    isExtensionDisabled,
}: IWaveDurationMenuProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleItemSelected = (extension: number): void => {
        onExtensionSelected && onExtensionSelected(extension);
        handleClose();
    };
    return (
        <>
            <Container>
                <Countdown variant="h6FixedWidth" isFixedWidth={true}>
                    {timerText}
                </Countdown>
                {onExtensionSelected && (
                    <IconButton
                        variant="outlined"
                        size="s"
                        disabled={isExtensionDisabled}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        aria-label={'extend current wave'}
                        onClick={handleOpen}
                    >
                        <Icon name="flip-2-outline" size="16px" fill="#2C3958" />
                    </IconButton>
                )}
                {onSkipClicked && (
                    <IconButton
                        variant="outlined"
                        size="s"
                        disabled={isSkipDisabled}
                        aria-label={'skip current wave'}
                        onClick={onSkipClicked}
                    >
                        <Icon name="skip-forward" size="20px" fill="#2C3958" />
                    </IconButton>
                )}
            </Container>
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                {extensionOptions.map((option) => (
                    <MenuItem key={option} onClick={() => handleItemSelected(option)}>
                        <Typography variant="body2">Hold current intensity and add {option} mins</Typography>
                    </MenuItem>
                ))}
            </StyledMenu>
        </>
    );
};

export default WaveDurationMenu;
