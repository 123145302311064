import React from 'react';
import { Redirect, RouteProps, useLocation } from 'react-router-dom';

import { Feature, Features } from '@/features';

import { PrivateRoute } from './PrivateRoute';

export function PaidRoute({ children, ...rest }: RouteProps) {
    const location = useLocation();

    return (
        <PrivateRoute {...rest}>
            <Feature
                name={Features.FREE_ACCESS}
                activeComponent={<>{children}</>}
                inactiveComponent={
                    <Redirect
                        to={{
                            pathname: '/subscriptions',
                            state: { from: location },
                        }}
                    />
                }
            />
        </PrivateRoute>
    );
}
