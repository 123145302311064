import styled from '@emotion/styled';
import React from 'react';

import { Typography } from '@/component-library';

import InfoButtonAndDialog from './InfoButtonAndDialog';

const Table = styled.table({
    width: '100%',
    marginBottom: '1em',
    borderCollapse: 'collapse',
});

const Thead = styled.thead({
    'tr td': {
        borderBottom: '1px solid rgba(0,0,0,0.2)',
        fontWeight: 'bold',
        paddingBottom: '0.5em',
    },
});

const Tr = styled.tr({
    '&:nth-child(odd) td': {
        background: 'rgba(0,0,0,0.05)',
    },
});

const Td = styled.td<{ last?: boolean }>`
    width: 33%;
    padding: 0.5em;
    text-align: ${(props) => (props.last ? 'right' : 'left')};
`;

const DosageInfoDialog: React.FC = () => (
    <InfoButtonAndDialog title="Dosage">
        <Typography variant="body2" color="textSecondary">
            Together with administration type, the selection of dosage will inform the respective durations of the
            different phases in the session (Pre-onset, Onset, Peak, Return).
        </Typography>
        <Typography variant="body2" color="textSecondary">
            Please take note that these are generalised values, and that there will be variance between individuals.
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" component="div">
            <Table>
                <Thead>
                    <tr>
                        <Td colSpan={3}>Ketamine</Td>
                    </tr>
                </Thead>
                <tbody>
                    <Tr>
                        <Td>Intramuscular</Td>
                        <Td>Low</Td>
                        <Td last>&lt;25mg</Td>
                    </Tr>
                    <Tr>
                        <Td></Td>
                        <Td>Medium</Td>
                        <Td last>25-40mg</Td>
                    </Tr>
                    <Tr>
                        <Td></Td>
                        <Td>High</Td>
                        <Td last>&gt;40mg</Td>
                    </Tr>
                    <Tr>
                        <Td>Intravenous</Td>
                        <Td>Low</Td>
                        <Td last>&lt;20mg</Td>
                    </Tr>
                    <Tr>
                        <Td></Td>
                        <Td>Medium</Td>
                        <Td last>20-40mg</Td>
                    </Tr>
                    <Tr>
                        <Td></Td>
                        <Td>High</Td>
                        <Td last>&gt;40mg</Td>
                    </Tr>
                    <Tr>
                        <Td>Oral</Td>
                        <Td>Low</Td>
                        <Td last>&lt;100mg</Td>
                    </Tr>
                    <Tr>
                        <Td></Td>
                        <Td>Medium</Td>
                        <Td last>100-200mg</Td>
                    </Tr>
                    <Tr>
                        <Td></Td>
                        <Td>High</Td>
                        <Td last> &gt;200mg</Td>
                    </Tr>
                    <Tr>
                        <Td>Nasal</Td>
                        <Td>Low</Td>
                        <Td last>&lt;56mg</Td>
                    </Tr>
                    <Tr>
                        <Td></Td>
                        <Td>Medium</Td>
                        <Td last>56-83mg</Td>
                    </Tr>
                    <Tr>
                        <Td></Td>
                        <Td>High</Td>
                        <Td last>&gt;83mg</Td>
                    </Tr>
                </tbody>
            </Table>
            <Table>
                <Thead>
                    <tr>
                        <Td colSpan={3}>Psilocybin</Td>
                    </tr>
                </Thead>
                <tbody>
                    <Tr>
                        <Td>Oral</Td>
                        <Td>Low</Td>
                        <Td last>&lt;10mg</Td>
                    </Tr>
                    <Tr>
                        <Td></Td>
                        <Td>Medium</Td>
                        <Td last>10-30mg</Td>
                    </Tr>
                    <Tr>
                        <Td></Td>
                        <Td>High</Td>
                        <Td last>&gt;30mg</Td>
                    </Tr>
                </tbody>
            </Table>
            <Table>
                <Thead>
                    <tr>
                        <Td colSpan={3}>MDMA</Td>
                    </tr>
                </Thead>
                <tbody>
                    <Tr>
                        <Td>Oral</Td>
                        <Td>Low</Td>
                        <Td last>&lt;75mg</Td>
                    </Tr>
                    <Tr>
                        <Td></Td>
                        <Td>Medium</Td>
                        <Td last>75-100mg</Td>
                    </Tr>
                    <Tr>
                        <Td></Td>
                        <Td>High</Td>
                        <Td last>&gt;100mg</Td>
                    </Tr>
                </tbody>
            </Table>
        </Typography>
    </InfoButtonAndDialog>
);

export default DosageInfoDialog;
