import { partition } from 'lodash';
import React, { useState } from 'react';
import {
    CoreEmotionalAtmosphere,
    PathType,
    PrePostLudePathScore,
    ScheduledPathScore,
    ScheduledWavepath,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { SegmentedControl } from '@/component-library';
import { ControlOption } from '@/component-library/components/Control/types';

import { GenericErrorBoundary } from '../GenericErrorBoundary';
import BridgeWaveSelector, { isBridgePathScore } from './BridgeWaveSelector';
import * as infoCopy from './infoCopy';
import PercDeepenWaveSelector, { isDeepenPathScore, isPercussivePathScore } from './PercDeepenWaveSelector';
import SilenceWaveSelector, { isSilencePathScore } from './SilenceWaveSelector';
import SootheWaveSelector, { isSoothePathScore } from './SootheWaveSelector';
import TonalDeepenWaveSelector from './TonalDeepenWaveSelector';

export interface IScheduledWavepathSelectorProps {
    pathScores: ScheduledPathScore[];
    wavepath: ScheduledWavepath;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
}

export interface IPrePostludeWavepathSelectorProps {
    pathScores: PrePostLudePathScore[];
    wavepath: Wavepath;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
}

const listToOptions = <T extends string>(list: T[]): ControlOption<T>[] =>
    list.map((item) => ({ label: item.toString(), value: item, disabled: false }));

export function ScheduledWavePathSelector(props: IScheduledWavepathSelectorProps): JSX.Element {
    const { wavepath, onUpdateWave, pathScores: allPathScores } = props;
    const currentDirection =
        wavepath.pathScore.direction === TherapeuticDirection.DEEPEN
            ? wavepath.pathScore.mode === 'Percussive'
                ? 'Deepen (Percussive)'
                : 'Deepen (Tonal)'
            : wavepath.pathScore.direction;
    const [therapeuticFunction, setFunction] = useState<TherapeuticDirection | string>(currentDirection); // should be current wave value

    const pathScores = allPathScores.filter((p) => p.type !== PathType.CURATED);

    const silencePathScores = pathScores.filter(isSilencePathScore);
    const soothePathScores = pathScores.filter(isSoothePathScore);
    const deepenPathScores = pathScores.filter(isDeepenPathScore);
    const [percDeepenPathScores, tonalDeepenPathScores] = partition(deepenPathScores, isPercussivePathScore);
    const bridgePathScores = pathScores.filter(isBridgePathScore);

    const onFunctionChange = (newTherapeuticFunction: string) => {
        let defaultWavePath: Wavepath | undefined = undefined;

        if (newTherapeuticFunction === TherapeuticDirection.SILENCE) {
            const defaultSilencePathScore = silencePathScores[0];
            defaultWavePath = {
                ...wavepath,
                duration: undefined,
                pathScore: defaultSilencePathScore,
                pathId: defaultSilencePathScore.id,
            };
        } else if (newTherapeuticFunction === TherapeuticDirection.SOOTHE) {
            // Is it necessary to sample with seed?
            const soothingDefaultScore = soothePathScores.filter(
                (x) => x.mode == 'Unstructured' && x.emotion == CoreEmotionalAtmosphere.STILLNESS,
            )[0];
            defaultWavePath = {
                ...wavepath,
                duration: undefined,
                pathScore: soothingDefaultScore,
                pathId: soothingDefaultScore.id,
            };
        } else if (newTherapeuticFunction === 'Deepen (Tonal)') {
            defaultWavePath = {
                ...wavepath,
                duration: undefined,
                pathScore: tonalDeepenPathScores[0],
                pathId: tonalDeepenPathScores[0].id,
            };
        } else if (newTherapeuticFunction === 'Deepen (Percussive)') {
            defaultWavePath = {
                ...wavepath,
                duration: undefined,
                pathScore: percDeepenPathScores[0],
                pathId: percDeepenPathScores[0].id,
            };
        } else if (newTherapeuticFunction === TherapeuticDirection.BRIDGE) {
            defaultWavePath = {
                ...wavepath,
                duration: undefined,
                pathScore: bridgePathScores[0],
                pathId: bridgePathScores[0].id,
            };
        }

        if (defaultWavePath) {
            onUpdateWave(defaultWavePath);
        }
        setFunction(newTherapeuticFunction);
    };

    return (
        <>
            <GenericErrorBoundary>
                <SegmentedControl
                    heading={'Function'}
                    name="wave-function"
                    canSave={false}
                    size="large"
                    inline
                    colour="dark"
                    options={listToOptions([
                        TherapeuticDirection.SILENCE,
                        TherapeuticDirection.SOOTHE,
                        'Deepen (Tonal)',
                        'Deepen (Percussive)',
                        TherapeuticDirection.BRIDGE,
                    ])}
                    value={therapeuticFunction}
                    onChange={onFunctionChange}
                    info={infoCopy.funktion}
                />
                {therapeuticFunction === TherapeuticDirection.SILENCE && (
                    <SilenceWaveSelector
                        onUpdateWave={onUpdateWave}
                        wavepath={wavepath}
                        pathScores={silencePathScores}
                    />
                )}
                {therapeuticFunction === TherapeuticDirection.SOOTHE && (
                    <SootheWaveSelector onUpdateWave={onUpdateWave} wavepath={wavepath} pathScores={soothePathScores} />
                )}
                {therapeuticFunction === 'Deepen (Tonal)' && (
                    <TonalDeepenWaveSelector
                        pathScores={tonalDeepenPathScores}
                        onUpdateWave={onUpdateWave}
                        wavepath={wavepath}
                    />
                )}
                {therapeuticFunction === 'Deepen (Percussive)' && (
                    <PercDeepenWaveSelector
                        pathScores={percDeepenPathScores}
                        onUpdateWave={onUpdateWave}
                        wavepath={wavepath}
                    />
                )}
                {therapeuticFunction === TherapeuticDirection.BRIDGE && (
                    <BridgeWaveSelector onUpdateWave={onUpdateWave} wavepath={wavepath} pathScores={bridgePathScores} />
                )}
            </GenericErrorBoundary>
        </>
    );
}
