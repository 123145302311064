import React from 'react';
import { useLocation } from 'react-router-dom';
import { isAdmin } from 'wavepaths-shared/domain/user';

import Layout from '@/component-library/components/Layout';
import { Features } from '@/features';

import { signOut, useAuthContext } from './auth';

export const LayoutContainer = ({ children }: { children: React.ReactChild[] | React.ReactChild }) => {
    const location = useLocation();
    const { userData, isEnabled } = useAuthContext();

    return (
        <Layout
            currentRoute={location.pathname}
            userName={userData?.name ?? ''}
            onSignOutClick={signOut}
            isAdmin={!!userData && isAdmin(userData)}
            showSavedTemplates={isEnabled(Features.SAVE_SESSION_TEMPLATES)}
            showTemplatePrograms={isEnabled(Features.SAVE_SESSION_TEMPLATES)} // MK20231003 TODO: perms to show tempalte programs
        >
            {children}
        </Layout>
    );
};
