import { SnackbarContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, styled, Theme } from '@material-ui/core/styles';
import React, { createContext, ReactNode } from 'react';

import { useSnackbar, UseSnackbarReturn } from '@/hooks';
//TODO: move this to a style types file

type SnackbarProps = SnackbarStylesProps & {
    message: string | null;
    confirmText: string;
    open: boolean;
    closeSnackbar: () => void;
    onClickCallback?: () => void;
    className?: string;
};

type SnackbarStylesProps = {
    type: 'success' | 'warning' | 'error';
    isLongButton: boolean;
};
const useStyles = makeStyles<Theme, SnackbarStylesProps>((theme) => ({
    snackBarContentStyle: ({ type }) => ({
        color: type === 'warning' ? theme.palette.primary.light : theme.palette.primary.dark,
        backgroundColor:
            type === 'success'
                ? theme.palette.success.main
                : type === 'warning'
                ? theme.palette.warning.main
                : theme.palette.error.main,
        lineHeight: '20px',
        fontSize: '13.3px',
        maxWidth: '334px',
    }),
    message: ({ isLongButton }) => ({
        maxWidth: isLongButton ? undefined : '230px',
    }),
    button: ({ type }) => ({
        color: type === 'warning' ? theme.palette.primary.light : theme.palette.primary.dark,
    }),
}));

const WavepathsSnackbar = React.memo(
    ({
        message,
        confirmText,
        open,
        closeSnackbar,
        type,
        isLongButton,
        onClickCallback,
        className,
    }: SnackbarProps): JSX.Element => {
        const classes = useStyles({ type, isLongButton });

        const closeBar = () => {
            onClickCallback && onClickCallback();
            closeSnackbar();
        };

        const action = (
            <Button className={classes.button} size="small" onClick={closeBar}>
                {confirmText}
            </Button>
        );

        const messageDiv = <div className={classes.message}>{message}</div>;

        return message ? (
            <Snackbar className={className} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open}>
                <SnackbarContent className={classes.snackBarContentStyle} message={messageDiv} action={action} />
            </Snackbar>
        ) : (
            <></>
        );
    },
);

const FixedSnackbar = styled(WavepathsSnackbar)({
    position: 'absolute',
});

export const GlobalSnackbarContext = createContext<ReturnType<typeof useSnackbar>>({
    setSnackbarContent: (content) => {
        console.debug({ info: 'setSnackbarContent', content });
    },
} as UseSnackbarReturn);

export const GlobalSnackbarWrapper: React.FC<{ children: ReactNode | ReactNode[] }> = ({ children }) => {
    const snackbarData = useSnackbar();
    return (
        <>
            <GlobalSnackbarContext.Provider value={snackbarData}>{children}</GlobalSnackbarContext.Provider>
            <FixedSnackbar
                type="warning"
                isLongButton={false}
                message={snackbarData.content}
                confirmText={'OK'}
                open={!!snackbarData.content}
                closeSnackbar={snackbarData.closeSnackbar}
            />
        </>
    );
};

export default WavepathsSnackbar;
