import React from 'react';
import { useHistory } from 'react-router-dom';

import SectionHeading from '@/component-library/components/SectionHeading';

import { NewsArticle } from './NewsArticle';
import { useNewsArticleLatest } from './useNews';

function NewsLatestContainer() {
    const { article: articleSelected } = useNewsArticleLatest();
    const history = useHistory();

    if (!articleSelected) {
        return null;
    }

    return (
        <>
            <SectionHeading text="Latest news" moreLink={{ label: 'See All', action: () => history.push('/news') }} />

            <NewsArticle
                key={articleSelected.id}
                article={articleSelected}
                onClick={() => history.push('/news?article=' + articleSelected.id)}
            />
        </>
    );
}

export default NewsLatestContainer;
