import styled from '@emotion/styled';
import { findIndex } from 'lodash';
import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useInterval } from 'react-use';
import {
    isPreludeWavepath,
    isScheduledWavepath,
    ScheduledPathScore,
    ScheduledWavepath,
    Session,
    SessionScore,
    SessionVariables,
    TimestampedSessionEvent,
    VoiceoverStage,
    Wavepath,
} from 'wavepaths-shared/core';
import { makeScoreExpressionEvaluator } from 'wavepaths-shared/scoreExpressions';

import { Connection, useRemoteCurrentWave, useRemoteSessionDurations } from '../../../common/hooks/useSessionTick';
import UserEvents from '../../../UserEvents';
import { getZeroTick } from '../../planner/SessionVariableInputs';
import { VoiceOverStagesContext } from '../../templateInfo';
import { isAnyWaveSelected, isWaveSelected, selectNoWave, selectWave, WaveSelection } from '../autoGuide/waveSelection';
import { PHASE_GAP, PIXELS_PER_MILLIS } from './constants';
import { TimelinePhase } from './TimelinePhase';
import { TimelinePlayhead } from './TimelinePlayhead';
import { TimelineWave } from './TimelineWave';
import { TimelineWaveProperties } from './timelineWaveUtils';

const Container = styled.div<{
    isScrollable: boolean;
}>(
    ({ isScrollable }) => `
    width: 100%;
    display: grid;
    place-content: center;
    overflow-x: ${isScrollable ? 'scroll' : undefined};
`,
);

const Overflow = styled.div<{
    timelineHeight: number;
    timelinePosition: 'fixed' | 'moving';
}>(
    ({ timelineHeight, timelinePosition }) => `
    position: relative;
    height: ${timelineHeight + 74}px;
    min-width: 200px;
    max-width: 100%;
    padding: 0 22px; 
    display: inline-grid;
    align-content: center;
    justify-content: ${timelinePosition === 'fixed' ? 'left' : 'start'};
`,
);

const SVG = styled.svg`
    overflow: visible;
    cursor: pointer;
    will-change: transform;
`;

const Phases = styled.div<{
    width: number;
}>(
    ({ width }) => `
    width: ${width}px;
    height: 0;
    display: grid;
    grid-auto-flow: column;
    gap: ${PHASE_GAP}px;
`,
);

interface TimelineProps {
    score: SessionScore;
    variables: SessionVariables;
    log?: TimestampedSessionEvent[];
    waveSelection: WaveSelection;
    setWaveSelection?: (newSelection: WaveSelection) => void;
    isScrollable?: boolean;
    connection?: Connection;
    phasesAlwaysVisible?: boolean;
    session?: Session;
    isPlanner?: boolean;
    Playhead: ReactNode;
    onScroll?: () => void;
}

const defaultLog: TimestampedSessionEvent[] = [];

export const TimelineContext = createContext<
    { waveClick: (wave: Wavepath, offsetSeconds?: number) => void } | undefined
>(undefined);

const getWaveSparklineOpacity = (selectedWaveIndex: number, currentWaveIndex: number) => (index: number) => {
    if (selectedWaveIndex === index) {
        return 'full';
    } else if (selectedWaveIndex >= 0) {
        return 'low';
    } else if (currentWaveIndex > index) {
        return 'medium';
    } else {
        return 'full';
    }
};

export const _Timeline: React.FC<TimelineProps> = React.memo(
    ({
        score,
        variables,
        log = defaultLog,
        waveSelection,
        setWaveSelection,
        isScrollable = false,
        phasesAlwaysVisible,
        isPlanner,
        Playhead,
        onScroll,
    }) => {
        const plan = score.wavepaths;
        const scheduledPlan = useMemo(() => plan.filter(isScheduledWavepath), [plan]);
        const zeroTick = useMemo(() => getZeroTick(score, variables), [score, variables]);
        const { sessionDuration } = useRemoteSessionDurations(zeroTick);
        const { wave: currentWave } = useRemoteCurrentWave(score.wavepaths);
        const currentWaveIndex = getCurrentWaveIndex(currentWave, scheduledPlan);
        const selectedWaveIndex = findIndex(scheduledPlan, (i) => isWaveSelected(waveSelection, i.id));

        const wrapperRef = useRef<HTMLDivElement>();

        const timelineWidth = Math.max(PIXELS_PER_MILLIS * sessionDuration, 0);
        const timelineHeight = 56;
        const customVoiceoverHeight = 20;
        const wavesHeight = timelineHeight - customVoiceoverHeight;
        const waves = useMemo(
            () =>
                scheduledPlan.map((item, idx) => ({
                    wave: item,
                    previousWavePathScore: scheduledPlan[idx - 1]?.pathScore,
                    x: item.plan!.fromTime! * PIXELS_PER_MILLIS,
                    width: (item.plan!.toTime! - item.plan!.fromTime!) * PIXELS_PER_MILLIS,
                    height: wavesHeight,
                })),
            [scheduledPlan, wavesHeight],
        );
        //simplify cases
        //const timelinePosition = timelineWidth <= wrapperWidth ? 'fixed' : 'moving';
        const timelinePosition = 'fixed';

        const phases = useMemo(() => getSessionPhases(score, variables, log), [score, variables, log]);
        const [phasesVisible, setPhasesVisible] = useState(false);

        const onWaveClick = useCallback(
            (wave: TimelineWaveProperties) => {
                if (!setWaveSelection) return;
                if (isWaveSelected(waveSelection, wave.wave.id)) {
                    setWaveSelection(selectNoWave());
                    UserEvents.closeWaveViaSparkline();
                } else {
                    setWaveSelection(selectWave(wave.wave));
                    UserEvents.openWaveViaSparkline();
                }
            },
            [waveSelection, setWaveSelection],
        );

        useEffect(() => {
            if (wrapperRef.current) {
                onScroll && wrapperRef.current.addEventListener('scroll', onScroll);

                return () => {
                    onScroll && wrapperRef?.current?.addEventListener('scroll', onScroll);
                };
            }
        }, [wrapperRef.current, onScroll]);

        const timelineStyles = useMemo(() => {
            return {
                width: timelineWidth,
                height: wavesHeight,
                borderBottom: '1px solid rgba(0,0,0, 0.02)',
            };
        }, [timelineWidth, wavesHeight]);

        const onMouseEnter = useCallback(() => setPhasesVisible(true), []);
        const onMouseLeave = useCallback(() => setPhasesVisible(false), []);

        return (
            <Container
                className="tour-sparkline"
                ref={(x) => {
                    wrapperRef.current = x ?? undefined;
                }}
                isScrollable={isScrollable}
            >
                <Overflow timelinePosition={timelinePosition} timelineHeight={timelineHeight}>
                    <TimelinePhases
                        timelineWidth={timelineWidth}
                        phases={phases}
                        phasesVisible={phasesVisible}
                        phasesAlwaysVisible={phasesAlwaysVisible}
                        isPlanner={isPlanner}
                    />
                    <TimelineWaves
                        timelineStyles={timelineStyles}
                        timelineWidth={timelineWidth}
                        timelineHeight={wavesHeight}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        waves={waves}
                        getWaveSparklineOpacity={getWaveSparklineOpacity(selectedWaveIndex, currentWaveIndex)}
                        onWaveClick={onWaveClick}
                        selectedWaveIndex={selectedWaveIndex}
                    />
                    {Playhead}
                    <TimelineCustomVoiceOvers score={score} />
                </Overflow>
            </Container>
        );
    },
);

const CustomVoiceOversContainer = styled.div`
    position: relative;
`;
function TimelineCustomVoiceOvers({ score }: { score: SessionScore }) {
    const voiceOverStages = score.voiceover || [];
    const selectionContext = useContext(VoiceOverStagesContext);
    const voiceOverStagesSorted = [...voiceOverStages].sort(
        (a, b) => (a.timing.from as number) - (b.timing.from as number),
    );
    const voiceOverStagesTrimmedForUX = voiceOverStagesSorted.map((item, sortedIndex) => {
        if (sortedIndex < voiceOverStagesSorted.length - 1) {
            const nextItem = voiceOverStagesSorted[sortedIndex + 1];
            if ((nextItem.timing.from as number) < (item.timing.to as number)) {
                const modifiedForUxItem: VoiceoverStage = {
                    ...item,
                    duration: (nextItem.timing.from as number) - (item.timing.from as number),
                    timing: {
                        ...item.timing,
                        to: nextItem.timing.from,
                    },
                };
                return modifiedForUxItem;
            }
        }
        return item;
    });
    return (
        <CustomVoiceOversContainer>
            {voiceOverStagesTrimmedForUX.map((item, displayIndex) => {
                const index = voiceOverStages.indexOf(item);
                return (
                    <div
                        key={index}
                        style={{
                            position: 'absolute',
                            left: Number(item.timing.from) * 1000 * PIXELS_PER_MILLIS,
                            width: Number(item.duration) * 1000 * PIXELS_PER_MILLIS,
                            height: '20px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            border: '1px solid purple',
                            borderRadius: '4px',
                            backgroundColor:
                                /*selectionContext &&
                                selectionContext.selectedIndex !== undefined &&
                                index === selectionContext.selectedIndex
                                    ? '#CBC3E3'
                                    :*/ 'transparent',
                            cursor: 'pointer',
                        }}
                        onClick={() => selectionContext && selectionContext.setSelectedIndex(index)}
                    >
                        {displayIndex + 1}
                    </div>
                );
            })}
        </CustomVoiceOversContainer>
    );
}

type TimelineWithPlayheadProps = Omit<TimelineProps, 'children' | 'Playhead'> & { elapsedTimeMs?: number };

function TimelineWithPlayhead({ elapsedTimeMs, waveSelection, variables, ...restProps }: TimelineWithPlayheadProps) {
    const timelineHeight = 36;
    const playheadRef = useRef<SVGSVGElement>(null);
    const isWaveSelected = isAnyWaveSelected(waveSelection);
    const isTrackingPlayhead = useRef(!isWaveSelected);
    const lastScrollTime = useRef<number>(0);

    useInterval(() => {
        const PLAYHEAD_LAST_SCROLL_WAIT = 2000;
        const scrollWaitExpired = Date.now() - (lastScrollTime.current ?? 0) > PLAYHEAD_LAST_SCROLL_WAIT;
        if (isTrackingPlayhead.current && scrollWaitExpired) {
            playheadRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }, 1000);

    useLayoutEffect(() => {
        if (isWaveSelected) {
            isTrackingPlayhead.current = false;
        } else {
            setTimeout(() => {
                isTrackingPlayhead.current = true;
                playheadRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }, 1000);
        }
    }, [isWaveSelected]);

    const handleScroll = () => {
        lastScrollTime.current = Date.now();
    };

    return (
        <_Timeline
            onScroll={handleScroll}
            variables={variables}
            waveSelection={waveSelection}
            {...restProps}
            Playhead={
                !!elapsedTimeMs ? (
                    <TimelinePlayhead
                        playheadRef={playheadRef}
                        key={'timeline_playhead'}
                        // TODO WHY not sessionDuration?
                        sessionDurationMs={Number(variables.totalDuration) * 60 * 1000}
                        elapsedTimeMs={elapsedTimeMs}
                        timelineHeight={timelineHeight}
                    />
                ) : null
            }
        />
    );
}

export const Timeline = React.memo(TimelineWithPlayhead);

function getCurrentWaveIndex(currentWave: Wavepath | null, scheduledPlan: ScheduledWavepath[]) {
    if (!currentWave || isPreludeWavepath(currentWave)) {
        return -1;
    } else if (isScheduledWavepath(currentWave)) {
        return scheduledPlan.indexOf(currentWave);
    } else {
        return Number.MAX_SAFE_INTEGER;
    }
}

function getSessionPhases(score: SessionScore, variables: SessionVariables, log: TimestampedSessionEvent[]) {
    const result: { startTime: number; duration: number; phase: string }[] = [];
    if (score.timingPlan) {
        const exprFn = makeScoreExpressionEvaluator(score, variables, log);
        let cumulativeTime = 0;
        for (const phase of score.timingPlan) {
            let phaseDuration = 0;
            if (phase.type === 'regular') {
                phaseDuration = exprFn.safe(phase.durationVariable) * 60 * 1000;
                result.push({
                    startTime: cumulativeTime,
                    duration: phaseDuration,
                    phase: phase.name,
                });
            }
            cumulativeTime += phaseDuration;
        }
        return result;
    } else {
        return [];
    }
}

_Timeline.displayName = 'Timeline';

const TimelineWaves = React.memo(
    ({
        timelineStyles,
        timelineWidth,
        timelineHeight,
        onMouseEnter,
        onMouseLeave,
        waves,
        getWaveSparklineOpacity,
        onWaveClick,
        selectedWaveIndex,
    }: {
        timelineStyles: React.CSSProperties | undefined;
        timelineWidth: number;
        timelineHeight: number;
        onMouseEnter: () => void;
        onMouseLeave: () => void;
        waves: {
            wave: ScheduledWavepath;
            previousWavePathScore: ScheduledPathScore;
            x: number;
            width: number;
            height: number;
        }[];
        getWaveSparklineOpacity: (idx: number) => 'full' | 'medium' | 'low';
        onWaveClick: (wave: TimelineWaveProperties) => void;
        selectedWaveIndex: number;
    }) => {
        return (
            <>
                {[
                    'timeline', // 'shadow' // TODO mirror
                ].map((i) => (
                    <SVG
                        key={i}
                        style={timelineStyles}
                        viewBox={`0 0 ${timelineWidth} ${timelineHeight}`}
                        preserveAspectRatio="none"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    >
                        {waves.map((wave, idx) => (
                            <TimelineWave
                                key={wave.wave.id}
                                wave={wave}
                                padding={0.1}
                                opacity={getWaveSparklineOpacity(idx)}
                                onClick={onWaveClick}
                                index={idx}
                                isSelected={selectedWaveIndex === idx}
                            />
                        ))}
                    </SVG>
                ))}
            </>
        );
    },
);

TimelineWaves.displayName = 'TimelineWaves';

const TimelinePhases = React.memo(
    ({
        timelineWidth,
        phases,
        phasesVisible,
        phasesAlwaysVisible,
        isPlanner,
    }: {
        timelineWidth: number;
        phases: {
            startTime: number;
            duration: number;
            phase: string;
        }[];
        phasesVisible: boolean;
        phasesAlwaysVisible?: boolean;
        isPlanner?: boolean;
    }) => {
        return (
            <Phases
                width={timelineWidth}
                style={{
                    zIndex: 1, // make sure phases are on top
                }}
            >
                {phases.map((phase, idx) => (
                    <TimelinePhase
                        position={idx % 2 === 0 ? 'top' : 'bottom'}
                        phasesVisible={phasesVisible || phasesAlwaysVisible}
                        key={'phaseSection' + idx}
                        phase={phase}
                        width={Math.round(phase.duration * PIXELS_PER_MILLIS) - PHASE_GAP}
                        state={'future'}
                        isPlanner={isPlanner}
                    />
                ))}
            </Phases>
        );
    },
);
TimelinePhases.displayName = 'Timeline Phases';
