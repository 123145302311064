import { inRange, isArray, isNumber, range, sortBy } from 'lodash';
import {
    Acousticness,
    CoreEmotionalAtmosphere,
    PathScoreModes,
    PathType,
    ScheduledExperimentalBridgePathScore,
    ScheduledPathScore,
    SessionScoreEmotionalIntensity,
} from 'wavepaths-shared/core';

import { ControlOption } from '@/component-library/components/Control/types';

export const AUTO_VALUE = 'Auto';

export const convertListToOptions = <T>(list: T[], set: Set<T>): ControlOption<T>[] => {
    return list.map((item) => ({ label: String(item), value: item, disabled: !set.has(item) }));
};

export const filterScoresByMode = <T extends ScheduledPathScore>(scores: T[], mode?: PathScoreModes): T[] =>
    scores.filter((score) => (mode && score.selectionCriteria ? score.mode === mode : true));

export const filterScoresByIntensity = <T extends ScheduledPathScore>(scores: T[], intensity?: string): T[] =>
    scores.filter((score) =>
        intensity && score.selectionCriteria ? score.selectionCriteria.emotionalIntensity === intensity : true,
    );

export const filterScoresByAmbience = (scores: ScheduledPathScore[], ambience?: string): ScheduledPathScore[] =>
    !ambience
        ? scores
        : scores.filter((score) => {
              if (!(score.type === PathType.GENERATIVE)) return false;
              if (!score.ambience) return ambience === 'None';
              return score.ambience === ambience;
          });

export const filterScoresByEmotion = <T extends ScheduledPathScore>(
    scores: T[],
    emotion?: CoreEmotionalAtmosphere | 'None',
): T[] =>
    scores.filter((score) => {
        if (!emotion) return true;

        if (emotion === 'None') {
            return !('emotion' in score);
        }

        if ('emotion' in score && typeof score.emotion === 'string') return score.emotion === emotion;
        return false;
    });

export const filterScoresByTone = <T extends ScheduledPathScore>(scores: T[], tone?: Acousticness): T[] =>
    scores.filter((score) =>
        tone && tone !== 'Mixed' && 'acousticness' in score ? score.acousticness === tone : true,
    );

export const filterScoresByMusic = <T extends ScheduledPathScore>(scores: T[], music?: string): T[] =>
    scores.filter((score) => {
        if (music) {
            return 'music' in score && score.music === music;
        } else {
            return !('music' in score) || !score.music || score.music === 'Any';
        }
    });

export const filterScoresByName = <T extends ScheduledPathScore>(scores: T[], name?: string): T[] =>
    scores.filter((score) => {
        return name ? score.name === name : true;
    });

export const filterScoresByDuration = (
    scores: ScheduledPathScore[],
    duration: number | undefined,
): ScheduledPathScore[] =>
    scores.filter((score) => {
        if (duration === undefined) return true;

        if (isArray(score.duration)) {
            // ie its generative
            if (isNumber(duration)) {
                return inRange(duration, score.duration[0], score.duration[1] + 1e-4);
            }
        } else {
            return Math.round(score.duration) === Math.round(Number(duration));
        }
    });

export const extractEmotionsFromScores = (scores: ScheduledPathScore[]): Set<CoreEmotionalAtmosphere> => {
    const emotions = new Set<CoreEmotionalAtmosphere>();
    for (const score of scores) {
        if ('emotion' in score) {
            if (typeof score.emotion === 'string') {
                emotions.add(score.emotion);
            }
        }
    }
    return emotions;
};

export const extractEmotionsFromScoresWithNone = (
    scores: ScheduledPathScore[],
): Set<CoreEmotionalAtmosphere | 'None'> => {
    const emotions = new Set<CoreEmotionalAtmosphere | 'None'>();
    for (const score of scores) {
        if ('emotion' in score) {
            if (typeof score.emotion === 'string') {
                emotions.add(score.emotion);
            }
        } else {
            emotions.add('None');
        }
    }
    return emotions;
};

export const extractModesFromScores = (scores: ScheduledPathScore[]): Set<PathScoreModes> => {
    const modes = new Set<PathScoreModes>();
    for (const score of scores) {
        if ('mode' in score) {
            if (typeof score.mode === 'string') {
                modes.add(score.mode);
            }
        }
    }
    return modes;
};

export const extractFromEmotionsFromScores = (scores: ScheduledExperimentalBridgePathScore[]) => {
    const emotions = new Set<CoreEmotionalAtmosphere>();
    for (const score of scores) {
        emotions.add(score.emotion.from);
    }
    return emotions;
};

export const extractToEmotionsFromScores = (scores: ScheduledExperimentalBridgePathScore[]) => {
    const emotions = new Set<CoreEmotionalAtmosphere>();
    for (const score of scores) {
        emotions.add(score.emotion.to);
    }
    return emotions;
};

export const extractIntensitiesFromScores = (
    scores: ScheduledPathScore[],
): Set<SessionScoreEmotionalIntensity | undefined> => {
    const intensities = new Set<SessionScoreEmotionalIntensity | undefined>();
    for (const score of scores) {
        intensities.add(score.selectionCriteria?.emotionalIntensity);
    }
    return intensities;
};

export const extractToneColoursFromScores = (scores: ScheduledPathScore[]): Set<Acousticness | undefined> => {
    const toneColours = new Set<Acousticness | undefined>();
    for (const score of scores) {
        toneColours.add('Mixed');
        'acousticness' in score && toneColours.add(score.acousticness);
    }
    return toneColours;
};

export const extractMusicOptionsFromScores = (scores: ScheduledPathScore[]): Set<string> => {
    const musics = new Set<string>();
    for (const score of scores) {
        'music' in score && score.music && musics.add(score.music);
    }
    return musics;
};

export const extractNameOptionsFromScores = (scores: ScheduledPathScore[]): string[] => {
    const names: string[] = [];
    for (const score of scores) {
        score.name && names.push(score.name);
    }
    return names;
};

export const extractDurationsFromScores = (scores: ScheduledPathScore[]): (number | 'Auto')[] => {
    const durations = new Set<number | 'Auto'>();
    for (const score of scores) {
        if ('duration' in score) {
            if (typeof score.duration === 'number') {
                durations.add(Math.round(score.duration));
            } else if (score.duration instanceof Array) {
                const min = score.duration[0];
                const max = score.duration[1];
                range(min, max + 1).forEach((d) => {
                    durations.add(d);
                });
                durations.add(AUTO_VALUE);
            }
        }
    }
    const sorted = sortBy(Array.from(durations), (value) => {
        return isNumber(value) ? value : undefined;
    });

    return [...sorted];
};

export const extractNatureSoundsFromScore = (scores: ScheduledPathScore[]): Set<string> => {
    const ambience = new Set<string>();
    for (const score of scores) {
        if ('ambience' in score) {
            if (typeof score.ambience === 'string') {
                ambience.add(score.ambience);
            }
        }
    }
    return ambience;
};
