import { useCallback } from 'react';
import { ActiveLayerDetails, LayerNumber, RequestType } from 'wavepaths-shared/core';

import { Connection, useCurrentMusicalContentStore } from '../../../common/hooks/useSessionTick';
import UserEvents from '../../../UserEvents';
import { Queueable } from '../actionQueue/useActionQueue';

export function useActiveLayers(
    connection: Connection,
    queueFunction: (queueable: Queueable) => void,
):
    | {
          activeLayers: ActiveLayerDetails[];
          refreshActiveLayers: (layerNumbers: LayerNumber[], queueFunction?: () => void) => void;
      }
    | 'loading' {
    const { musicalContent } = useCurrentMusicalContentStore();

    const _activeLayers = musicalContent?.activeLayers ?? [];
    const isLoading = !musicalContent;

    const refreshActiveLayers = useCallback(
        (layerNumbers: LayerNumber[], whenQueued?: () => void) => {
            const soundCount = layerNumbers.length;
            const soundNoun = layerNumbers.length > 1 ? 'sounds' : 'sound';
            queueFunction({
                description: `Replacing ${soundCount} ${soundNoun}`,
                callback: () => {
                    connection.sendRequest({
                        type: RequestType.RefreshLayers,
                        layerNumbers,
                    });
                    whenQueued && whenQueued();
                },
                onCancel: () => {
                    UserEvents.instrumentsRefreshCanceled(layerNumbers);
                },
                onSkipQueue: () => {
                    UserEvents.instrumentsRefreshSkippedQueue(layerNumbers);
                },
            });
            UserEvents.instrumentsRefreshed(
                _activeLayers.filter(({ layer }) => layerNumbers.includes(layer as LayerNumber)),
            );
        },
        [_activeLayers, connection],
    );

    if (isLoading) return 'loading';

    return {
        activeLayers: _activeLayers,
        refreshActiveLayers,
    };
}
