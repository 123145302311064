import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles(() => ({
    header: {
        width: 'min(100vw, 650px)',
        position: 'relative',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '1fr auto 1fr',
        gridTemplateAreas: '"headerLeft headerCenter headerRight"',
        gap: '12px',
        alignItems: 'center',
        padding: 'min(16px, 1vh)',
    },
    left: {
        gridArea: 'headerLeft',
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'start',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    center: {
        gridArea: 'headerCenter',
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'center',
    },
    right: {
        gridArea: 'headerRight',
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'end',
    },
}));

interface HeaderProps {
    className?: string;
    left?: React.ReactNode;
    center?: React.ReactNode;
    right?: React.ReactNode;
}
export const Header: React.FC<HeaderProps> = ({ className, left, center, right }) => {
    const classes = useStyles();
    return (
        <header className={classNames(classes.header, className)}>
            <div className={classes.left}>{left}</div>
            <div className={classes.center}>{center}</div>
            <div className={classes.right}>{right}</div>
        </header>
    );
};
