import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { format } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { Session } from 'wavepaths-shared/core';

import { Typography } from '@/component-library';

import {
    getEmotionalIntensity,
    getEnumValue,
    getMedicine,
    getScheduledEnd,
    getScheduledStart,
} from '../../common/domain/session';
import { useRemoteSessionDurations } from '../../common/hooks/useSessionTick';
import { formatDate, formatDuration } from '../../dateUtilsV2';
import { SessionName } from './SessionName';

const useStyles = makeStyles({
    root: {
        paddingBottom: 16,
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        overflow: 'hidden',
    },
    sessionName: {
        overflow: 'hidden',
    },
    dateContainer: {
        paddingTop: 24,
        paddingBottom: 24,
    },
    infoContainer: {
        margin: 0,
        paddingBottom: 24,
        display: 'flex',
        flexWrap: 'wrap',
    },
    infoLabel: {
        width: '37%',
        textAlign: 'right',
    },
    infoValue: {
        width: '63%',
        marginLeft: 'auto',
        paddingLeft: 20,
    },
});

interface SessionInfoProps {
    session: Session;
    closeDialog: () => void;
}
export const SessionInfo: React.FC<SessionInfoProps> = ({ session, closeDialog }) => {
    const { sessionDuration } = useRemoteSessionDurations();
    const classes = useStyles();

    const scheduledStart = getScheduledStart(session);
    const medicine = getMedicine(session);
    const emotionalIntensity = getEmotionalIntensity(session);
    const scheduledEnd = getScheduledEnd(session, sessionDuration);

    const sessionUse = getEnumValue(session, 'sessionUse');
    const administration = getEnumValue(session, 'administration');
    const dosage = getEnumValue(session, 'dosage');

    return (
        <div className={classes.root}>
            <div className={classes.headerContainer}>
                <div className={classes.sessionName}>{session && <SessionName session={session} variant="h6" />}</div>
                <IconButton style={{ padding: 0 }} onClick={closeDialog}>
                    <CloseIcon />
                </IconButton>
            </div>

            <div className={classes.dateContainer}>
                {scheduledStart && (
                    <Typography variant="body2">
                        {_.capitalize(formatDate(scheduledStart))} {format(scheduledStart, 'HH:mm')} -{' '}
                        {format(scheduledEnd, 'HH:mm')} ({formatDuration(sessionDuration ?? 0)})
                    </Typography>
                )}
            </div>
            <dl className={classes.infoContainer}>
                {sessionUse && (
                    <>
                        <dt className={classes.infoLabel}>
                            <Typography variant="body2">Session use:</Typography>
                        </dt>
                        <dd className={classes.infoValue}>
                            <Typography variant="body2">{sessionUse}</Typography>
                        </dd>
                    </>
                )}
                {medicine && (
                    <>
                        <dt className={classes.infoLabel}>
                            <Typography variant="body2">Medicine:</Typography>
                        </dt>
                        <dd className={classes.infoValue}>
                            <Typography variant="body2">{medicine}</Typography>
                        </dd>
                    </>
                )}
                {administration && (
                    <>
                        <dt className={classes.infoLabel}>
                            <Typography variant="body2">Administration:</Typography>
                        </dt>
                        <dd className={classes.infoValue}>
                            <Typography variant="body2">{administration}</Typography>
                        </dd>
                    </>
                )}
                {dosage && (
                    <>
                        <dt className={classes.infoLabel}>
                            <Typography variant="body2">Dosage:</Typography>
                        </dt>
                        <dd className={classes.infoValue}>
                            <Typography variant="body2">{dosage}</Typography>
                        </dd>
                    </>
                )}
                {emotionalIntensity && (
                    <>
                        <dt className={classes.infoLabel}>
                            <Typography variant="body2">Emotional intensity:</Typography>
                        </dt>
                        <dd className={classes.infoValue}>
                            <Typography variant="body2">{emotionalIntensity}</Typography>
                        </dd>
                    </>
                )}
            </dl>
        </div>
    );
};
